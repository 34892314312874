import React from 'react';

import Icon from '../Icon/Icon';

const IconIndicator = (props) => {
    return (
        <Icon width='12' height='12' viewBox='0 0 12 12' fill='none' {...props}>
            <path
                d='M7.15026 1.92578H9.5559C10.1199 1.92578 10.5772 2.38301 10.5772 2.94704V9.75545C10.5772 10.3195 10.1199 10.7767 9.5559 10.7767H2.02126C1.45723 10.7767 1 10.3195 1 9.75545V2.94704C1 2.38301 1.45723 1.92578 2.02126 1.92578H4.4269'
                stroke='#3B3B3B'
                strokeMiterlimit='10'
            />
            <path
                d='M7.50204 8.05313V4.64893M5.78859 8.05313V4.64893M4.07514 8.05313V4.64893M2.36169 4.64893H9.21549V8.05313H2.36169V4.64893Z'
                stroke='#3B3B3B'
                strokeWidth='0.7'
                strokeMiterlimit='10'
            />
            <path
                d='M6.75137 2.88859C7.28313 2.35681 7.28312 1.49464 6.75134 0.962877C6.21956 0.431116 5.35739 0.431131 4.82563 0.96291C4.29387 1.49469 4.29389 2.35686 4.82566 2.88862C5.35744 3.42038 6.21961 3.42036 6.75137 2.88859Z'
                stroke='#3B3B3B'
                strokeMiterlimit='10'
            />
        </Icon>
    );
};

export default IconIndicator;
