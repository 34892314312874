import React from 'react';

import Icon from '../Icon/Icon';

const IconPlus = (props) => {
    return (
        <Icon width='12' height='12' viewBox='0 0 12 12' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.49995 2.00006C6.49995 1.72392 6.27609 1.50006 5.99995 1.50006C5.72381 1.50006 5.49995 1.72392 5.49995 2.00006V5.50004H2C1.72386 5.50004 1.5 5.72389 1.5 6.00004C1.5 6.27618 1.72386 6.50004 2 6.50004H5.49995V10.0001C5.49995 10.2762 5.72381 10.5001 5.99995 10.5001C6.27609 10.5001 6.49995 10.2762 6.49995 10.0001V6.50004H10C10.2761 6.50004 10.5 6.27618 10.5 6.00004C10.5 5.72389 10.2761 5.50004 10 5.50004H6.49995V2.00006Z'
                fill='white'
            />
        </Icon>
    );
};

export default IconPlus;
