export const SHOW_DRAWER = 'app/drawer/SHOW_DRAWER';
export const HIDE_DRAWER = 'app/drawer/HIDE_DRAWER';

const initialState = {
    isVisible: false,
    contentType: null,
};

export default function reducer(state = {...initialState}, action) {
    switch (action.type) {
        case SHOW_DRAWER:
            return {
                ...state,
                isVisible: true,
                contentType: action.payload,
            };
        case HIDE_DRAWER:
            return {
                ...state,
                isVisible: false,
                contentType: null,
            };
        default:
            return state;
    }
}
export const showDrawer = (contentType) => {
    return {
        type: SHOW_DRAWER,
        payload: contentType,
    };
};

export const hideDrawer = () => {
    return {type: HIDE_DRAWER};
};
