import React from 'react';
import {useSelector} from 'react-redux';

import * as localizedKeys from '../../consts/localization/localizationKeys';
import * as menuTypes from '../../consts/menu/menuTypes';
import * as routePaths from '../../consts/route/routePaths';
import {ADMIN, MANAGER, SUPER_ADMIN} from '../../consts/user/userRolesId';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {makeSelectCurrentUserInfo} from '../../state/selectors/currentUser';
import IconModem from '../Icons/IconModem';
import IconReport from '../Icons/IconReport';
import {
    IconContourBuilding,
    IconContourLocation,
    IconEye,
    IconSettingsFilled,
    IconStar,
    IconUsers,
} from '../Icons/index';

const getMenuItems = () => {
    const localizedStrings = getLocalizedStrings();
    const currentUserInfo = useSelector(makeSelectCurrentUserInfo());

    const {roleId} = currentUserInfo || {};

    const isAdmin = roleId === ADMIN;
    const isManager = roleId === MANAGER;
    const isSuperAdmin = roleId === SUPER_ADMIN;

    const isImportantRole = isAdmin || isManager || isSuperAdmin;

    const menuItems = [
        {
            type: menuTypes.MAP,
            value: localizedStrings[localizedKeys.MENU_ITEM_MAP],
            href: routePaths.MAP_PAGE,
        },
    ];

    if (!roleId) return menuItems;

    isImportantRole
        ? menuItems.push(
              {
                  type: menuTypes.OBJECTS,
                  value: localizedStrings[localizedKeys.MENU_ITEM_OBJECTS],
                  href: routePaths.OBJECTS_PAGE,
              },
              {
                  type: menuTypes.DATA_COLLECTORS,
                  value: localizedStrings[localizedKeys.MENU_ITEM_MODEMS],
                  href: routePaths.DATA_COLLECTORS_PAGE,
              },
              {
                  type: menuTypes.USERS,
                  value: localizedStrings[localizedKeys.MENU_ITEM_USERS],
                  href: routePaths.USERS_PAGE,
              },
              {
                  type: menuTypes.REPORTS,
                  value: localizedStrings[localizedKeys.MENU_ITEM_REPORTS],
                  href: routePaths.REPORTS_PAGE,
              },
              {
                  type: menuTypes.SERVICES,
                  value: localizedStrings[localizedKeys.MENU_ITEM_MODULES],
                  href: routePaths.SERVICES_PAGE,
              },
              {
                  type: menuTypes.SETTINGS,
                  value: localizedStrings[localizedKeys.MENU_ITEM_SETTINGS],
                  href: routePaths.SETTINGS_PAGE,
              }
          )
        : menuItems.push({
              type: menuTypes.USER_OBJECTS,
              value: localizedStrings[localizedKeys.MENU_ITEM_USER_OBJECTS],
              href: routePaths.ROOMER_OBJECTS_PAGE,
          });

    return menuItems;
};

const getMenuIcons = (type, isActive) => {
    const iconTypes = {
        [menuTypes.MAP]: <IconContourLocation isWhite={isActive} />,
        [menuTypes.USER_OBJECTS]: <IconContourBuilding isWhite={isActive} />,
        [menuTypes.OBJECTS]: <IconContourBuilding isWhite={isActive} />,
        [menuTypes.SERVICES]: <IconStar isWhite={isActive} />,
        [menuTypes.USERS]: <IconUsers isWhite={isActive} />,
        [menuTypes.REPORTS]: <IconReport isWhite={isActive} />,
        [menuTypes.SETTINGS]: <IconSettingsFilled isWhite={isActive} />,
        [menuTypes.MONITORING]: <IconEye isWhite={isActive} />,
        [menuTypes.DATA_COLLECTORS]: <IconModem isWhite={isActive} />,
    };

    return iconTypes[type];
};

export default {
    getMenuItems,
    getMenuIcons,
};
