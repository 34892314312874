export const registerRequestDataMapping = (data) => {
    const mappedData = {
        Name: data?.name,
        Surname: data?.surname,
        PhoneNumber: data?.phone,
        Email: data?.email,
        Password: data?.password,
        RoomId: data?.apartment?.id,
        CompanyId: data?.company?.id,
        BuildingId: data?.building?.id,
    };

    return JSON.stringify(mappedData);
};

export const loginRequestDataMapping = (data) => {
    const mappedData = {
        Login: data?.login,
        Password: data?.password,
        RefreshToken: data?.refreshToken,
    };

    return JSON.stringify(mappedData);
};

export const authMapping = (responseData) => {
    const RESPONSE_DELAY_MS = 10 * 1000;
    const expirationDate = Date.now() + new Date(responseData.expiration).getTime() - RESPONSE_DELAY_MS;

    return {
        accessToken: responseData.token,
        refreshToken: responseData?.refreshToken,
        refreshTokenExpiration: responseData?.refreshTokenExpiration,
        expiration: expirationDate,
    };
};

export const passRestoreVerificationMapping = (data) => {
    const mappedData = {
        Email: data?.email,
        VerificationCode: data?.verificationCode,
        Password: data?.password,
        ConfirmPassword: data?.confirmPassword,
    };

    return JSON.stringify(mappedData);
};

export const changePasswordMapping = (data) => {
    const mappedData = {
        OldPassword: data?.oldPassword,
        Password: data?.password,
        ConfirmPassword: data?.confirmPassword,
    };

    return JSON.stringify(mappedData);
};
