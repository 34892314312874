import * as authScreenNames from '../../consts/auth/authScreenNames';
import {logoutClearData} from '../../state/ducks/global';
import {dispatch} from '../../state/store';
import storageService from '../storage/storageService';
import appRouterService from './appRouterService';

const initializeUserSessionActions = () => {};

const initializeStartActions = () => {};

const onAppInit = () => {};

const getAppBaseUrl = () => {};

const logout = () => {
    const rememberMe = storageService.getRememberMeFromStorage();

    if (rememberMe) {
        storageService.removeAccessTokenFromStorage();
        storageService.removeAccessTokenExpirationFromStorage();
        storageService.removeRefreshTokenFromStorage();
        storageService.removeDemoViewFromStorage();
    } else {
        storageService.clearLocalStorage();
    }

    dispatch(logoutClearData());

    appRouterService.forwardToAuthPage(authScreenNames.LOGIN);
};

export default {
    logout,
    getAppBaseUrl,
    initializeStartActions,
    onAppInit,
    initializeUserSessionActions,
};
