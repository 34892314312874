import * as apiMethods from '../../consts/api/apiMethods';
import * as requestParamTypes from '../../consts/app/requestParamTypes';
import {setUserResponsibilities, setUserResponsibilitiesData} from '../../state/ducks/users';
import {makeSelectRequestParams} from '../../state/selectors/requestParams';
import {getState} from '../../state/store';
import ApiClient from '../api/clients/apiClient';
import log from '../logger/log';
import {
    addResponsibilityRequestPostMapping,
    editResponsibilityRequestPostMapping,
    responsibilitiesListRequestDataMapping,
} from '../mappings/responsibilitiesMapping';

const getUserResponsibilitiesById = async ({userID, onRequestSuccess}) => {
    try {
        return await new ApiClient().callPost({
            methodName: apiMethods.USERS_GET_RESPONSIBILITIES_LIST_BY_ID,
            // mapper: ,
            action: setUserResponsibilities,
            requestConfig: {
                args: userID,
                data: {
                    page: 1,
                    // size: 10,
                    query: '',
                    sortBy: 'UpdateTs',
                    direction: 1,
                    filters: {},
                },
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getUserResponsibilitiesById: ${e}`);
    }
};

const getUserResponsibilitiesListById = async ({userID, onRequestSuccess}) => {
    //  const requestParams = useSelector(makeSelectRequestParams(RESPONSIBILITIES));
    // const userResponsibilitiesData = useSelector(makeSelectUserResponsibilitiesData());
    const requestParams = makeSelectRequestParams(requestParamTypes.RESPONSIBILITIES)(getState());

    try {
        return await new ApiClient().callPost({
            methodName: apiMethods.USERS_GET_RESPONSIBILITIES_LIST_BY_ID,
            // mapper: ,
            action: setUserResponsibilitiesData,
            mapper: responsibilitiesListRequestDataMapping,

            requestConfig: {
                args: userID,
                data: requestParams,
                // {
                //     page: 1,
                //     // size: 10,
                //     query: '',
                //     sortBy: 'UpdateTs',
                //     direction: 1,
                //     filters: {},
                // },
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getUserResponsibilitiesById: ${e}`);
    }
};
const addUserResponsibilitiesByOrganization = async ({data, onRequestSuccess}) => {};

const addNewResponsibility = async ({data, onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.USERS_ADD_USER_RESPONSIBILITIES_BY_COMPANY_ID,
            requestConfig: {
                data: addResponsibilityRequestPostMapping(data),
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`addNewResponsibility: ${e}`);
    }
};

const addAllBuildingResponsibilitiesByCompanyId = async ({companyId, userId, onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.USER_ADD_ALL_BUILDINGS_RESPONSIBILITIES_BY_COMPANY_ID,
            requestConfig: {
                data: {
                    companyId,
                    userId,
                },
            },

            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`addAllBuildingResponsibilitiesByCompanyId: ${e}`);
    }
};

const editUserResponsibility = async ({data, onRequestSuccess}) => {
    try {
        await new ApiClient().callPut({
            methodName: apiMethods.USERS_EDIT_USER_RESPONSIBILITY,
            requestConfig: {
                data: editResponsibilityRequestPostMapping(data),
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`editUser: ${e}`);
    }
};

const deleteUsersResponsibility = async ({responsibilityID, userId, onRequestSuccess}) => {
    await new ApiClient().callDelete({
        methodName: apiMethods.USERS_DELETE_USER_RESPONSIBILITIES_BY_ID,
        requestConfig: {
            data: {
                responsibilityID,
                userID: userId,
            },
        },
        onResponse: onRequestSuccess,
    });
};

export default {
    addNewResponsibility,
    addUserResponsibilitiesByOrganization,
    getUserResponsibilitiesById,
    editUserResponsibility,
    deleteUsersResponsibility,
    addAllBuildingResponsibilitiesByCompanyId,
    getUserResponsibilitiesListById,
};
