import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Router} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';

import AppLoader from '../../components/AppLoader/AppLoader';
import {initConfig} from '../../config/config';
import history from '../../services/history';
import {initLocalizations} from '../../services/localization/localizationService';
import {setIsAppLoaded} from '../../state/ducks/global';
import {makeIsAppLoading} from '../../state/selectors/global';
import AppRoutes from '../AppRoutes/AppRoutes';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const mapStateToProps = createStructuredSelector({
    isAppLoading: makeIsAppLoading(),
});

const App = (props) => {
    const {isAppLoading, dispatch} = props;
    const browserHistory = history.getHistory();

    const initApp = async () => {
        await initConfig();
        await initLocalizations();

        dispatch(setIsAppLoaded());
    };

    useEffect(() => {
        initApp();
    }, []);

    return (
        <ErrorBoundary>
            {isAppLoading ? (
                <AppLoader />
            ) : (
                <Router history={browserHistory}>
                    <AppRoutes />
                </Router>
            )}
        </ErrorBoundary>
    );
};

export default connect(mapStateToProps)(App);
