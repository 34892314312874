import configAppGetters from './configGetters/configAppGetters';
import configDataCollectionGetters from './configGetters/configDataCollectionGetters';
import configDateGetters from './configGetters/configDateGetters';
import configDurationGetters from './configGetters/configDurationGetters';
import configImageGetters from './configGetters/configImageGetters';
import configLogsGetters from './configGetters/configLogsGetters';
import configUploadGetters from './configGetters/configUploadGetters';

export default {
    ...configAppGetters,
    ...configDurationGetters,
    ...configLogsGetters,
    ...configUploadGetters,
    ...configDateGetters,
    ...configImageGetters,
    ...configDataCollectionGetters,
};
