const SET_DATA_COLLECTORS = 'app/data-collectors/SET_DATA_COLLECTORS';
const SET_CURRENT_DATA_COLLECTOR = 'app/data-collectors/SET_CURRENT_DATA_COLLECTOR';
const UPDATE_DATA_COLLECTOR = 'app/data-collectors/UPDATE_DATA_COLLECTOR';
const CLEAN_DATA_COLLECTOR_DATA = 'app/data-collectors/CLEAN_DATA_COLLECTOR_DATA';

const initialState = {
    currentDataCollector: null,
    dataCollectorsData: null,
};

export default function reducer(state = {...initialState}, action) {
    switch (action.type) {
        case SET_CURRENT_DATA_COLLECTOR: {
            const payload = action.payload || {};

            return {...state, currentDataCollector: payload};
        }
        case SET_DATA_COLLECTORS: {
            const payload = action.payload || {};

            return {...state, dataCollectorsData: payload};
        }
        case UPDATE_DATA_COLLECTOR: {
            const {uspdId, status} = action.payload || {};
            const items = state.dataCollectorsData.items;

            return {
                ...state,
                dataCollectorsData: {
                    ...state.dataCollectorsData,
                    items: items.map((item) => {
                        if (item?.id === uspdId) {
                            return {...item, status};
                        } else {
                            return item;
                        }
                    }),
                },
            };
        }
        case CLEAN_DATA_COLLECTOR_DATA: {
            return {...state, ...initialState};
        }
        default:
            return state;
    }
}

export const setDataCollectors = (payload) => ({
    type: SET_DATA_COLLECTORS,
    payload,
});
export const setCurrentDataCollector = (payload) => ({
    type: SET_CURRENT_DATA_COLLECTOR,
    payload,
});
export const updateDataCollector = (dataCollector) => ({
    type: UPDATE_DATA_COLLECTOR,
    payload: dataCollector,
});
export const cleanDataCollectorData = () => ({
    type: CLEAN_DATA_COLLECTOR_DATA,
});
