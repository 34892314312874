import React from 'react';

import Icon from '../Icon/Icon';

const IconTrashCan = (props) => {
    return (
        <Icon width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
            <path
                d='M12.4164 5.72294C12.97 4.98479 12.4433 3.93141 11.5206 3.93141H4.20518C3.28249 3.93141 2.7558 4.98479 3.30941 5.72294V5.72294C3.7132 6.26132 3.93147 6.91615 3.93147 7.58912V11.7942C3.93147 13.2418 5.10491 14.4152 6.55241 14.4152H9.17336C10.6209 14.4152 11.7943 13.2418 11.7943 11.7942V7.58912C11.7943 6.91615 12.0126 6.26132 12.4164 5.72294V5.72294Z'
                stroke='#3B3B3B'
                strokeWidth='0.982854'
            />
            <path
                d='M6.55246 11.139L6.55246 7.20753'
                stroke='#3B3B3B'
                strokeWidth='0.982854'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.17328 11.139L9.17328 7.20753'
                stroke='#3B3B3B'
                strokeWidth='0.982854'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.242 3.93142L5.59855 2.86178C5.77692 2.32666 6.2777 1.96571 6.84177 1.96571H8.88412C9.44818 1.96571 9.94897 2.32666 10.1273 2.86178L10.4839 3.93142'
                stroke='#3B3B3B'
                strokeWidth='0.982854'
                strokeLinecap='round'
            />
        </Icon>
    );
};

export default IconTrashCan;
