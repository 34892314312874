import cn from 'classnames';
import React from 'react';

import Link from '../Link/Link';
import menuService from './menuService';

const MenuItem = ({menuType, value, isActive, href, onClick}) => {
    const icon = menuService.getMenuIcons(menuType, isActive);

    const containerClasses = cn('h-[66px] w-full flex flex-col justify-center items-center hover:cursor-pointer', {
        'bg-transparent-white before:block before:absolute before:left-0 before:h-[66px] before:w-[3px] before:bg-white':
            isActive,
    });

    const valueClasses = cn('text-11 font-semibold leading-12', isActive ? 'text-white' : 'text-santa');

    return (
        <Link to={href} className={containerClasses} onClick={onClick}>
            <span className='mb-[3px]'>{icon}</span>
            <div className={valueClasses}>{value}</div>
        </Link>
    );
};

export default MenuItem;
