import {InfoCircleOutlined} from '@ant-design/icons';
import {Col, Popover, Row} from 'antd';
import React from 'react';

import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import stylesService from '../../../services/styles/stylesService';
import DataCollectorInfoPopover from '../../DataCollectorInfoPopover/DataCollectorInfoPopover';
import Divider from '../../Divider/Divider';
import StatusBadge from '../../StatusBadge/StatusBadge';

const DrawerSectionDataCollector = ({uspds}) => {
    const localizedStrings = getLocalizedStrings();
    const unknownText = 'Невідомо';

    return (
        <section className={'py-5'}>
            <div className={'mb-4 flex flex-row justify-center'}>
                <div className={'mr-1 font-medium text-zambezi'}>{localizedStrings[localizationKeys.DCTD_TEXT]}</div>
                <Popover overlayClassName={'max-w-[250px] w-auto'} content={<DataCollectorInfoPopover />}>
                    <InfoCircleOutlined style={{color: 'var(--color-zambezi)'}} />
                </Popover>
            </div>
            {uspds.map((uspd) => {
                const deviceNumber = uspd?.deviceNumber || unknownText;
                const simNumber = uspd?.simNumber || unknownText;
                const ipAddress = uspd?.ipAddress || unknownText;

                return (
                    <Row gutter={2} align='middle' justify='space-between' wrap={false} key={uspd?.id}>
                        <Col>
                            <Row justify={'start'}>
                                <StatusBadge
                                    statusColor={`inline w-auto !mr-1 ${
                                        stylesService.statusColorClassNames[uspd?.transferStatus]
                                    }`}
                                />
                                <span className={'text-11 font-semibold'}>{deviceNumber}</span>
                            </Row>
                        </Col>
                        <Divider vertical />
                        <Col align={'center'} span={8}>
                            <Row justify={'center'}>
                                <p className={'text-11'}>
                                    {localizedStrings[localizationKeys.IP_TEXT]}: {ipAddress}
                                </p>
                            </Row>
                        </Col>
                        <Divider vertical />
                        <Col align={'center'} span={7}>
                            <Row justify={'end'}>
                                <p className={'text-11'}>{simNumber}</p>
                            </Row>
                        </Col>
                    </Row>
                );
            })}
        </section>
    );
};

export default DrawerSectionDataCollector;
