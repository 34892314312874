import {Result} from 'antd';
import React, {Component} from 'react';

import log from '../../services/logger/log';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        if (error) {
            log.fatal(`ErrorBoundary: error: ${error}`);
        } else {
            log.error(`ErrorBoundary: empty error`);
        }
        log.info(JSON.stringify(errorInfo));
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={'flex justify-center items-center h-full'}>
                    <Result
                        status='error'
                        title='Здається, щось пішло не так...'
                        subTitle='Внутрішня помилка додатку'
                    />
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
