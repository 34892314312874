import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';

import Form from '../../../../../components/Form/Form';
import log from '../../../../../services/logger/log';
import helpers from '../../../../../utils/helpers';
import roomProfileModalService from './roomProfileModalService';

const RoomProfileModal = (props) => {
    const {onCancel, onOk} = props || {};
    const [form] = AntForm.useForm();

    const {formFields, title, submitButton, cancelButton, initialValues} = roomProfileModalService.getFormContent();

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }

        return () => {
            form.resetFields();
        };
    }, []);

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            helpers.runFunction(onOk, values);
        } catch (error) {
            log.info('Validate Failed:', error);
        }
    };

    const handleRadioChange = (value, name) => setFormField(name, value);

    const setFormField = (name, key, value) => form.setFieldsValue({[name]: {id: key, value}});

    const handleSelect = async (value, name, option) => {
        const currentValue = form.getFieldValue(name);

        if (currentValue?.value === option.value) return;

        setFormField(name, option.key, option.value);
    };

    return (
        <Form
            handleRadioChange={handleRadioChange}
            handleSelect={handleSelect}
            closeModal={onCancel}
            initialValues={initialValues}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formFields}
            cancelButton={cancelButton}
            formInstance={form}
        />
    );
};

export default React.memo(RoomProfileModal);
