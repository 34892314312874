enum DeviceTypes {
    Other             = 1,
    Oil               = 2,
    Electricity       = 3,
    Gas               = 4,
    Heat              = 5,
    Cooling           = 6,
    HeatAndCooling    = 7,
    Steam             = 8,
    HotWater          = 9,
    ColdWater         = 10,
    Water             = 11,
    HeatCostAllocator = 12,
    Unknown           = 13,
    CompressedAir     = 14,
    Pressure          = 15,
    ADConverter       = 16,
    SmokeDetector     = 17,
    RoomSensor        = 18,
    GasDetector       = 19,
    CircuitBreaker    = 20,
    Ventil            = 21,
    Display           = 22,
    Sewage            = 23,
    Waste             = 24,
    CarbonDioxide     = 25,
}

export default DeviceTypes;
