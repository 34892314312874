const companyTypesMapper = (object) =>
    Object.keys(object).map((key) => ({
        key: Number(key),
        value: object[key],
        name: object[key],
    }));

export const organizationListRequestDataMapping = (data) => {
    const addObjectKeyFromId = (item) => ({...item, key: item?.id});

    const mappedData = {
        filters: {
            status: data?.filters?.Status,
            companyTypeId: data?.filters?.CompanyTypeId,
        },
        hasNextPage: data?.hasNextPage,
        hasPreviousPage: data?.hasPreviousPage,
        items: [...data.items].map((item) => addObjectKeyFromId(item)),
        itemsInResponse: data?.itemsInResponse,
        page: data?.page,
        pages: data?.pages,
        size: data?.size,
        sortableColumns: data?.sortableColumns,
        totalFilteredRecords: data?.totalFilteredRecords,
        totalRecords: data?.totalRecords,
        companyTypesArray: companyTypesMapper(data.filters.CompanyTypeId),
    };

    return mappedData;
};

export const organizationNamesRequestDataMapping = (data) => {
    const addObjectNameFromValue = (item) => ({...item, name: item?.value, label: item?.value});

    const mappedData = {
        items: [...data.items].map((item) => addObjectNameFromValue(item)),
    };

    return mappedData;
};
