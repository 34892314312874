export const USERS = 'USERS';
export const ORGANIZATION = 'ORGANIZATION';
export const BUILDINGS = 'BUILDINGS';
export const REPORTS = 'REPORTS';
export const DATA_COLLECTORS = 'DATA_COLLECTORS';
export const ROOMS = 'ROOMS';
export const DIAGNOSTIC = 'DIAGNOSTIC';
export const MANUFACTURERS = 'MANUFACTURERS';
export const RESPONSIBILITIES = 'RESPONSIBILITIES';
export const ARS_ORGANIZATIONS = 'ARS_ORGANIZATIONS';
