import * as modalTypes from '../../consts/modals/modalTypes';

const SHOW_MODAL = 'app/modal/SHOW_MODAL';
const HIDE_MODAL = 'app/modal/HIDE_MODAL';
const CLEAR_MODALS = 'app/modal/CLEAR_MODALS';

const initialState = {
    globalModals: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_MODALS: {
            return {
                ...state,
                ...initialState,
            };
        }
        case SHOW_MODAL: {
            const {modalType, modalProps} = action.payload;

            if (state.globalModals.some((modal) => modal.modalType === modalType)) {
                return state;
            } else {
                return {
                    ...state,
                    globalModals: [
                        ...state.globalModals,
                        {
                            modalType,
                            modalProps,
                        },
                    ],
                };
            }
        }
        case HIDE_MODAL: {
            const {modalType} = action.payload;

            return {...state, globalModals: state.globalModals.filter((modal) => modal.modalType !== modalType)};
        }
        default:
            return state;
    }
};

export const showModalAction = ({modalType, modalProps}) => ({
    type: SHOW_MODAL,
    payload: {modalType, modalProps},
});

export const hideModalAction = (modalType) => ({
    type: HIDE_MODAL,
    payload: {modalType},
});

export const clearModalsAction = () => ({
    type: CLEAR_MODALS,
});
export const showAddDataCollectorModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.CREATE_DATA_COLLECTOR_MODAL, modalProps});

export const showAddDataCollectorToBuildingModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ADD_DATA_COLLECTOR_TO_BUILDING_MODAL, modalProps});

export const showAddUserObjectModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ADD_USER_OBJECTS_MODAL_TYPE, modalProps});

export const showEditUserObjectModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.EDIT_USER_OBJECTS_MODAL_TYPE, modalProps});

export const showAddUserModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ADD_USERS_MODAL_TYPE, modalProps});

export const showAddUserToBuildingModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ADD_USERS_TO_BUILDING_MODAL_TYPE, modalProps});

export const showEditUserModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.EDIT_USERS_MODAL_TYPE, modalProps});

export const showGenerateReportModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.REPORTS_MODAL, modalProps});

export const showAddOrganizationModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ORGANIZATION_CREATE_MODAL, modalProps});

export const showCopyOrganizationModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ORGANIZATION_COPY_MODAL, modalProps});

export const showEditOrganizationModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ORGANIZATION_EDIT_MODAL, modalProps});

export const showEditDataCollectorModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.EDIT_DATA_COLLECTOR_MODAL, modalProps});

export const showEditBuildingModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.EDIT_BUILDING_MODAL, modalProps});

export const showCreateBuildingModalAction = (modalProps) =>
    showModalAction({
        modalType: modalTypes.CREATE_BUILDING_MODAL,
        modalProps,
    });

export const showMapModalAction = (modalProps) =>
    showModalAction({
        modalType: modalTypes.MAP_MODAL_TYPE,
        modalProps,
    });

export const showCopyBuildingModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.COPY_BUILDING_MODAL, modalProps});

export const showAddCityModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.COMMON_SETTINGS_TAB_ADD_CITY, modalProps});

export const showAddRoomTypeModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.SETTING_ADD_ROOM_TYPE, modalProps});

export const showEditRoomTypeModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.SETTING_EDIT_ROOM_TYPE, modalProps});

export const showAddDCModelModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.SETTINGS_ADD_DC_MODEL_TYPE, modalProps});

export const showEditDCModelModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.SETTINGS_EDIT_DC_MODEL_TYPE, modalProps});

export const showAddDeviceGroupModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.SETTINGS_ADD_DEVICE_GROUPS, modalProps});

export const showAddDeviceModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ADD_DEVICE_MODAL_TYPE, modalProps});

export const showEditDeviceModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.EDIT_DEVICE_MODAL_TYPE, modalProps});

export const showEditDeviceModelModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.SETTINGS_EDIT_DEVICE_MODEL, modalProps});

export const showEditDeviceGroupModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.SETTINGS_EDIT_DEVICE_GROUPS, modalProps});

export const showAddDeviceModelModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.SETTINGS_ADD_DEVICE_MODEL, modalProps});

export const showAddUlcModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.SERVICES_ADD_ULC, modalProps});

export const showConfigureKyivenergoModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.SERVICES_CONFIGURE_KYIVENERGO, modalProps});

export const showConfigureLvkModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.SERVICES_CONFIGURE_LVK, modalProps});

export const showConfigureKvkModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.SERVICES_CONFIGURE_KVK, modalProps});

export const showEditBuildingProfileModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.BUILDING_PROFILE_MODAL_TYPE, modalProps});

export const showAddRoomToBuildingModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ADD_ROOM_TO_BUILDING, modalProps});

export const showEditRoomProfileModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ROOM_PROFILE_MODAL_TYPE, modalProps});

export const showConfirmModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.CONFIRM_MODAL_TYPE, modalProps});

export const showUploadFileModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.UPLOAD_FILE_MODAL_TYPE, modalProps});

export const showAddOrganizationToArsModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ADD_ARS_ORGANIZATION_MODAL_TYPE, modalProps});

export const showEditArsOrganizationModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.EDIT_ARS_ORGANIZATION_MODAL_TYPE, modalProps});

export const showAddArsBuildingsModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ADD_BUILDINGS_TO_ARS_MODAL_TYPE, modalProps});

export const showArsOrganizationInfoModalAction = (modalProps) =>
    showModalAction({modalType: modalTypes.ARS_STATISTIC_ORGANIZATION_MODAL_TYPE, modalProps});
