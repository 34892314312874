export const SET_ROOM_TYPES = 'app/settings/SET_ROOM_TYPES';
export const SET_DATA_COLLECTOR_MODELS = 'app/settings/SET_DATA_COLLECTOR_MODELS';
export const SET_ROLE_CLAIMS_BLOCKS = 'app/settings/SET_ROLE_CLAIMS_BLOCKS';
export const SET_DATA_COLLECTION_SETTINGS = 'app/settings/SET_DATA_COLLECTION_SETTINGS';
export const SET_DATA_COLLECTION_RESULTS = 'app/settings/SET_DATA_COLLECTION_RESULTS';
export const SET_MANUFACTURERS = 'app/settings/SET_MANUFACTURERS';
export const SET_DEVICE_GROUP_PROFILE = 'app/settings/SET_DEVICE_GROUP_PROFILE';
export const SET_DATA_COLLECTION_IN_PROGRESS = 'app/settings/SET_DATA_COLLECTION_IN_PROGRESS';

export default function reducer(
    state = {
        roomTypes: null,
        dcModels: null,
        users: {
            roleClaimsBlocks: null,
        },
        dataCollection: {
            inProgress: false,
            settings: null,
            collectionResults: null,
        },
        manufacturers: null,
        deviceGroupProfile: null,
    },
    action
) {
    switch (action.type) {
        case SET_DATA_COLLECTION_IN_PROGRESS: {
            return {
                ...state,
                dataCollection: {
                    ...state.dataCollection,
                    inProgress: action.payload,
                },
            };
        }
        case SET_DATA_COLLECTION_RESULTS: {
            const collectionResults = action.payload || {};

            return {
                ...state,
                dataCollection: {
                    ...state.dataCollection,
                    collectionResults,
                },
            };
        }
        case SET_DATA_COLLECTION_SETTINGS: {
            const settings = action.payload || {};

            return {
                ...state,
                dataCollection: {
                    ...state.dataCollection,
                    settings: {
                        ...state.dataCollection.settings,
                        ...settings,
                    },
                },
            };
        }
        case SET_ROLE_CLAIMS_BLOCKS: {
            const {roleClaimsBlocks = []} = action.payload || {};

            return {
                ...state,
                users: {
                    ...state.users,
                    roleClaimsBlocks,
                },
            };
        }
        case SET_ROOM_TYPES: {
            const {items} = action.payload || {};

            return {
                ...state,
                roomTypes: items,
            };
        }
        case SET_DATA_COLLECTOR_MODELS: {
            const {items} = action.payload || {};

            return {
                ...state,
                dcModels: items,
            };
        }
        case SET_MANUFACTURERS: {
            return {
                ...state,
                manufacturers: {
                    ...action.payload,
                },
            };
        }

        case SET_DEVICE_GROUP_PROFILE: {
            return {
                ...state,
                deviceGroupProfile: action.payload,
            };
        }

        default:
            return state;
    }
}

export const setRoomTypes = (payload) => ({
    type: SET_ROOM_TYPES,
    payload,
});

export const setDataCollectorModels = (payload) => ({
    type: SET_DATA_COLLECTOR_MODELS,
    payload,
});

export const setRoleClaimsBlocks = (payload) => ({
    type: SET_ROLE_CLAIMS_BLOCKS,
    payload,
});

export const setDataCollectionSettings = (payload) => ({
    type: SET_DATA_COLLECTION_SETTINGS,
    payload,
});

export const setDataCollectionResults = (payload) => ({
    type: SET_DATA_COLLECTION_RESULTS,
    payload,
});

export const setManufacturers = (payload) => ({
    type: SET_MANUFACTURERS,
    payload,
});

export const setDeviceGroupProfile = (payload) => ({
    type: SET_DEVICE_GROUP_PROFILE,
    payload,
});

export const setDataCollectionInProgress = (payload) => ({
    type: SET_DATA_COLLECTION_IN_PROGRESS,
    payload,
});
