import {Spin} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';

import {makeIsGlobalLoading} from '../../state/selectors/global';

const LoaderGlobal = () => {
    const isGlobalLoading = useSelector(makeIsGlobalLoading());

    if (!isGlobalLoading) return;

    return (
        <div className='bg-overlay fixed w-full h-full z-[9999]'>
            <Spin size='large' className='flex justify-center items-center w-full h-full' />
        </div>
    );
};

export default LoaderGlobal;
