const filtersMapping = (filters) => {
    if (!filters) return;
    const mappedFilters = {};
    const filtersKeys = Object.keys(filters);

    if (filtersKeys?.length) {
        filtersKeys.forEach((key) => {
            const camelCasedFilterKey = key.charAt(0).toLowerCase() + key.slice(1);

            mappedFilters[camelCasedFilterKey] = filters[key];
        });

        return mappedFilters;
    }

    return filters;
};

export const buildingsListRequestDataMapping = (data) => {
    const mappedData = {
        hasNextPage: data?.hasNextPage,
        hasPreviousPage: data?.hasPreviousPage,
        filters: filtersMapping(data?.filters),
        items: data?.items,
        itemsInResponse: data?.itemsInResponse,
        page: data?.page,
        pages: data?.pages,
        size: data?.size,
        sortableColumns: data?.sortableColumns,
        totalFilteredRecords: data?.totalFilteredRecords,
        totalRecords: data?.totalRecords,
    };

    return mappedData;
};

export const editBuildingRequestDataMapping = (data) => {
    const mappedData = {
        account: data?.account,
        address: data?.address,
        buildingId: data?.buildingId,
        heatSquare: data?.heatSquare ? data?.heatSquare : 0,
        latitude: data?.latitude,
        longitude: data?.longitude,
        name: data?.name,
        organizationID: data?.organizationID,
        year: data?.year ? data?.year : 0,
        consumer: data?.consumer,
    };

    return mappedData;
};

export const createBuildingRequestDataMapping = (data) => {
    const mappedData = {
        account: data?.account,
        address: data?.address,
        heatSquare: data?.heatSquare ? data?.heatSquare : 0,
        latitude: data?.latitude,
        longitude: data?.longitude,
        name: data?.name,
        organizationID: data?.organizationID,
        year: data?.year ? data?.year : 0,
        cityId: data?.cityID,
        consumer: data?.consumer,
    };

    return mappedData;
};

export const usersByBuildingIdRequestDataMapping = (data) => {
    const mappedData = {
        buildingId: data?.buildingId,
        hasNextPage: data?.hasNextPage,
        hasPreviousPage: data?.hasPreviousPage,
        filters: filtersMapping(data?.filters),
        items: data?.items,
        itemsInResponse: data?.itemsInResponse,
        page: data?.page,
        pages: data?.pages,
        size: data?.size,
        sortableColumns: data?.sortableColumns,
        totalFilteredRecords: data?.totalFilteredRecords,
        totalRecords: data?.totalRecords,
        direction: data?.direction,
        query: '',
    };

    return mappedData;
};
