import {REPORT_TYPE_BUILDING, REPORT_TYPE_CURRENT, REPORT_TYPE_GROUP, REPORT_TYPE_INDIVIDUAL} from '../localization/localizationKeys';

export const reportTypesLocalized = {1: REPORT_TYPE_CURRENT, 2: REPORT_TYPE_GROUP, 3: REPORT_TYPE_BUILDING, 4: REPORT_TYPE_INDIVIDUAL};

export enum reportTypes {
    Current = 1,
    Group,
    Building,
    Individual,
}
