import React from 'react';
import {useSelector} from 'react-redux';

import ChooseOnMapTooltip from '../../../../../components/Tooltips/ChooseOnMapTooltip';
import * as formInputNames from '../../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import * as modalTypes from '../../../../../consts/modals/modalTypes';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import organizationsService from '../../../../../services/organizations/organizationsService';
import {makeSelectCurrentBuilding} from '../../../../../state/selectors/buildings';
import {makeSelectCities, makeSelectOrganizationsNames} from '../../../../../state/selectors/organizations';
import helpers from '../../../../../utils/helpers';

const getFormContent = (modalType, form) => {
    const localizedStrings = getLocalizedStrings();
    const currentBuilding = useSelector(makeSelectCurrentBuilding());
    const isEdit = modalType === modalTypes.EDIT_BUILDING_MODAL;
    const isCopy = modalType === modalTypes.COPY_BUILDING_MODAL;

    const isInitialValues = isEdit || isCopy;

    const title =
        (isEdit && localizedStrings[localizationKeys.BUILDING_FORM_EDIT_TITLE]) ||
        (isCopy && localizedStrings[localizationKeys.BUILDING_FORM_COPY_TITLE]) ||
        localizedStrings[localizationKeys.BUILDING_FORM_ADD_TITLE];

    const submitButton =
        (isEdit && localizedStrings[localizationKeys.BUILDING_FORM_EDIT_SUBMIT_BTN]) ||
        (isCopy && localizedStrings[localizationKeys.BUILDING_FORM_COPY_SUBMIT_BTN]) ||
        localizedStrings[localizationKeys.BUILDING_FORM_ADD_SUBMIT_BTN];

    const cancelButton = localizedStrings[localizationKeys.BUILDING_FORM_CANCEL_BTN];

    const initialValues = isInitialValues && {
        ...currentBuilding,
        year: currentBuilding?.year || '',
        heatSquare: currentBuilding?.heatSquare || '',
        [formInputNames.ORGANIZATION_ID]: currentBuilding?.organizationID,
        [formInputNames.BUILDING_ID]: currentBuilding?.buildingID,
        [formInputNames.CONSUMER]: currentBuilding?.consumer,
    };

    const formFields = [
        {
            key: helpers.guid(),
            name: formInputNames.BUILDING_ID,
            hidden: true,
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.NAME,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_NAME],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_NAME],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.CITY_ID,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_CITY],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_CITY],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            fieldValue: 'key',
            requestOptions: {
                selectRequestData: makeSelectCities,
                getRequestData: () => organizationsService.getCities(),
            },
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.ADDRESS,
            itemDeps: formInputNames.CITY_ID,
            disabled: (_, cityID) => !cityID,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_ADDRESS],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_ADDRESS],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
            suffix: <ChooseOnMapTooltip modalType={modalType} form={form} />,
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            required: true,
            fieldLabel: 'value',
            fieldValue: 'key',
            name: formInputNames.ORGANIZATION_ID,
            itemDeps: formInputNames.CITY_ID,
            disabled: (_, cityId) => !cityId,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_COMPANY],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_COMPANY],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
            requestOptions: {
                selectRequestData: makeSelectOrganizationsNames,
                getRequestData: () => organizationsService.getOrganizationsNames(),
            },
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.LATITUDE,
            itemDeps: formInputNames.CITY_ID,
            disabled: (_, cityID) => !cityID,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_LATITUDE],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_LATITUDE],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.LONGITUDE,
            itemDeps: formInputNames.CITY_ID,
            disabled: (_, cityID) => !cityID,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_LONGITUDE],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_LONGITUDE],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.YEAR,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_YEAR],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_YEAR],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.HEAT_SQUARE,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_HEAT_SQUARE],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_HEAT_SQUARE],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.ACCOUNT,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_ACCOUNT],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_ACCOUNT],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.CONSUMER,
            maxLength: 100,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_CONSUMER],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_CONSUMER],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_CONSUMER],
        },
    ];

    return {formFields, title, submitButton, cancelButton, initialValues};
};

export default {
    getFormContent,
};
