import React from 'react';
import {useSelector} from 'react-redux';

import ChooseOnMapTooltip from '../../../../../components/Tooltips/ChooseOnMapTooltip';
import * as formInputNames from '../../../../../consts/form/formInputNames';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import {makeSelectBuildingProfile} from '../../../../../state/selectors/buildings';
import helpers from '../../../../../utils/helpers';

const getFormContent = (modalType, form) => {
    const localizedStrings = getLocalizedStrings();
    const buildingProfile = useSelector(makeSelectBuildingProfile());

    const title = localizedStrings[localizationKeys.BUILDING_FORM_EDIT_TITLE];

    const submitButton = localizedStrings[localizationKeys.BUILDING_FORM_EDIT_SUBMIT_BTN];

    const cancelButton = localizedStrings[localizationKeys.BUILDING_FORM_CANCEL_BTN];

    const initialValues = {
        ...buildingProfile,
        [formInputNames.YEAR]: buildingProfile?.year ? buildingProfile?.year : '',
        [formInputNames.HEAT_SQUARE]: buildingProfile?.square ? buildingProfile?.square : '',
        [formInputNames.ORGANIZATION]: buildingProfile?.organizationName,
        [formInputNames.ORGANIZATION_ID]: buildingProfile?.organizationID,
        [formInputNames.BUILDING_ID]: buildingProfile?.buildingID,
        [formInputNames.CONSUMER]: buildingProfile?.consumer,
    };

    const formFields = [
        {
            key: helpers.guid(),
            name: formInputNames.BUILDING_ID,
            hidden: true,
        },
        {
            key: helpers.guid(),
            name: formInputNames.ORGANIZATION_ID,
            hidden: true,
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.NAME,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_NAME],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_NAME],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.ADDRESS,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_ADDRESS],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_ADDRESS],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
            suffix: <ChooseOnMapTooltip modalType={modalType} form={form} />,
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.LATITUDE,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_LATITUDE],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_LATITUDE],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.LONGITUDE,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_LONGITUDE],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_LONGITUDE],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.YEAR,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_YEAR],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_YEAR],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.HEAT_SQUARE,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_HEAT],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_HEAT_SQUARE],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.ACCOUNT,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_ACCOUNT],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_ACCOUNT],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.CONSUMER,
            maxLength: 100,
            label: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_LABEL_CONSUMER],
            placeholder: localizedStrings[localizationKeys.BUILDING_FORM_ITEM_PLACEHOLDER_CONSUMER],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_CONSUMER],
        },
    ];

    return {formFields, title, submitButton, cancelButton, initialValues};
};

export default {
    getFormContent,
};
