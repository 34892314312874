import DeviceTypes from '../../consts/devices/devicesTypes.ts';
import * as localizationKeys from '../../consts/localization/localizationKeys';
import {toUtcFormat} from '../../utils/dateUtils.js';
import {getLocalizedStrings} from '../localization/localizationService.js';
export const createDeviceRequestDataMapping = (data) => {
    const isWaterTypeIds =
        data?.typeId?.id === DeviceTypes.HotWater ||
        data?.typeId?.id === DeviceTypes.ColdWater ||
        data?.typeId?.id === DeviceTypes.Water ||
        data?.typeId?.id === DeviceTypes.Sewage;

    let mappedData = {
        manualID: data?.manualId,
        groupingType: data?.groupingType?.id,
        uspdID: data?.uspdId?.id,
        modelID: data?.modelID?.id,
        // typeID: data?.typeId?.id,
        groupID: data?.typeId?.id,
        buildingId: data?.buildingId,
        roomId: data?.roomId?.id || data?.roomId,
        serviceDate: data?.serviceDate && toUtcFormat(data?.serviceDate),
        parentId: data?.parentId?.id,
    };

    if (isWaterTypeIds) {
        mappedData = {
            ...mappedData,
            factoryID: data?.factoryId || '',
            waterDeviceID: data?.waterDeviceId || '',
        };
    }

    return mappedData;
};

export const editDeviceRequestDataMapping = (data) => {
    const isWaterTypeIds =
        data?.typeId?.id === DeviceTypes.HotWater ||
        data?.typeId?.id === DeviceTypes.ColdWater ||
        data?.typeId?.id === DeviceTypes.Water ||
        data?.typeId?.id === DeviceTypes.Sewage;

    let mappedData = {
        manualID: data?.manualId,
        groupingType: data?.groupingType?.id,
        uspdID: data?.uspdId?.id,
        roomId: data?.roomId?.id || data?.roomId,
        modelID: data?.modelID?.id || data?.modelID,
        // typeID: data?.typeId?.id,
        groupID: data?.typeId?.id,
        parentId: data?.parentId?.id,
        deviceID: data?.deviceID,
        buildingId: data?.buildingId,
        serviceDate: data?.serviceDate && toUtcFormat(data?.serviceDate),
    };

    if (isWaterTypeIds) {
        mappedData = {
            ...mappedData,
            factoryID: data?.factoryId || '',
            waterDeviceID: data?.waterDeviceId || '',
        };
    }

    return mappedData;
};

export const deviceChartRequestDataMapping = (data) => {
    const localizedStrings = getLocalizedStrings();

    const localizedFeaturesKeys = {
        Energy: localizedStrings[localizationKeys.ENERGY_PARAMETER_VALUE],
        Heat: localizedStrings[localizationKeys.HEAT_PARAMETER_VALUE],
        HeatPower: localizedStrings[localizationKeys.HEAT_POWER_PARAMETER_VALUE],
        HeatLoss: localizedStrings[localizationKeys.HEAT_LOSS_PARAMETER_VALUE],
        ServingTemperature: localizedStrings[localizationKeys.SERVING_TEMPERATURE_PARAMETER_VALUE],
        ProcessingTemperature: localizedStrings[localizationKeys.PROCESSING_TEMPERATURE_PARAMETER_VALUE],
        Downtime: localizedStrings[localizationKeys.DOWNTIME_PARAMETER_VALUE],
        OperatingTime: localizedStrings[localizationKeys.OPERATION_TIME_PARAMETER_VALUE],
        Volume: localizedStrings[localizationKeys.VOLUME_PARAMETER_VALUE],
    };

    const mappedData = {
        ...data,
        possibleParameters: data?.possibleParameters?.map((item) => ({
            localizedValue: localizedFeaturesKeys[item?.value],
            ...item,
        })),
    };

    return mappedData;
};
