import React from 'react';

import Icon from '../Icon/Icon';

const IconClose = (props) => {
    return (
        <Icon width='14' height='14' viewBox='0 0 14 14' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.1968 2.86373C12.4897 2.57084 12.4897 2.09597 12.1968 1.80307C11.9039 1.51018 11.4291 1.51018 11.1362 1.80307L6.9999 5.93934L2.86364 1.80308C2.57075 1.51019 2.09588 1.51019 1.80298 1.80308C1.51009 2.09598 1.51009 2.57085 1.80298 2.86374L5.93924 7L1.80298 11.1363C1.51009 11.4291 1.51009 11.904 1.80298 12.1969C2.09588 12.4898 2.57075 12.4898 2.86364 12.1969L6.9999 8.06066L11.1362 12.1969C11.4291 12.4898 11.9039 12.4898 12.1968 12.1969C12.4897 11.904 12.4897 11.4292 12.1968 11.1363L8.06056 7L12.1968 2.86373Z'
                fill='#18191A'
            />
        </Icon>
    );
};

export default IconClose;
