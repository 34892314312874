import React from 'react';

import Icon from '../Icon/Icon';

const IconBellOn = (props) => {
    return (
        <Icon width='24' height='25' viewBox='0 0 24 25' fill='none' {...props}>
            <path
                d='M12 4.78572C10.4087 4.78572 8.88258 5.41786 7.75736 6.54308C6.63214 7.6683 6 9.19442 6 10.7857V15.0714C6 15.7534 5.72908 16.4075 5.24685 16.8897C4.76461 17.3719 4.11056 17.6429 3.42857 17.6429H20.5714C19.8894 17.6429 19.2354 17.3719 18.7532 16.8897C18.2709 16.4075 18 15.7534 18 15.0714V10.7857C18 9.19442 17.3679 7.6683 16.2426 6.54308C15.1174 5.41786 13.5913 4.78572 12 4.78572ZM12 4.78572V2.21429M13.4829 21.0714C13.3322 21.3312 13.1159 21.5469 12.8556 21.6967C12.5954 21.8466 12.3003 21.9255 12 21.9255C11.6997 21.9255 11.4046 21.8466 11.1444 21.6967C10.8841 21.5469 10.6678 21.3312 10.5171 21.0714'
                stroke='#5ED8B7'
                strokeWidth='0.857143'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default IconBellOn;
