import React from 'react';

import Icon from '../Icon/Icon';

const IconReport = ({isWhite, props}) => {
    const fillColor = isWhite ? 'var(--color-white)' : 'var(--color-santas-gray)';

    return (
        <Icon width='24' height='24' viewBox='0 0 18 24' fill='none' {...props}>
            <path
                d='M12.3771 14.3894L11.2826 15.3392L11.0622 15.0365C10.9575 14.899 10.803 14.8079 10.632 14.7828C10.4609 14.7578 10.2869 14.8007 10.1471 14.9024C10.0073 15.0042 9.91293 15.1566 9.8842 15.3271C9.85546 15.4976 9.89465 15.6725 9.99337 15.8145L10.6376 16.6989C10.6988 16.7834 10.7792 16.8521 10.8722 16.8994C10.9652 16.9466 11.0681 16.9711 11.1724 16.9707C11.3316 16.9707 11.4854 16.9133 11.6058 16.8091L13.2439 15.3869C13.3127 15.331 13.3695 15.2618 13.411 15.1835C13.4525 15.1052 13.4778 15.0193 13.4854 14.931C13.4931 14.8427 13.4829 14.7538 13.4555 14.6695C13.4281 14.5852 13.384 14.5073 13.3258 14.4404C13.2677 14.3735 13.1967 14.3189 13.1171 14.28C13.0374 14.2411 12.9508 14.2186 12.8623 14.2138C12.7738 14.2091 12.6852 14.2222 12.6019 14.2523C12.5185 14.2825 12.4421 14.3291 12.3771 14.3894Z'
                fill={fillColor}
            />
            <path
                d='M17.5648 1.1394e-07H5.74892C5.10207 -0.000197292 4.48154 0.256121 4.02339 0.712754C3.56525 1.16939 3.30687 1.78906 3.30493 2.43591V3.3086H2.43738C1.79118 3.30938 1.17168 3.56642 0.71475 4.02335C0.257822 4.48028 0.00077761 5.09978 0 5.74598V19.4527C0.00077761 20.0989 0.257822 20.7184 0.71475 21.1754C1.17168 21.6323 1.79118 21.8893 2.43738 21.8901H14.2533C14.8996 21.8895 15.5193 21.6326 15.9764 21.1756C16.4335 20.7187 16.6906 20.099 16.6914 19.4527V18.5837H17.5641C18.2109 18.582 18.8305 18.3237 19.2872 17.8657C19.7439 17.4077 20.0002 16.7872 20 16.1405V2.43664C19.9996 1.79078 19.743 1.17146 19.2864 0.714624C18.8299 0.257792 18.2107 0.000778381 17.5648 1.1394e-07ZM15.3691 19.4535C15.3687 19.7492 15.251 20.0327 15.0418 20.2418C14.8326 20.4509 14.549 20.5684 14.2533 20.5686H2.43738C2.14162 20.5684 1.85803 20.4509 1.64883 20.2418C1.43963 20.0327 1.32192 19.7492 1.32153 19.4535V5.74671C1.32173 5.45083 1.43935 5.16712 1.64857 4.95791C1.85779 4.74869 2.14149 4.63106 2.43738 4.63087H14.2533C14.5492 4.63106 14.8329 4.74869 15.0421 4.95791C15.2513 5.16712 15.3689 5.45083 15.3691 5.74671V19.4535ZM18.6777 16.1412C18.6777 16.7597 18.1782 17.2622 17.5641 17.2622H16.6914V5.74671C16.6906 5.10039 16.4335 4.48078 15.9764 4.02383C15.5193 3.56688 14.8996 3.30992 14.2533 3.30934H4.62719V2.43664C4.62719 1.82252 5.13039 1.32227 5.74892 1.32227H17.5648C18.1789 1.32227 18.6785 1.82179 18.6785 2.43664L18.6777 16.1412Z'
                fill={fillColor}
            />
            <path
                d='M12.8106 11.4777H3.88013C3.70478 11.4777 3.53662 11.5473 3.41264 11.6713C3.28865 11.7953 3.21899 11.9634 3.21899 12.1388C3.21899 12.3141 3.28865 12.4823 3.41264 12.6063C3.53662 12.7303 3.70478 12.7999 3.88013 12.7999H12.8106C12.9859 12.7999 13.1541 12.7303 13.2781 12.6063C13.402 12.4823 13.4717 12.3141 13.4717 12.1388C13.4717 11.9634 13.402 11.7953 13.2781 11.6713C13.1541 11.5473 12.9859 11.4777 12.8106 11.4777ZM8.20173 14.9383H3.88013C3.70478 14.9383 3.53662 15.008 3.41264 15.132C3.28865 15.2559 3.21899 15.4241 3.21899 15.5994C3.21899 15.7748 3.28865 15.943 3.41264 16.0669C3.53662 16.1909 3.70478 16.2606 3.88013 16.2606H8.20173C8.37707 16.2606 8.54524 16.1909 8.66922 16.0669C8.79321 15.943 8.86286 15.7748 8.86286 15.5994C8.86286 15.4241 8.79321 15.2559 8.66922 15.132C8.54524 15.008 8.37707 14.9383 8.20173 14.9383ZM6.7803 8.05078H9.91039C10.0857 8.05078 10.2539 7.98113 10.3779 7.85714C10.5019 7.73315 10.5715 7.56499 10.5715 7.38965C10.5715 7.21431 10.5019 7.04614 10.3779 6.92216C10.2539 6.79817 10.0857 6.72852 9.91039 6.72852H6.7803C6.60495 6.72852 6.43679 6.79817 6.3128 6.92216C6.18882 7.04614 6.11916 7.21431 6.11916 7.38965C6.11916 7.56499 6.18882 7.73315 6.3128 7.85714C6.43679 7.98113 6.60495 8.05078 6.7803 8.05078Z'
                fill={fillColor}
            />
        </Icon>
    );
};

export default IconReport;
