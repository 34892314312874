import 'antd/dist/reset.css';
import 'leaflet/dist/leaflet.css';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './assets/css/global.scss';
import './assets/css/variables/main.scss';
import 'dayjs/locale/uk';

import {ConfigProvider} from 'antd';
import uk_UA from 'antd/es/locale/uk_UA';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';

import App from './app/containers/App/App';
import {makeConfigureStore} from './app/state/store';

const render = async () => {
    const store = makeConfigureStore();
    const root = ReactDOM.createRoot(document.getElementById('root'));

    root.render(
        <Provider store={store}>
            <ConfigProvider
                locale={uk_UA}
                theme={{
                    token: {
                        fontFamily: `var(--font-family)`,
                    },
                }}
            >
                <App />
            </ConfigProvider>
        </Provider>
    );
};

render();
