import React from 'react';

import Icon from '../Icon/Icon';

const IconHeat = (props) => {
    const fill = props?.fill || '#0187F9';

    return (
        <Icon width='23' height='23' viewBox='0 0 23 23' fill='none' {...props}>
            <path
                d='M19.375 17.125C20.7363 17.125 22.75 16.225 22.75 13.75C22.75 11.275 20.7363 10.375 19.375 10.375H17.125V8.125H19.375C21.85 8.125 22.75 6.11125 22.75 4.75C22.75 2.275 20.7363 1.375 19.375 1.375H17.125V0.25H16V1.375H7V0.25H5.875V1.375H0.25V3.625H5.875V5.875H3.625C2.26375 5.875 0.25 6.775 0.25 9.25C0.25 11.725 2.26375 12.625 3.625 12.625H5.875V14.875H3.625C2.26375 14.875 0.25 15.775 0.25 18.25C0.25 20.725 2.26375 21.625 3.625 21.625H5.875V22.75H7V21.625H16V22.75H17.125V21.625H22.75V19.375H17.125V17.125H19.375ZM19.375 12.625C19.8813 12.625 20.5 12.8388 20.5 13.75C20.5 14.6613 19.8813 14.875 19.375 14.875H17.125V12.625H19.375ZM16 10.375H7V8.125H16V10.375ZM19.375 3.625C19.8813 3.625 20.5 3.85 20.5 4.75C20.5 5.25625 20.2863 5.875 19.375 5.875H17.125V3.625H19.375ZM7 3.625H16V5.875H7V3.625ZM3.625 10.375C3.11875 10.375 2.5 10.1613 2.5 9.25C2.5 8.33875 3.11875 8.125 3.625 8.125H5.875V10.375H3.625ZM7 12.625H16V14.875H7V12.625ZM3.625 19.375C3.11875 19.375 2.5 19.1613 2.5 18.25C2.5 17.3388 3.11875 17.125 3.625 17.125H5.875V19.375H3.625ZM16 19.375H7V17.125H16V19.375Z'
                fill={fill}
            />
        </Icon>
    );
};

export default IconHeat;
