import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import drawerContentTypes from '../../../consts/drawer/drawerContentTypes';
import {showDrawer} from '../../../state/ducks/drawer';
import {makeSelectAuthToken, makeSelectLogin} from '../../../state/selectors/auth';
import {dispatch} from '../../../state/store';
import Logo from '../../Logo/Logo';
import Profile from '../../UserMenu/UserMenu';
import UserNotifications from '../../UserNotifications/UserNotifications';

const mapStateToProps = createStructuredSelector({
    login: makeSelectLogin(),
    authToken: makeSelectAuthToken(),
});

const Header = ({login, authToken}) => {
    const headerClasses = `
        h-[67px] bg-white flex justify-between items-center 
        sticky top-0 w-full border-b border-b-whisper drop-shadow-lg
        pl-[31px] pr-7 z-[1010]
    `;

    const onNotificationsClick = () => {
        dispatch(showDrawer(drawerContentTypes.NOTIFICATIONS));
    };

    return (
        <header className={headerClasses}>
            <Logo />
            <div className='flex justify-between items-center'>
                <UserNotifications count={25} onClick={onNotificationsClick} />
                <Profile userName={authToken && login} />
            </div>
        </header>
    );
};

export default connect(mapStateToProps)(Header);
