import {MANAGER, USER} from '../../consts/user/userRolesId';
import {makeSelectCurrentUserInfo} from '../../state/selectors/currentUser';
import {makeSelectUserInfo} from '../../state/selectors/users';
import {getState} from '../../state/store';

export const usersListRequestDataMapping = (data) => {
    const mappedData = {
        list: data?.items,
        totalRecords: data?.totalRecords,
        page: data?.page,
        size: data?.size,
        filters: {
            status: data?.filters?.Status,
            role: data?.filters?.Role,
            isNotificationEnabled: data?.filters?.IsNotificationEnabled,
        },
        totalFilteredRecords: data?.totalFilteredRecords,
        hasPreviousPage: data?.hasPreviousPage,
        hasNextPage: data?.hasNextPage,
    };

    return mappedData;
};

export const addUserRequestPostMapping = (data) => {
    const isUser = data?.role?.id === USER;
    const isManager = data?.role?.id === MANAGER;

    const mappedData = {
        Name: data?.name,
        Surname: data?.surname,
        PhoneNumber: data?.phone,
        Email: data?.email,
        RoleId: data?.role?.id,
        CompanyId: data?.company?.id,
    };

    if (isManager) {
        mappedData.BuildingId = data?.building?.id;
    }

    if (isUser) {
        mappedData.BuildingId = data?.building?.id;
        mappedData.RoomId = data?.apartment?.id;
    }

    return JSON.stringify(mappedData);
};

export const editUserRequestPostMapping = (data) => {
    const userInfo = makeSelectUserInfo()(getState());

    const userResponsibilities = [...(userInfo?.userResponsibilities || [])];

    const existingResponsibilityIndex = userResponsibilities.findIndex(
        (item) => item.responsibilityID === data?.responsibilityId
    );

    let currentUserResponsibility = null;

    if (data?.company?.id || data?.building?.id || data.apartment?.id) {
        currentUserResponsibility = {
            CompanyID: data?.company?.id,
            BuildingID: data?.building?.id,
            roomId: data?.apartment?.id,
        };
    }

    if (currentUserResponsibility) {
        if (existingResponsibilityIndex !== -1) {
            userResponsibilities[existingResponsibilityIndex] = currentUserResponsibility;
        } else {
            userResponsibilities.push(currentUserResponsibility);
        }
    } else {
        if (existingResponsibilityIndex !== -1) {
            userResponsibilities.splice(existingResponsibilityIndex, 1);
        }
    }

    const mappedData = {
        isNotified: typeof data?.notification == 'boolean' ? data?.notification : userInfo?.isNotified,
        Login: data?.login,
        RoleId: +data?.role?.id || userInfo?.roleId,
        Status: +data?.status?.id,
        PhoneNumber: data?.phone,
        Surname: data?.surname,
        Name: data?.name,
        UserID: data?.id || userInfo?.id,
        Image: data?.Image,
        ImageExtension: data?.ImageExtension,
    };

    return JSON.stringify(mappedData);
};

export const userRolesRequestDataMapping = (data) => {
    const mappedData = {
        items: data?.items?.map((item) => {
            return {
                key: item.key,
                value: item.value,
            };
        }),
    };

    return mappedData;
};

export const userStatusesRequestDataMapping = (data) => {
    const mappedData = {
        items: data?.items?.map((item) => {
            return {
                key: item.key,
                value: item.value,
            };
        }),
    };

    return mappedData;
};

export const editCurrentUserRequestPostMapping = (data) => {
    const currentUserInfo = makeSelectCurrentUserInfo()(getState());

    const mappedData = {
        Login: data?.login,
        PhoneNumber: data?.phone,
        Surname: data?.surname,
        Name: data?.name,
        isNotified: currentUserInfo?.isNotified,
        RoleId: currentUserInfo?.roleId,
        Status: data?.status?.id,
        UserID: currentUserInfo?.id,
        UserResponsobilities: currentUserInfo?.userResponsibilities,
        Image: data?.Image,
        ImageExtension: data?.ImageExtension,
        IsImageChanged: true,
    };

    return JSON.stringify(mappedData);
};
