import React from 'react';

import Icon from '../Icon/Icon';

const IconPlusMinus = (props) => {
    return (
        //TODO: Delete unused icons

        <Icon width='16' height='16' viewBox='0 0 24 24' fill='none' {...props}>
            <path
                d='m11 4v5h-5v2h5v5h2v-5h5v-2h-5v-5zm-5 14v2h12v-2z'
                fill='#3B3B3B'
                fillRule='evenodd'
                clipRule='evenodd'
                strokeWidth='0.5'
            />

            {/* <g stroke='#141b34' strokeWidth='1.2'>
                <path d='m12 7.5v6.3636m3.5-3.1818h-7m7 5.8182h-7' strokeLinecap='round' strokeLinejoin='round' />
                <circle cx='12' cy='12' r='10' />
            </g> */}
            {/* <path
                    clipRule='evenodd'
                    d='m11.9426 1.25h.1148c2.3084-.00001 4.1174-.00002 5.5289.18975 1.4447.19424 2.5848.59958 3.4796 1.49439s1.3001 2.03483 1.4944 3.47957c.1897 1.41148.1897 3.22052.1897 5.52889v.1148c0 2.3084 0 4.1174-.1897 5.5289-.1943 1.4447-.5996 2.5848-1.4944 3.4796s-2.0349 1.3001-3.4796 1.4944c-1.4115.1897-3.2205.1897-5.5289.1897h-.1148c-2.30837 0-4.11741 0-5.52889-.1897-1.44474-.1943-2.58476-.5996-3.47957-1.4944s-1.30015-2.0349-1.49439-3.4796c-.18977-1.4115-.18976-3.2205-.18975-5.5289v-.1148c-.00001-2.30837-.00002-4.11741.18975-5.52889.19424-1.44474.59958-2.58476 1.49439-3.47957s2.03483-1.30015 3.47957-1.49439c1.41148-.18977 3.22052-.18976 5.52889-.18975zm-5.32902 1.67637c-1.27841.17188-2.04913.49877-2.61878 1.06843-.56966.56965-.89655 1.34037-1.06843 2.61878-.17478 1.29998-.17637 3.0082-.17637 5.38642 0 2.3782.00159 4.0864.17637 5.3864.11853.8816.31077 1.5218.60551 2.0211l15.87562-15.87562c-.4993-.29474-1.1395-.48698-2.0211-.60551-1.3-.17478-3.0082-.17637-5.3864-.17637-2.37822 0-4.08644.00159-5.38642.17637zm13.85452 1.66617-15.87556 15.87556c.49929.2948 1.13945.487 2.02104.6055 1.29998.1748 3.0082.1764 5.38642.1764 2.3782 0 4.0864-.0016 5.3864-.1764 1.2784-.1718 2.0491-.4987 2.6188-1.0684s.8966-1.3404 1.0684-2.6188c.1748-1.3.1764-3.0082.1764-5.3864 0-2.37822-.0016-4.08644-.1764-5.38642-.1185-.88159-.3107-1.52175-.6055-2.02104zm-12.4681.15742c.41421 0 .75.33579.75.75v1.75002h1.75c.4142 0 .75.33579.75.75s-.3358.75-.75.75h-1.75v1.75002c0 .4142-.33579.75-.75.75s-.75-.3358-.75-.75v-1.75002h-1.75c-.41421 0-.75-.33579-.75-.75s.33579-.75.75-.75h1.75v-1.75002c0-.41421.33579-.75.75-.75zm4.25 12.25004c0-.4142.3358-.75.75-.75h5c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-5c-.4142 0-.75-.3358-.75-.75z'
                    fill='#3B3B3B'
                    fillRule='evenodd'
                    strokeWidth='1'
                /> */}
            {/* // < width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}> */}
            {/* <path d='M10 7h6v2h-6v-2z' stroke='#444444' strokeWidth='0.8' />
            <path d='M4 5h-2v2h-2v2h2v2h2v-2h2v-2h-2z' stroke='#444444' strokeWidth='0.8' />
            <path fill='#444444' d='M6 2l3 12h1l-3-12z' /> */}
        </Icon>
    );
};

export default IconPlusMinus;
