import * as apiMethods from '../../consts/api/apiMethods';
import {setKvkData, setKvkSettingsData, setKvkStatisticsData, setKvkStatus} from '../../state/ducks/services';
import ApiClient from '../api/clients/apiClient';
import log from '../logger/log';

const getKvkBuildingLists = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KVK_BUILDING_LISTS,
            action: setKvkData,
        });
    } catch (e) {
        log.error(`getBuildingsList: ${e}`);
    }
};

const addBuildingToApplied = async ({data, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SERVICES_KVK_BUILDING_LISTS,
        // mapper: buildingToAppliedMapping,
        requestConfig: {
            data,
        },
        onResponse: onRequestSuccess,
    });
};

const getKvkStatus = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KVK_STATE,
            action: setKvkStatus,
        });
    } catch (e) {
        log.error(`getKvkStatus: ${e}`);
    }
};

const turnOffKvkDataSending = async ({onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_KVK_OFF,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`turnOffKvkDataSending: ${e}`);
    }
};

const turnOnKvkDataSending = async ({onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_KVK_ON,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`turnOnKvkDataSending: ${e}`);
    }
};

const getKvkStatistics = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KVK_STATISTICS,
            action: setKvkStatisticsData,
        });
    } catch (e) {
        log.error(`getKvkStatistics: ${e}`);
    }
};

const getKvkSettings = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KVK_SETTINGS,
            action: setKvkSettingsData,
        });
    } catch (e) {
        log.error(`getKvkSettings: ${e}`);
    }
};

const updateKvkSettings = async ({data, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SERVICES_KVK_SETTINGS,
        requestConfig: {
            data,
        },
        onResponse: onRequestSuccess,
    });
};

export default {
    getKvkBuildingLists,
    addBuildingToApplied,
    getKvkStatus,
    turnOffKvkDataSending,
    turnOnKvkDataSending,
    getKvkSettings,
    updateKvkSettings,
    getKvkStatistics,
};
