import {Dropdown as AntDropdown, Menu} from 'antd';
import cn from 'classnames';
import React, {cloneElement, useEffect, useRef, useState} from 'react';

import useOutsideClick from '../../hooks/useOutsideClick';
import helpers from '../../utils/helpers';

const Dropdown = (props) => {
    const {customDropdown, open, closeOnOverlay = true, afterOpenChange, ...restProps} = props || {};
    const [isOpen, setIsOpen] = useState(open);

    const onItemClick = (onClick, menuItemKey) => {
        helpers.runFunction(onClick, menuItemKey);
        setIsOpen(false);
    };

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        helpers.runFunction(afterOpenChange, isOpen);
    }, [isOpen]);

    const dropdownState = [isOpen, setIsOpen];
    const options = {dropdownState, afterOpenChange, closeOnOverlay, onItemClick};

    return customDropdown ? (
        <CustomModeDropdown {...restProps} {...options} />
    ) : (
        <AntModeDropdown {...restProps} {...options} />
    );
};

const filterMenu = (menu, selectedItemKey) => {
    return menu ? menu.filter((item) => item.key !== selectedItemKey) : [];
};

const CustomModeDropdown = (props) => {
    const {
        containerClassName,
        trigger,
        labelClassName,
        disabled,
        customMenu,
        menu,
        menuClassName,
        menuItemClassName,
        dropdownState,
        closeOnOverlay,
        onItemClick,
        selectedValue,
        notFilterItems,
    } = props || {};
    const [isOpen, setIsOpen] = dropdownState;

    const [selectedItemKey, setSelectedItemKey] = useState();
    const [filteredMenu, setFilteredMenu] = useState([]);

    const containerRef = useRef(null);

    useOutsideClick(containerRef, () => {
        if (closeOnOverlay) {
            setIsOpen(false);
        }
    });

    const handleClick = () => {
        if (isOpen) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    useEffect(() => {
        if (!selectedValue) {
            if (menu && menu.length > 0 && menu[0].key) {
                setSelectedItemKey(menu[0].key);
            }
        } else {
            setSelectedItemKey(selectedValue.key);
        }
    }, []);

    useEffect(() => {
        if (!notFilterItems) {
            setFilteredMenu(filterMenu(menu, selectedItemKey));
        } else {
            setFilteredMenu(menu);
        }
    }, [menu]);

    const ulClassName = cn(
        `z-50 absolute bg-white w-[300px] !p-1 right-7 drop-shadow-userMenu max-h-[200px] overflow-y-auto`,
        menuClassName,
        'left-0'
    );

    const liClassName = cn(
        `flex items-center !h-10 hover:cursor-pointer hover:bg-white-smoke select-none`,
        menuItemClassName
    );

    const lbClassName = cn('', labelClassName);

    return (
        <div className={containerClassName} ref={containerRef}>
            {cloneElement(trigger, {
                onClick: !disabled ? handleClick : () => {},
            })}
            {isOpen ? (
                <ul className={ulClassName}>
                    {customMenu ||
                        filteredMenu.map((menuItem) => (
                            <li
                                onClick={() => {
                                    onItemClick(menuItem?.onClick);
                                    setSelectedItemKey(menuItem?.key);
                                }}
                                className={liClassName}
                                key={menuItem.key}
                            >
                                {menuItem?.icon && <span className='pl-5'>{menuItem.icon}</span>}
                                <span className={lbClassName}>{menuItem?.label}</span>
                            </li>
                        ))}
                </ul>
            ) : null}
        </div>
    );
};

const AntModeDropdown = (props) => {
    const {
        containerClassName,
        trigger,
        disabled,
        handleClick,
        customMenu,
        menu,
        labelClassName,
        onItemClick,
        placement,
        selectedValue,
        notFilterItems,
    } = props || {};

    const [selectedItemKey, setSelectedItemKey] = useState();
    const [filteredMenu, setFilteredMenu] = useState([]);

    const ulClassName = cn(`drop-shadow-userMenu min-w-[200px] max-w-[300px] max-h-[200px] overflow-y-auto`);

    const liClassName = cn(
        `flex items-center py-1 hover:cursor-pointer hover:text-[var(--color-theme-blue)] hover:bg-white-smoke select-none`
    );

    const handleMenuItemClick = (event, key, name, onClick) => {
        event.domEvent.stopPropagation();
        onItemClick(onClick, key);
        setSelectedItemKey(key);

        if (props.afterSelectingItem) {
            props.afterSelectingItem(name);
        }
    };

    useEffect(() => {
        if (!selectedValue) {
            if (menu && menu.length > 0 && menu[0].key) {
                setSelectedItemKey(menu[0].key);
            }
        } else {
            setSelectedItemKey(selectedValue.key);
        }
    }, []);

    useEffect(() => {
        if (!notFilterItems) {
            setFilteredMenu(filterMenu(menu, selectedItemKey));
        } else {
            setFilteredMenu(menu);
        }
    }, [menu]);

    const handleVisibleChange = (visible) => {
        if (visible) {
            /// to be continued
        }
    };

    const childrenMenu = (
        <Menu className={ulClassName}>
            {filteredMenu &&
                filteredMenu.map(({key, onClick, icon, label} = {}) => (
                    <Menu.Item onClick={(event) => handleMenuItemClick(event, key, label, onClick)} key={key}>
                        <div className={liClassName}>
                            {icon && <span>{icon}</span>}
                            <span className={labelClassName}>{label}</span>
                        </div>
                    </Menu.Item>
                ))}
        </Menu>
    );

    return (
        <AntDropdown
            disabled={disabled}
            placement={placement}
            onClick={(e) => e.stopPropagation()}
            trigger={['click']}
            onOpenChange={handleVisibleChange}
            /* todo: Ostrovskyi. D. - deprecated prop */
            overlay={childrenMenu}
        >
            <div className={containerClassName}>
                {cloneElement(trigger, {
                    onClick: !disabled ? handleClick : () => {},
                })}
                {!!customMenu && <ul className={ulClassName}>{customMenu}</ul>}
            </div>
        </AntDropdown>
    );
};

export default Dropdown;
