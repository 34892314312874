export const EDIT_USERS_MODAL_TYPE = 'EDIT_USERS_MODAL_TYPE';
export const ADD_USER_OBJECTS_MODAL_TYPE = 'ADD_USER_OBJECTS_MODAL_TYPE';
export const EDIT_USER_OBJECTS_MODAL_TYPE = 'EDIT_USER_OBJECTS_MODAL_TYPE';
export const ADD_USERS_MODAL_TYPE = 'ADD_USERS_MODAL_TYPE';
export const ADD_USERS_TO_BUILDING_MODAL_TYPE = 'ADD_USERS_TO_BUILDING_MODAL_TYPE';
export const ORGANIZATION_EDIT_MODAL = 'ORGANIZATION_EDIT_MODAL';
export const ORGANIZATION_CREATE_MODAL = 'ORGANIZATION_CREATE_MODAL';
export const ORGANIZATION_COPY_MODAL = 'ORGANIZATION_COPY_MODAL';
export const SERVICES_BUILDING_ADD = 'SERVICES_BUILDING_ADD';
export const SERVICES_ADD_ULC = 'SERVICES_ADD_ULC';
export const SERVICES_EDIT_ULC = 'SERVICES_EDIT_ULC';
export const SERVICES_CONFIGURE_SERVICE = 'SERVICES_CONFIGURE_SERVICE';
export const SERVICES_CONFIGURE_KYIVENERGO = 'SERVICES_CONFIGURE_KYIVENERGO';
export const SERVICES_CONFIGURE_LVK = 'SERVICES_CONFIGURE_LVK';
export const SERVICES_CONFIGURE_KVK = 'SERVICES_CONFIGURE_KVK';
export const REPORTS_MODAL = 'REPORTS_MODAL';
export const CREATE_DATA_COLLECTOR_MODAL = 'CREATE_DATA_COLLECTOR_MODAL';
export const ADD_DATA_COLLECTOR_TO_BUILDING_MODAL = 'ADD_DATA_COLLECTOR_TO_BUILDING_MODAL';
export const EDIT_DATA_COLLECTOR_MODAL = 'EDIT_DATA_COLLECTOR_MODAL';
export const CREATE_BUILDING_MODAL = 'CREATE_BUILDING_MODAL';
export const EDIT_BUILDING_MODAL = 'EDIT_BUILDING_MODAL';
export const COPY_BUILDING_MODAL = 'COPY_BUILDING_MODAL';
export const COMMON_SETTINGS_TAB_ADD_CITY = 'COMMON_SETTINGS_TAB_ADD_CITY';
export const SETTING_ADD_ROOM_TYPE = 'SETTING_ADD_ROOM_TYPE';
export const SETTING_EDIT_ROOM_TYPE = 'SETTING_EDIT_ROOM_TYPE';
export const SETTINGS_ADD_DC_MODEL_TYPE = 'SETTINGS_ADD_DC_MODEL_TYPE';
export const SETTINGS_EDIT_DC_MODEL_TYPE = 'SETTINGS_EDIT_DC_MODEL_TYPE';
export const SETTINGS_ADD_DEVICE_MODEL = 'SETTINGS_ADD_DEVICE_MODEL';
export const SETTINGS_ADD_DEVICE_GROUPS = 'SETTINGS_ADD_DEVICE_GROUPS';
export const SETTINGS_EDIT_DEVICE_GROUPS = 'SETTINGS_EDIT_DEVICE_GROUPS';
export const SETTINGS_EDIT_DEVICE_MODEL = 'SETTINGS_EDIT_DEVICE_MODEL';
export const ADD_DEVICE_MODAL_TYPE = 'ADD_DEVICE_MODAL_TYPE';
export const EDIT_DEVICE_MODAL_TYPE = 'EDIT_DEVICE_MODAL_TYPE';
export const BUILDING_PROFILE_MODAL_TYPE = 'BUILDING_PROFILE_MODAL_TYPE';
export const ADD_ROOM_TO_BUILDING = 'ADD_ROOM_TO_BUILDING';
export const ROOM_PROFILE_MODAL_TYPE = 'ROOM_PROFILE_MODAL_TYPE';
export const CONFIRM_MODAL_TYPE = 'CONFIRM_MODAL_TYPE';
export const MAP_MODAL_TYPE = 'MAP_MODAL_TYPE';
export const UPLOAD_FILE_MODAL_TYPE = 'UPLOAD_FILE_MODAL_TYPE';
export const ADD_ARS_ORGANIZATION_MODAL_TYPE = 'ADD_ARS_ORGANIZATION_MODAL_TYPE';
export const EDIT_ARS_ORGANIZATION_MODAL_TYPE = 'EDIT_ARS_ORGANIZATION_MODAL_TYPE';
export const ADD_BUILDINGS_TO_ARS_MODAL_TYPE = 'ADD_BUILDINGS_TO_ARS_MODAL_TYPE';
export const ARS_STATISTIC_ORGANIZATION_MODAL_TYPE = 'ARS_STATISTIC_ORGANIZATION_MODAL_TYPE';
