import {notification} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import appConfig from '../../config/appConfig';
import {makeNotifications} from '../../state/selectors/global';

const Notifications = React.memo(() => {
    const notificationDuration = appConfig.getNotificationDuration();
    const notifications = useSelector(makeNotifications());

    const [api, contextHolder] = notification.useNotification();

    const showNotification = (notification) => {
        const {text, type, description, notificationId, duration} = notification || {};

        const isDuration = Object.prototype.hasOwnProperty.call(notification, 'duration');

        notifications.shift();

        api[type]({
            key: notificationId,
            message: text,
            placement: 'top',
            className: `w-[660px]`,
            duration: isDuration ? duration : notificationDuration,
            description: <span className={'whitespace-pre-line'}>{description}</span>,
            onClose: () => {
                api.destroy(notificationId);
            },
        });
    };

    useEffect(() => {
        if (!notifications?.length) return;

        showNotification(notifications[0]);
    }, [notifications]);

    return contextHolder;
});

Notifications.displayName = 'Notifications';

export default Notifications;
