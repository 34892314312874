export const addCityRequestMapping = (data) => {
    const mappedData = {
        Name: data?.cityName,
    };

    return JSON.stringify(mappedData);
};

export const addRoomTypeRequestMapping = (data) => {
    const mappedData = {
        Name: data?.roomTypeName,
        Description: data?.roomTypeDescription,
    };

    return JSON.stringify(mappedData);
};

export const editRoomTypeRequestMapping = (data) => {
    const mappedData = {
        Name: data?.roomTypeName,
        Description: data?.roomTypeDescription,
        RoomTypeID: data?.roomTypeId,
    };

    return JSON.stringify(mappedData);
};

export const addDCModelRequestMapping = (data) => {
    const mappedData = {
        Name: data?.dcModelName,
        Description: data?.dcModelDescription,
    };

    return JSON.stringify(mappedData);
};

export const deleteDeviceModelRequestMapping = (modelId) => {
    const mappedData = {
        DeviceModelID: modelId,
    };

    return JSON.stringify(mappedData);
};

export const addDeviceGroupRequestMapping = (data) => {
    const mappedData = {
        name: data?.groupsName,
        description: data?.groupsDescription,
        appliedDeviceTypes: data?.groupsTypes,
    };

    return JSON.stringify(mappedData);
};

export const editDeviceGroupRequestMapping = (data) => {
    const mappedData = {
        groupID: data?.groupID,
        name: data?.groupsName,
        description: data?.groupsDescription,
        appliedDeviceTypes: data?.groupsTypes,
    };

    return JSON.stringify(mappedData);
};

export const deleteDeviceGroupRequestMapping = (groupID) => {
    const mappedData = {
        groupID,
    };

    return JSON.stringify(mappedData);
};

export const addDeviceModelRequestMapping = (data, groupID) => {
    const mappedData = {
        Name: data?.deviceModelName,
        Description: data?.deviceModelDescription,
        ManufacturerID: data?.manufacturerId,
        GroupID: groupID,
    };

    return JSON.stringify(mappedData);
};

export const manufacturersListRequestDataMapping = (data) => {
    const mappedData = {
        hasNextPage: data?.hasNextPage,
        hasPreviousPage: data?.hasPreviousPage,
        items: data?.items,
        itemsInResponse: data?.itemsInResponse,
        page: data?.page,
        pages: data?.pages,
        size: data?.size,
        sortableColumns: data?.sortableColumns,
        totalFilteredRecords: data?.totalFilteredRecords,
        totalRecords: data?.totalRecords,
        query: data?.query,
        sortBy: data?.sortBy,
        direction: data?.direction,
    };

    return JSON.stringify(mappedData);
};

export const editDeviceModelRequestMapping = (values) => {
    const mappedData = {
        DeviceModelID: values?.deviceModelID,
        Name: values?.deviceModelName,
        Description: values?.deviceModelDescription,
        ManufacturerId: values?.manufacturerId?.id || values.manufacturerId,
    };

    return JSON.stringify(mappedData);
};

export const editDCModelRequestMapping = (data) => {
    const mappedData = {
        Name: data?.dcModelName,
        Description: data?.dcModelDescription,
        UspdModelID: data?.dcModelID,
    };

    return JSON.stringify(mappedData);
};

const sortClaimsByTextField = (arr) => {
    const order = {Read: 1, Write: 2, Edit: 3, Delete: 4};

    return arr.sort((a, b) => order[a.text] - order[b.text]);
};

export const roleClaimsMapping = (data) => {
    const {roleClaimsBlocks} = data || {};

    const mappedRoleClaimsBlocks = roleClaimsBlocks.map((claimsBlock) => {
        const {roleClaims} = claimsBlock || {};

        return {
            ...claimsBlock,
            roleClaims: roleClaims.map((roleClaim) => {
                const {claims} = roleClaim || {};

                return {
                    ...roleClaim,
                    claims: sortClaimsByTextField(claims),
                };
            }),
        };
    });

    return {roleClaimsBlocks: mappedRoleClaimsBlocks};
};

export const manufacturersListMapping = (data) => {
    const {items, ...rest} = data;

    const updatedItems = items.map((item) => ({
        ...item,
        name: `${item.name} (${item.uniqueDataKey})`,
    }));

    return {
        ...rest,
        items: updatedItems,
    };
};
