import {useSelector} from 'react-redux';

import * as requestParamTypes from '../../../../consts/app/requestParamTypes';
import * as formInputNames from '../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import buildingsService from '../../../../services/buildings/buildingsService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import organizationsService from '../../../../services/organizations/organizationsService';
import {makeSelectBuildingsData} from '../../../../state/selectors/buildings';
import {makeSelectCurrentUserInfo} from '../../../../state/selectors/currentUser';
import {makeSelectOrganizationsNames} from '../../../../state/selectors/organizations';
import {getState} from '../../../../state/store';
import helpers from '../../../../utils/helpers';

const getFormContent = (form) => {
    const localizedStrings = getLocalizedStrings();

    const currentUserInfo = makeSelectCurrentUserInfo()(getState());
    const {userResponsibilities} = currentUserInfo || {};

    const [responsibility] = userResponsibilities || [];

    const formFields = [
        {
            key: helpers.guid(),
            name: formInputNames.COMPANY,
            formItemType: formItemTypes.SELECT,
            required: true,
            row: true,
            fieldLabel: 'name',
            label: localizedStrings[localizationKeys.SERVICES_MODAL_FORM_COMPANY_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICES_MODAL_FORM_COMPANY_PLACEHOLDER],
            requestOptions: {
                selectRequestData: makeSelectOrganizationsNames,
                getRequestData: () => organizationsService.getOrganizationsNames(responsibility.cityId),
            },
        },
        {
            key: helpers.guid(),
            name: formInputNames.BUILDING,
            formItemType: formItemTypes.SELECT,
            required: true,
            fieldLabel: 'name',
            label: localizedStrings[localizationKeys.SERVICES_MODAL_FORM_BUILDING_LABEL],
            row: true,
            placeholder: localizedStrings[localizationKeys.SERVICES_MODAL_FORM_BUILDING_LABEL],
            requestOptions: {
                paramsType: requestParamTypes.BUILDINGS,
                selectRequestData: makeSelectBuildingsData,
                getRequestData: () => buildingsService.getBuildingsListByOrganizationId(form.getFieldValue('company')),
            },
        },
    ];

    return {
        formFields,
    };
};

export default {
    getFormContent,
};
