import cn from 'classnames';
import React from 'react';

import * as userMenuTypes from '../../consts/userMenu/userMenuTypes';

const UserMenuItem = ({label, type}) => {
    const classes = cn(
        'font-semibold text-14 leading-[24px] tracking-02 ml-5',
        {
            'text-flamingo': type === userMenuTypes.USER_MENU_LOGOUT_ITEM,
        },
        {
            'text-primary': type === userMenuTypes.USER_MENU_PROFILE_ITEM,
        },
        {
            'text-midnight': type === userMenuTypes.USER_MENU_SETTINGS_ITEM,
        }
    );

    return <div className={classes}>{label}</div>;
};

export default UserMenuItem;
