const decodeBtoa = (baseString) => {
    if (baseString) {
        return window.btoa(baseString);
    }

    return null;
};

const encodeAtob = (encodedString) => {
    if (encodedString) {
        return window.atob(encodedString);
    }

    return null;
};

export default {
    decodeBtoa,
    encodeAtob,
};
