import dayjs from 'dayjs';

import {HOUR_PERIOD_KEY} from '../../consts/charts/chartPeriods';
import * as localizationKeys from '../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';

export const SET_DEVICE_GROUPS = 'app/device/SET_DEVICE_GROUPS';
export const SET_DEVICE_TYPES = 'app/device/SET_DEVICE_TYPES';
export const SET_DEVICE_MODELS = 'app/device/SET_DEVICE_MODELS';
export const SET_CURRENT_MODEL = 'app/device/SET_CURRENT_MODEL';
export const SET_DEVICES = 'app/device/SET_DEVICES';
export const SET_DEVICE_PROFILE = 'app/device/SET_DEVICE_PROFILE';
export const SET_DEVICE_CHART = 'app/device/SET_DEVICE_CHART';
export const SET_SELECTED_DATE = 'app/device/SET_SELECTED_DATE';
export const SET_PARAMETER_TYPE = 'app/device/SET_PARAMETER_TYPE';
export const CLEAN_DEVICE_DATA = 'app/device/CLEAN_DEVICE_DATA';
export const SET_DEVICE_TYPES_UNASSIGNED = 'app/device/SET_DEVICE_TYPES_UNASSIGNED';
export const SET_DEVICE_TYPES_GROUPS = 'app/device/SET_DEVICE_TYPES_GROUPS';
export const CLEAN_DEVICE_MODELS = 'app/device/CLEAN_DEVICE_MODELS';
export const SET_CHART_ACTIVE_PERIOD_KEY = 'app/device/SET_CHART_ACTIVE_PERIOD_KEY';

const initialState = {
    deviceTypes: null,
    devices: null,
    models: null,
    currentModel: null,
    deviceGroups: null,
    setDeviceTypesGroups: null,
    deviceChart: {
        year: dayjs().year(),
        month: dayjs().month() + 1,
        day: dayjs().date(),
        parameter: null,
        parameterType: 1,
    },
    deviceInfo: null,
    selectedDate: null,
    chartActivePeriodKey: HOUR_PERIOD_KEY,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DEVICE_GROUPS: {
            const deviceGroups = action.payload || {};

            return {
                ...state,
                deviceGroups,
            };
        }
        case SET_DEVICE_TYPES: {
            const {deviceTypes} = action.payload || {};

            return {
                ...state,
                deviceTypes,
            };
        }
        case SET_DEVICE_MODELS: {
            const models = action.payload || {};

            return {
                ...state,
                models,
            };
        }
        case SET_DEVICES: {
            const {devices} = action.payload || {};

            return {
                ...state,
                devices,
            };
        }
        case SET_DEVICE_CHART: {
            const deviceChart = action.payload || {};

            deviceChart.possibleParameters = action.payload.possibleParameters?.map((item) => ({
                localizedValue: parsePossibleParameter(item?.value),
                value: item.value,
                key: item?.key,
            }));

            return {
                ...state,
                deviceChart,
            };
        }
        case SET_DEVICE_PROFILE: {
            const deviceInfo = action.payload;

            return {
                ...state,
                deviceInfo,
            };
        }
        case SET_SELECTED_DATE: {
            const selectedDate = action.payload;
            const deviceChart = {...state.deviceChart, ...selectedDate};

            return {
                ...state,
                deviceChart,
            };
        }
        case SET_PARAMETER_TYPE: {
            const parameterType = action.payload;
            const deviceChart = {...state.deviceChart, ...parameterType};

            return {
                ...state,
                deviceChart,
            };
        }
        case SET_DEVICE_TYPES_UNASSIGNED: {
            const deviceTypesUnassigned = action.payload;

            return {
                ...state,
                deviceTypesUnassigned,
            };
        }
        case SET_DEVICE_TYPES_GROUPS: {
            const deviceTypesGroups = action.payload;

            return {
                ...state,
                deviceTypesGroups,
            };
        }
        case SET_CHART_ACTIVE_PERIOD_KEY: {
            const chartActivePeriodKey = action.payload;

            return {
                ...state,
                chartActivePeriodKey,
            };
        }

        case CLEAN_DEVICE_DATA: {
            return {
                ...state,
                ...initialState,
            };
        }
        case CLEAN_DEVICE_MODELS: {
            const models = null;

            return {
                ...state,
                models,
            };
        }
        case SET_CURRENT_MODEL: {
            return {
                ...state,
                currentModel: {...action.payload},
            };
        }
        default:
            return state;
    }
}

export const setDeviceGroups = (payload) => ({
    type: SET_DEVICE_GROUPS,
    payload,
});

export const setDeviceTypes = (payload) => ({
    type: SET_DEVICE_TYPES,
    payload: {deviceTypes: payload},
});

export const setDeviceModels = (payload) => ({
    type: SET_DEVICE_MODELS,
    payload,
});

export const setDevices = (payload) => ({
    type: SET_DEVICES,
    payload: {devices: payload},
});

export const setDeviceInfo = (payload) => ({
    type: SET_DEVICE_PROFILE,
    payload,
});

export const setDeviceChart = (payload) => ({
    type: SET_DEVICE_CHART,
    payload,
});

export const setSelectedDate = (payload) => ({
    type: SET_SELECTED_DATE,
    payload,
});

export const setParameterType = (payload) => ({
    type: SET_PARAMETER_TYPE,
    payload,
});

export const setDeviceTypesUnassigned = (payload) => ({
    type: SET_DEVICE_TYPES_UNASSIGNED,
    payload,
});

export const setDeviceTypesGroups = (payload) => ({
    type: SET_DEVICE_TYPES_GROUPS,
    payload,
});

export const setChartActivePeriodKey = (payload) => ({
    type: SET_CHART_ACTIVE_PERIOD_KEY,
    payload,
});

export const cleanDeviceData = () => ({
    type: CLEAN_DEVICE_DATA,
});

export const cleanDeviceModels = () => ({
    type: CLEAN_DEVICE_MODELS,
});
export const setCurrentModel = (payload) => ({
    type: SET_CURRENT_MODEL,
    payload,
});

const parsePossibleParameter = (value) => {
    const localizedStrings = getLocalizedStrings();

    switch (value) {
        case 'Q1_Unified': {
            return localizedStrings[localizationKeys.CHART_Q1_UNIFIED_VALUE];
        }
        case 'Q2': {
            return localizedStrings[localizationKeys.CHART_Q2_VALUE];
        }
        case 'V': {
            return localizedStrings[localizationKeys.CHART_V_VALUE];
        }
        case 'P': {
            return localizedStrings[localizationKeys.CHART_P_VALUE];
        }
        case 'G': {
            return localizedStrings[localizationKeys.CHART_G_VALUE];
        }
        case 'T1': {
            return localizedStrings[localizationKeys.CHART_T1_VALUE];
        }
        case 'T2': {
            return localizedStrings[localizationKeys.CHART_T2_VALUE];
        }
        case 'dT': {
            return localizedStrings[localizationKeys.CHART_dT_VALUE];
        }
        case 'Bd': {
            return localizedStrings[localizationKeys.CHART_Bd_VALUE];
        }
        case 'Fd': {
            return localizedStrings[localizationKeys.CHART_Fd_VALUE];
        }
        case 'Pd': {
            return localizedStrings[localizationKeys.CHART_Pd_VALUE];
        }
        case 'E': {
            return localizedStrings[localizationKeys.CHART_E_VALUE];
        }
        default:
            return 'Unknown';
    }
};
