import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {clearRequestParams, setRequestParams, UNDO_REQUEST_PARAMS} from '../state/ducks/requestParams';
import {makeSelectLastRequestParamsAction, makeSelectPresentRequestParams} from '../state/selectors/requestParams';
import {dispatch} from '../state/store';
import helpers from '../utils/helpers';
import useDidUpdate from './useDidUpdate';

const useRequestParams = ({handler, paramsType, deps = [], additionalInitParamProps}) => {
    const requestParams = useSelector(makeSelectPresentRequestParams(paramsType)) || {};
    const lastRequestParamsAction = useSelector(makeSelectLastRequestParamsAction()) || {};

    useDidUpdate(() => {
        const isLastActionFailed = lastRequestParamsAction === UNDO_REQUEST_PARAMS;
        const isTypeDifferent = requestParams?.paramsType !== paramsType;

        if (isLastActionFailed || isTypeDifferent) return;

        helpers.runFunction(handler, requestParams?.paramsProps);
    }, [requestParams]);

    useEffect(() => {
        if (!paramsType) return;

        dispatch(setRequestParams({paramsType, paramsProps: additionalInitParamProps}));

        return () => {
            dispatch(clearRequestParams(paramsType));
        };
    }, [paramsType, ...deps]);

    return [requestParams?.paramsProps];
};

export default useRequestParams;
