import * as formInputNames from '../../../../../consts/form/formInputNames';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import * as modalTypes from '../../../../../consts/modals/modalTypes';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import helpers from '../../../../../utils/helpers';

const getDCModelModalContent = (modalType, initialData) => {
    const localizedStrings = getLocalizedStrings();
    const isAddDCModelModalType = modalType == modalTypes.SETTINGS_ADD_DC_MODEL_TYPE;

    const submitButton = isAddDCModelModalType
        ? localizedStrings[localizationKeys.SETTINGS_DC_MODEL_ADD_FORM_SUBMIT_BUTTON]
        : localizedStrings[localizationKeys.SETTINGS_DC_MODEL_EDIT_FORM_SUBMIT_BUTTON];

    const title = isAddDCModelModalType
        ? localizedStrings[localizationKeys.SETTINGS_DC_MODEL_ADD_FORM_TITLE]
        : localizedStrings[localizationKeys.SETTINGS_DC_MODEL_EDIT_FORM_TITLE];

    const cancelButton = localizedStrings[localizationKeys.SETTINGS_DC_MODEL_FORM_CANCEL_BUTTON];

    const initialValues = {
        dcModelName: initialData?.title,
        dcModelDescription: initialData?.extraDescription,
    };

    const formContent = [
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.DC_MODEL_NAME,
            label: localizedStrings[localizationKeys.SETTINGS_DC_MODEL_FORM_NAME_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SETTINGS_DC_MODEL_FORM_NAME_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SETTINGS_DC_MODEL_FORM_NAME_FIELD_ERROR_MESSAGE],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.DC_MODEL_DESCRIPTION,
            label: localizedStrings[localizationKeys.SETTINGS_DC_MODEL_FORM_DESCRIPTION_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SETTINGS_DC_MODEL_FORM_DESCRIPTION_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SETTINGS_DC_MODEL_FORM_DESCRIPTION_FIELD_ERROR_MESSAGE],
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        initialValues,
        formContent,
    };
};

export default {getDCModelModalContent};
