import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import objectDefaultImage from '../../../../assets/images/default/objectDefaultImage.png';
import * as localizedKeys from '../../../consts/localization/localizationKeys';
import appRouterService from '../../../services/app/appRouterService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {IconLocationYellow} from '../../Icons';
import BuildingOptionsMenu from '../../TableOptionsMenu/BuildingOptionsMenu/BuildingOptionsMenu';

const ObjectImageCard = (props) => {
    const localizedStrings = getLocalizedStrings();
    const {objectData, roomsCount, children, className, isMenuButtonVisible} = props || {};

    const {name, address, image, year, organizationName, organizationID} = objectData || {};

    const [imageUri, setImageUri] = useState('');

    useEffect(() => {
        setImageUri('');
        if (image) {
            setImageUri('/data/' + image?.toString().replace(/\\/g, '/'));
        }
    }, [image]);

    return (
        <>
            <section className={cn(className)}>
                <div className={'relative'}>
                    <img className={`w-[347px] h-[194px] bg-[#d8e2e6]`} src={imageUri || objectDefaultImage} alt='' />
                    <div
                        className={
                            'absolute bottom-[10%] flex flex-col w-full justify-center items-center text-white drop-shadow-textBlack'
                        }
                    >
                        {name && <div className={' text-16 font-bold '}>{name}</div>}

                        {address && (
                            <div className={'flex leading-3 text-12'}>
                                <IconLocationYellow className={'m-auto mr-[6px]'} />
                                <span dangerouslySetInnerHTML={{__html: address}} />
                            </div>
                        )}
                    </div>
                    {isMenuButtonVisible && (
                        <div
                            className={
                                'cursor-pointer absolute top-[7px] right-[7px] flex justify-center items-center w-[34px] h-[34px] rounded-full shadow-card_menu bg-[#FFFFFFE5]'
                            }
                        >
                            <BuildingOptionsMenu />
                        </div>
                    )}
                </div>

                <div className={'flex flex-col justify-center items-center text-[#999999] py-3 text-11'}>
                    <div className={'flex justify-center items-center text-[#999999] text-11'}>
                        {!isNaN(year) && (
                            <>
                                <div className={'flex py-1'}>
                                    <span>{localizedStrings[localizedKeys.DRAWER_OBJECT_TEXT_YEAR]}: </span>
                                    <b className={'text-zambezi'}>&nbsp;{year}</b>
                                </div>
                                <div className={'flex w-[4px] h-[4px] bg-[#999999] my-auto mx-2 rounded-full'} />
                            </>
                        )}
                        {!isNaN(roomsCount) && (
                            <div className={'inline-flex'}>
                                <span>{localizedStrings[localizedKeys.DRAWER_OBJECT_TEXT_APARTMENTS]}: </span>
                                <b className={'text-zambezi'}>&nbsp;{roomsCount}</b>
                            </div>
                        )}
                    </div>
                    <h3
                        onClick={() => appRouterService.forwardToOrganizationPage(organizationID)}
                        className={'text-16 font-bold text-zambezi text-center py-1 cursor-pointer hover:text-primary'}
                    >
                        {organizationName}
                    </h3>
                </div>
                {children}
            </section>
        </>
    );
};

export default ObjectImageCard;
