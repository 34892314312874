import * as apiMethods from '../../consts/api/apiMethods';
import * as requestParamTypes from '../../consts/app/requestParamTypes';
import {
    setCurrentUserInfo,
    setCurrentUserResponsibilitiesData,
    setCurrentUserStatuses,
} from '../../state/ducks/currentUser';
import {makeSelectRequestParams} from '../../state/selectors/requestParams';
import {dispatch, getState} from '../../state/store';
import helpers from '../../utils/helpers';
import ApiClient from '../api/clients/apiClient';
import log from '../logger/log';
import {responsibilitiesListRequestDataMapping} from '../mappings/responsibilitiesMapping';
import {editCurrentUserRequestPostMapping, userStatusesRequestDataMapping} from '../mappings/userMappings';

const getCurrentUserInfoById = async ({id, onRequestSuccess}) =>
    await new ApiClient().callGet({
        methodName: apiMethods.USERS_GET_USER_INFO_BY_ID,
        requestConfig: {
            args: id,
        },
        onResponse: (data) => {
            dispatch(setCurrentUserInfo({...data, id}));
            helpers.runFunction(onRequestSuccess);
        },
    });

const getCurrentUserResponsibilitiesListByIdWithData = async ({userID, onRequestSuccess}) => {
    try {
        return await new ApiClient().callPost({
            methodName: apiMethods.USERS_GET_RESPONSIBILITIES_LIST_BY_ID,
            // mapper: ,
            action: setCurrentUserResponsibilitiesData,
            requestConfig: {
                args: userID,
                data: {
                    page: 1,
                    // size: 10,
                    query: '',
                    sortBy: 'UpdateTs',
                    direction: 1,
                    filters: {},
                },
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getCurrentUserResponsibilitiesListByIdWithData: ${e}`);
    }
};

const getCurrentUserResponsibilitiesListById = async ({userID, onRequestSuccess}) => {
    const requestParams = makeSelectRequestParams(requestParamTypes.RESPONSIBILITIES)(getState());

    try {
        return await new ApiClient().callPost({
            methodName: apiMethods.USERS_GET_RESPONSIBILITIES_LIST_BY_ID,

            action: setCurrentUserResponsibilitiesData,
            mapper: responsibilitiesListRequestDataMapping,

            requestConfig: {
                args: userID,
                data: requestParams,
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getUserResponsibilitiesById: ${e}`);
    }
};

const getCurrentUserStatuses = async (onRequestSuccess) => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.USERS_GET_USER_STATUSES,
            action: setCurrentUserStatuses,
            mapper: userStatusesRequestDataMapping,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getUserStatuses: ${e}`);
    }
};

const editCurrentUser = async ({data, onRequestSuccess}) => {
    try {
        await new ApiClient().callPut({
            methodName: apiMethods.USERS_EDIT_USER,
            requestConfig: {
                data: editCurrentUserRequestPostMapping(data),
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`editCurrentUser: ${e}`);
    }
};

const editCurrentUserImage = async ({file, userId, onRequestSuccess}) => {
    const formData = new FormData();

    formData.append('file', file);

    try {
        await new ApiClient().callPost({
            methodName: apiMethods.USER_IMAGE,
            requestConfig: {
                data: formData,
                args: userId,
            },
            contentType: 'multipart/form-data',
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`editCurrentUserImage: ${e}`);
    }
};

export default {
    getCurrentUserResponsibilitiesListById,
    getCurrentUserInfoById,
    getCurrentUserStatuses,
    editCurrentUser,
    editCurrentUserImage,
    getCurrentUserResponsibilitiesListByIdWithData,
};
