import * as userStatusesKeys from '../../consts/user/userStatuses';

export const SET_USERS_DATA = 'app/users/SET_USERS_DATA';
export const SET_USER_ROLES = 'app/users/SET_USER_ROLES';
export const SET_USER_INFO = 'app/users/SET_USER_INFO';
export const SET_USER_STATUSES = 'app/users/SET_USER_STATUSES';
export const SET_USER_RESPONSIBILITIES = 'app/users/SET_USER_RESPONSIBILITIES';
export const CLEAN_USERS_DATA = 'app/users/CLEAN_USERS_DATA';
export const SET_USER_RESPONSIBILITIES_DATA = 'app/users/SET_USER_RESPONSIBILITIES_DATA';

const initialState = {
    usersList: [],
    userRoles: null,
    userStatuses: null,
    allowedToEditStatuses: null,
    userInfo: null,
    userResponsibilitiesData: null,
    userResponsibilities: [],
    filters: null,
    totalRecords: null,
    totalFilteredRecords: null,
    hasNextPage: null,
    hasPreviousPage: null,
    page: 1,
    size: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_USER_INFO: {
            const {userInfo} = action.payload || {};

            return {...state, userInfo: {...state.userInfo, ...userInfo}};
        }

        case SET_USER_RESPONSIBILITIES_DATA: {
            const {userResponsibilitiesData} = action.payload || {};

            return {...state, userResponsibilitiesData};
        }

        case SET_USER_RESPONSIBILITIES: {
            const {items} = action.payload || {};

            return {...state, userResponsibilities: items};
        }
        case SET_USERS_DATA: {
            const {list, totalRecords, hasNextPage, hasPreviousPage, page, size, filters, totalFilteredRecords} =
                action.payload || {};

            return {
                ...state,
                totalRecords,
                totalFilteredRecords,
                hasNextPage,
                hasPreviousPage,
                filters,
                page,
                size,
                usersList: list,
            };
        }
        case SET_USER_ROLES: {
            const {userRoles} = action.payload || {};

            return {...state, userRoles};
        }
        case CLEAN_USERS_DATA: {
            return {...state, ...initialState};
        }
        case SET_USER_STATUSES: {
            const {userStatuses} = action.payload || {};

            const allowedToEditStatuses = userStatuses?.items?.filter(
                (status) => status.key !== +userStatusesKeys.WAITING_FOR_APPROVAL
            );

            return {...state, userStatuses, allowedToEditStatuses: {items: allowedToEditStatuses}};
        }
        default:
            return state;
    }
}

export const setUsersData = (payload) => ({
    type: SET_USERS_DATA,
    payload: {...payload},
});

export const setUserRoles = (payload) => ({
    type: SET_USER_ROLES,
    payload: {userRoles: payload},
});

export const setUserStatuses = (payload) => ({
    type: SET_USER_STATUSES,
    payload: {userStatuses: payload},
});

export const setUserInfo = (payload) => ({
    type: SET_USER_INFO,
    payload: {userInfo: payload},
});

export const setUserResponsibilities = (payload) => ({
    type: SET_USER_RESPONSIBILITIES,
    payload,
});

export const setUserResponsibilitiesData = (payload) => ({
    type: SET_USER_RESPONSIBILITIES_DATA,
    payload: {userResponsibilitiesData: payload},
});

export const cleanUsersData = () => ({
    type: CLEAN_USERS_DATA,
});
