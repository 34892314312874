import React from 'react';
import {useSelector} from 'react-redux';

import DeviceTypes from '../../../consts/devices/devicesTypes.ts';
import {makeSelectBuildingProfile} from '../../../state/selectors/buildings';
import {IconElectricity, IconHeatCostAllocator, IconSnowFlake, IconUtilsHeating, IconWaterDrop} from '../../Icons';

const indicatorIcons = [
    {type: DeviceTypes.Heat, icon: <IconUtilsHeating />},
    {type: DeviceTypes.ColdWater, icon: <IconWaterDrop />},
    {type: DeviceTypes.HotWater, icon: <IconWaterDrop fill='#F25555' />},
    {type: DeviceTypes.Electricity, icon: <IconElectricity fill='#0187F9' />},
    {type: DeviceTypes.Cooling, icon: <IconSnowFlake />},
    {type: DeviceTypes.HeatCostAllocator, icon: <IconHeatCostAllocator />},
];

const ObjectUtilitiesDrawerSection = () => {
    const buildingProfile = useSelector(makeSelectBuildingProfile());

    const {devicesCounts} = buildingProfile || {};

    return (
        <section className='flex flex-row justify-between py-5 text-11 font-semibold'>
            {indicatorIcons.map(({type, icon}) => (
                <ObjectUtilityItem key={type} icon={icon} count={devicesCounts[type] || 0} />
            ))}
        </section>
    );
};

const ObjectUtilityItem = ({icon, count}) => (
    <div className='flex flex-col items-center'>
        <div className='w-[45px] h-[45px] bg-[#ECF5F9] rounded-full flex items-center justify-center'>{icon}</div>
        <div className='mt-[5px] text-center text-zambezi'>{count}</div>
    </div>
);

export default ObjectUtilitiesDrawerSection;
