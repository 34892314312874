import React, {useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';

import * as routePaths from '../../consts/route/routePaths';
import AppLayout from '../App/AppLayout';
import loadableRoutes from './loadableRoutes';

const Root = () => <Redirect to={routePaths.MAP_PAGE} />;
const AppRoute = (props) => <AppLayout {...props} />;

const AppRoutes = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Switch>
            <AppRoute exact path={routePaths.ROOT} component={Root} />
            <AppRoute exact path={routePaths.AUTH_PAGE} component={loadableRoutes.AuthPage} />
            <AppRoute path={routePaths.AUTH_PAGE_PARAMETRIZED} component={loadableRoutes.AuthPage} />
            <AppRoute path={routePaths.SERVICES_KYIVENERGO_PAGE} component={loadableRoutes.KyivEnergoPage} />
            <AppRoute path={routePaths.SERVICES_KVK_PAGE} component={loadableRoutes.KvkPage} />
            <AppRoute path={routePaths.SERVICES_LVK_PAGE} component={loadableRoutes.LvkPage} />
            <AppRoute
                exact
                path={routePaths.SERVICES_ARS_ORGANIZATIONS_PAGE_PARAMETRIZED}
                component={loadableRoutes.ArsOrganizationPage}
            />
            <AppRoute
                exact
                path={routePaths.SERVICES_ARS_ORGANIZATIONS_PAGE}
                component={loadableRoutes.ArsOrganizationsPage}
            />
            <AppRoute path={routePaths.SERVICES_PAGE} component={loadableRoutes.ServicesPage} />
            <AppRoute path={routePaths.MAP_PAGE} component={loadableRoutes.MapPage} />
            <AppRoute path={routePaths.OBJECTS_PAGE} component={loadableRoutes.OrganizationsPage} />
            <AppRoute path={routePaths.ROOMER_OBJECTS_PAGE} component={loadableRoutes.RoomerObjectsPage} />
            <AppRoute path={routePaths.USERS_OBJECTS_PAGE_PARAMETRIZED} component={loadableRoutes.UsersObjectsPage} />
            <AppRoute path={routePaths.REPORTS_PAGE} component={loadableRoutes.ReportsPage} />
            <AppRoute exact path={routePaths.ORGANIZATION_PAGE} component={loadableRoutes.OrganizationPage} />
            <AppRoute
                exact
                path={routePaths.ORGANIZATION_PAGE_PARAMETRIZED}
                component={loadableRoutes.OrganizationPage}
            />
            <AppRoute path={routePaths.USERS_PAGE} component={loadableRoutes.UsersPage} />
            <AppRoute path={routePaths.SETTINGS_PAGE} component={loadableRoutes.SettingsPage} />
            <AppRoute path={routePaths.MONITORING_PAGE} component={loadableRoutes.MonitoringPage} />
            <AppRoute path={routePaths.PROFILE_PAGE} component={loadableRoutes.ProfilePage} />
            <AppRoute exact path={routePaths.DIAGNOSTIC_PAGE} component={loadableRoutes.DiagnosticPage} />
            <AppRoute path={routePaths.DIAGNOSTIC_PAGE_PARAMETRIZED} component={loadableRoutes.DiagnosticPage} />
            <AppRoute exact path={routePaths.BUILDING_PROFILE_PAGE} component={loadableRoutes.BuildingProfilePage} />
            <AppRoute
                exact
                path={routePaths.BUILDING_PROFILE_PAGE_PARAMETRIZED}
                component={loadableRoutes.BuildingProfilePage}
            />
            <AppRoute exact path={routePaths.DATA_COLLECTORS_PAGE} component={loadableRoutes.DataCollectorsPage} />
            <AppRoute
                path={routePaths.DATA_COLLECTORS_PAGE_PARAMETRIZED}
                component={loadableRoutes.DataCollectorsPage}
            />
            <AppRoute exact path={routePaths.ROOM_PROFILE_PAGE} component={loadableRoutes.RoomProfilePage} />
            <AppRoute path={routePaths.ROOM_PROFILE_PAGE_PARAMETRIZED} component={loadableRoutes.RoomProfilePage} />
            <Route exact path={routePaths.WILDCARD}>
                <Redirect to={routePaths.ROOT} />
            </Route>
        </Switch>
    );
};

export default AppRoutes;
