import React, {memo, useEffect, useState} from 'react';
import {MapContainer} from 'react-leaflet';
import {connect, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import AntButton from '../../../../components/AntButton/AntButton';
import openStreetApiService from '../../../../services/map/openStreetApiService';
import {setCustomMarker} from '../../../../state/ducks/maps';
import {makeSelectBuildingProfile, makeSelectCurrentBuilding} from '../../../../state/selectors/buildings';
import {selectCustomMarker} from '../../../../state/selectors/maps';
import {makeSelectCityNameByCityID} from '../../../../state/selectors/organizations';
import {dispatch} from '../../../../state/store';
import helpers from '../../../../utils/helpers';
import MapContent from './components/MapContent';
import MapForm from './components/MapForm';
import {getCoordinatesByCityAndAddress} from './mapModalService';

const defaultCenterCoordinates = [50.4457, 30.5204];
const streetAreaZoomValue = 15;

const mapStateToProps = createStructuredSelector({
    currentBuilding: makeSelectCurrentBuilding(),
    buildingProfile: makeSelectBuildingProfile(),
    customMarker: selectCustomMarker,
});

const MapModal = memo(function MapModal({onCancel, form, customMarker, buildingProfile, currentBuilding}) {
    const formFields = form?.getFieldsValue() || {};

    // cityID field is priority, then get it from buildingProfile if no such in form field;
    // todo: remove last OR condition (currentBuilding?.cityID); response for buildingProfile should include cityID field (waiting from backend);
    const cityID = formFields?.cityID || buildingProfile?.cityID || currentBuilding?.cityID;
    const cityName = useSelector(makeSelectCityNameByCityID(cityID));

    const [zoom, setZoom] = useState(streetAreaZoomValue);

    useEffect(() => {
        // Set initial marker on modal open:
        (async () => {
            const latitude = formFields?.latitude || buildingProfile?.latitude;
            const longitude = formFields?.longitude || buildingProfile?.longitude;
            const address = formFields?.address || buildingProfile?.address;

            // coordinates - first priority; check them first;
            if (latitude && longitude) {
                // if coordinates exists - search address by coordinates and set marker on map;
                await setMarker(latitude, longitude, address);
            } else {
                // (create new building case)
                // if coordinates fields are empty in form, try to get coordinates by city and address;
                await getCoordinatesByCityAndAddress(cityName, address);
            }
        })();

        return () => {
            dispatch(setCustomMarker(null));
        };
    }, []);

    const closeModal = () => helpers.runFunction(onCancel);

    const handleSubmit = () => {
        const {latitude, longitude, address} = customMarker || {};

        if (form && customMarker) {
            form.setFieldsValue({
                latitude,
                longitude,
                address,
            });
        }

        closeModal();
    };

    const setMarker = async (latitude, longitude, address) => {
        const {address: responseAddress = {}} = await openStreetApiService.getAddressByCoordinates(latitude, longitude);
        const {road = '', house_number = ''} = responseAddress || {};

        const addressString = `${road}${house_number && ', ' + house_number}`;

        const markerPayload = {
            latitude,
            longitude,
            address: addressString,
        };

        if (address) markerPayload.address = address;

        dispatch(setCustomMarker(markerPayload));
    };

    const onMapClick = async (event) => {
        const [latitude, longitude] = [event.latlng.lat, event.latlng.lng];

        await setMarker(latitude, longitude);
    };

    const onFormSearchAddressClick = async (address) => {
        const response = await openStreetApiService.getCoordinatesByAddress(address);

        if (response) {
            await setMarker(response?.lat, response?.lon);
        }
    };

    const formatAddress = (address) => {
        if (address.includes(cityName)) {
            return address.substring(0, address.indexOf(',') + 2);
        } else {
            return `${cityName}, ${address}`;
        }
    };

    const modalTitle = `Виберіть будинок ${cityName ? 'у місті ' + cityName : ''}`;
    const confirmBtnText = 'Підтвердити';
    const cancelBtnText = 'Відмінити';

    const updatedCustomMarker = {...customMarker};

    if (updatedCustomMarker && updatedCustomMarker.address) {
        updatedCustomMarker.addressDescription = {};
        updatedCustomMarker.addressDescription.address = formatAddress(updatedCustomMarker.address);
        updatedCustomMarker.addressDescription.cityName = cityName;
    }

    return (
        <>
            <p className={'text-24 py-2 text-center font-semibold'}>{modalTitle}</p>

            <MapContainer
                className={'flex w-full h-[300px] xl:h-[350px] xxl:h-[500px]'}
                center={defaultCenterCoordinates}
                zoom={zoom}
                scrollWheelZoom
            >
                <MapContent
                    zoom={zoom}
                    setZoom={setZoom}
                    latitude={customMarker?.latitude}
                    longitude={customMarker?.longitude}
                    onMapClick={onMapClick}
                />
            </MapContainer>

            <div className={'flex flex-col py-5'}>
                <MapForm
                    latitude={customMarker?.latitude}
                    longitude={customMarker?.longitude}
                    addressDescription={updatedCustomMarker?.addressDescription}
                    onFormSearchAddressClick={onFormSearchAddressClick}
                />
            </div>

            <div className='flex flex-row justify-between pb-5'>
                <AntButton disabled={!customMarker?.address} primary onClick={handleSubmit}>
                    {confirmBtnText}
                </AntButton>
                <AntButton onClick={onCancel}>{cancelBtnText}</AntButton>
            </div>
        </>
    );
});

export default connect(mapStateToProps)(MapModal);
