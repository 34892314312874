// import debounce from 'lodash.debounce';

import appConfig from '../../../config/appConfig';
import * as apiMethods from '../../../consts/api/apiMethods';
import * as httpErrorsTypes from '../../../consts/app/httpErrorsTypes';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {undoRequestParamsAction} from '../../../state/ducks/requestParams';
import {dispatch} from '../../../state/store';
import arrayUtils from '../../../utils/arrayUtils';
import stringUtils from '../../../utils/stringUtils';
import urlUtils from '../../../utils/urlUtils';
import {showErrorNotification} from '../../app/notificationService';
import {getLocalizedStrings} from '../../localization/localizationService';
import log from '../../logger/log';
import server from '../../server/server';
import storageService from '../../storage/storageService';

// const debouncedShowErrorNotifications = debounce(showErrorNotification, 300);

// let lastHttpErrorType = null;

export default class ApiClient {
    constructor() {
        this.ignoreMethods = [];
        this.undoableMethods = [
            apiMethods.GROUP_BUILDINGS,
            apiMethods.DATA_COLLECTORS_LIST,
            apiMethods.USERS_GET_USERS_LIST,
            apiMethods.ORGANIZATIONS_LIST,
            apiMethods.REPORTS_LIST,
            apiMethods.DIAGNOSTIC_LIST,
            apiMethods.USERS_GET_RESPONSIBILITIES_LIST_BY_ID,
            apiMethods.SETTINGS_MANUFACTURERS_LIST,
            apiMethods.SERVICES_ARS_LIST,
            apiMethods.USERS_GET_RESPONSIBILITIES_LIST_BY_ID,
            apiMethods.DATA_COLLECTORS_BY_BUILDING_ID,
        ];
    }

    async getHeaders(contentType) {
        const apiAuthToken = storageService.getAccessTokenFromStorage();

        return {
            'Content-Type': contentType || 'application/json',
            ...(apiAuthToken ? {Authorization: 'Bearer ' + apiAuthToken} : {}),
        };
    }

    ignoreCheckError({ignoreErrorCodes, methodUrl, status} = {}) {
        return arrayUtils.toArray(ignoreErrorCodes).includes(status) || this.ignoreMethods.includes(methodUrl);
    }

    async callRequest(options) {
        try {
            const {contentType, ...restOptions} = options;
            const response = await this.callMethod(options, contentType);

            if (!response) return null;

            const {action, mapper, onResponse} = restOptions;
            let {data} = response;

            if (mapper) data = mapper(data);
            if (action) dispatch(action(data));
            if (onResponse) onResponse(data);

            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async callMethod(callOptions, contentType) {
        const {httpMethod, methodUrl, requestConfig, skipLoader = false, ...restOptions} = callOptions;
        const url = this.getApiUrl(methodUrl);
        const headers = requestConfig?.headers ?? (await this.getHeaders(contentType));
        const params = requestConfig?.params ?? {};
        const data = requestConfig?.data ?? {};

        try {
            const response = await httpMethod(url, {headers, params, data, skipLoader});

            log.info(
                `${this.constructor.name}: request: '${methodUrl}' params: ${JSON.stringify(
                    params
                )} has successful response`
            );
            return response;
        } catch (e) {
            return await this.errorCheck({
                error: e,
                methodUrl,
                httpMethod,
                requestConfig,
                ...restOptions,
            });
        }
    }

    async errorCheck(options) {
        const {error, methodUrl, methodName, ...restOptions} = options;
        const {response} = error || {};
        const {data, status} = response || {};

        const errorMessage =
            response?.data?.error || getLocalizedStrings()[localizationKeys.NOTIFICATION_FAILED_GLOBAL_CANT_GET_DATA];

        const isShouldBeIgnored = this.ignoreCheckError({
            ignoreErrorCodes: restOptions.ignoreErrorCodes,
            methodUrl,
            status,
        });

        if (isShouldBeIgnored) return;

        // const handleErrorNotification =
        //     lastHttpErrorType === status ? debouncedShowErrorNotifications : showErrorNotification;

        // lastHttpErrorType = status;

        switch (status) {
            case httpErrorsTypes.NOT_AUTHORIZED:
                break;
            case httpErrorsTypes.BAD_REQUEST:
                showErrorNotification(errorMessage, {
                    description: data?.invalidations?.join('\n') || [],
                });
                break;
            case httpErrorsTypes.FORBIDDEN:
            case httpErrorsTypes.NOT_FOUND:
            case httpErrorsTypes.SERVER_ERROR:
                showErrorNotification(errorMessage);
                break;
            default:
                showErrorNotification(errorMessage);
                break;
        }

        if (this.undoableMethods.includes(methodName) && status !== httpErrorsTypes.NOT_AUTHORIZED) {
            dispatch(undoRequestParamsAction());
        }

        throw error;
    }

    getApiUrl(methodName) {
        return urlUtils.join(appConfig.getApiUrl(), methodName);
    }

    getMethodUrl({methodName, requestConfig}) {
        const args = requestConfig?.args;

        return args ? stringUtils.formatString(methodName, ...arrayUtils.toArray(args)) : methodName;
    }

    callGet = (options) =>
        this.callRequest({
            ...options,
            methodUrl: this.getMethodUrl(options),
            httpMethod: server.get,
        });

    callPost = (options) =>
        this.callRequest({
            ...options,
            methodUrl: this.getMethodUrl(options),
            httpMethod: server.post,
        });

    callDelete = (options) =>
        this.callRequest({
            ...options,
            methodUrl: this.getMethodUrl(options),
            httpMethod: server.requestDelete,
        });

    callPut = (options) =>
        this.callRequest({
            ...options,
            methodUrl: this.getMethodUrl(options),
            httpMethod: server.put,
        });
}
