/*
    Created for processing queue of 401-error requests using axios interceptors.
    Requests, which synchronously sent and failed with 401 error will be added to queue and then re-sent from queue with updated accessToken.
    Asynchronous requests will not require such logic, they will be processed normally.
*/

import * as localizationKeys from '../../../consts/localization/localizationKeys';
import appService from '../../app/appService';
import {showErrorNotification} from '../../app/notificationService';
import authService from '../../auth/authService';
import {getLocalizedStrings} from '../../localization/localizationService';
import httpStatusTypesService from '../httpStatusTypesService';

export const authResponseInterceptorHandler = async (error, axiosInstance) => {
    const originalRequest = error.config;
    const isNotAuthorized = httpStatusTypesService.isNotAuthorized(error?.response?.status);
    const isNotBadRequest = !httpStatusTypesService.isBadRequest(error?.response?.status);
    const isForbidden = httpStatusTypesService.isForbidden(error?.response?.status);

    if (
        ((isNotAuthorized && originalRequest._retry === undefined) || originalRequest._retry >= 0) &&
        isNotBadRequest &&
        !isForbidden
    ) {
        /// after the last attempt when we got a 401 - logout
        if (originalRequest._retry <= 0 && isNotAuthorized) {
            appService.logout();
            showErrorNotification(getLocalizedStrings()[localizationKeys.NOTIFICATION_ERROR_FETCHING_DATA]);
            return;
        }

        if (!originalRequest._retry) {
            originalRequest._retry = 2; // attempts = n - 1 (if 3 attempts then it needs to be specified 2)
        }

        const {accessToken} = await authService.fetchToken();

        if (accessToken) {
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        }

        originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;

        originalRequest._retry--;

        return axiosInstance(originalRequest);
    }

    return Promise.reject(error);
};
