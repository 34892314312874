import * as openStreetApiMethods from '../../consts/api/nominatimApiMethods';
import * as localizationKeys from '../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {saveRequestResponse} from '../../state/ducks/maps';
import {makeSelectResponseByParams} from '../../state/selectors/maps';
import {dispatch, getState} from '../../state/store';
import OpenStreetApiClient from '../api/clients/openStreetApiClient';
import {showWarningNotification} from '../app/notificationService';
import log from '../logger/log';

const limit = 1;
const country = 'UA';
const format = 'json';

const getAddressByCoordinates = async (lat, lon) => {
    const params = {
        format,
        lat,
        lon,
        'accept-language': 'uk',
    };

    // const existingResponse = makeSelectResponseByParams(params)(getState());
    // if (existingResponse) return existingResponse;

    try {
        const response = await new OpenStreetApiClient().callGet({
            methodName: openStreetApiMethods.REVERSE,
            //onResponse: (response) => dispatch(saveRequestResponse(params, response)),
            requestConfig: {
                params,
            },
        });

        return response;
    } catch (error) {
        console.error('Failed to reverse geocode:', error);
        return null;
    }
};

const getCoordinatesByAddress = async (address, city) => {
    const localizedStrings = getLocalizedStrings();

    const params = {
        format,
        limit,
        'accept-language': 'uk',
    };

    if (city && address) {
        params.city = city;
        params.street = address;
        params.country = country;
    } else {
        params.q = `${city || ''} ${address || ''}`;
    }

    //const existingResponse = makeSelectResponseByParams(params)(getState());
    // if (existingResponse) return existingResponse[0];

    try {
        const response = await new OpenStreetApiClient().callGet({
            methodName: openStreetApiMethods.SEARCH,
            //onResponse: (response) => dispatch(saveRequestResponse(params, response)),
            requestConfig: {
                params,
            },
        });

        if (response && response.length > 0) {
            const {lat, lon, ...rest} = response[0] || {};

            return {
                lat: +lat,
                lon: +lon,
                ...rest,
            };
        } else {
            showWarningNotification(localizedStrings[localizationKeys.MAP_ADDRESS_SEARCH_FAILED]);
            log.info(`openStreetApiService: No coordinates for such address found`);

            return null;
        }
    } catch (error) {
        log.error('Error fetching coordinates:', error);
    }
    return null;
};

export default {
    getCoordinatesByAddress,
    getAddressByCoordinates,
};
