import {ExclamationCircleFilled} from '@ant-design/icons';
import cn from 'classnames';
import React, {useEffect, useRef} from 'react';

import AntButton from '../../../../components/AntButton/AntButton';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import {CONFIRM_MODAL_TYPE} from '../../../../consts/modals/modalTypes';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import {hideModalAction} from '../../../../state/ducks/modal';
import {dispatch} from '../../../../state/store';
import helpers from '../../../../utils/helpers';

const ConfirmModal = (props) => {
    const {onOk, onCancel, confirmModalTitle} = props;
    const localizedStrings = getLocalizedStrings();
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleCancel();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleOk = async () => {
        await helpers.runFunction(onOk);
        dispatch(hideModalAction(CONFIRM_MODAL_TYPE));
    };

    const handleCancel = () => {
        helpers.runFunction(onCancel);
        dispatch(hideModalAction(CONFIRM_MODAL_TYPE));
    };

    const submitButtonText = localizedStrings[localizationKeys.CONFIRM_MODAL_BUTTON_TEXT_SUBMIT];
    const cancelButtonText = localizedStrings[localizationKeys.CONFIRM_MODAL_BUTTON_TEXT_CANCEL];
    const titleTextContent = confirmModalTitle || localizedStrings[localizationKeys.CONFIRM_MODAL_DEFAULT_TITLE_TEXT];

    return (
        <div ref={modalRef} className='flex-column m-auto px-[55px] py-5'>
            <div className={'w-full text-center'}>
                <ExclamationCircleFilled className={'text-[60px] text-orange-peel'} />
            </div>
            <p className={'text-center text-24 my-8 font-semibold'}>{titleTextContent}</p>
            <div>
                <div className={cn('flex justify-between items-center')}>
                    <AntButton className='h-[50px]' onClick={handleCancel} cancelHover bordered>
                        {cancelButtonText}
                    </AntButton>

                    <AntButton className='h-[50px]' htmlType='submit' primary onClick={handleOk}>
                        {submitButtonText}
                    </AntButton>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ConfirmModal);
