const unique = (a) => Array.from(new Set(a));

const equals = (array1, array2) => {
    if (!array1 || !array2) return false;
    if (array1.length !== array2.length) return false;
    for (let i = 0, l = array1.length; i < l; i++) {
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
            if (!array1[i].equals(array2[i])) return false;
        } else if (array1[i] !== array2[i]) {
            return false;
        }
    }
    return true;
};

const toArray = (a) => (a !== undefined ? (Array.isArray(a) ? a : [a]) : []);

const splitArray = (input, itemsPerChunk) => {
    return input.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / itemsPerChunk);

        if (!result[chunkIndex]) {
            result[chunkIndex] = [];
        }

        result[chunkIndex].push(item);

        return result;
    }, []);
};

const fillArray = (length, elements = []) => {
    if (elements.length >= length) {
        return elements.slice(0, length);
    } else {
        const nullElementsLength = length - elements.length;
        const arrayWithNulls = Array(nullElementsLength).fill(null);

        return [...arrayWithNulls, ...elements];
    }
};

const isArrayNotEmpty = (array) => array?.length > 0;
const getRandomArrayItem = (array) => array[Math.floor(Math.random() * array.length)];

export default {
    equals,
    splitArray,
    toArray,
    unique,
    fillArray,
    isArrayNotEmpty,
    getRandomArrayItem,
};
