import React from 'react';

import Icon from '../Icon/Icon';

const IconEye = ({isWhite, props}) => {
    const fillColor = isWhite ? 'var(--color-white)' : 'var(--color-santas-gray)';

    return (
        <Icon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.54256 10.8556C4.36847 7.51686 7.91882 5.25 12 5.25C16.0812 5.25 19.6316 7.51694 21.4575 10.8557C21.8475 11.569 21.8475 12.4312 21.4574 13.1444C19.6315 16.4831 16.0812 18.75 12 18.75C7.91882 18.75 4.36843 16.4831 2.54254 13.1443C2.15248 12.431 2.15249 11.5688 2.54256 10.8556ZM9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12ZM12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25Z'
                fill={fillColor}
            />
        </Icon>
    );
};

export default IconEye;
