import React from 'react';

import Icon from '../Icon/Icon';

const IconWaterDrop = (props) => {
    const fill = props?.fill || '#0187F9';

    return (
        <Icon width='21' height='26' viewBox='0 0 21 26' fill='none' {...props}>
            <path
                d='M10.5 25.4166C7.76599 25.4166 5.36048 24.4642 3.28348 22.5594C1.20562 20.6538 0.166687 18.0756 0.166687 14.8249C0.166687 12.7798 0.941687 10.5624 2.49169 8.17284C4.04169 5.78325 6.38821 3.19992 9.53127 0.422835C9.66043 0.315197 9.81113 0.229085 9.98335 0.164502C10.1556 0.0999187 10.3278 0.067627 10.5 0.067627C10.6722 0.067627 10.8445 0.0999187 11.0167 0.164502C11.1889 0.229085 11.3396 0.315197 11.4688 0.422835C14.6118 3.19992 16.9584 5.78325 18.5084 8.17284C20.0583 10.5624 20.8333 12.7798 20.8333 14.8249C20.8333 18.0756 19.7944 20.6538 17.7166 22.5594C15.6396 24.4642 13.234 25.4166 10.5 25.4166ZM10.5 22.8333C12.6743 22.8333 14.5093 22.0905 16.0051 20.6051C17.5017 19.1197 18.25 17.193 18.25 14.8249C18.25 13.2964 17.6149 11.5471 16.3448 9.57688C15.0747 7.60752 13.1264 5.41728 10.5 3.00617C7.87363 5.41728 5.92537 7.60752 4.65523 9.57688C3.38509 11.5471 2.75002 13.2964 2.75002 14.8249C2.75002 17.193 3.49789 19.1197 4.99364 20.6051C6.49025 22.0905 8.32571 22.8333 10.5 22.8333ZM10.8552 21.5416C11.1136 21.5201 11.3344 21.418 11.5179 21.2355C11.7004 21.052 11.7917 20.8312 11.7917 20.5728C11.7917 20.2715 11.6948 20.0295 11.5011 19.8469C11.3073 19.6635 11.0597 19.5826 10.7584 19.6041C9.87571 19.6687 8.93925 19.4267 7.94898 18.8782C6.9587 18.3288 6.33439 17.3329 6.07606 15.8905C6.033 15.6537 5.91977 15.46 5.73635 15.3093C5.5538 15.1586 5.34412 15.0833 5.10731 15.0833C4.80592 15.0833 4.55835 15.1961 4.3646 15.4217C4.17085 15.6481 4.10627 15.9121 4.17085 16.2135C4.53682 18.1725 5.39794 19.5718 6.75419 20.4114C8.11044 21.251 9.47745 21.6277 10.8552 21.5416Z'
                fill={fill}
            />
        </Icon>
    );
};

export default IconWaterDrop;
