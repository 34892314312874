import React from 'react';

import Icon from '../Icon/Icon';

const IconEdit = (props) => {
    return (
        <Icon width='16' height='17' viewBox='0 0 16 17' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.21548 3.18315C9.93757 2.45997 11.1088 2.45952 11.8314 3.18216L13.5081 4.85889C14.2246 5.57536 14.232 6.73525 13.5248 7.46087L7.31198 13.8353C6.83724 14.3224 6.18612 14.597 5.50621 14.597L3.65434 14.5969C2.79356 14.5969 2.10613 13.8793 2.14233 13.0186L2.22183 11.1285C2.24841 10.4966 2.51102 9.89777 2.95771 9.45041L7.45394 4.94736C7.45867 4.82475 7.5078 4.7035 7.60132 4.60983C7.69485 4.51616 7.81597 4.46691 7.93848 4.46209L9.21548 3.18315ZM3.67141 10.1641L8.0532 5.77568L10.8507 8.57318C10.8823 8.60474 10.917 8.63124 10.9538 8.65268L6.58972 13.1303C6.30487 13.4225 5.9142 13.5873 5.50626 13.5873L3.65438 13.5872C3.36745 13.5872 3.13831 13.348 3.15038 13.0611L3.22988 11.171C3.24583 10.7918 3.4034 10.4325 3.67141 10.1641ZM12.8025 6.75585L11.6374 7.95132C11.6171 7.91836 11.5927 7.88722 11.5641 7.85867L8.76663 5.06117L9.92918 3.89685C10.2574 3.56813 10.7898 3.56793 11.1182 3.8964L12.795 5.57313C13.1206 5.8988 13.124 6.42602 12.8025 6.75585ZM13.5897 14.2204C13.8683 14.2204 14.0942 13.9944 14.0942 13.7155C14.0942 13.4367 13.8683 13.2107 13.5897 13.2107H9.57099C9.29238 13.2107 9.06652 13.4367 9.06652 13.7155C9.06652 13.9944 9.29238 14.2204 9.57099 14.2204H13.5897Z'
                fill='#3B3B3B'
            />
        </Icon>
    );
};

export default IconEdit;
