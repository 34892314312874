import {useEffect, useRef} from 'react';

const useDidUpdate = (effect, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) effect();
        else didMount.current = true;
    }, deps);
};

export default useDidUpdate;
