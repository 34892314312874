import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';

import Form from '../../../../components/Form/Form';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import log from '../../../../services/logger/log';
import helpers from '../../../../utils/helpers';
import organizationModalService from './organizationModalService';

const OrganizationModal = ({modalType, onCancel}) => {
    const [form] = AntForm.useForm();
    const localizedStrings = getLocalizedStrings();
    const modalProps = organizationModalService.getModalProps(modalType);
    const formFields = organizationModalService.getFormFields(modalProps);
    const {title, initialValues, onSubmit, submitBtnText} = modalProps || {};

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const handleSelect = (value, name) => {
        form.setFieldsValue({[name]: value});
    };

    const closeModal = () => {
        helpers.runFunction(onCancel);
        form.resetFields();
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();

            helpers.runFunction(onSubmit, values);
            closeModal();

            form.resetFields();
        } catch (info) {
            log.info('Validate Failed:', info);
        }
    };
    const handleRadioChange = (value, name) => {
        form.setFieldsValue({[name]: value});
    };

    return (
        <Form
            handleRadioChange={handleRadioChange}
            handleSelect={handleSelect}
            closeModal={onCancel}
            initialValues={initialValues}
            submitButton={submitBtnText}
            onFinish={handleOk}
            title={title}
            formFields={formFields}
            cancelButton={localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_CANCEL_BTN_TEXT]}
            formInstance={form}
        />
    );
};

export default OrganizationModal;
