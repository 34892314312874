import appConfig from '../../config/appConfig';

//  overrides console messages (used deprecated props etc.) if that includes "antd" substr
//  globally configurable

// todo: Ostrovskyi D. - locally check and debug all of the warnings/errors from antd and fix according to recommendations
const antdConsoleMessagesOverride = (level = 'error') => {
    const originalConsole = console[level];
    const isAntdWarningsEnabled = appConfig.getAntdWarningsEnabled();

    if (!isAntdWarningsEnabled) return;

    console[level] = (...args) => {
        if (args.length > 0 && typeof args[0] === 'string' && args[0].includes('antd')) {
            // If the warning message includes 'antd', it's suppressed
            return;
        }
        originalConsole(...args);
    };
};

export default antdConsoleMessagesOverride;
