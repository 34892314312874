import * as apiMethods from '../../consts/api/apiMethods';
import * as requestParamTypes from '../../consts/app/requestParamTypes';
import {setDeviceGroups, setDeviceModels, setDeviceTypesUnassigned} from '../../state/ducks/device';
import {initialParams} from '../../state/ducks/requestParams';
import {
    setDataCollectorModels,
    setDeviceGroupProfile,
    setManufacturers,
    setRoleClaimsBlocks,
    setRoomTypes,
} from '../../state/ducks/settings';
import {makeSelectRequestParams} from '../../state/selectors/requestParams';
import {dispatch, getState} from '../../state/store';
import ApiClient from '../api/clients/apiClient';
import log from '../logger/log';
import {
    addCityRequestMapping,
    addDCModelRequestMapping,
    addDeviceGroupRequestMapping,
    addDeviceModelRequestMapping,
    addRoomTypeRequestMapping,
    deleteDeviceGroupRequestMapping,
    deleteDeviceModelRequestMapping,
    editDCModelRequestMapping,
    editDeviceGroupRequestMapping,
    editDeviceModelRequestMapping,
    editRoomTypeRequestMapping,
    manufacturersListMapping,
    manufacturersListRequestDataMapping,
    roleClaimsMapping,
} from '../mappings/settingMappings';

const getRoomTypes = async () => {
    await new ApiClient().callGet({
        methodName: apiMethods.SETTINGS_ROOM_TYPES,
        action: setRoomTypes,
    });
};

const getDeviceGroupsList = async () => {
    await new ApiClient().callGet({
        methodName: apiMethods.SETTINGS_DEVICE_GROUPS,
        action: setDeviceGroups,
    });
};

const getDeviceGroupByID = async ({groupID, onRequestSuccess}) => {
    await new ApiClient().callGet({
        methodName: apiMethods.SETTINGS_DEVICE_GROUP_BY_ID,
        requestConfig: {
            args: groupID,
        },
        action: setDeviceGroupProfile,
        onResponse: onRequestSuccess,
    });
};

const getDevicesModelsByGroupID = async (groupID) => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.DEVICES_MODELS_BY_GROUP_ID,
            action: setDeviceModels,
            requestConfig: {
                args: groupID,
            },
        });
    } catch (e) {
        dispatch(setDeviceModels([]));
        log.error(`getDevicesModelsByGroupID: ${e}`);
    }
};

const addDeviceGroup = async ({values, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SETTINGS_DEVICE_GROUPS,
        requestConfig: {
            data: addDeviceGroupRequestMapping(values),
        },
        onResponse: onRequestSuccess,
    });
};

const editDeviceGroup = async ({values, onRequestSuccess}) => {
    await new ApiClient().callPut({
        methodName: apiMethods.SETTINGS_DEVICE_GROUPS,
        requestConfig: {
            data: editDeviceGroupRequestMapping(values),
        },
        onResponse: onRequestSuccess,
    });
};

const deleteDeviceGroup = async ({groupID, onRequestSuccess}) => {
    await new ApiClient().callDelete({
        methodName: apiMethods.SETTINGS_DEVICE_GROUPS,
        requestConfig: {
            data: deleteDeviceGroupRequestMapping(groupID),
        },
        onResponse: onRequestSuccess,
    });
};

const getDeviceTypesUnassigned = async () => {
    await new ApiClient().callGet({
        methodName: apiMethods.SETTINGS_DEVICE_TYPES_UNASSIGNED,
        action: setDeviceTypesUnassigned,
    });
};

const addDeviceModel = async ({values, groupID, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.DEVICE_MODELS,
        requestConfig: {
            data: addDeviceModelRequestMapping(values, groupID),
        },
        onResponse: onRequestSuccess,
    });
};

const editDeviceModel = async ({values, onRequestSuccess}) => {
    await new ApiClient().callPut({
        methodName: apiMethods.DEVICE_MODELS,
        requestConfig: {
            data: editDeviceModelRequestMapping(values),
        },
        onResponse: onRequestSuccess,
    });
};

const deleteDeviceModel = async ({modelId, onRequestSuccess}) => {
    await new ApiClient().callDelete({
        methodName: apiMethods.DEVICE_MODELS,
        requestConfig: {
            data: deleteDeviceModelRequestMapping(modelId),
        },
        onResponse: onRequestSuccess,
    });
};

const getManufacturersList = async (name) => {
    const stateRequestParams = makeSelectRequestParams(requestParamTypes.MANUFACTURERS)(getState());
    const requestParams = !stateRequestParams ? initialParams : stateRequestParams;

    await new ApiClient().callPost({
        methodName: apiMethods.SETTINGS_MANUFACTURERS_LIST,
        action: setManufacturers,
        mapper: manufacturersListMapping,
        requestConfig: {
            data: manufacturersListRequestDataMapping(requestParams),
            args: name,
        },
    });
};

const addRoomType = async ({values, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SETTINGS_ROOM_TYPES,
        requestConfig: {
            data: addRoomTypeRequestMapping(values),
        },
        onResponse: onRequestSuccess,
    });
};

const deleteRoomType = async ({roomTypeID, onRequestSuccess}) => {
    await new ApiClient().callDelete({
        methodName: apiMethods.SETTINGS_ROOM_TYPES,
        requestConfig: {
            data: {
                roomTypeID,
            },
        },
        onResponse: onRequestSuccess,
    });
};

const editRoomType = async ({values, onRequestSuccess}) => {
    await new ApiClient().callPut({
        methodName: apiMethods.SETTINGS_ROOM_TYPES,
        requestConfig: {
            data: editRoomTypeRequestMapping(values),
        },
        onResponse: onRequestSuccess,
    });
};

const getDataCollectorModels = async () => {
    await new ApiClient().callGet({
        methodName: apiMethods.SETTINGS_DATA_COLLECTOR_MODELS,
        action: setDataCollectorModels,
    });
};

const addDataCollectorModel = async ({values, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SETTINGS_DATA_COLLECTOR_MODELS,
        requestConfig: {
            data: addDCModelRequestMapping(values),
        },
        onResponse: onRequestSuccess,
    });
};

const deleteDataCollectorModel = async ({uspdModelID, onRequestSuccess}) => {
    await new ApiClient().callDelete({
        methodName: apiMethods.SETTINGS_DATA_COLLECTOR_MODELS,
        requestConfig: {
            data: {
                uspdModelID,
            },
        },
        onResponse: onRequestSuccess,
    });
};

const editDataCollectorModel = async ({values, onRequestSuccess}) => {
    await new ApiClient().callPut({
        methodName: apiMethods.SETTINGS_DATA_COLLECTOR_MODELS,
        requestConfig: {
            data: editDCModelRequestMapping(values),
        },
        onResponse: onRequestSuccess,
    });
};

const addCity = async ({values, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SETTINGS_CITIES,
        requestConfig: {
            data: addCityRequestMapping(values),
        },
        onResponse: onRequestSuccess,
    });
};

const getRoleClaims = async () => {
    await new ApiClient().callGet({
        methodName: apiMethods.SETTINGS_ROLES_CLAIMS,
        action: setRoleClaimsBlocks,
        mapper: roleClaimsMapping,
    });
};
const postRoleClaims = async (formData, onRequestSuccess) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SETTINGS_ROLES_CLAIMS,
        action: setRoleClaimsBlocks,
        mapper: roleClaimsMapping,
        requestConfig: {
            data: formData,
        },
        onResponse: onRequestSuccess,
    });
};

export default {
    postRoleClaims,
    getRoleClaims,
    getRoomTypes,
    addRoomType,
    deleteRoomType,
    editRoomType,
    getDataCollectorModels,
    deleteDataCollectorModel,
    editDataCollectorModel,
    addDataCollectorModel,
    getDeviceGroupsList,
    getDeviceGroupByID,
    getDevicesModelsByGroupID,
    getDeviceTypesUnassigned,
    addDeviceGroup,
    deleteDeviceGroup,
    getManufacturersList,
    addDeviceModel,
    editDeviceModel,
    deleteDeviceModel,
    editDeviceGroup,
    addCity,
};
