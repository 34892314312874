import dayjs from 'dayjs';

import * as apiMethods from '../../consts/api/apiMethods';
import {SERVICES_KTE_STATUS} from '../../consts/api/modulesHostApiClient';
import {
    setKteStatus,
    setKyivEnergoData,
    setKyivEnergoSettingsData,
    setKyivEnergoStatisticsData,
} from '../../state/ducks/services';
import ApiClient from '../api/clients/apiClient';
import ModulesHostApiClient from '../api/clients/modulesHostApiClient';
import downloadService from '../download/downloadService';
import log from '../logger/log';
import {buildingToAppliedMapping} from '../mappings/serviceMappings';

const getKyivEnergoBuildingLists = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KYIVENERGO_BUILDING_LISTS,
            action: setKyivEnergoData,
        });
    } catch (e) {
        log.error(`getBuildingsList: ${e}`);
    }
};

const addBuildingToApplied = async ({data, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SERVICES_KYIVENERGO_BUILDING_LISTS,
        // mapper: buildingToAppliedMapping,
        requestConfig: {
            data,
        },
        onResponse: onRequestSuccess,
    });
};

const getKyivEnergoSettings = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KYIVENERGO_SETTINGS,
            action: setKyivEnergoSettingsData,
        });
    } catch (e) {
        log.error(`getKyivEnergoSettings: ${e}`);
    }
};

const updateKyivEnergoSettings = async ({data, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SERVICES_KYIVENERGO_SETTINGS,
        requestConfig: {
            data,
        },
        onResponse: onRequestSuccess,
    });
};

const getKyivEnergoStatistics = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KYIVENERGO_STATISTICS,
            action: setKyivEnergoStatisticsData,
        });
    } catch (e) {
        log.error(`getKyivEnergoStatistics: ${e}`);
    }
};

const getJsonKteStatistics = async () => {
    try {
        const response = await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KTE_DOWNLOAD_STATISTICS,
        });
        const getDate = (dateString) => {
            return dateString ? dayjs(dateString).format('YYYY-MM-DD') : '-';
        };

        const date = getDate(response.Date);
        const filename = `KTE_${date}`;

        const isDownloaded = await downloadService.JSONToTextFile(response, filename);

        return response;
    } catch (e) {
        log.error(`getJsonKteStatistics: ${e}`);
    }
};

const getKteStatus = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KTE_STATE,
            action: setKteStatus,
        });
    } catch (e) {
        log.error(`getKteStatus: ${e}`);
    }
};

const turnOffKteDataSending = async ({onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_KTE_OFF,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`offKte: ${e}`);
    }
};

const turnOnKteDataSending = async ({onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_KTE_ON,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`onKte: ${e}`);
    }
};

export default {
    getKyivEnergoBuildingLists,
    addBuildingToApplied,
    getKyivEnergoSettings,
    updateKyivEnergoSettings,
    getKyivEnergoStatistics,
    getJsonKteStatistics,
    getKteStatus,
    turnOffKteDataSending,
    turnOnKteDataSending,
};
