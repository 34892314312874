import React from 'react';

import Icon from '../Icon/Icon';

const IconNotification = ({color = '#E5E6E6', ...rest} = {}) => {
    return (
        <Icon width='21' height='26' viewBox='0 0 21 26' fill='none' {...rest}>
            <path
                d='M19.656 17.8504C18.1225 16.5541 17.2431 14.6593 17.2431 12.6521V9.82425C17.2431 6.25503 14.5917 3.30038 11.1573 2.80543V1.70985C11.1573 1.14897 10.7028 0.695618 10.1429 0.695618C9.58313 0.695618 9.12866 1.14897 9.12866 1.70985V2.80543C5.69317 3.30038 3.04291 6.25503 3.04291 9.82425V12.6521C3.04291 14.6593 2.16351 16.5541 0.620715 17.8584C0.226241 18.1962 3.05176e-05 18.6871 3.05176e-05 19.2064C3.05176e-05 20.1853 0.796223 20.9815 1.77498 20.9815H18.5108C19.4897 20.9815 20.2859 20.1853 20.2859 19.2064C20.2859 18.6871 20.0597 18.1962 19.656 17.8504Z'
                fill={color}
            />
            <path
                d='M10.1429 25.0388C11.9799 25.0388 13.5166 23.7292 13.8695 21.9959H6.41641C6.76947 23.7292 8.30614 25.0388 10.1429 25.0388Z'
                fill={color}
            />
        </Icon>
    );
};

export default IconNotification;
