import {UserOutlined} from '@ant-design/icons';
import {Avatar} from 'antd';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {makeSelectCurrentUserInfo} from '../../state/selectors/currentUser';
import Dropdown from '../Dropdown/Dropdown';
import {IconChevronBottomBlack} from '../Icons';
import userMenuService from './userMenuService';

const UserMenu = ({userName}) => {
    const menuItems = userMenuService.getMenuItems();
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const currentUserInfo = useSelector(makeSelectCurrentUserInfo());

    return (
        <div className='flex justify-between items-center pl-[18px]'>
            <div className='text-charcoal font-medium mr-[6px] text-14'>{userName}</div>
            <Dropdown
                trigger={
                    <div className='flex justify-between items-center hover:cursor-pointer'>
                        <Avatar
                            className='flex items-center justify-center'
                            src={'/data/' + currentUserInfo?.image?.toString().replace(/\\/g, '/')}
                            icon={<UserOutlined />}
                        />
                        <IconChevronBottomBlack
                            className={`ml-[6px] duration-150 ${isDropdownOpened ? 'rotate-180' : 'rotate-0'}`}
                        />
                    </div>
                }
                notFilterItems={1}
                afterOpenChange={(isOpened) => setIsDropdownOpened(isOpened)}
                menu={menuItems}
            />
        </div>
    );
};

export default UserMenu;
