import * as formInputNames from '../../../../../consts/form/formInputNames';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import helpers from '../../../../../utils/helpers';
import regexUtils from '../../../../../utils/regexUtils';

const getCityModalContent = () => {
    const localizedStrings = getLocalizedStrings();

    const submitButton = [localizedStrings[localizationKeys.SETTINGS_CITY_ADD_FORM_SUBMIT_BUTTON]];

    const title = [localizedStrings[localizationKeys.SETTINGS_CITY_ADD_FORM_TITLE]];

    const cancelButton = [localizedStrings[localizationKeys.SETTINGS_CITY_ADD_FORM_CANCEL_BUTTON]];

    const formContent = [
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.CITY_NAME,
            label: [localizedStrings[localizationKeys.SETTINGS_CITY_FORM_NAME_FIELD_LABEL]],
            placeholder: [localizedStrings[localizationKeys.SETTINGS_CITY_FORM_NAME_FIELD_PLACEHOLDER]],
            errorMessage: [localizedStrings[localizationKeys.SETTINGS_CITY_FORM_NAME_FIELD_ERROR_MESSAGE]],
            maxLength: 50,
            minLength: 2,
            pattern: regexUtils.CITY_NAME_REGEX,
            feedbackContent: [
                localizedStrings[localizationKeys.SETTINGS_CITY_FORM_NAME_INPUT_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.SETTINGS_CITY_FORM_NAME_INPUT_FEEDBACK_RULE_2],
                localizedStrings[localizationKeys.SETTINGS_CITY_FORM_NAME_INPUT_FEEDBACK_RULE_3],
            ],
            row: true,
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        formContent,
    };
};

export default {getCityModalContent};
