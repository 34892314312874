import React from 'react';

import Icon from '../Icon/Icon';

const IconFilter = ({fill = `#B3B3B3`, ...props}) => {
    return (
        <Icon width='16' height='16' viewBox='0 0 16 16' fill={fill} {...props}>
            <path
                d='M13.4369 2H2.56315C2.06351 2 1.8114 2.60623 2.16542 2.96026L6.5 7.29549V12.125C6.5 12.3085 6.58955 12.4805 6.73993 12.5858L8.61493 13.8979C8.98484 14.1568 9.5 13.8944 9.5 13.437V7.29549L13.8347 2.96026C14.188 2.60694 13.9376 2 13.4369 2Z'
                fill={fill}
            />
        </Icon>
    );
};

export default IconFilter;
