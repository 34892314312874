import * as formItemTypes from '../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import * as modalTypes from '../../../../consts/modals/modalTypes';
import {organizationStatuses} from '../../../../consts/organizations/organizationStatuses.ts';
import {showSuccessNotification} from '../../../../services/app/notificationService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import organizationsService from '../../../../services/organizations/organizationsService';
import {
    makeSelectCities,
    makeSelectCompanyTypesArray,
    makeSelectOrganizationClipboard,
} from '../../../../state/selectors/organizations';
import {getState} from '../../../../state/store';
import helpers from '../../../../utils/helpers';
import regexUtils from '../../../../utils/regexUtils';

const onCreateFinish = async (formValues) => {
    const localizedStrings = getLocalizedStrings();

    const {organizationID} = (await organizationsService.createOrganization(formValues)) || {};

    if (organizationID) {
        showSuccessNotification(localizedStrings[localizationKeys.ORGS_PAGE_NOTIFICATION_CREATE_SUCCESS]);
        await organizationsService.getOrganizationsList();
    }
};

const onCopyFinish = async (formValues) => await onCreateFinish(formValues);

const onEditFinish = async (formValues) => {
    const localizedStrings = getLocalizedStrings();

    const organizationClipboard = makeSelectOrganizationClipboard()(getState());

    const {organizationID} = await organizationsService.editOrganization({
        ...formValues,
        OrganizationID: organizationClipboard?.id,
    });

    if (organizationID) {
        showSuccessNotification(localizedStrings[localizationKeys.ORGS_PAGE_NOTIFICATION_EDIT_SUCCESS]);
        await organizationsService.getOrganizationsList();
    }
};
const getModalProps = (modalType) => {
    const organizationClipboard = makeSelectOrganizationClipboard()(getState());

    const localizedStrings = getLocalizedStrings();

    const initialValues = {
        Name: organizationClipboard?.name,
        Address: organizationClipboard?.address,
        CityID: organizationClipboard?.cityId,
        CompanyTypeID: organizationClipboard?.typeId,
        Status: organizationClipboard?.status,
    };

    switch (modalType) {
        case modalTypes.ORGANIZATION_EDIT_MODAL:
            return {
                title: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_EDIT_TITLE],
                submitBtnText: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_EDIT_BTN_TEXT],
                initialValues,
                isCityEditAvailable: true,
                isStatusRequired: true,
                onSubmit: onEditFinish,
            };
        case modalTypes.ORGANIZATION_CREATE_MODAL:
            return {
                title: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_CREATE_TITLE],
                submitBtnText: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_CREATE_BTN_TEXT],
                initialValues: null,
                onSubmit: onCreateFinish,
                isCityEditAvailable: true,
            };
        case modalTypes.ORGANIZATION_COPY_MODAL:
            return {
                title: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_COPY_TITLE],
                submitBtnText: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_COPY_BTN_TEXT],
                initialValues,
                isCityEditAvailable: true,
                onSubmit: onCopyFinish,
            };
        default:
            return {};
    }
};
const getFormFields = (modalData = {}) => {
    const localizedStrings = getLocalizedStrings();
    const {isCityEditAvailable, isStatusRequired} = modalData || {};

    const formFields = [
        {
            key: helpers.guid(),
            required: true,
            name: 'Name',
            label: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_FIELD_LABEL_NAME],
            placeholder: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_FIELD_PLACEHOLDER_NAME],
            errorMessage: localizedStrings[localizationKeys.ORGS_PAGE_ORG_FORM_FIELD_ERROR_MESSAGE],
            feedbackContent: [
                localizedStrings[localizationKeys.ORGS_PAGE_ORG_FORM_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.ORGS_PAGE_ORG_FORM_FIELD_FEEDBACK_RULE_2],
            ],
            pattern: regexUtils.ORGANIZATION_REGEX,
        },
        {
            key: helpers.guid(),
            required: true,
            name: 'Address',
            label: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_FIELD_LABEL_ADDRESS],
            placeholder: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_FIELD_PLACEHOLDER_ADDRESS],
            errorMessage: localizedStrings[localizationKeys.ORGS_PAGE_ORG_FORM_FIELD_ERROR_MESSAGE],
            feedbackContent: [
                localizedStrings[localizationKeys.ORGS_PAGE_ORG_FORM_FIELD_FEEDBACK_RULE_1],
                localizedStrings[localizationKeys.ORGS_PAGE_ORG_FORM_FIELD_FEEDBACK_RULE_2],
            ],
            pattern: regexUtils.ADDRESS_REGEX,
        },
        {
            key: helpers.guid(),
            required: true,
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            name: 'CityID',
            label: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_FIELD_LABEL_CITY],
            placeholder: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_FIELD_PLACEHOLDER_CITY],
            errorMessage: localizedStrings[localizationKeys.ORGS_PAGE_ORG_FORM_FIELD_ERROR_MESSAGE],
            withSearch: false,
            requestOptions: {
                selectRequestData: makeSelectCities,
                getRequestData: () => organizationsService.getCities(),
            },
            hidden: !isCityEditAvailable,
        },
        {
            key: helpers.guid(),
            required: true,
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            name: 'CompanyTypeID',
            label: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_FIELD_LABEL_TYPE],
            placeholder: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_FIELD_PLACEHOLDER_TYPE],
            errorMessage: localizedStrings[localizationKeys.ORGS_PAGE_ORG_FORM_FIELD_ERROR_MESSAGE],
            requestOptions: {
                selectRequestData: makeSelectCompanyTypesArray,
            },
        },
        {
            key: helpers.guid(),
            required: isStatusRequired,
            formItemType: formItemTypes.RADIO,
            hidden: !isStatusRequired,
            name: 'Status',
            label: localizedStrings[localizationKeys.ORGS_PAGE_MODAL_FORM_FIELD_LABEL_STATUS],
            radioButtonOptions: [
                {
                    name: localizedStrings[localizationKeys.ORGS_STATUS_TEXT_ACTIVE],
                    value: organizationStatuses.Active,
                },
                {
                    name: localizedStrings[localizationKeys.ORGS_STATUS_TEXT_BLOCKED],
                    value: organizationStatuses.Blocked,
                },
            ],
        },
    ];

    return formFields;
};

export default {
    getModalProps,
    getFormFields,
};
