import {setAuthData} from '../../state/ducks/auth';
import {dispatch} from '../../state/store';
import storageService from '../storage/storageService';
import userSessionService from '../user/userSessionService';

const setAuthStoreData = (responseData, formData) => {
    dispatch(setAuthData({...responseData, ...formData}));

    const {login} = formData || {};
    let {remember, password} = formData || {};

    if (!remember && remember !== false) {
        remember = storageService.getRememberMeFromStorage();
    }

    if (!password) {
        password = storageService.getUserPasswordFromStorage();
    }

    storageService.setUserLoginToStorage(login);

    if (remember) {
        storageService.setUserPasswordToStorage(password);
        storageService.setRememberMeToStorage(remember);
    } else {
        storageService.removeRememberMeFromStorage();
    }

    userSessionService.setUserSessionTokenData({...responseData});
};

const parseJWTFromStorage = () => {
    const token = storageService.getAccessTokenFromStorage();
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};

// todo: Ostrovskyi D. - created for a future logic with check token expiration before every request
const checkIsTokenExpired = () => {
    const expiration = storageService.getAccessTokenExpirationFromStorage();
    const isExpired = expiration <= Date.now();

    return isExpired;
};

export default {setAuthStoreData, parseJWTFromStorage, checkIsTokenExpired};
