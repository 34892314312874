import dataCollectorTransferStatuses from '../../consts/dataCollector/dataCollectorTransferStatuses.ts';

const statusColorClassNames = {
    [dataCollectorTransferStatuses.DataTransferDisabled]: 'bg-grey-status',
    [dataCollectorTransferStatuses.AllDevicesWithoutErrors]: 'bg-green-status',
    [dataCollectorTransferStatuses.NoDataRetrieved]: 'bg-red-status',
    [dataCollectorTransferStatuses.SomeDevicesWithErrors]: 'bg-yellow-status',
    [dataCollectorTransferStatuses.AllDevicesWithErrors]: 'bg-orange-status',
    [dataCollectorTransferStatuses.TimepointsDoNotMatchWithCurrentDate]: 'bg-blue-status',
};

export default {statusColorClassNames};
