import React from 'react';

import Icon from '../Icon/Icon';

const IconLocation = ({isWhite, ...props}) => {
    const {color} = props || {};
    const fillColor = color ?? (isWhite ? 'var(--color-white)' : 'var(--color-santas-gray)');

    return (
        <Icon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.25 10.4167C3.25 5.62281 7.17493 1.75 12 1.75C16.8251 1.75 20.75 5.62281 20.75 10.4167C20.75 13.3982 19.0113 16.3409 17.1686 18.4829C16.236 19.5669 15.2463 20.482 14.3733 21.1328C13.9374 21.4577 13.5186 21.7258 13.1405 21.9162C12.786 22.0947 12.3812 22.25 12 22.25C11.6188 22.25 11.214 22.0947 10.8595 21.9162C10.4814 21.7258 10.0626 21.4577 9.62674 21.1328C8.75371 20.482 7.76395 19.5669 6.83144 18.4829C4.98872 16.3409 3.25 13.3982 3.25 10.4167ZM12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13Z'
                fill={fillColor}
            />
        </Icon>
    );
};

export default IconLocation;
