import React from 'react';

import Icon from '../Icon/Icon';

const IconChevronLeft = ({active, ...restProps}) => {
    const fillColor = active ? 'var(--color-primary)' : '#6F727A';

    return (
        <Icon width='16' height='16' viewBox='0 0 16 16' fill='none' {...restProps}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.1093 11.6562C10.2543 11.5112 10.3358 11.3145 10.3358 11.1094C10.3358 10.9043 10.2543 10.7076 10.1093 10.5626L7.5624 8.01564L10.1093 5.46871C10.2502 5.32284 10.3282 5.12747 10.3264 4.92468C10.3247 4.72188 10.2433 4.5279 10.0999 4.38449C9.95651 4.24109 9.76252 4.15975 9.55973 4.15799C9.35694 4.15623 9.16156 4.23418 9.01569 4.37507L5.92194 7.46882C5.77695 7.61386 5.69549 7.81055 5.69549 8.01564C5.69549 8.22073 5.77695 8.41742 5.92194 8.56246L9.01569 11.6562C9.16073 11.8012 9.35742 11.8827 9.56251 11.8827C9.7676 11.8827 9.96429 11.8012 10.1093 11.6562Z'
                fill={fillColor}
            />
        </Icon>
    );
};

export default IconChevronLeft;
