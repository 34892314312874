import * as formInputNames from '../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import helpers from '../../../../utils/helpers';

const getFormContent = (form, responsibility) => {
    const localizedStrings = getLocalizedStrings();

    const formFields = [
        {
            key: helpers.guid(),
            name: 'reportType',
            formItemType: formItemTypes.SELECT,
            // required: true,
            fieldLabel: 'name',
            row: true,
            label: localizedStrings[localizationKeys.SERVICES_MODAL_FORM_REPORT_TYPE_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICES_MODAL_FORM_REPORT_TYPE_PLACEHOLDER],
            errorMessage: `Потрібно вибрати тип звіту`,
        },
        {
            key: helpers.guid(),
            name: 'reportDevice',
            formItemType: formItemTypes.CHECKBOX,
            // required: true,
            row: true,
            fieldLabel: 'name',
            label: localizedStrings[localizationKeys.SERVICES_MODAL_FORM_REPORT_DEVICE_TYPE_LABEL],
            options: [
                {label: 'Вода', value: 1},
                {label: 'Тепло', value: 2},
                {label: 'Електроенергія', value: 3},
                {label: 'Температура', value: 4},
            ],
            errorMessage: 'Потрібно вибрати хоча б один прилад',
        },
        {
            key: helpers.guid(),
            name: 'reportDate',
            formItemType: formItemTypes.DATE_PICKER,
            // required: true,
            fieldLabel: 'name',
            row: true,
            placeholder: localizedStrings[localizationKeys.SERVICES_MODAL_FORM_REPORT_DATE_PLACEHOLDER],
            label: localizedStrings[localizationKeys.SERVICES_MODAL_FORM_REPORT_DATE_LABEL],
            errorMessage: `Потрібно вказати дату звіту`,
        },
        {
            key: helpers.guid(),
            // required: true,
            row: true,
            type: 'email',
            name: formInputNames.EMAIL,
            label: localizedStrings[localizationKeys.SERVICES_MODAL_FORM_REPORT_EMAIL_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICES_MODAL_FORM_REPORT_EMAIL_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICES_MODAL_FORM_REPORT_EMAIL_VALIDATION_ERROR],
        },
    ];

    const initialValues = {
        [formInputNames.COMPANY]: responsibility?.companyName && {
            id: responsibility?.companyId,
            value: responsibility?.companyName,
        },
        [formInputNames.BUILDING]: responsibility?.buildingAdress && {
            id: responsibility?.buildingId,
            value: responsibility?.buildingName,
        },
    };

    return {
        formFields,
        initialValues,
    };
};

export default {
    getFormContent,
};
