import dayjs from 'dayjs';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import * as requestParamTypes from '../../../../../consts/app/requestParamTypes.js';
import * as formInputNames from '../../../../../consts/form/formInputNames.js';
import * as formItemTypes from '../../../../../consts/form/formItemTypes.js';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys.js';
import {reportTypes} from '../../../../../consts/reports/reportTypes.ts';
// import {reportTypes} from '../../../../../consts/reports/reportTypes.ts';
import deviceService from '../../../../../services/device/deviceService.js';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService.js';
import reportsService from '../../../../../services/reports/reportsService.js';
import settingsService from '../../../../../services/settings/settingsService.js';
// import reportsService from '../../../../../services/reports/reportsService.js';
import {makeSelectDeviceTypes, makeSelectorDeviceGroups} from '../../../../../state/selectors/device.js';
import {makeSelectCities, makeSelectOrganizationsNames} from '../../../../../state/selectors/organizations.js';
import {makeSelectReportTypes} from '../../../../../state/selectors/reports.js';
import helpers from '../../../../../utils/helpers.js';
import regexUtils from '../../../../../utils/regexUtils.js';

const getAddArsBuildingsModalContent = ({selectedBuilding}) => {
    const localizedStrings = getLocalizedStrings();

    let initialValues = {};
    const timePickerFormat = 'HH:mm';
    const title = localizedStrings[localizationKeys.SERVICE_ARS_BUILDINGS_SETTINGS_MODAL_TITLE];
    // const title = localizedStrings[localizationKeys.USERS_OBJECTS_PAGE_ADD_USER_MODAL_OBJECTS_TITLE];
    const submitButton = localizedStrings[localizationKeys.SERVICE_ARS_BUILDINGS_SETTINGS_SUBMIT_MODAl_BUTTON];
    // const submitButton = localizedStrings[localizationKeys.USERS_OBJECTS_PAGE_ADD_MODAl_BUTTON];
    const cancelButton = localizedStrings[localizationKeys.SERVICE_ARS_BUILDINGS_SETTINGS_CANCEL_MODAl_BUTTON];

    const isCurrentReport = (id) => id === reportTypes.Current;
    const isIndividualReport = (id) => id === reportTypes.Individual;
    const isBuildingReport = (id) => id === reportTypes.Building;
    const isGroupReport = (id) => id === reportTypes.Group;

    if (selectedBuilding) {
        const selectedBuildingSettings = selectedBuilding.settings;
        const {reportProperties, source} = selectedBuildingSettings || {};

        const allReportTypes = useSelector(makeSelectReportTypes()) || [];
        const deviceGroups = useSelector(makeSelectorDeviceGroups()) || {};
        const deviceGroupsList = deviceGroups.items || [];

        useEffect(() => {
            reportsService.getReportTypes();
            settingsService.getDeviceGroupsList();
        }, []);

        initialValues = {
            [formInputNames.ORGANIZATION_ID]: selectedBuildingSettings?.organizationID,
            [formInputNames.ORGANIZATION]: selectedBuildingSettings?.organization,
            [formInputNames.REPORT_TYPE]: reportProperties?.type && {
                id: reportProperties?.type,
                value: allReportTypes.find((reportType) => reportType.key === reportProperties?.type)?.value,
            },
            [formInputNames.DEVICE_GROUP]: reportProperties?.groupID && {
                id: reportProperties.groupID,
                value: deviceGroupsList.find((deviceGroup) => deviceGroup.key === reportProperties?.groupID)?.value,
            },
            [formInputNames.START_DATE]: selectedBuildingSettings?.dayOfMonth
                ? String(selectedBuildingSettings?.dayOfMonth)
                : '',
            [formInputNames.LOCAL_TIME]: selectedBuildingSettings?.localTime
                ? dayjs(selectedBuildingSettings.localTime, 'HH:mm')
                : undefined,
            [formInputNames.SOURCE]: source?.type,
            [formInputNames.SOURCE_ADDRESS]: source?.address || '',
            [formInputNames.FOLDER]: source?.folder || '',
            [formInputNames.LOGIN]: source?.login || '',
            [formInputNames.PASSWORD]: source?.password || '',
        };
    }

    // if (selectedOrg) {

    //     initialValues = {

    //     }
    // }

    const formContent = [
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            required: true,
            value: reportTypes,
            name: formInputNames.REPORT_TYPE,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_REPORT_TYPE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_REPORT_TYPE_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_REPORT_FORM_TYPE_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectReportTypes,
                getRequestData: () => reportsService.getReportTypes(),
            },
        },
        {
            key: helpers.guid(),
            name: formInputNames.DEVICE_GROUP,
            fieldLabel: 'value',
            fieldValue: 'key',
            itemDeps: formInputNames.REPORT_TYPE,
            required: (_, report_type) => isCurrentReport(report_type?.id) || isGroupReport(report_type?.id),
            disabled: (_, report_type) => isIndividualReport(report_type?.id) || isBuildingReport(report_type?.id),
            formItemType: formItemTypes.SELECT,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectDeviceTypes,
                getRequestData: () => deviceService.getDeviceTypesGroups(),
                // selectRequestData: isAddDeviceModalType ? makeSelectDeviceTypes : makeSelectDeviceProfileTypes,
                // getRequestData: () => deviceService.getDeviceTypesGroups(),
            },
        },

        {
            key: helpers.guid(),
            required: true,
            maxLength: 5,
            name: formInputNames.START_DATE,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_ERROR],
            pattern: regexUtils.DAY_OF_MONTH_REGEX,
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.LOCAL_TIME,
            formItemType: formItemTypes.TIME_PICKER,
            format: timePickerFormat,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            name: formInputNames.SOURCE,
            formItemType: formItemTypes.RADIO,
            itemDeps: formInputNames.HANDLER,
            required: true,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_LABEL],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_ERROR],
            radioButtonOptions: [
                {
                    name: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_FTP],
                    value: 1,
                },
                {
                    name: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_EMAIL],
                    value: 2,
                },
            ],
        },
        {
            key: helpers.guid(),
            name: formInputNames.SOURCE_ADDRESS,
            required: true,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_ERROR],
        },
        {
            key: helpers.guid(),
            name: formInputNames.FOLDER,
            itemDeps: formInputNames.SOURCE,
            hidden: (_, source) => source !== 1,
            disabled: (_, source) => source !== 1, // "1" it is a value of formInputNames.SOURCE
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_FOLDER_PATH_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_FOLDER_PATH_PLACEHOLDER],
            errorMessage:
                localizedStrings[
                    localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PLACEHOLDER_FOLDER_PATH_ERROR
                ],
        },
        {
            key: helpers.guid(),
            itemDeps: formInputNames.SOURCE,
            required: (_, source) => source !== 2,
            hidden: (_, source) => source !== 1,
            name: formInputNames.LOGIN,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_ERROR],
        },

        {
            key: helpers.guid(),
            itemDeps: formInputNames.SOURCE,
            required: (_, source) => source !== 2,
            hidden: (_, source) => source !== 1,
            formItemType: formItemTypes.PASSWORD,
            name: formInputNames.PASSWORD,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PASSWORD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PASSWORD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.NOTIFICATION_SERVICE_FORM_FIELD_ERROR],
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        formContent,
        // requireDeps,
        initialValues,
    };
};

export default {
    getAddArsBuildingsModalContent,
};
