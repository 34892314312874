import React from 'react';

import * as localizationKeys from '../../consts/localization/localizationKeys';
import * as userMenuTypes from '../../consts/userMenu/userMenuTypes';
import appRouterService from '../../services/app/appRouterService';
import appService from '../../services/app/appService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {IconLogout, IconSettingsOutlined, IconUserOutlined} from '../Icons';
import UserMenuItem from './UserMenuItem';

const getMenuItems = () => {
    const localizedStrings = getLocalizedStrings();
    const items = [
        {
            key: '1',
            label: (
                <UserMenuItem
                    type={userMenuTypes.USER_MENU_PROFILE_ITEM}
                    label={localizedStrings[localizationKeys.USER_MENU_PROFILE_ITEM]}
                />
            ),
            icon: <IconUserOutlined />,
            onClick: () => appRouterService.forwardToProfilePage(),
        },
        {
            key: '2',
            label: (
                <UserMenuItem
                    type={userMenuTypes.USER_MENU_SETTINGS_ITEM}
                    label={localizedStrings[localizationKeys.USER_MENU_SETTING_ITEM]}
                />
            ),
            icon: <IconSettingsOutlined />,
        },
        {
            key: '3',
            label: (
                <UserMenuItem
                    type={userMenuTypes.USER_MENU_LOGOUT_ITEM}
                    label={localizedStrings[localizationKeys.USER_MENU_LOGOUT_ITEM]}
                />
            ),
            icon: <IconLogout />,
            onClick: () => appService.logout(),
        },
    ];

    return items;
};

export default {
    getMenuItems,
};
