export default {
    data: {
        app: {
            apiUrl: undefined,
            reportsApiUrl: undefined,
            dataCollectionApiUrl: undefined,
            modulesHostApiUrl: undefined,
            openStreetApiUrl: undefined,
        },
        dataCollection: {
            maxIntervalMinutes: undefined,
            minIntervalMinutes: undefined,
            stepMinutes: undefined,
        },
        upload: {
            supportedImageTypes: undefined,
        },
        durations: {
            notification: undefined, // s
            searchDelay: undefined, // ms
        },
        logs: {
            isDebug: undefined,
            logLevel: undefined,
            antdWarningsEnabled: undefined,
        },
    },
};
