import {useEffect, useState} from 'react';

import {showConfirmModalAction} from '../state/ducks/modal';
import {dispatch} from '../state/store';

const useActionConfirmPopup = (menuOptions = []) => {
    const [menuItems, setMenuItems] = useState([]);

    const callWithActionConfirmPopup = (callback, confirmModalTitle) => {
        return () => {
            dispatch(
                showConfirmModalAction({
                    onOk: callback,
                    confirmModalTitle,
                })
            );
        };
    };

    useEffect(() => {
        const result = menuOptions.map((item) => {
            const {isActionConfirmRequired, onClick, confirmModalTitle} = item || {};

            if (isActionConfirmRequired && onClick) {
                item.onClick = callWithActionConfirmPopup(onClick, confirmModalTitle);
            }
            return item;
        });

        setMenuItems(result);
    }, [menuOptions]);

    return menuItems;
};

export default useActionConfirmPopup;
