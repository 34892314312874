import L from 'leaflet';

import svgDotBlue from '../../../assets/svg/mapAssets/dotBlue.svg';
import svgDotGreen from '../../../assets/svg/mapAssets/dotGreen.svg';
import svgDotGrey from '../../../assets/svg/mapAssets/dotGrey.svg';
import svgDotOrange from '../../../assets/svg/mapAssets/dotOrange.svg';
import svgDotRed from '../../../assets/svg/mapAssets/dotRed.svg';
import svgDotYellow from '../../../assets/svg/mapAssets/dotYellow.svg';
import svgMarkerBlue from '../../../assets/svg/mapAssets/markerBlue.svg';
import svgMarkerGreen from '../../../assets/svg/mapAssets/markerGreen.svg';
import svgMarkerGrey from '../../../assets/svg/mapAssets/markerGrey.svg';
import svgMarkerOrange from '../../../assets/svg/mapAssets/markerOrange.svg';
import svgMarkerRed from '../../../assets/svg/mapAssets/markerRed.svg';
import svgMarkerYellow from '../../../assets/svg/mapAssets/markerYellow.svg';

const markerIconSize = new L.Point(40, 50);
const dotIconSize = new L.Point(20, 20);

export const dotRed = new L.Icon({
    iconUrl: svgDotRed,
    iconRetinaUrl: svgDotRed,
    iconSize: dotIconSize,
});
export const dotOrange = new L.Icon({
    iconUrl: svgDotOrange,
    iconRetinaUrl: svgDotOrange,
    iconSize: dotIconSize,
});
export const dotBlue = new L.Icon({
    iconUrl: svgDotBlue,
    iconRetinaUrl: svgDotBlue,
    iconSize: dotIconSize,
});
export const dotYellow = new L.Icon({
    iconUrl: svgDotYellow,
    iconRetinaUrl: svgDotYellow,
    iconSize: dotIconSize,
});
export const dotGreen = new L.Icon({
    iconUrl: svgDotGreen,
    iconRetinaUrl: svgDotGreen,
    iconSize: dotIconSize,
});
export const dotGrey = new L.Icon({
    iconUrl: svgDotGrey,
    iconRetinaUrl: svgDotGrey,
    iconSize: dotIconSize,
});

export const markerRed = new L.Icon({
    iconUrl: svgMarkerRed,
    iconRetinaUrl: svgMarkerRed,
    iconSize: markerIconSize,
});

export const markerYellow = new L.Icon({
    iconUrl: svgMarkerYellow,
    iconRetinaUrl: svgMarkerYellow,
    iconSize: markerIconSize,
});
export const markerOrange = new L.Icon({
    iconUrl: svgMarkerOrange,
    iconRetinaUrl: svgMarkerOrange,
    iconSize: markerIconSize,
});
export const markerBlue = new L.Icon({
    iconUrl: svgMarkerBlue,
    iconRetinaUrl: svgMarkerBlue,
    iconSize: markerIconSize,
});

export const markerGreen = new L.Icon({
    iconUrl: svgMarkerGreen,
    iconRetinaUrl: svgMarkerGreen,
    iconSize: markerIconSize,
});
export const markerGrey = new L.Icon({
    iconUrl: svgMarkerGrey,
    iconRetinaUrl: svgMarkerGrey,
    iconSize: markerIconSize,
});
