export const SET_ROOM_PROFILE = 'app/rooms/SET_ROOM_PROFILE';
export const CLEAN_ROOM_DATA = 'app/rooms/CLEAN_ROOM_DATA';

export const initialState = {
    roomProfile: null,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_ROOM_PROFILE: {
            const roomProfile = action.payload;

            return {...state, roomProfile};
        }
        case CLEAN_ROOM_DATA: {
            return {...state, ...initialState};
        }
        default:
            return state;
    }
}

export const setRoomProfile = (payload) => ({
    type: SET_ROOM_PROFILE,
    payload,
});

export const cleanRoomData = () => ({
    type: CLEAN_ROOM_DATA,
});
