import {createSelector} from 'reselect';

import urlUtils from '../../utils/urlUtils';

const selectMaps = (state) => state.maps;
const selectRequests = createSelector(selectMaps, (mapsState) => mapsState.requests);

export const selectMapBuildings = createSelector(selectMaps, (mapsState) => mapsState.mapBuildings);

export const selectCustomMarker = createSelector(selectMaps, (mapsState) => mapsState.customMarker);

export const makeSelectResponseByParams = (params) =>
    createSelector(selectRequests, (requestsState) => {
        const serializedParams = urlUtils.serializeAndSortParams(params);
        const isExists = serializedParams in requestsState;
        const result = requestsState?.[serializedParams];

        return isExists ? result : null;
    });
