import {InboxOutlined} from '@ant-design/icons';
import {Divider, Upload} from 'antd';
import React, {useState} from 'react';

import AntButton from '../../../../../components/AntButton/AntButton';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import log from '../../../../../services/logger/log';
import uploadFileModalService from './uploadFileModalService';

const {Dragger} = Upload;

const UploadFileModal = (props) => {
    const localizedStrings = getLocalizedStrings();

    const [file, setFile] = useState(null);

    const {onOk, onCancel} = props;

    const modalData = uploadFileModalService.getUploadFileModalContent();

    const {title, submitButton, cancelButton} = modalData;

    const onUpload = async () => {
        try {
            onOk(file);
        } catch (error) {
            log.error(`UploadFile - validate failed: ${error}`);
        }
    };

    const onChange = async (info) => {
        const fileObj = info.fileList[0]?.originFileObj;

        setFile(fileObj);
    };

    return (
        <>
            <div className={'px-[110px] pt-[42px]'}>
                {title && <h3 className='font-semibold text-24 text-charcoal text-center pb-11'>{title}</h3>}
                <Dragger
                    maxCount={1}
                    name='file'
                    multiple={false}
                    accept='text/csv'
                    onChange={onChange}
                    customRequest={({onSuccess}) => onSuccess('ok')}
                >
                    <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>
                        {localizedStrings[localizationKeys.BUILDING_PROFILE_PAGE_UPLOAD_FILE_SUBTITLE]}
                    </p>
                    <p className='ant-upload-hint'>
                        {localizedStrings[localizationKeys.BUILDING_PROFILE_PAGE_UPLOAD_FILE_DESCRIPTION]}
                    </p>
                </Dragger>
            </div>

            <>
                <Divider className='mt-[30px] mb-[26px]' />
                <div className={'flex justify-between items-center px-[55px]'}>
                    {cancelButton && (
                        <AntButton className='h-[50px]' type='button' onClick={onCancel} bordered cancelHover>
                            {cancelButton}
                        </AntButton>
                    )}
                    <AntButton className='h-[50px]' type='button' onClick={onUpload} primary disabled={!file}>
                        {submitButton}
                    </AntButton>
                </div>
            </>
        </>
    );
};

export default UploadFileModal;
