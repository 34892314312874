import React from 'react';

import Icon from '../Icon/Icon';

const IconArrowRightSlider = (props) => {
    return (
        <Icon width='11' height='10' viewBox='0 0 11 10' fill='none' {...props}>
            <path
                d='M8.58862 5.03252L2.03125 5.03251M5.71977 7.90137L8.58862 5.03252L5.71977 2.16366'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default IconArrowRightSlider;
