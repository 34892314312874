import {QuestionCircleFilled} from '@ant-design/icons';
import {List, Popover} from 'antd';
import cn from 'classnames';
import React from 'react';

const FormHelper = ({isVisible, content, errorMessage}) => {
    const popoverContent = content ? (
        <List className='!p-0' size='small'>
            {content.map((item, idx) => (
                <List.Item className='!p-1' key={idx}>
                    {item}
                </List.Item>
            ))}
        </List>
    ) : (
        errorMessage
    );

    return (
        <Popover className={cn('absolute -right-3 -top-3 hidden z-30', isVisible && '!block')} content={popoverContent}>
            <QuestionCircleFilled style={{color: 'var(--color-radical-red)'}} />
        </Popover>
    );
};

export default FormHelper;
