import {createBrowserHistory} from 'history';

let browserHistory;

const getHistory = () => {
    if (!browserHistory) {
        browserHistory = createBrowserHistory();
    }

    return browserHistory;
};

const history = {getHistory};

export default history;
