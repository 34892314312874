import {Badge} from 'antd';
import cn from 'classnames';
import React from 'react';

import {IconNotification} from '../Icons';

const UserNotifications = ({count, onClick}) => {
    const containerClasses = cn({'hover:cursor-pointer': count}, 'h-6');

    return (
        <div className={containerClasses} onClick={onClick}>
            <Badge size='small' count={count} color='var(--color-radical-red)'>
                <IconNotification />
            </Badge>
        </div>
    );
};

export default UserNotifications;
