import React from 'react';

import Icon from '../Icon/Icon';

const IconChevronBottomBlack = ({color, width, height, ...props}) => {
    const strokeColor = color || '#161616';
    const iconWidth = width || '11';
    const iconHeight = height || '11';

    return (
        <Icon width={iconWidth} height={iconHeight} fill='none' viewBox='0 0 11 11' {...props}>
            <path
                d='M9.16664 4.12499L5.49998 7.79166L1.83331 4.12499'
                stroke={strokeColor}
                strokeWidth='1.5'
                strokeLinecap='round'
            />
        </Icon>
    );
};

export default IconChevronBottomBlack;
