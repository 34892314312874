import React from 'react';

import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import helpers from '../../../utils/helpers';
import {IconFile, IconTrashCan} from '../../Icons';
import OptionsMenu from '../OptionsMenu';

const ReportOptionsMenu = ({handlers, record, dataSource, isForHeader, isDisabled}) => {
    const localizedStrings = getLocalizedStrings();

    const {handleArchive, handleDelete} = handlers || {};

    const menu = isForHeader
        ? [
              {
                  key: helpers.guid(),
                  icon: <IconFile className='w-4 h-4' />,
                  label: localizedStrings[localizationKeys.REPORTS_PAGE_ARCHIVE_MENU_OPTION],
                  onClick: () => helpers.runFunctionForArray(handleArchive, record),
              },

              {
                  key: helpers.guid(),
                  icon: <IconTrashCan />,
                  label: localizedStrings[localizationKeys.REPORTS_PAGE_DELETE_MENU_OPTION],
                  onClick: () => helpers.runFunctionForArray(handleDelete, record),
                  isActionConfirmRequired: true,
              },
          ]
        : [
              {
                  key: helpers.guid(),
                  icon: <IconFile className='w-4 h-4' />,
                  label: localizedStrings[localizationKeys.REPORTS_PAGE_ARCHIVE_MENU_OPTION],
                  onClick: () => helpers.runFunction(handleArchive, [record.id]),
              },
              {
                  key: helpers.guid(),
                  icon: <IconTrashCan />,
                  label: localizedStrings[localizationKeys.REPORTS_PAGE_DELETE_MENU_OPTION],
                  onClick: () => helpers.runFunction(handleDelete, [record.id]),
                  isActionConfirmRequired: true,
              },
          ];

    return <OptionsMenu menuItems={menu} record={record} dataSource={dataSource} disabled={isDisabled} />;
};

export default ReportOptionsMenu;
