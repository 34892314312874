import React from 'react';

import Icon from '../Icon/Icon';

const IconReportLine = (props) => {
    return (
        <Icon width='20' height='13' viewBox='0 0 20 13' fill='none' {...props}>
            <path d='M1.5 13L7.5 7L11.5 11L20 1.42L18.59 0L11.5 8L7.5 4L0 11.5L1.5 13Z' fill='black' />
        </Icon>
    );
};

export default IconReportLine;
