import {Divider as AntDivider} from 'antd';
import cn from 'classnames';
import React from 'react';

const Divider = (props) => {
    const {vertical, className, ...rest} = props || {};
    const dividerCn = cn(
        `m-0 border-divider border `,
        {
            ['opacity-40']: !vertical,
        },
        className
    );

    return <AntDivider type={vertical ? 'vertical' : 'horizontal'} className={dividerCn} {...rest} />;
};

export default Divider;
