import consoleLog from './consoleLogger';

const log = {
    setLevel: (consoleLogLevel) => {
        consoleLog.setLevel(consoleLogLevel);
    },
    debug: (msg) => {
        consoleLog.debug(msg);
    },
    info: (msg) => {
        consoleLog.info(msg);
    },
    error: (msg) => {
        consoleLog.error(msg);
    },
    fatal: (msg) => {
        consoleLog.error(msg);
    },
};

export default log;
