import {useSelector} from 'react-redux';

import * as formInputNames from '../../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import settingsService from '../../../../../services/settings/settingsService';
import {makeSelectRoomProfile} from '../../../../../state/selectors/rooms';
import {makeSelectRoomTypes} from '../../../../../state/selectors/settings';
import helpers from '../../../../../utils/helpers';

const getFormContent = () => {
    const localizedStrings = getLocalizedStrings();
    const roomProfile = useSelector(makeSelectRoomProfile());

    const title = localizedStrings[localizationKeys.ROOM_FORM_EDIT_TITLE];

    const submitButton = localizedStrings[localizationKeys.ROOM_FORM_EDIT_SUBMIT_BTN];

    const cancelButton = localizedStrings[localizationKeys.ROOM_FORM_CANCEL_BTN];

    const initialValues = {
        [formInputNames.ROOM_NUMBER]: roomProfile?.number,
        [formInputNames.SECTION]: roomProfile?.section,
        [formInputNames.FLOOR]: roomProfile?.floor || '',
        [formInputNames.SQUARE]: roomProfile?.square || '',
        [formInputNames.ROOM_ACCOUNT_NUMBER]: roomProfile?.accountNumber,
        [formInputNames.ROOM_TYPE_ID]: roomProfile?.roomTypeID && {
            id: roomProfile?.roomTypeID,
            value: roomProfile?.roomTypeName,
        },
        [formInputNames.BUILDING_ID]: roomProfile?.buildingID,
        [formInputNames.ROOM_ID]: roomProfile?.roomID,
    };

    const formFields = [
        {
            key: helpers.guid(),
            name: formInputNames.ROOM_ID,
            hidden: true,
        },
        {
            key: helpers.guid(),
            name: formInputNames.BUILDING_ID,
            hidden: true,
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.ROOM_NUMBER,
            label: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_ROOM_NUMBER_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_ROOM_NUMBER_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.ROOM_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.SECTION,
            label: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_SECTION_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_SECTION_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.ROOM_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.FLOOR,
            label: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_FLOOR_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_FLOOR_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.ROOM_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            name: formInputNames.ROOM_TYPE_ID,
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            fieldValue: 'key',
            label: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_ROOM_TYPE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_ROOM_TYPE_FIELD_PLACEHOLDER],
            requestOptions: {
                selectRequestData: makeSelectRoomTypes,
                getRequestData: () => settingsService.getRoomTypes(),
            },
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.SQUARE,
            label: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_SQUARE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_SQUARE_FIELD_PLACEHOLDER],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.ROOM_ACCOUNT_NUMBER,
            label: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_ACCOUNT_NUMBER_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_ACCOUNT_NUMBER_FIELD_PLACEHOLDER],
        },
    ];

    return {formFields, title, submitButton, cancelButton, initialValues};
};

export default {
    getFormContent,
};
