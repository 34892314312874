import antdConsoleMessagesOverride from '../services/logger/antdWarningOverride';
import log from '../services/logger/log';
import {addReduxLoggerIfEnabled} from '../state/store';
import appConfig from './appConfig';
import configService from './configService';
import configTemplate from './configTemplate.js';

const config = {...configTemplate};

const initConfig = async () => {
    const configData = configService.getDefaultConfigData();

    config.data = {...config.data, ...configData};

    if (configData) {
        await configService.initExternalConfig();
    }

    log.setLevel(appConfig.getLogLevel());

    antdConsoleMessagesOverride();

    await addReduxLoggerIfEnabled();
};

export {config, initConfig};
