import React from 'react';

import Icon from '../Icon/Icon';

const IconBellOff = (props) => {
    return (
        <Icon width='24' height='25' viewBox='0 0 24 25' fill='none' {...props}>
            <path
                d='M9.05143 4.69998C9.96455 4.18474 10.9967 3.91777 12.0452 3.92566C13.0936 3.93355 14.1217 4.21604 15.0269 4.74498C15.9322 5.27391 16.683 6.03083 17.2047 6.94031C17.7263 7.8498 18.0005 8.88009 18 9.92855V13.3571M13.4829 20.2143C13.3322 20.474 13.1159 20.6897 12.8556 20.8396C12.5954 20.9895 12.3003 21.0684 12 21.0684C11.6997 21.0684 11.4046 20.9895 11.1444 20.8396C10.8841 20.6897 10.6678 20.474 10.5171 20.2143M2.57143 3.07141L21.4286 21.9286M16.2857 16.7857H3.42857C4.11055 16.7857 4.76461 16.5148 5.24684 16.0325C5.72908 15.5503 6 14.8963 6 14.2143V9.92855C5.99879 8.96831 6.22807 8.0218 6.66857 7.16855L16.2857 16.7857Z'
                stroke='#FF4B5C'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default IconBellOff;
