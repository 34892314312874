import dayjs from 'dayjs';

const relativeTime = require('dayjs/plugin/relativeTime');
const isToday = require('dayjs/plugin/isToday');
const utc = require('dayjs/plugin/utc');
const objectSupport = require('dayjs/plugin/objectSupport');

dayjs.extend(utc);
dayjs.locale('uk');
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(objectSupport);

const UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

export const getDateAndTimeFormatted = (date) => {
    const dateFormat = 'YYYY-MM-DD';
    const timeFormat = 'HH:mm:ss';

    return {
        date: dayjs(date).format(dateFormat),
        time: dayjs(date).format(timeFormat),
    };
};

export const getRelativeTimeToNow = (date) => {
    const relativeTime = dayjs().to(toUtcFormat(date));

    return relativeTime.charAt(0).toUpperCase() + relativeTime.slice(1);
};

export const getDifferenceInDays = (date) => {
    return Math.floor(dayjs().diff(dayjs(date), 'days'));
};

export const toUtcFormat = (date) => {
    return dayjs(date).format(UTC_FORMAT);
};

export const utcStartOfDay = (date) => {
    const startOfLocalDay = new Date(date);

    startOfLocalDay.setHours(0, 0, 0, 0);
    return startOfLocalDay.toISOString();
};

export const utcEndOfDay = (date) => {
    const endOfLocalDay = dayjs(date);

    const isToday = endOfLocalDay.isToday();

    if (isToday) {
        return endOfLocalDay.subtract(1, 'minute').toISOString();
    }

    if (!isToday) {
        return endOfLocalDay.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toISOString();
    }
};
