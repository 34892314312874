import {createSelector} from 'reselect';

const selectServicesKyivEnergo = (state) => state.services.kyivEnergo;
const selectServicesLvk = (state) => state.services.lvk;
const selectServicesKvk = (state) => state.services.kvk;
const selectServicesArs = (state) => state.services.arsOrganizationsData;
const selectArsOrganizationBuildingsList = (state) => state.services.arsBuildingsList;
const selectArsOrganizationsSettings = (state) => state.services.organizationSettings;
const selectArsOrganizationStatistics = (state) => state.services.arsOrganizationStatistics;
const selectArsUnappliedOrganizations = (state) => state.services.arsUnappliedOrganizationsNames;
const selectArsStatus = (state) => state.services.arsStatus;

const makeSelectKyivEnergoBuildingsData = () =>
    createSelector(selectServicesKyivEnergo, (kyivEnergoState) => kyivEnergoState.buildings);

const makeSelectKyivEnergoSettingsData = () =>
    createSelector(selectServicesKyivEnergo, (kyivEnergoState) => kyivEnergoState.settings);

const makeSelectKyivEnergoStatisticsData = () =>
    createSelector(selectServicesKyivEnergo, (kyivEnergoState) => kyivEnergoState.statistics);

const makeSelectKteStatusData = () =>
    createSelector(selectServicesKyivEnergo, (kyivEnergoState) => kyivEnergoState.status);

const makeSelectLvkBuildingsData = () => createSelector(selectServicesLvk, (lvkState) => lvkState.buildings);

const makeSelectLvkStatusData = () => createSelector(selectServicesLvk, (lvkState) => lvkState.status);

const makeSelectLvkSettingsData = () => createSelector(selectServicesLvk, (lvkState) => lvkState.settings);

const makeSelectLvkStatisticsData = () => createSelector(selectServicesLvk, (lvkState) => lvkState.statistics);

const makeSelectKvkBuildingsData = () => createSelector(selectServicesKvk, (kvkState) => kvkState.buildings);

const makeSelectKvkStatusData = () => createSelector(selectServicesKvk, (kvkState) => kvkState.status);

const makeSelectKvkSettingsData = () => createSelector(selectServicesKvk, (kvkState) => kvkState.settings);

const makeSelectKvkStatisticsData = () => createSelector(selectServicesKvk, (kvkState) => kvkState.statistics);

const makeSelectArsOrganizationsData = () =>
    createSelector(selectServicesArs, (arsOrganizationsDataState) => arsOrganizationsDataState);

const makeSelectArsOrganizationSettings = () =>
    createSelector(selectArsOrganizationsSettings, (arsOrganizationsSettings) => arsOrganizationsSettings || {});

const makeSelectArsOrganizationStatistics = () =>
    createSelector(selectArsOrganizationStatistics, (arsOrganizationStatistics) => arsOrganizationStatistics || {});

const makeSelectArsOrganizationsDataItems = () =>
    createSelector(selectServicesArs, (arsOrganizationsDataState) => arsOrganizationsDataState?.items || []);

const makeSelectArsOrganizationBuildingsList = () =>
    createSelector(selectArsOrganizationBuildingsList, (arsBuildingsListState) => arsBuildingsListState);

const makeSelectArsUnappliedOrganizations = () =>
    createSelector(selectArsUnappliedOrganizations, (arsOrganizationsState) => arsOrganizationsState);

const makeSelectArsStatus = () => createSelector(selectArsStatus, (arsStatus) => arsStatus);

export {
    makeSelectKyivEnergoBuildingsData,
    makeSelectKyivEnergoSettingsData,
    makeSelectKyivEnergoStatisticsData,
    makeSelectKteStatusData,
    makeSelectLvkBuildingsData,
    makeSelectLvkStatusData,
    makeSelectLvkSettingsData,
    makeSelectLvkStatisticsData,
    makeSelectKvkBuildingsData,
    makeSelectKvkStatusData,
    makeSelectKvkSettingsData,
    makeSelectKvkStatisticsData,
    makeSelectArsOrganizationsData,
    makeSelectArsOrganizationsDataItems,
    makeSelectArsOrganizationSettings,
    makeSelectArsOrganizationStatistics,
    makeSelectArsOrganizationBuildingsList,
    makeSelectArsUnappliedOrganizations,
    makeSelectArsStatus,
};
