import {Form, Select, Spin} from 'antd';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import useRequestParams from '../../../hooks/useRequestParams';
import useSearch from '../../../hooks/useSearch';
import {setRequestParams} from '../../../state/ducks/requestParams';
import {makeIsGlobalLoading} from '../../../state/selectors/global';
import {dispatch} from '../../../state/store';
import helpers from '../../../utils/helpers';

// todo: Ostrovskyi D. - hardcoded value should be moved to the higher level
const INCREMENT_SIZE = 5;

const FormSelect = React.memo((props) => {
    const {
        placeholder,
        onSelect,
        onChange,
        withSearch = false,
        disabled,
        form,
        name,
        fieldLabel,
        fieldValue = 'key',
        requestOptions,
        withInitialRequest = true,
        itemPlacement,
        allowClear,
        mode,
    } = props;

    const [searchValue, setSearchValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isFetchDataOnLoad, setIsFetchDataOnLoad] = useState(withInitialRequest);
    const {selectRequestData, getRequestData, paramsType, options} = requestOptions || {};

    const isGlobalLoading = useSelector(makeIsGlobalLoading());
    const selectData = options?.length
        ? options
        : useSelector((state) => (selectRequestData ? selectRequestData()(state) : null));

    const {hasNextPage, items, size} = selectData || {};
    const currentValue = Form.useWatch(name, form);

    const [requestParams] = useRequestParams({
        handler: async () => {
            if (!paramsType || (!withInitialRequest && !isFetchDataOnLoad)) return;

            await getSelectData();
        },
        paramsType,
    });

    useSearch({paramsType, searchValue});

    const changeRequestParams = (data) => {
        if (!paramsType) return;

        dispatch(setRequestParams({paramsType, paramsProps: data}));
    };

    const getSelectData = async () => {
        if (disabled) return;

        helpers.runFunction(getRequestData, requestParams);
    };

    const onScroll = (e) => {
        const {target} = e;
        const isBottom = target.scrollHeight - target.scrollTop === target.clientHeight;

        if (isBottom && hasNextPage) {
            changeRequestParams({size: size + INCREMENT_SIZE});
        }
    };

    const onClick = async () => {
        const isDataExist = selectData?.length ? selectData : selectData?.items;

        if (isDataExist) return;

        await getSelectData();
    };

    const onClear = () => form.setFieldValue(name);

    const notFoundContent = !items?.length && (
        <div className='flex justify-center items-center'>
            {isGlobalLoading ? <Spin size='small' /> : <span>Пусто&#128528;</span>}{' '}
        </div>
    );

    const onDropdownVisibleChange = async (open) => {
        if (isGlobalLoading) return;

        setIsOpen(open);
        setIsFetchDataOnLoad(true);
    };

    const handleSelect = (value, option) => {
        helpers.runFunction(onSelect, [value, name, option]);
    };

    const handleChange = (value, option) => {
        helpers.runFunction(onChange, [value, name, option]);
    };

    return (
        <div>
            <Select
                onClick={onClick}
                onClear={onClear}
                onSelect={handleSelect}
                onChange={handleChange}
                onSearch={withSearch && setSearchValue}
                onDropdownVisibleChange={onDropdownVisibleChange}
                getPopupContainer={(trigger) => trigger.parentElement}
                open={isOpen}
                allowClear={allowClear}
                options={selectData?.length ? selectData : selectData?.items}
                disabled={disabled}
                placeholder={placeholder}
                onPopupScroll={onScroll}
                searchValue={searchValue}
                defaultValue={currentValue}
                value={currentValue}
                virtual={false}
                placement={items && itemPlacement}
                showSearch={withSearch}
                filterOption={(inputValue, option) => option}
                notFoundContent={notFoundContent}
                prefixCls='ep-form-item-select'
                size='large'
                mode={mode}
                fieldNames={{
                    label: fieldLabel,
                    value: fieldValue,
                }}
            />
        </div>
    );
});

FormSelect.displayName = 'FormSelect';

export default FormSelect;
