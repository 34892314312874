import * as apiMethods from '../../consts/api/apiMethods';
import helpers from '../../utils/helpers';
import ApiClient from '../api/clients/apiClient';
import appService from '../app/appService';
import log from '../logger/log';
import {
    authMapping,
    changePasswordMapping,
    loginRequestDataMapping,
    passRestoreVerificationMapping,
    registerRequestDataMapping,
} from '../mappings/authMappings';
import storageService from '../storage/storageService';
import authDataStoreService from './authDataStoreService';

const postRegistrationForm = async ({formData, throwException, onRequestError, onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.AUTH_SIGN_UP,
            requestConfig: {
                data: registerRequestDataMapping(formData),
            },
            throwException,
        });

        helpers.runFunction(onRequestSuccess);
    } catch ({response}) {
        if (response?.data) {
            onRequestError(response.data);
        }
    }
};

const postLoginForm = async ({formData, throwException, onRequestError, onRequestSuccess}) => {
    try {
        const response = await new ApiClient().callPost({
            methodName: apiMethods.AUTH_SIGN_IN,
            requestConfig: {
                data: loginRequestDataMapping(formData),
            },
            throwException,
        });

        if (response) {
            helpers.runFunction(onRequestSuccess);

            const onAuthMethodResponse = (response) => {
                const responseData = authMapping(response);

                authDataStoreService.setAuthStoreData(responseData, formData);

                return responseData;
            };

            onAuthMethodResponse(response);
        }
    } catch ({response}) {
        if (response?.data?.error) {
            onRequestError(response?.data?.error);
        }
    }
};

const fetchToken = async () => {
    const login = storageService.getUserLoginFromStorage();
    const refreshToken = storageService.getRefreshTokenFromStorage();

    try {
        return await new ApiClient().callPost({
            methodName: apiMethods.AUTH_REFRESH_TOKEN,
            requestConfig: {
                data: loginRequestDataMapping({login, refreshToken}),
            },
            mapper: authMapping,
            onResponse: (responseData) => {
                authDataStoreService.setAuthStoreData(responseData, {login});
            },
        });
    } catch (error) {
        appService.logout();

        throw Error(error);
    }
};

const passwordRestore = async ({email, onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.AUTH_PASSWORD_RESTORE,
            requestConfig: {
                data: {
                    email,
                },
            },
            onResponse: onRequestSuccess,
        });
    } catch (error) {
        log.error(`passwordRestore: ${error}`);
    }
};

const passwordRestoreVerification = async ({values, onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.AUTH_PASSWORD_RESTORE_VERIFICATION,
            requestConfig: {
                data: passRestoreVerificationMapping(values),
            },
            onResponse: onRequestSuccess,
        });
    } catch (error) {
        log.error(`passwordRestoreVerification: ${error}`);
    }
};

const changePassword = async ({values, onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.AUTH_CHANGE_PASSWORD,
            requestConfig: {
                data: changePasswordMapping(values),
            },
            onResponse: onRequestSuccess,
        });
    } catch (error) {
        log.error(`changePassword: ${error}`);
    }
};

export default {
    postRegistrationForm,
    postLoginForm,
    fetchToken,
    passwordRestore,
    changePassword,
    passwordRestoreVerification,
};
