import * as routeParams from './routeParams';

export const ROOT = '/';
export const SERVICES_PAGE = '/services';
export const SERVICES_KYIVENERGO_PAGE = `${SERVICES_PAGE}/kyivteploenergo`;
export const SERVICES_KVK_PAGE = `${SERVICES_PAGE}/kyivvodokanal`;
export const SERVICES_LVK_PAGE = `${SERVICES_PAGE}/lvivvodokanal`;
export const SERVICES_ARS_ORGANIZATIONS_PAGE = `${SERVICES_PAGE}/ars-organizations`;
export const SERVICES_ARS_ORGANIZATIONS_PAGE_PARAMETRIZED = `${SERVICES_ARS_ORGANIZATIONS_PAGE}/:${routeParams.ARS_ORGANIZATION_ID}`; // work
export const AUTH_PAGE = '/auth';
export const AUTH_PAGE_PARAMETRIZED = `${AUTH_PAGE}/:${routeParams.SCREEN_NAME}`;
export const MAP_PAGE = '/map';
export const OBJECTS_PAGE = `/objects`;
export const ROOMER_OBJECTS_PAGE = `/user-objects`;
export const USERS_OBJECTS_PAGE = `/users-objects`;
export const USERS_OBJECTS_PAGE_PARAMETRIZED = `${USERS_OBJECTS_PAGE}/:${routeParams.USER_ID}`;
export const ORGANIZATION_PAGE = `/organization`;
export const ORGANIZATION_PAGE_PARAMETRIZED = `${ORGANIZATION_PAGE}/:${routeParams.ORGANIZATION_ID}`;
export const USERS_PAGE = `/users`;
export const SETTINGS_PAGE = `/settings`;
export const MONITORING_PAGE = `/monitoring`;
export const REPORTS_PAGE = `/reports`;
export const DATA_COLLECTORS_PAGE = `/data-collectors`;
export const DATA_COLLECTORS_PAGE_PARAMETRIZED = `${DATA_COLLECTORS_PAGE}/:${routeParams.BUILDING_ID}`;
export const PROFILE_PAGE = '/profile';
export const DIAGNOSTIC_PAGE = '/diagnostic';
export const DIAGNOSTIC_PAGE_PARAMETRIZED = `${DIAGNOSTIC_PAGE}/:${routeParams.BUILDING_ID}/:${routeParams.ORGANIZATION_ID}`;
export const BUILDING_PROFILE_PAGE = `/building-profile`;
export const BUILDING_PROFILE_PAGE_PARAMETRIZED = `${BUILDING_PROFILE_PAGE}/:${routeParams.BUILDING_ID}`;
export const WILDCARD = '*';
export const ROOM_PROFILE_PAGE = `/room-profile`;
export const ROOM_PROFILE_PAGE_PARAMETRIZED = `${ROOM_PROFILE_PAGE}/:${routeParams.ROOM_ID}`;
