import {Radio} from 'antd';
import React from 'react';

const FormRadioButton = ({form, name, options, value, onChange, disabled}) => {
    const handleChange = (event) => {
        if (onChange) {
            onChange(event);
        } else {
            form.setFieldValue(name, event?.target?.value);
        }
    };

    return (
        <Radio.Group
            prefixCls='ep-form-item-radio'
            onChange={handleChange}
            disabled={disabled}
            value={value}
            size='large'
        >
            {options.map((item) => (
                <Radio.Button key={item.name} value={item.value}>
                    {item.name}
                </Radio.Button>
            ))}
        </Radio.Group>
    );
};

export default FormRadioButton;
