import {useState} from 'react';

import appConfig from '../config/appConfig';
import {setRequestParams} from '../state/ducks/requestParams';
import {dispatch} from '../state/store';
import useDebounce from './useDebounce';
import useDidUpdate from './useDidUpdate';

const useSearch = ({page, paramsType, searchValue}) => {
    const [lastVisitedPage, setLastVisitedPage] = useState(null);
    const debounceDelay = appConfig.getSearchDelayDuration();
    const debouncedValue = useDebounce(searchValue, debounceDelay);

    const handleFocus = () => {
        if (lastVisitedPage || !page) return;

        setLastVisitedPage(page);
    };

    const handleBlur = () => {
        if (searchValue || !lastVisitedPage) return;

        setLastVisitedPage(null);
    };

    useDidUpdate(() => {
        const inputValue = searchValue?.toLowerCase().trim();
        const onUnbountPage = 1;
        const onUnmountValue = '';

        const newPage = inputValue ? onUnbountPage : lastVisitedPage || onUnbountPage;
        const newQuery = inputValue || onUnmountValue;

        dispatch(setRequestParams({paramsType, paramsProps: {query: newQuery, page: newPage}}));
    }, [debouncedValue]);

    return [handleFocus, handleBlur];
};

export default useSearch;
