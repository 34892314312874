import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {IconNotification} from '../../../components/Icons';

const mapStateToProps = createStructuredSelector({});
const NotificationsDrawerContainer = () => {
    return (
        <div className={'flex gap-2 h-full flex-col items-center justify-center'}>
            <IconNotification color={'#000'} />

            <p>{`Тут скоро з'являться сповіщення`}</p>
        </div>
    );
};

export default connect(mapStateToProps)(NotificationsDrawerContainer);
