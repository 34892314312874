import React from 'react';

import logo from '../../../assets/svg/mapAssets/logo.svg';

const Logo = () => {
    const alt = 'EnergyPortal Logo';

    return (
        <div>
            <img src={logo} className={'w-full h-auto'} alt={alt} />
        </div>
    );
};

export default Logo;
