import React from 'react';

import Icon from '../Icon/Icon';

const IconLockOpen = (props) => {
    return (
        <Icon width='15' height='16' viewBox='0 0 15 16' fill='none' {...props}>
            <path
                d='M2 9V8.66667C2 7.19391 3.19391 6 4.66667 6H10C11.4728 6 12.6667 7.19391 12.6667 8.66667V11.3333C12.6667 12.8061 11.4728 14 10 14H4.66667C3.19391 14 2 12.8061 2 11.3333V11'
                stroke='#3B3B3B'
                strokeLinecap='round'
            />
            <path d='M7.33325 10.6666L7.33325 9.33331' stroke='#3B3B3B' strokeLinecap='round' strokeLinejoin='round' />
            <path
                d='M9.11123 3.42819C9.26907 3.65478 9.58071 3.7105 9.8073 3.55266C10.0339 3.39481 10.0896 3.08317 9.93176 2.85659L9.11123 3.42819ZM4.85221 2.69908C4.68063 2.91545 4.71694 3.22994 4.93331 3.40152C5.14967 3.5731 5.46417 3.53679 5.63575 3.32042L4.85221 2.69908ZM5.16675 6V4.66667H4.16675V6H5.16675ZM5.16675 4.66667C5.16675 3.47005 6.1368 2.5 7.33341 2.5V1.5C5.58451 1.5 4.16675 2.91777 4.16675 4.66667H5.16675ZM9.93176 2.85659C9.5541 2.31447 9.01633 1.90404 8.39377 1.68281L8.05892 2.62508C8.48488 2.77645 8.85283 3.05727 9.11123 3.42819L9.93176 2.85659ZM8.39377 1.68281C7.77121 1.46157 7.09504 1.4406 6.45997 1.62284L6.73579 2.58405C7.17032 2.45936 7.63296 2.47371 8.05892 2.62508L8.39377 1.68281ZM6.45997 1.62284C5.8249 1.80508 5.26273 2.1814 4.85221 2.69908L5.63575 3.32042C5.91663 2.96622 6.30127 2.70874 6.73579 2.58405L6.45997 1.62284Z'
                fill='#3B3B3B'
            />
        </Icon>
    );
};

export default IconLockOpen;
