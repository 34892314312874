import React from 'react';

import Icon from '../Icon/Icon';

const IconApprove = (props) => {
    return (
        <Icon width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
            <path
                d='M1.33374 6.66699C1.33374 6.06264 1.33374 5.35996 1.33374 4.66691C1.33374 2.82596 2.82612 1.33366 4.66707 1.33366L11.3337 1.33366C13.1747 1.33366 14.6671 2.82604 14.6671 4.66699L14.6671 11.3337C14.6671 13.1746 13.1747 14.667 11.3337 14.667L4.66707 14.667C2.82612 14.667 1.33374 13.1746 1.33374 11.3337L1.33374 9.33366'
                stroke='#3B3B3B'
                strokeLinecap='round'
            />
            <path
                d='M6.33366 7.66703L7.66699 9.00036L10.3337 6.3337'
                stroke='#3B3B3B'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default IconApprove;
