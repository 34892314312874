const formatString = (string, ...values) => {
    for (let i = 0; i < values.length; i++) {
        string = string.replace(`{${i}}`, values[i]);
    }

    return string;
};

const formatByName = (str, values) => {
    if (!str) return str;

    return str.replace(/\{\{|\}\}|\{(\w+)\}/g, (m, n) => {
        if (m == '{{') {
            return '{';
        }
        if (m == '}}') {
            return '}';
        }
        return values[n];
    });
};

const isAlphaNumeric = (str) => {
    let code, i, len;

    for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);
        if (
            !(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)
        ) {
            // lower alpha (a-z)
            return false;
        }
    }
    return true;
};

const isEngLanguage = (str) => /^[A-Za-z]*$/.test(str);

const isNullOrEmpty = (str) => !str || str === 'null';

const getIsTextIncludesFormatChar = (inputText) => {
    const FORMAT_CHAR = '{0}';

    return !!inputText?.includes(FORMAT_CHAR);
};

const toString = (param) => {
    if (!param && param !== 0) {
        return undefined;
    } else {
        return String(param);
    }
};

const formatStringWithPow = (string) => {
    if (string.includes('^')) {
        return string.replace('^', `<sup>`) + `</sup>`;
    }

    return string;
};

const getBooleanFromString = (string) => string === 'true';

export default {
    formatStringWithPow,
    formatString,
    formatByName,
    isNullOrEmpty,
    isAlphaNumeric,
    getIsTextIncludesFormatChar,
    toString,
    isEngLanguage,
    getBooleanFromString,
};
