import React from 'react';

import Icon from '../Icon/Icon';

const IconSwap = (props) => {
    return (
        <Icon width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
            <path
                d='M12.8724 5.83068C12.4723 4.93213 11.8316 4.16172 11.021 3.60461C10.2104 3.0475 9.26151 2.7254 8.27927 2.67392C7.29703 2.62244 6.31969 2.84359 5.4553 3.31292C5.00622 3.55675 4.59671 3.86264 4.23791 4.21973'
                stroke='#3B3B3B'
                strokeLinecap='round'
            />
            <path
                d='M2.15458 3.6769L2.64085 5.78326C2.72367 6.14201 3.08164 6.3657 3.4404 6.28287L5.54675 5.7966'
                stroke='#3B3B3B'
                strokeLinecap='round'
            />
            <path
                d='M3.12766 10.1691C3.52772 11.0677 4.16847 11.8381 4.97907 12.3952C5.78967 12.9523 6.73854 13.2744 7.72078 13.3259C8.70301 13.3774 9.68036 13.1562 10.5447 12.6869C10.9544 12.4644 11.3312 12.1904 11.6666 11.8729'
                stroke='#3B3B3B'
                strokeLinecap='round'
            />
            <path
                d='M13.8454 12.323L13.3591 10.2166C13.2763 9.85787 12.9184 9.63418 12.5596 9.717L10.4532 10.2033'
                stroke='#3B3B3B'
                strokeLinecap='round'
            />
        </Icon>
    );
};

export default IconSwap;
