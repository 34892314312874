import * as authScreenNames from '../../consts/auth/authScreenNames';
import * as routePaths from '../../consts/route/routePaths';
import urlUtils from '../../utils/urlUtils';
import routerService from '../routerService';

const forwardToRoot = () => routerService.forwardTo(routePaths.ROOT);
const forwardToServicesPage = () => routerService.forwardTo(routePaths.SERVICES_PAGE);
const forwardToServiceKyivenergoPage = () => routerService.forwardTo(routePaths.SERVICES_KYIVENERGO_PAGE);
const forwardToServiceKvkPage = () => routerService.forwardTo(routePaths.SERVICES_KVK_PAGE);
const forwardToServiceLvkPage = () => routerService.forwardTo(routePaths.SERVICES_LVK_PAGE);
const forwardToServiceArsOrganizationsPage = () => routerService.forwardTo(routePaths.SERVICES_ARS_ORGANIZATIONS_PAGE);
const forwardToServiceArsOrganizationPage = (organizationId = '') =>
    routerService.forwardTo(urlUtils.join(routePaths.SERVICES_ARS_ORGANIZATIONS_PAGE, organizationId));
const forwardToAuthPage = (screenName = authScreenNames.LOGIN) =>
    routerService.forwardTo(urlUtils.join(routePaths.AUTH_PAGE, screenName));
const forwardToLoginPage = () => routerService.forwardTo(urlUtils.join(routePaths.AUTH_PAGE, authScreenNames.LOGIN));
const forwardToMapPage = () => routerService.forwardTo(routePaths.MAP_PAGE);
const forwardToRoomerObjectsPage = () => routerService.forwardTo(routePaths.ROOMER_OBJECTS_PAGE);
const forwardToUsersObjectsPage = (userId = '') =>
    routerService.forwardTo(urlUtils.join(routePaths.USERS_OBJECTS_PAGE, userId));
const forwardToOrganizationsPage = () => routerService.forwardTo(routePaths.OBJECTS_PAGE);
const forwardToOrganizationPage = (organization = '') =>
    routerService.forwardTo(urlUtils.join(routePaths.ORGANIZATION_PAGE, organization));
const forwardToUsersPage = () => routerService.forwardTo(routePaths.USERS_PAGE);
const forwardToSettingsPage = () => routerService.forwardTo(routePaths.SETTINGS_PAGE);
const forwardToMonitoringPage = () => routerService.forwardTo(routePaths.MONITORING_PAGE);
const forwardToReportsPage = () => routerService.forwardTo(routePaths.REPORTS_PAGE);
const forwardToDataCollectorsPage = (buildingId = '') =>
    routerService.forwardTo(urlUtils.join(routePaths.DATA_COLLECTORS_PAGE, buildingId));
const forwardToProfilePage = () => routerService.forwardTo(routePaths.PROFILE_PAGE);
const forwardToDiagnosticPage = (buildingId = '', organizationId = '') =>
    routerService.forwardTo(urlUtils.join(routePaths.DIAGNOSTIC_PAGE, buildingId, organizationId));
const forwardToBuildingProfilePage = (buildingId = '') => {
    return routerService.forwardTo(urlUtils.join(routePaths.BUILDING_PROFILE_PAGE, buildingId));
};
const forwardToRoomProfilePage = (roomId = '') => {
    return routerService.forwardTo(urlUtils.join(routePaths.ROOM_PROFILE_PAGE, roomId));
};

export default {
    forwardToRoot,
    forwardToServicesPage,
    forwardToLoginPage,
    forwardToAuthPage,
    forwardToMapPage,
    forwardToRoomerObjectsPage,
    forwardToUsersObjectsPage,
    forwardToOrganizationsPage,
    forwardToOrganizationPage,
    forwardToUsersPage,
    forwardToSettingsPage,
    forwardToMonitoringPage,
    forwardToReportsPage,
    forwardToDataCollectorsPage,
    forwardToProfilePage,
    forwardToDiagnosticPage,
    forwardToBuildingProfilePage,
    forwardToRoomProfilePage,
    forwardToServiceKyivenergoPage,
    forwardToServiceKvkPage,
    forwardToServiceLvkPage,
    forwardToServiceArsOrganizationsPage,
    forwardToServiceArsOrganizationPage,
};
