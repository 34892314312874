import React from 'react';

import Icon from '../Icon/Icon';

const IconLock = (props) => {
    return (
        <Icon width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
            <rect x='2.66669' y='6' width='10.6667' height='8' rx='2.66667' stroke='#3B3B3B' />
            <path d='M8.00003 10.6667L8.00003 9.33333' stroke='#3B3B3B' strokeLinecap='round' strokeLinejoin='round' />
            <path
                d='M10.6667 6V4.66667C10.6667 3.19391 9.4728 2 8.00004 2V2C6.52728 2 5.33337 3.19391 5.33337 4.66667L5.33337 6'
                stroke='#3B3B3B'
            />
        </Icon>
    );
};

export default IconLock;
