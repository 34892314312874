import React from 'react';

import dataCollectorTransferStatuses from '../../consts/dataCollector/dataCollectorTransferStatuses.ts';
import * as localizationKeys from '../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import stylesService from '../../services/styles/stylesService';
import StatusBadge from '../StatusBadge/StatusBadge';

const DataCollectorInfoPopover = () => {
    const localizedStrings = getLocalizedStrings();

    const statusExplicationStrings = {
        [dataCollectorTransferStatuses[dataCollectorTransferStatuses.AllDevicesWithoutErrors]]:
            localizedStrings[localizationKeys.DATA_COLLECTOR_STATUS_ALL_DEVICES_WITHOUT_ERRORS],
        [dataCollectorTransferStatuses[dataCollectorTransferStatuses.NoDataRetrieved]]:
            localizedStrings[localizationKeys.DATA_COLLECTOR_STATUS_NO_DATA_RETRIEVED],
        [dataCollectorTransferStatuses[dataCollectorTransferStatuses.AllDevicesWithErrors]]:
            localizedStrings[localizationKeys.DATA_COLLECTOR_STATUS_ALL_DEVICES_WITH_ERRORS],
        [dataCollectorTransferStatuses[dataCollectorTransferStatuses.SomeDevicesWithErrors]]:
            localizedStrings[localizationKeys.DATA_COLLECTOR_STATUS_SOME_DEVICES_WITH_ERRORS],
        [dataCollectorTransferStatuses[dataCollectorTransferStatuses.TimepointsDoNotMatchWithCurrentDate]]:
            localizedStrings[localizationKeys.DATA_COLLECTOR_STATUS_TIMEPOINTS_DO_NOT_MATH_WITH_CURRENT_DATE],
        [dataCollectorTransferStatuses[dataCollectorTransferStatuses.DataTransferDisabled]]:
            localizedStrings[localizationKeys.DATA_COLLECTOR_STATUS_DATA_TRANSFER_DISABLED],
    };

    const availableStatuses = Object.keys(statusExplicationStrings);

    return (
        <>
            {availableStatuses.map((status, index) => (
                <div key={index} className={'mb-1 flex flex-row items-baseline'}>
                    <StatusBadge
                        statusColor={stylesService.statusColorClassNames[dataCollectorTransferStatuses[status]]}
                        value={statusExplicationStrings[status]}
                    />
                </div>
            ))}
        </>
    );
};

export default DataCollectorInfoPopover;
