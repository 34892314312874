import {createSelector} from 'reselect';

const selectAuth = (state) => state.auth;

const makeSelectAuthToken = () => createSelector(selectAuth, (authState) => authState.accessToken);

const makeSelectRefreshToken = () => createSelector(selectAuth, (authState) => authState.refreshToken);
const makeSelectLogin = () => createSelector(selectAuth, (authState) => authState.login);

const makeSelectUserPassword = () => createSelector(selectAuth, (authState) => authState.password);

const makeSelectRememberMe = () => createSelector(selectAuth, (authState) => authState.rememberMe);

export {makeSelectAuthToken, makeSelectLogin, makeSelectUserPassword, makeSelectRememberMe, makeSelectRefreshToken};
