import buildingsService from '../../../../services/buildings/buildingsService';
import openStreetApiService from '../../../../services/map/openStreetApiService';
import organizationsService from '../../../../services/organizations/organizationsService';
import {setCustomMarker} from '../../../../state/ducks/maps';
import {makeSelectBuildingProfile, makeSelectCurrentBuilding} from '../../../../state/selectors/buildings';
import {makeSelectCities, makeSelectCityNameByCityID} from '../../../../state/selectors/organizations';
import {dispatch, getState} from '../../../../state/store';

export const getCoordinatesByCityAndAddress = async (city, address) => {
    const state = getState();

    const {buildingID} = makeSelectBuildingProfile()(state) || makeSelectCurrentBuilding()(state) || {};

    if (city) {
        const {lat, lon, display_name, ...rest} = await openStreetApiService.getCoordinatesByAddress(address, city);

        if (lat && lon) {
            dispatch(
                setCustomMarker({
                    address: display_name,
                    latitude: lat,
                    longitude: lon,
                })
            );
        }

        return {lat, lon, display_name, ...rest};
    } else {
        const cityID = tryGetCityIDOfBuilding(buildingID);

        if (!cityID) return null;

        const cityName = makeSelectCityNameByCityID(cityID)(getState());

        if (!cityName) return null;

        return await getCoordinatesByCityAndAddress(cityName, address);
    }
};

const tryGetCityIDOfBuilding = async (buildingID) => {
    const cities = makeSelectCities()(getState()) || {};

    if (!cities?.length) await organizationsService.getCities();

    // todo: remove "getBuildingById" request after /buildings/{uuid}/details response will includes "cityID";
    const {cityID} = (await buildingsService.getBuildingById(buildingID)) || {};

    return cityID;
};
