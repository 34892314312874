import {createSelector} from 'reselect';

const selectDataCollectors = (state) => state.dataCollectors;

const makeSelectDataCollectorsData = () =>
    createSelector(selectDataCollectors, (dataCollectorsState) => dataCollectorsState.dataCollectorsData);

const makeSelectDataCollectorsList = () =>
    createSelector(makeSelectDataCollectorsData(), (dataCollectorsData) => dataCollectorsData?.items);

const makeSelectCurrentDataCollector = () =>
    createSelector(selectDataCollectors, (dataCollectorsState) => dataCollectorsState.currentDataCollector);

const makeSelectDataCollectorTypes = () =>
    createSelector(makeSelectDataCollectorsData(), (dataCollectorsData) => dataCollectorsData?.dataCollectorTypes);

const makeSelectDataCollectorSortableColumns = () =>
    createSelector(makeSelectDataCollectorsData(), (dataCollectorsData) => dataCollectorsData?.sortableColumns);

export {
    makeSelectDataCollectorsData,
    makeSelectDataCollectorsList,
    makeSelectCurrentDataCollector,
    makeSelectDataCollectorTypes,
    makeSelectDataCollectorSortableColumns,
};
