import * as apiMethods from '../../consts/api/apiMethods';
import {BUILDING_DETAILS_BY_ID} from '../../consts/api/apiMethods';
import * as requestParamTypes from '../../consts/app/requestParamTypes';
import {
    setBuildingDataCollectors,
    setBuildingProfile,
    setBuildings,
    setCurrentBuilding,
    setIndicators,
    setRooms,
    setUsersData,
} from '../../state/ducks/buildings';
import {initialParams} from '../../state/ducks/requestParams';
import {makeSelectBuildingsOrganizationId, makeSelectRoomBuildingId} from '../../state/selectors/buildings';
import {makeSelectRequestParams} from '../../state/selectors/requestParams';
import {getState} from '../../state/store';
import helpers from '../../utils/helpers';
import ApiClient from '../api/clients/apiClient';
import log from '../logger/log';
import {
    buildingsListRequestDataMapping,
    createBuildingRequestDataMapping,
    editBuildingRequestDataMapping,
    usersByBuildingIdRequestDataMapping,
} from '../mappings/buildingMappings';

const getUsersByBuildingId = async ({buildingId, onRequestSuccess}) => {
    const requestParams = makeSelectRequestParams(requestParamTypes.USERS)(getState());

    try {
        return await new ApiClient().callPost({
            methodName: apiMethods.USERS_BY_BUILDING_ID,
            action: setUsersData,
            mapper: usersByBuildingIdRequestDataMapping,
            requestConfig: {
                args: buildingId,
                data: requestParams,
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getUsersByBuildingId: ${e}`);
    }
};

const getBuildingsListByOrganizationId = async (id) => {
    const requestParams = makeSelectRequestParams(requestParamTypes.BUILDINGS)(getState());
    const organizationId = id || makeSelectBuildingsOrganizationId()(getState());

    try {
        await new ApiClient().callPost({
            methodName: apiMethods.BUILDINGS_LIST_BY_ORGANIZATION_ID,
            action: setBuildings,
            mapper: buildingsListRequestDataMapping,
            requestConfig: {
                data: requestParams,
                args: organizationId,
            },
        });
    } catch (e) {
        log.error(`getBuildingsListByOrganizationId: ${e}`);
    }
};

const getBuildingsList = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.BUILDINGS,
            action: setBuildings,
        });
    } catch (e) {
        log.error(`getBuildingsList: ${e}`);
    }
};

const getOrganizationBuildingsByCounterType = async ({id, requestParams, onRequestSuccess}) => {
    const stateRequestParams = makeSelectRequestParams(requestParamTypes.BUILDINGS)(getState());

    return await new ApiClient().callPost({
        methodName: apiMethods.GROUP_BUILDINGS,
        action: setBuildings,
        mapper: buildingsListRequestDataMapping,
        requestConfig: {
            data: {
                ...stateRequestParams,
                ...requestParams,
            },
            args: id,
        },
        onResponse: onRequestSuccess,
    });
};

const editBuilding = async ({formData, onRequestSuccess, onRequestError}) => {
    try {
        await new ApiClient().callPut({
            methodName: apiMethods.BUILDINGS,
            requestConfig: {
                data: editBuildingRequestDataMapping(formData),
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`editBuilding: ${e}`);
        helpers.runFunction(onRequestError);
    }
};

const deleteBuilding = async ({buildingID, onRequestSuccess, onRequestError}) => {
    try {
        await new ApiClient().callDelete({
            methodName: apiMethods.BUILDINGS,
            requestConfig: {
                data: {BuildingID: buildingID},
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`deleteBuilding: ${e}`);
        helpers.runFunction(onRequestError);
    }
};

const uploadFile = async ({file, organizationID, onRequestSuccess, onRequestError}) => {
    const formData = new FormData();

    formData.append('file', file);

    try {
        await new ApiClient().callPost({
            methodName: apiMethods.UPLOAD_FILE,

            headers: {'Content-type': 'multipart/form-data'},
            requestConfig: {
                data: formData,
                args: organizationID,
            },
            contentType: 'multipart/form-data',
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`uploadFile: ${e}`);
        helpers.runFunction(onRequestError);
    }
};

const uploadImage = async ({file, buildingId, onRequestSuccess, onRequestError}) => {
    const formData = new FormData();

    formData.append('file', file);

    try {
        await new ApiClient().callPost({
            methodName: apiMethods.UPLOAD_IMAGE,
            requestConfig: {
                data: formData,
                args: buildingId,
            },
            contentType: 'multipart/form-data',
            onResponse: onRequestSuccess,
        });

        return true;
    } catch (e) {
        log.error(`uploadFile: ${e}`);
        helpers.runFunction(onRequestError);

        return false;
    }
};

const createBuilding = async ({formData, onRequestSuccess, onRequestError}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.BUILDINGS,
            requestConfig: {
                data: createBuildingRequestDataMapping(formData),
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`createBuilding: ${e}`);
        helpers.runFunction(onRequestError);
    }
};
const getBuildingById = async (id, onRequestSuccess, onRequestError) => {
    try {
        return await new ApiClient().callGet({
            methodName: apiMethods.BUILDING_BY_ID,
            action: setCurrentBuilding,
            requestConfig: {
                args: id,
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`getBuildingById: ${e}`);
        helpers.runFunction(onRequestError);
    }
};

const getIndicatorsByBuildingId = async (requestParams, buildingId) => {
    const currentBuildingId = makeSelectRoomBuildingId()(getState());

    try {
        await new ApiClient().callPost({
            methodName: apiMethods.INDICATORS_BY_BUILDING_ID,
            action: setIndicators,
            requestConfig: {
                data: requestParams,
                args: buildingId || currentBuildingId,
            },
        });
    } catch (e) {
        log.error(`getIndicatorsByBuildingId: ${e}`);
    }
};

const getRoomsByBuildingId = async (buildingId) => {
    const currentBuildingId = buildingId || makeSelectRoomBuildingId()(getState());
    const stateRequestParams = makeSelectRequestParams(requestParamTypes.ROOMS)(getState());
    const requestParams = !stateRequestParams ? initialParams : stateRequestParams;

    try {
        await new ApiClient().callPost({
            methodName: apiMethods.ROOMS_BY_BUILDING_ID,
            action: setRooms,
            requestConfig: {
                data: {...requestParams, direction: 1},
                args: buildingId || currentBuildingId,
            },
        });
    } catch (e) {
        log.error(`getRoomsByBuildingId: ${e}`);
    }
};

const getCurrentRoomsByBuildingId = async (buildingId) => {
    const currentBuildingId = makeSelectRoomBuildingId()(getState());
    const stateRequestParams = makeSelectRequestParams(requestParamTypes.ROOMS)(getState());
    const requestParams = !stateRequestParams ? initialParams : stateRequestParams;

    try {
        await new ApiClient().callPost({
            methodName: apiMethods.ROOMS_BY_BUILDING_ID,
            action: setRooms,
            requestConfig: {
                data: {...requestParams, direction: 1},
                args: currentBuildingId || buildingId,
            },
        });
    } catch (e) {
        log.error(`getCurrentRoomsByBuildingId: ${e}`);
    }
};

const getDataCollectorByBuildingId = async (buildingId) => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.DATA_COLLECTOR_BY_BUILDING_ID,
            action: setBuildingDataCollectors,
            requestConfig: {
                args: buildingId,
            },
        });
    } catch (e) {
        log.error(`getRoomsByBuildingId: ${e}`);
    }
};

const getBuildingDetailsById = async (id) => {
    return await new ApiClient().callGet({
        methodName: BUILDING_DETAILS_BY_ID,
        action: setBuildingProfile,
        requestConfig: {
            args: id,
        },
    });
};

export default {
    getBuildingDetailsById,
    getOrganizationBuildingsByCounterType,
    getBuildingsListByOrganizationId,
    getBuildingsList,
    getBuildingById,
    createBuilding,
    editBuilding,
    deleteBuilding,
    getRoomsByBuildingId,
    getDataCollectorByBuildingId,
    uploadFile,
    uploadImage,
    getIndicatorsByBuildingId,
    getCurrentRoomsByBuildingId,
    getUsersByBuildingId,
};
