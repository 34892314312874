import React, {useCallback, useEffect} from 'react';
import {Marker, TileLayer, useMap} from 'react-leaflet';

import {markerGreen} from '../../../../../services/map/mapMarkerCreatorService';

const MapContent = ({latitude, longitude, onMapClick, zoom, setZoom}) => {
    const map = useMap();

    const setView = useCallback(() => {
        if (latitude && longitude) {
            map.setView([latitude, longitude], zoom);
        }
    }, [latitude, longitude]);

    useEffect(() => {
        setView();
    }, [latitude, longitude]);

    useEffect(() => {
        map.invalidateSize();
    }, []);

    const handleMapClick = async (event) => {
        setZoom(map.getZoom());

        await onMapClick(event);
    };

    useEffect(() => {
        map.on('click', handleMapClick);

        return () => {
            map.off('click', handleMapClick);
        };
    }, [map]);

    return (
        <>
            <TileLayer
                noWrap
                subdomains='abcd'
                attribution='&copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a target="_blank" href="https://carto.com/attributions">CARTO</a>'
                url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
            />
            {latitude && longitude && <Marker position={[latitude, longitude]} icon={markerGreen} />}
        </>
    );
};

export default MapContent;
