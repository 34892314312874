import React from 'react';

import Icon from '../Icon/Icon';

const IconChevronRightBlue = (props) => {
    return (
        <Icon width='5' height='7' viewBox='0 0 5 7' fill='none' {...props}>
            <path
                d='M1.17892 6.28572L3.96464 3.50001L1.17892 0.714294'
                stroke='#0187F9'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default IconChevronRightBlue;
