import React from 'react';

import Icon from '../Icon/Icon';

const IconElectricity = (props) => {
    const fill = props?.fill || '#FFAE00';

    return (
        <Icon width='16' height='30' viewBox='0 0 16 30' fill='none' {...props}>
            <path
                d='M6.66667 11.6266V13.6666H11.68L9.33333 18.3732V16.3332H4.32L6.66667 11.6266ZM9.33333 0.333252L0 18.9999H6.66667V29.6666L16 10.9999H9.33333V0.333252Z'
                fill={fill}
            />
        </Icon>
    );
};

export default IconElectricity;
