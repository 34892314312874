import {EditOutlined} from '@ant-design/icons';
import {Button, Upload} from 'antd';
import React from 'react';

import appConfig from '../../config/appConfig';
import * as notificationTypes from '../../consts/app/notificationTypes';
import * as formInputNames from '../../consts/form/formInputNames';
import * as localizationKeys from '../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {setImageExtension, setNotification, setOriginImageUrl, setShortImageUrl} from '../../state/ducks/global';
import {dispatch} from '../../state/store';
import helpers from '../../utils/helpers';
import regexUtils from '../../utils/regexUtils';

const checkImageExtension = (imageExtension) => {
    if (!imageExtension) return false;
    const allowedExtensions = appConfig.getSupportedImageTypes();
    const isSupported = allowedExtensions.find((allowedExtension) => allowedExtension === imageExtension);

    return isSupported;
};

const ImageUpload = ({beforeUpload, afterUpload, name, form}) => {
    const localizedStrings = getLocalizedStrings();
    const maxFileSizeMB = 1;

    const handleBeforeUpload = () => {
        if (beforeUpload) {
            return helpers.runFunction(beforeUpload);
        }

        return false;
    };

    const handleImageChange = (info) => {
        const reader = new FileReader();
        const imageExtension = info.file.type.split('/')[1];
        const isExtensionSupported = checkImageExtension(info.file.type);

        if (!isExtensionSupported) {
            dispatch(
                setNotification({
                    type: notificationTypes.ERROR,
                    text: localizedStrings[localizationKeys.PROFILE_PAGE_EDIT_USER_FORM_IMAGE_FIELD_FEEDBACK],
                })
            );
            return;
        }

        const fileSizeBytes = info.file.size;
        const fileSizeMB = fileSizeBytes / (1024 * 1024);

        if (fileSizeMB > maxFileSizeMB) {
            dispatch(
                setNotification({
                    type: notificationTypes.ERROR,
                    text: localizedStrings[localizationKeys.PROFILE_PAGE_EDIT_IMAGE_EXCEED_SIZE],
                })
            );
            return;
        }

        if (info.file) {
            reader.readAsDataURL(info.file);
            reader.onloadend = () => {
                const originImageUrl = reader.result;
                const base64Url = originImageUrl.replace(regexUtils.BASE_64_REGEX, '');

                if (imageExtension) {
                    dispatch(setImageExtension(imageExtension));
                    form.setFieldValue(formInputNames.IMAGE_EXTENSION, imageExtension);
                }
                if (base64Url) {
                    dispatch(setShortImageUrl(base64Url));
                    form.setFieldValue(formInputNames.IMAGE, base64Url);
                }
                if (reader.result) {
                    dispatch(setOriginImageUrl(reader.result));
                }
                if (afterUpload) {
                    afterUpload(originImageUrl);
                }
            };
        }
    };

    return (
        <>
            <Upload
                onCancel={() => false}
                name={name}
                className='w-full h-full'
                showUploadList={false}
                beforeUpload={handleBeforeUpload}
                onChange={handleImageChange}
            >
                <Button
                    size='large'
                    className='h-full w-full'
                    icon={<EditOutlined />}
                    style={{right: 26, bottom: 27, width: '200px', height: '200px'}}
                />
            </Upload>
        </>
    );
};

export default ImageUpload;
