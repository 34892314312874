import React from 'react';

import Icon from '../Icon/Icon';

const IconHouseOutlined = (props) => {
    return (
        <Icon width='20' height='22' fill='none' {...props}>
            <g
                fill='none'
                fillRule='evenodd'
                stroke='#3B3B3B'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
            >
                <path d='m1 8 9-7 9 7v11a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8z' />
                <path d='M7 21V11h6v10' />
            </g>
        </Icon>
    );
};

export default IconHouseOutlined;
