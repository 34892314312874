import * as notificationTypes from '../../consts/app/notificationTypes';
import {setNotification} from '../../state/ducks/global';
import {dispatch} from '../../state/store';

export const showErrorNotification = (errorText, rest) =>
    dispatch(
        setNotification({
            type: notificationTypes.ERROR,
            text: errorText,
            ...rest,
        })
    );

export const showWarningNotification = (warningText, rest) =>
    dispatch(
        setNotification({
            type: notificationTypes.WARNING,
            text: warningText,
            ...rest,
        })
    );

export const showSuccessNotification = (successText, rest) =>
    dispatch(
        setNotification({
            type: notificationTypes.SUCCESS,
            text: successText,
            ...rest,
        })
    );

export const showResponseNotification = (failedItems, message, record) => {
    const uniqueErrorKeysSet = new Set(failedItems.map((obj) => obj.errorKey));
    const uniqueErrorsArray = Array.from(uniqueErrorKeysSet).map((errorKey) => {
        return failedItems.find((obj) => obj.errorKey === errorKey).errorKey;
    });

    if (failedItems.length == 0) {
        showSuccessNotification(message[0]);
    } else if (failedItems.length < record.length) {
        showWarningNotification(message[1] + uniqueErrorsArray);
    } else {
        showErrorNotification(message[2] + uniqueErrorsArray);
    }
};
