import {Drawer} from 'antd';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {IconClose} from '../../components/Icons';
import drawerContentTypes from '../../consts/drawer/drawerContentTypes';
import {hideDrawer} from '../../state/ducks/drawer';
import {makeSelectDrawerContentType, makeSelectIsDrawerVisible} from '../../state/selectors/drawer';
import {dispatch} from '../../state/store';
import MapBuildingDrawerContainer from './containers/MapBuildingDrawerContainer';
import NotificationsDrawerContainer from './containers/NotificationsDrawerContainer';

const COMPONENT_MAP = {
    [drawerContentTypes.MAP_OBJECT]: MapBuildingDrawerContainer,
    [drawerContentTypes.NOTIFICATIONS]: NotificationsDrawerContainer,
};

const mapStateToProps = createStructuredSelector({
    isOpen: makeSelectIsDrawerVisible(),
    contentType: makeSelectDrawerContentType(),
});

const AppDrawer = ({isOpen, contentType}) => {
    const ContentComponent = COMPONENT_MAP[contentType] || null;
    const dispatchHideDrawer = () => dispatch(hideDrawer());

    useEffect(() => {
        if (!contentType && isOpen) {
            dispatchHideDrawer();
        }
    }, [contentType]);

    return (
        <Drawer
            onClose={dispatchHideDrawer}
            closeIcon={null}
            open={isOpen}
            extra={<IconClose className={'w-6 h-6 cursor-pointer p-1'} onClick={dispatchHideDrawer} />}
            mask={false}
            width={415}
            headerStyle={{borderBottom: `none`, padding: `83px 10px 12px`}}
            bodyStyle={{padding: `0 34px 30px`, overflowY: 'overlay'}}
        >
            {ContentComponent && <ContentComponent />}
        </Drawer>
    );
};

export default connect(mapStateToProps)(AppDrawer);
