import React from 'react';

import Icon from '../Icon/Icon';

const IconContourLocation = ({isWhite, ...props}) => {
    const {color} = props || {};
    const fillColor = color ?? (isWhite ? 'var(--color-white)' : 'var(--color-santas-gray)');

    return (
        <Icon width='24' height='24' viewBox='0 0 26 26' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.0838 9.41616C4.0838 4.22409 8.30789 0 13.5 0C18.6921 0 22.9161 4.22409 22.9162 9.41616C22.9162 15.8597 14.4896 25.3192 14.1309 25.7187C13.7945 26.0934 13.2061 26.094 12.8692 25.7187C12.5104 25.3192 4.0838 15.8597 4.0838 9.41616ZM5.77949 9.41627C5.77949 13.8385 10.664 20.4847 13.5 23.8534C16.3367 20.4836 21.2205 13.8387 21.2205 9.41621C21.2205 5.15907 17.7572 1.69569 13.5 1.69569C9.24287 1.69569 5.77949 5.15907 5.77949 9.41627ZM8.76251 9.41622C8.76251 6.80393 10.8877 4.67868 13.5 4.67868C16.1122 4.67868 18.2374 6.80393 18.2374 9.41622C18.2374 12.0285 16.1122 14.1538 13.5 14.1538C10.8878 14.1538 8.76251 12.0285 8.76251 9.41622ZM10.4582 9.41622C10.4582 11.0935 11.8227 12.4581 13.5 12.4581C15.1773 12.4581 16.5418 11.0935 16.5418 9.41622C16.5418 7.73891 15.1773 6.37437 13.5 6.37437C11.8227 6.37437 10.4582 7.73891 10.4582 9.41622Z'
                fill={fillColor}
            />
        </Icon>
    );
};

export default IconContourLocation;
