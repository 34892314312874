import {DatePicker} from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import React, {useEffect, useRef, useState} from 'react';

import appConfig from '../../config/appConfig';
import * as formInputNames from '../../consts/form/formInputNames';
import useDidUpdate from '../../hooks/useDidUpdate';
import {setSelectedDate} from '../../state/ducks/device';
import {dispatch} from '../../state/store';
import {getDifferenceInDays} from '../../utils/dateUtils';
import helpers from '../../utils/helpers';

const AntDatePicker = (props) => {
    const {
        name,
        form,
        placeholder,
        className,
        onChange,
        disabled,
        value,
        mode,
        picker,
        format,
        alternativeView = false,
        withDisabledDate = true,
    } = props || {};

    const classNames = cn(`w-full h-12`, className);
    const minYearDate = appConfig.getMinYearDate();
    const startDate = form?.getFieldValue(formInputNames.START_DATE);

    const handleDateChange = (date) => {
        const updatedDate = {
            year: dayjs(date).year(),
            month: dayjs(date).month() + 1,
            day: dayjs(date).date(),
        };

        dispatch(setSelectedDate(updatedDate));

        if (onChange) {
            onChange(date, name);
        } else {
            form?.setFieldValue(name, date);
        }
    };

    const onPanelDateChange = (date) => {
        const isMinPickerDate = date && dayjs(date).year() - 10 <= minYearDate;
        const prevBtn = document.querySelector('.ant-picker-header-super-prev-btn');

        if (!prevBtn) return;
        if (isMinPickerDate) {
            prevBtn.style.display = 'none';
        } else {
            prevBtn.style.display = 'block';
        }
    };

    const checkIsDateDisabled = (date) => {
        const differenceInDays = dayjs().subtract(getDifferenceInDays(date), 'day');

        const endDateRange = startDate && differenceInDays <= dayjs(startDate);

        const isPickerYearValid = dayjs(date).year() < minYearDate;
        const isDateLessThenTommorow = dayjs().add(12, 'hours') <= date;
        const isEndDateLargeThenStart = name !== formInputNames.START_DATE && endDateRange;

        return !withDisabledDate
            ? isPickerYearValid
            : isDateLessThenTommorow || isPickerYearValid || isEndDateLargeThenStart;
    };

    return (
        <DatePicker
            panelRender={(panelNode) =>
                alternativeView ? (
                    <DatePickerPanel
                        format={format}
                        minYearDate={minYearDate}
                        handleDateChange={handleDateChange}
                        value={value}
                        picker={picker}
                    />
                ) : (
                    panelNode
                )
            }
            format={format}
            picker={picker}
            mode={mode}
            value={value ? dayjs(value) : ''}
            disabled={disabled}
            onChange={handleDateChange}
            className={classNames}
            defaultValue={dayjs().format(format)}
            allowClear={false}
            disabledDate={checkIsDateDisabled}
            onPanelChange={(date) => onPanelDateChange(date)}
            placeholder={placeholder}
        />
    );
};

const DatePickerPanel = ({value, handleDateChange, picker, minYearDate, format = 'MMMM, YYYY'}) => {
    const [dates, setDates] = useState([]);

    const initialDatesCount = 15;
    const initialDate = dayjs();
    const scrollRef = useRef();

    useEffect(() => {
        setInitialDates();
    }, []);

    useDidUpdate(() => {
        setDates([]);
        setInitialDates();
    }, [picker]);

    const upperCaseFirstLetter = (value) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    const subtractDate = (date, count) => {
        return date.subtract(count, picker);
    };

    const setInitialDates = () => {
        setDates((prevDates) => {
            const newDates = [];

            for (let i = 0; i <= initialDatesCount; i++) {
                const newDate = subtractDate(initialDate, i);

                newDates.push({
                    name: upperCaseFirstLetter(newDate.format(format)),
                    value: newDate,
                });
            }
            return [...prevDates, ...newDates];
        });
    };

    const handleScroll = () => {
        const isBottom =
            scrollRef.current.scrollHeight - scrollRef.current.scrollTop <= scrollRef.current.clientHeight + 50;

        if (isBottom) {
            const newDate = subtractDate(initialDate, dates?.length);

            if (newDate.year() == minYearDate - 1) return;

            setDates((prevDates) => [
                ...prevDates,
                {
                    name: upperCaseFirstLetter(newDate.format(format)),
                    value: newDate,
                },
            ]);
        }
    };
    const onDateChange = (value) => {
        helpers.runFunction(handleDateChange, value);
    };

    return (
        <div onScroll={handleScroll} ref={scrollRef} className='w-[193px] h-[228px] overflow-auto'>
            {dates.map((item, idx) => {
                const isActive = upperCaseFirstLetter(value?.format(format)) === item?.name;

                return (
                    <div
                        key={idx}
                        className={cn('p-2 hover:bg-cultured hover:cursor-pointer', {
                            'text-primary': isActive,
                        })}
                        onClick={() => onDateChange(item.value)}
                    >
                        {item?.name}
                    </div>
                );
            })}
        </div>
    );
};

export default AntDatePicker;
