import storageService from '../storage/storageService';

const setUserSessionTokenData = (data) => {
    const {accessToken, refreshToken, expiration, isAnonymous = false} = data;

    if (!isAnonymous) {
        storageService.setAccessTokenToStorage(accessToken);
        storageService.setAccessTokenExpirationToStorage(expiration);
        storageService.setRefreshTokenToStorage(refreshToken);
    }
};

export default {
    setUserSessionTokenData,
};
