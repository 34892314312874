import React from 'react';

import Icon from '../Icon/Icon';

const IconFile = (props) => {
    return (
        <Icon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
            <path
                d='M7.42194 20.749H15.7188C16.5261 20.749 17.1303 20.5433 17.5313 20.1318C17.9376 19.7256 18.1407 19.1162 18.1407 18.3037V11.21C18.1407 10.8714 18.1225 10.5876 18.086 10.3584C18.0495 10.1292 17.9766 9.9209 17.8672 9.7334C17.7631 9.54069 17.6068 9.33756 17.3985 9.12402L13.0938 4.74121C12.8959 4.53809 12.7032 4.38444 12.5157 4.28027C12.3282 4.1709 12.1225 4.09798 11.8985 4.06152C11.6797 4.01986 11.4245 3.99902 11.1329 3.99902H7.42194C6.61464 3.99902 6.00787 4.20475 5.60162 4.61621C5.20058 5.02767 5.00006 5.63965 5.00006 6.45215V18.3037C5.00006 19.1214 5.20058 19.7334 5.60162 20.1396C6.00787 20.5459 6.61464 20.749 7.42194 20.749ZM7.47662 19.4912C7.07558 19.4912 6.77089 19.387 6.56256 19.1787C6.35944 18.9704 6.25787 18.6709 6.25787 18.2803V6.47559C6.25787 6.09017 6.35944 5.79069 6.56256 5.57715C6.77089 5.36361 7.07819 5.25684 7.48444 5.25684H10.961V9.7959C10.961 10.2855 11.0834 10.6527 11.3282 10.8975C11.573 11.1423 11.9402 11.2646 12.4297 11.2646H16.8829V18.2803C16.8829 18.6709 16.7787 18.9704 16.5704 19.1787C16.3672 19.387 16.0626 19.4912 15.6563 19.4912H7.47662ZM12.5704 10.085C12.4141 10.085 12.3021 10.0511 12.2344 9.9834C12.1719 9.91569 12.1407 9.80371 12.1407 9.64746V5.49902L16.6407 10.085H12.5704Z'
                fill='#3B3B3B'
            />
        </Icon>
    );
};

export default IconFile;
