import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';

import Form from '../../../../../components/Form/Form';
import * as formInputNames from '../../../../../consts/form/formInputNames';
import log from '../../../../../services/logger/log';
import organizationsService from '../../../../../services/organizations/organizationsService';
import {setCurrentBuilding} from '../../../../../state/ducks/buildings';
import helpers from '../../../../../utils/helpers';
import buildingModalService from './organizationBuildingModalService';

const OrganizationBuildingModal = (props) => {
    const [form] = AntForm.useForm();
    const {modalType, onCancel, onOk, dispatch} = props || {};

    const {formFields, title, submitButton, cancelButton, initialValues} = buildingModalService.getFormContent(
        modalType,
        form
    );

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }

        return () => {
            form.resetFields();
            dispatch(setCurrentBuilding(null));
        };
    }, []);

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            helpers.runFunction(onOk, values);
        } catch (error) {
            log.info('Validate Failed:', error);
        }
    };

    const setFormField = (name, value) => form.setFieldsValue({[name]: value});

    const resetFormField = (fieldNames) => {
        const fieldsToReset = {};

        fieldNames.forEach((name) => (fieldsToReset[name] = null));
        form.setFieldsValue(fieldsToReset);
    };

    const handleSelect = async (value, name, option) => {
        const currentValue = form.getFieldValue(name);

        if (currentValue?.value === option.value) return;

        setFormField(name, option.key);

        switch (name) {
            case formInputNames.CITY_ID: {
                resetFormField([
                    formInputNames.ORGANIZATION_ID,
                    formInputNames.ADDRESS,
                    formInputNames.LATITUDE,
                    formInputNames.LONGITUDE,
                ]);

                await organizationsService.getOrganizationsNames(value);
                break;
            }
            default:
                break;
        }
    };

    return (
        <Form
            handleSelect={handleSelect}
            closeModal={onCancel}
            initialValues={initialValues}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formFields}
            cancelButton={cancelButton}
            formInstance={form}
        />
    );
};

export default React.memo(OrganizationBuildingModal);
