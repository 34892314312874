const downloadFileByPath = async (path, name) => {
    return fetch(path)
        .then((transfer) => transfer.blob())
        .then((bytes) => {
            const elm = document.createElement('a');

            const filename = /[^/]*$/.exec(path);

            elm.href = URL.createObjectURL(bytes);
            elm.setAttribute('download', name || filename[0]);
            elm.click();

            return true;
        })
        .catch((error) => {
            console.log(error);
        });
};

const JSONToTextFile = (obj, filename) => {
    const blob = new Blob([JSON.stringify(obj, null, 2)], {
        type: 'text/plain;charset=utf-8',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    a.download = `${filename}.txt`;
    a.click();
    URL.revokeObjectURL(url);
};

export default {
    downloadFileByPath,
    JSONToTextFile,
};
