import './MapForm.scss';

import {Form, Input} from 'antd';
import Search from 'antd/es/input/Search';
import React, {useEffect, useState} from 'react';

import helpers from '../../../../../utils/helpers';

const MapForm = ({addressDescription, latitude, longitude, onFormSearchAddressClick}) => {
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({addressDescription, latitude, longitude});

    const handleFormChange = (changedValues) => {
        setFormData({...formData, ...changedValues});
    };

    useEffect(() => {
        const initialValues = {
            address: addressDescription?.address || '',
            latitude,
            longitude,
        };

        form.setFieldsValue(initialValues);

        setFormData({addressDescription, latitude, longitude});

        return () => {
            setFormData(null);
        };
    }, [addressDescription?.address, latitude, longitude]);

    const handleSearch = (...args) => {
        helpers.runFunction(onFormSearchAddressClick, args);
    };

    const formName = 'MAP_FORM';
    const addressFieldPlaceholder = 'Київ, просп. Берестейський, 121а';
    const addressFieldLabel = `Шукати за адресою:`;
    const latitudeFieldLabel = `Широта:`;
    const longitudeFieldLabel = `Довгота:`;

    return (
        <Form
            initialValues={formData}
            className={'mapForm flex flex-row'}
            layout='vertical'
            name={formName}
            onValuesChange={handleFormChange}
            form={form}
        >
            <Form.Item className={'mb-2 w-2/3'} name={'address'} label={addressFieldLabel}>
                <Search placeholder={addressFieldPlaceholder} onSearch={handleSearch} />
            </Form.Item>
            <div className={'flex ml-2 gap-2 flex-row'}>
                <Form.Item className={'mb-0 w-1/2'} name={'latitude'} label={latitudeFieldLabel}>
                    <Input />
                </Form.Item>
                <Form.Item className={'mb-0 w-1/2'} name={'longitude'} label={longitudeFieldLabel}>
                    <Input />
                </Form.Item>
            </div>
        </Form>
    );
};

export default React.memo(MapForm);
