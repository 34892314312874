import React from 'react';

import Icon from '../Icon/Icon';

// todo: Ostrovskyi D. - provided icon is of poor quality, should request better one or wait
const IconHeatCostAllocator = (props) => {
    return (
        <Icon width='13' height='26' viewBox='0 0 13 26' fill='none' {...props}>
            <rect width='13' height='26' fill='url(#pattern0)' />
            <mask
                id='mask0_18_228'
                style={{maskType: 'alpha'}}
                maskUnits='userSpaceOnUse'
                x='0'
                y='0'
                width='13'
                height='26'
            >
                <rect width='13' height='26' fill='url(#pattern1)' />
            </mask>
            <g mask='url(#mask0_18_228)'>
                <rect width='13' height='26' fill='var(--color-theme-blue)' />
            </g>
            <defs>
                <pattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'>
                    <use xlinkHref='#image0_18_228' transform='scale(0.0769231 0.0384615)' />
                </pattern>
                <pattern id='pattern1' patternContentUnits='objectBoundingBox' width='1' height='1'>
                    <use xlinkHref='#image0_18_228' transform='scale(0.0769231 0.0384615)' />
                </pattern>
                <image
                    id='image0_18_228'
                    width='13'
                    height='26'
                    xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAaCAYAAABsONZfAAAFGUlEQVR4nAXBe2wTdRwA8G+vv9vdre12d32Ordu6OrbVERQRYhyvKLAhLMIGIkQTSQwJEGP8S0kAjX/wh5CARuMjIRoRFl6RyXwFRLbx2OjKGI89Wze27tp1vb6u7b3Pz8dULBZBVhQIBkOtvb3972VzWQ/Pp0hZVkxut0vDcVzVNFVUVX3OzjJ/Hz3yyXmTpmmmjw8fvdDz+1+dL69cAXV1vlFD13ORyLRY66vOqqr2bElFxWw8Hm8bCj1Y27HjzUPoi5OnzoyNjXeuaXnlXD6fj5dS1OzT0bEKkiIqkRlZ0+ns0nAkUn3owP51bo/7eHAo9C4KhYbf2btn94cvLF92OpcTgOf5lwJNjUPNzQHIZnNQKBatp7/8enz44ciBZc2BB7d6+7dimAkzZzIZvqamBiorl7R898OZYCwev1Jd7QUhn4er3dcEAIgmk7ze13fHVEpRFkzVNbgfHHp0b2AQXC5n/65dHW0Iof8mJqe8djsDrZteB6fD7h4KDfNcLC5Ve6twBIZRtNlsB7lYbPBc14V0ik+Prlv76uUnT0a9jx4/xj84dGDh5q0+A8NMEo6QEp2PGahYLGI2m7WzlKIywWBIwDBsxcRkGHX/1lPPMLT4408/L4bDEUdb68ZYKpWuDkemCYRhGKHI6mMuFu/y1fmmWIbO1dRUa+3tb5AMzXjvDQx8trCQsDidznw+X6hWFJnCEEKgqIqNZRguGo22FIvia9ev/7OWwEtc8Xict7PslK7roKoqLQiCHy8pEZDb7ZrMZrPe+nq/b93almt9fbcBAFBjU4MxH52vGrwfXGO1WtTFxaQ9k8mS1lJLBpEkqVut1uOjo2MbFFlx1Ppqf2VYRvV43PTIyOPjgUDT97mc4MRx5HK7XbGFhYSGRaPz5jpfbaJ188Zv+u/cbQ+FHhzjuNjBEydPnWAY+tL+9/edpygKYrGFUoTjCU3XzNhzfj+XTCZXSbKc3L2zY195Wdm/uVyOW9b8/Kd+f90VTdeBpsvR/aEhFA5HFIIgTMhms/LDDx+RiqJCW+smCASabs3OzYHDYYezZ8/DQiIB6XRGNmNmwuN2C5NTU2ZsnuMygUAjvW3rFtB0DRKLi6BpOkSjHCR5HqxWC5AkkWtqbLBIsiSmUmkMLV1azw8Pj7w4PjEBySQPuq6Dx+0Gp8MO27ZugarKJXD5ytVcoVCwiaJYEEXRQCk+xSNktldVVYLDbgdFUaCcpsHOsoBhGNjKrGCxWNJPnjwt8/vrhIoKj4ZYlomHwxFSkiQwDANKCAJm5+agu/sa4DgOCEcwPj4hBAJN7OrVK4vd3T06Ki8vj9jKbIqm6QRJEJIoSeByOmDH9nYwmTCwO1jo6rooGAY0uJwus6woKub1Vo3LspyfmXnmWkwmgeM4yOcLoBsGpNIp4LgYyLKSmZ/nlgAYiKJIDZNEabbMZsuXUlSAoWmora0BTdOgt+82pNNZkCQZqFIqwadS3uDQA1uhIIoYw9AgSpIpFl9oJEgCstkcTE/PQKCxAdavWwMsy4LT4Ximqgo+OjZOJZN8AjOZTCDk82XjExPb6nw+qKqsBKfDASzLQs8ff8KFi5egzu+7S9O0WFJSslmSxOXIU+GBnR3bD5/9pevbI8c+H6jwuActltI+i9UyI0lSIpFYLOAIUYIgkMPDI3v3vP3WVybDMAAA4MaNm57e/jsfKYqyKhqdryBJwizLCsrlBFGSRHN5eZm8q3PH0Q0b1l/+H/FVlMih9U0wAAAAAElFTkSuQmCC'
                />
            </defs>
        </Icon>
    );
};

export default IconHeatCostAllocator;
