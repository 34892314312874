import {Progress} from 'antd';
import React from 'react';

import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import numberConverter from '../../../utils/numberConverter';

const DrawerSectionCoverageProgress = ({averageStatusPercentage, successPercentage}) => {
    const roundedAverageStatusPercentage = numberConverter.formatPercent(averageStatusPercentage, 1);
    const roundedSuccessPercentage = numberConverter.formatPercent(successPercentage, 1);

    const localizedStrings = getLocalizedStrings();

    return (
        <>
            <section className={'pt-5 mb-4 text-center'}>
                <div className={'font-medium text-zambezi'}>
                    {localizedStrings[localizationKeys.DATA_COVERAGE_TEXT]}
                </div>
                <Progress
                    className={'mb-0'}
                    percent={roundedAverageStatusPercentage}
                    success={{
                        percent: roundedSuccessPercentage,
                        strokeColor: 'var(--color-marker-green)',
                    }}
                    strokeColor={'var(--color-flamingo)'}
                    showInfo={false}
                    trailColor={'#E6E6E6'}
                />
                <div className={'text-11 font-semibold text-zambezi'}>{roundedSuccessPercentage + ''}%</div>
            </section>
        </>
    );
};

export default DrawerSectionCoverageProgress;
