import StorageClient from './storageClient';

class LocalStorageClient extends StorageClient {
    constructor() {
        super(localStorage);
    }
}

const storageClient = new LocalStorageClient();

export default storageClient;
