import cn from 'classnames';
import React from 'react';

export const BodyCell = ({children, className, bodyCellClassName, withMenu, ...props}) => (
    <td
        className={cn(
            'border-granite-gray text-eerie text-12 leading-[20px] px-4 !py-0 h-[45px]',
            {'last:!p-0': withMenu},
            className,
            bodyCellClassName
        )}
        {...props}
    >
        {children}
    </td>
);

export const BodyRow = ({children, className, ...props}) => (
    <tr className={className} {...props}>
        {children}
    </tr>
);

export const HeaderCell = ({children, className, headerCellClassName, ...props}) => (
    <th
        className={cn(
            className,
            '!bg-white !text-nero !font-semibold !text-12 leading-[20px] !h-[45px] !py-0',
            headerCellClassName
        )}
        {...props}
    >
        {children}
    </th>
);

export const TableWrapper = ({children, ...props}) => (
    <table className='border border-t !border-t-quartz border-quartz !rounded-[3px] ' {...props}>
        {children}
    </table>
);
