import {Empty} from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import {useSelector} from 'react-redux';

import AntButton from '../../../../../components/AntButton/AntButton';
import AntTable from '../../../../../components/AntTable/AntTable';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import {ARS_STATISTIC_ORGANIZATION_MODAL_TYPE} from '../../../../../consts/modals/modalTypes';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import {hideModalAction} from '../../../../../state/ducks/modal';
import {makeSelectArsOrganizationStatistics} from '../../../../../state/selectors/services';
import {dispatch} from '../../../../../state/store';
import helpers from '../../../../../utils/helpers';
import arsStatisticsOrganizationModalService from '../ArsStatisticsOrganizationModal/arsStatisticsOrganizationModalService';

const ArsStatisticsOrganizationModal = (props) => {
    const localizedStrings = getLocalizedStrings();

    const resultStatusMap = {
        1: localizedStrings[localizationKeys.SERVICE_ARS_STATUS_MESSAGE_1],
        2: localizedStrings[localizationKeys.SERVICE_ARS_STATUS_MESSAGE_2],
        3: localizedStrings[localizationKeys.SERVICE_ARS_STATUS_MESSAGE_3],
        4: localizedStrings[localizationKeys.SERVICE_ARS_STATUS_MESSAGE_4],
        5: localizedStrings[localizationKeys.SERVICE_ARS_STATUS_MESSAGE_5],
    };
    const {organization, buildingsStatistics = []} = useSelector(makeSelectArsOrganizationStatistics()) || {};

    const {onCancel} = props;

    const handleCancel = () => {
        helpers.runFunction(onCancel);
        dispatch(hideModalAction(ARS_STATISTIC_ORGANIZATION_MODAL_TYPE));
    };

    const StatisticsInfoList = buildingsStatistics.map((item) => {
        const resultStatusText = resultStatusMap[item?.status];
        let dateInfo = '-';

        if (item?.dateLocal !== '0001-01-01T00:00:00') {
            const dateString = item?.dateLocal;

            dateInfo = dayjs(dateString).format('YYYY-MM-DD, HH:mm');
        }

        return {
            dateInfo,
            buildingAddress: item?.buildingAddress,
            status: resultStatusText,
        };
    });

    const tableColumns = arsStatisticsOrganizationModalService.getStatisticsOrganizationColumns();

    const cancelButtonText = localizedStrings[localizationKeys.INFO_MODAL_BUTTON_TEXT];
    const titleTextContent = `${localizedStrings[localizationKeys.ARS_INFO_MODAL_TITLE_TEXT]}`;

    return (
        <div className='flex-column m-auto px-[25px] py-5'>
            <p className={'text-center text-24 my-4 font-semibold'}>
                {titleTextContent}
                <span className='ml-2'>{`"${organization}"`}</span>
            </p>
            <div className=' max-h-[55vh]'>
                {!StatisticsInfoList.length ? (
                    <Empty className={'mt-4'} />
                ) : (
                    <AntTable
                        bordered
                        showPagination={false}
                        scroll={{
                            y: 350,
                        }}
                        dataSource={StatisticsInfoList}
                        columns={tableColumns}
                    />
                )}
            </div>
            <div className={cn('flex  justify-center')}>
                <AntButton className='h-[50px] mt-5' onClick={handleCancel}>
                    {cancelButtonText}
                </AntButton>
            </div>
        </div>
    );
};

export default React.memo(ArsStatisticsOrganizationModal);
