import './FormCheckbox.scss';

import {Checkbox} from 'antd';
import cn from 'classnames';
import React from 'react';

const FormCheckbox = (props) => {
    const {options, form, name, className, children, initialValue} = props || {};

    const handleBoxChecked = (event) => {
        const isChecked = event.target.checked;

        form.setFieldValue(name, isChecked);
    };
    const handleGroupChecked = (values) => {
        form.setFieldValue(name, values);
    };

    if (options) {
        return (
            <Checkbox.Group
                prefixCls={'ep-checkbox-group'}
                className={'flex flex-col'}
                options={options}
                onChange={handleGroupChecked}
            />
        );
    } else {
        return (
            <Checkbox
                onChange={handleBoxChecked}
                style={{marginInlineStart: '0'}}
                className={cn('flex', className)}
                defaultChecked={initialValue}
            >
                {children}
            </Checkbox>
        );
    }
};

export default FormCheckbox;
