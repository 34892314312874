import * as requestParamTypes from '../../../../../../consts/app/requestParamTypes';
import * as formInputNames from '../../../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../../../consts/localization/localizationKeys';
import * as modalTypes from '../../../../../../consts/modals/modalTypes';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import settingsService from '../../../../../../services/settings/settingsService';
import {makeSelectCurrentModel} from '../../../../../../state/selectors/device';
import {makeSelectorManufacturersList} from '../../../../../../state/selectors/settings';
import {getState} from '../../../../../../state/store';
import helpers from '../../../../../../utils/helpers';

const getDeviceModelModalContent = (modalType) => {
    const initialData = makeSelectCurrentModel()(getState());

    const localizedStrings = getLocalizedStrings();
    const isAddDeviceModelModalType = modalType == modalTypes.SETTINGS_ADD_DEVICE_MODEL;

    const submitButton = isAddDeviceModelModalType
        ? localizedStrings[localizationKeys.SETTING_DEVICE_MODEL_ADD_FORM_SUBMIT_BUTTON]
        : localizedStrings[localizationKeys.SETTING_DEVICE_MODEL_EDIT_FORM_SUBMIT_BUTTON];

    const title = isAddDeviceModelModalType
        ? localizedStrings[localizationKeys.SETTING_DEVICE_MODEL_ADD_FORM_TITLE]
        : localizedStrings[localizationKeys.SETTING_DEVICE_MODEL_EDIT_FORM_TITLE];

    const cancelButton = localizedStrings[localizationKeys.SETTING_DEVICE_MODEL_FORM_CANCEL_BUTTON];

    const initialValues = {
        deviceModelName: initialData?.value,
        deviceModelDescription: initialData?.description,
        [formInputNames.DEVICE_MODEL_MANUFACTURER_ID]: initialData?.manufacturerId && {
            value: `${initialData.manufacturerName} (${initialData.manufacturerUniqueDataKey})`,
            id: initialData.manufacturerId,
        },
    };

    const formContent = [
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.DEVICE_MODEL_NAME,
            label: localizedStrings[localizationKeys.SETTING_DEVICE_MODEL_FORM_NAME_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SETTING_DEVICE_MODEL_FORM_NAME_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SETTING_DEVICE_MODEL_FORM_NAME_FIELD_ERROR_MESSAGE],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.DEVICE_MODEL_DESCRIPTION,
            label: localizedStrings[localizationKeys.SETTINGS_DEVICE_MODEL_FORM_DESCRIPTION_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SETTINGS_DEVICE_MODEL_FORM_DESCRIPTION_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SETTINGS_DEVICE_MODEL_FORM_DESCRIPTION_FIELD_ERROR_MESSAGE],
        },
        {
            key: helpers.guid(),
            name: formInputNames.DEVICE_MODEL_MANUFACTURER_ID,
            fieldLabel: 'name',
            fieldValue: 'id',
            withSearch: true,
            formItemType: formItemTypes.SELECT,
            required: true,
            label: localizedStrings[localizationKeys.SETTINGS_DEVICE_MODEL_FORM_MANUFACTURER_ID_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SETTINGS_DEVICE_MODEL_FORM_MANUFACTURER_ID_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SETTINGS_DEVICE_MODEL_FORM_MANUFACTURER_ID_REQUIRED_ERROR],
            requestOptions: {
                paramsType: requestParamTypes.MANUFACTURERS,
                selectRequestData: makeSelectorManufacturersList,
                getRequestData: () => settingsService.getManufacturersList(),
            },
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        initialValues,
        formContent,
    };
};

export default {getDeviceModelModalContent};
