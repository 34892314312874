import React, {useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {Route, useLocation} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';

import Header from '../../components/Layout/Header/Header';
import Menu from '../../components/Menu/Menu';
import * as authScreenNames from '../../consts/auth/authScreenNames';
import {USER} from '../../consts/user/userRolesId';
import appRouterService from '../../services/app/appRouterService';
import authDataStoreService from '../../services/auth/authDataStoreService';
import responsibilitiesService from '../../services/responsibilities/responsibilitiesService';
import currentUserService from '../../services/user/currentUserService';
import usersService from '../../services/users/usersService';
import {makeSelectAuthToken} from '../../state/selectors/auth';
import {makeSelectCurrentUserInfo} from '../../state/selectors/currentUser';
import {makeIsDemoView} from '../../state/selectors/global';
import DrawersRoot from '../Drawers/DrawersRoot';
import LoaderGlobal from '../LoaderGlobal/LoaderGlobal';
import ModalsRoot from '../Modals/ModalsRoot';
import Notifications from '../Notifications/Notifications';

const mapStateToProps = createStructuredSelector({
    isDemoView: makeIsDemoView(),
    authToken: makeSelectAuthToken(),
});

const AppLayout = (props) => {
    const {component: Component, path, isDemoView, authToken} = props;
    const {pathname} = useLocation();

    const isUserHaveAccess = isDemoView || authToken;

    const currentUserInfo = useSelector(makeSelectCurrentUserInfo());

    const {roleId, id} = currentUserInfo || {};

    const isUser = roleId === USER;

    const getInitialData = async () => {
        const {id} = authDataStoreService.parseJWTFromStorage();

        await currentUserService.getCurrentUserInfoById({id});
    };

    useEffect(() => {
        if (isUserHaveAccess) return;

        if (pathname?.includes(authScreenNames.REGISTRATION)) {
            return appRouterService.forwardToAuthPage(authScreenNames.REGISTRATION);
        }
        appRouterService.forwardToAuthPage();
    }, []);

    useEffect(() => {
        getInitialData();
    }, [authToken]);

    useEffect(() => {
        if (isUserHaveAccess && isUser) {
            currentUserService.getCurrentUserResponsibilitiesListByIdWithData({userID: id});
        }
    }, [isUser, isUserHaveAccess]);

    return (
        <Route
            path={path}
            render={(matchProps) => (
                <>
                    <Notifications />
                    <DrawersRoot />
                    <ModalsRoot />
                    <LoaderGlobal />
                    {isUserHaveAccess ? (
                        <div className='flex flex-col items-center relative bg-wild_sand font-inter min-h-screen'>
                            <Header />
                            <div className='flex flex-1 w-full'>
                                <Menu />
                                <Component {...matchProps} />
                            </div>
                        </div>
                    ) : (
                        <Component {...matchProps} />
                    )}
                </>
            )}
        />
    );
};

export default connect(mapStateToProps)(AppLayout);
