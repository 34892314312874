import {useSelector} from 'react-redux';

import * as formInputNames from '../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import organizationsService from '../../../../services/organizations/organizationsService';
import {makeSelectOrganizationsNames} from '../../../../state/selectors/organizations';
import {makeSelectKvkSettingsData} from '../../../../state/selectors/services';
import helpers from '../../../../utils/helpers';
import regexUtils from '../../../../utils/regexUtils';

const getFormContent = () => {
    const localizedStrings = getLocalizedStrings();

    const settings = useSelector(makeSelectKvkSettingsData()) || {};
    const organizationNames = useSelector(makeSelectOrganizationsNames()) || [];

    const initialValues = {
        ...settings,

        [formInputNames.COMPANY]: settings?.organizationID,
        loginExUrl: settings?.loginExUrl || '',
        baseUrl: settings?.baseUrl || '',
        executeExUrl: settings?.executeExUrl || '',
        dayOfMonth: settings?.dayOfMonth ? String(settings?.dayOfMonth) : '',
        login: settings?.login || '',
        // [formInputNames.LOGIN]: settings?.login,
        [formInputNames.PASSWORD]: settings?.password,
    };

    const formFields = [
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            required: true,
            name: formInputNames.COMPANY,
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_COMPANY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_COMPANY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_COMPANY_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectOrganizationsNames,
                getRequestData: () => organizationsService.getOrganizationsNames(),
            },
        },
        {
            key: helpers.guid(),
            required: true,
            // row: true,
            maxLength: 5,
            name: 'dayOfMonth',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_ERROR],
            pattern: regexUtils.DAY_OF_MONTH_REGEX,
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.LOGIN,
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_LOGIN_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_LOGIN_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.NOTIFICATION_SERVICE_FORM_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.PASSWORD,
            name: formInputNames.PASSWORD,
            required: true,
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_PASSWORD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_PASSWORD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_PASSWORD_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: 'loginExUrl',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_LOGIN_EX_URL_FIELD_LABEL],
            maxLength: 100,
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_LOGIN_EX_URL_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_LOGIN_EX_URL_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            maxLength: 100,
            name: 'executeExUrl',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_EXECUTE_EX_URL_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_EXECUTE_EX_URL_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_EXECUTE_EX_URL_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            row: true,
            required: true,
            name: 'baseUrl',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_BASE_URL_FIELD_LABEL],
            maxLength: 100,
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_BASE_URL_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_BASE_URL_FIELD_ERROR],
        },
    ];

    return {
        formFields,
        initialValues,
    };
};

export default {
    getFormContent,
};
