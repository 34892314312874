export const MAP = 'MAP';
export const SERVICES = 'SERVICES';
export const ORGANIZATIONS = 'ORGANIZATIONS';
export const USERS = 'USERS';
export const SETTINGS = 'SETTINGS';
export const MONITORING = 'MONITORING';
export const OBJECTS = 'OBJECTS';
export const USER_OBJECTS = 'USER-OBJECTS';
export const REPORTS = 'REPORTS';
export const DATA_COLLECTORS = 'DATA-COLLECTORS';
