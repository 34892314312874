import * as storageKeys from '../../consts/app/storageKeys';
import localStorageClient from './localStorageClient';

const setUserPasswordToStorage = (data) => localStorageClient.setDecodedItem(storageKeys.USER_PASSWORD, data);
const getUserPasswordFromStorage = () => localStorageClient.getEncodedItem(storageKeys.USER_PASSWORD);
const removeUserPasswordFromStorage = () => localStorageClient.removeItem(storageKeys.USER_PASSWORD);

const setUserLoginToStorage = (data) => localStorageClient.setDecodedItem(storageKeys.USER_LOGIN, data);
const getUserLoginFromStorage = () => localStorageClient.getEncodedItem(storageKeys.USER_LOGIN);
const removeUserLoginFromStorage = () => localStorageClient.removeItem(storageKeys.USER_LOGIN);

const setAccessTokenExpirationToStorage = (data) =>
    localStorageClient.setDecodedItem(storageKeys.ACCESS_TOKEN_EXPIRATION, data);
const getAccessTokenExpirationFromStorage = () =>
    localStorageClient.getEncodedItem(storageKeys.ACCESS_TOKEN_EXPIRATION);
const removeAccessTokenExpirationFromStorage = () => localStorageClient.removeItem(storageKeys.ACCESS_TOKEN_EXPIRATION);

const setRefreshTokenToStorage = (data) => localStorageClient.setDecodedItem(storageKeys.REFRESH_TOKEN, data);
const getRefreshTokenFromStorage = () => localStorageClient.getEncodedItem(storageKeys.REFRESH_TOKEN);
const removeRefreshTokenFromStorage = () => localStorageClient.removeItem(storageKeys.REFRESH_TOKEN);

const setAccessTokenToStorage = (data) => localStorageClient.setDecodedItem(storageKeys.ACCESS_TOKEN, data);
const getAccessTokenFromStorage = () => localStorageClient.getEncodedItem(storageKeys.ACCESS_TOKEN);
const removeAccessTokenFromStorage = () => localStorageClient.removeItem(storageKeys.ACCESS_TOKEN);

const setRememberMeToStorage = (data) => localStorageClient.setDecodedItem(storageKeys.REMEMBER_ME, data);
const getRememberMeFromStorage = (data) => localStorageClient.getEncodedItem(storageKeys.REMEMBER_ME, data);
const removeRememberMeFromStorage = () => localStorageClient.removeItem(storageKeys.REMEMBER_ME);

const setDemoViewToStorage = () => localStorageClient.setDecodedItem(storageKeys.DEMO_VIEW, true);
const getDemoViewFromStorage = () => localStorageClient.getEncodedItem(storageKeys.DEMO_VIEW);
const removeDemoViewFromStorage = () => localStorageClient.removeItem(storageKeys.DEMO_VIEW);
const clearLocalStorage = () => localStorageClient.clearStorage();

export default {
    setAccessTokenToStorage,
    getAccessTokenFromStorage,
    setDemoViewToStorage,
    getDemoViewFromStorage,
    removeDemoViewFromStorage,
    setRefreshTokenToStorage,
    getAccessTokenExpirationFromStorage,
    setAccessTokenExpirationToStorage,
    removeAccessTokenExpirationFromStorage,
    setUserPasswordToStorage,
    getUserPasswordFromStorage,
    removeUserPasswordFromStorage,
    setUserLoginToStorage,
    getUserLoginFromStorage,
    removeUserLoginFromStorage,
    setRememberMeToStorage,
    getRememberMeFromStorage,
    removeRememberMeFromStorage,
    removeAccessTokenFromStorage,
    removeRefreshTokenFromStorage,
    clearLocalStorage,
    getRefreshTokenFromStorage,
};
