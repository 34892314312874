import undoable from 'redux-undo';

import * as tableSortTypes from '../../consts/table/tableSortTypes';

export const SET_REQUEST_PARAMS = 'app/requestOptions/SET_REQUEST_PARAMS';
export const CLEAR_REQUEST_PARAMS = 'app/requestOptions/CLEAR_REQUEST_PARAMS';
export const UNDO_REQUEST_PARAMS = 'app/requestOptions/UNDO_REQUEST_PARAMS';
export const CLEAR_ALL_REQUEST_PARAMS = 'app/requestOptions/CLEAR_ALL_REQUEST_PARAMS';

const PAGE_SIZE = 10;
const INITIAL_DIRECTION = 2;
const INITIAL_PAGE = 1;
const INITIAL_SORT_FIELD = tableSortTypes.CREATE_TS;

export const initialParams = {
    page: INITIAL_PAGE,
    size: PAGE_SIZE,
    query: '',
    direction: INITIAL_DIRECTION,
    filters: {},
    sortBy: INITIAL_SORT_FIELD,
};

function reducer(state = {requestParams: [], lastAction: null}, action) {
    switch (action.type) {
        case CLEAR_REQUEST_PARAMS: {
            const {paramsType} = action.payload;

            return {
                ...state,
                requestParams: state.requestParams.filter((params) => params.paramsType !== paramsType),
            };
        }
        case CLEAR_ALL_REQUEST_PARAMS: {
            return {...state, requestParams: []};
        }
        case UNDO_REQUEST_PARAMS: {
            return {...state, lastAction: UNDO_REQUEST_PARAMS};
        }
        case SET_REQUEST_PARAMS: {
            const {paramsType, paramsProps} = action.payload;

            const existingParamIndex = state.requestParams.findIndex((param) => param.paramsType === paramsType);

            const updatedRequestParams =
                existingParamIndex !== -1
                    ? state.requestParams.map((param, index) => {
                          if (index === existingParamIndex) {
                              return {paramsType, paramsProps: {...param.paramsProps, ...paramsProps}};
                          }
                          return param;
                      })
                    : [...state.requestParams, {paramsType, paramsProps: {...initialParams, ...paramsProps}}];

            return {
                ...state,
                requestParams: updatedRequestParams,
                lastAction: SET_REQUEST_PARAMS,
            };
        }
        default:
            return state;
    }
}

export const setRequestParams = ({paramsType, paramsProps}) => ({
    type: SET_REQUEST_PARAMS,
    payload: {paramsType, paramsProps},
});

export const clearRequestParams = (paramsType) => ({
    type: CLEAR_REQUEST_PARAMS,
    payload: {paramsType},
});

export const clearAllRequestParams = (paramsType) => ({
    type: CLEAR_ALL_REQUEST_PARAMS,
    payload: {paramsType},
});

export const undoRequestParamsAction = () => ({
    type: UNDO_REQUEST_PARAMS,
});

const undoableRequestParams = undoable(reducer, {
    undoType: UNDO_REQUEST_PARAMS,
    neverSkipReducer: true,
    limit: 4,
});

export default undoableRequestParams;
