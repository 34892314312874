import urlUtils from '../../utils/urlUtils';

const SAVE_REQUEST_RESPONSE = 'maps/requests/SAVE_REQUEST_RESPONSE';
const SET_CUSTOM_MARKER = 'maps/requests/SET_CUSTOM_MARKER';
const SET_MAP_BUILDINGS = 'maps/requests/SET_MAP_BUILDINGS';

const initialState = {
    mapBuildings: [],
    customMarker: null,
    requests: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_MAP_BUILDINGS:
            const {points} = action.payload;

            return {
                ...state,
                mapBuildings: points,
            };
        case SET_CUSTOM_MARKER:
            const markerData = action.payload;

            return {
                ...state,
                customMarker: {...markerData},
            };
        case SAVE_REQUEST_RESPONSE:
            const params = action.payload.params;
            const serializedParams = urlUtils.serializeAndSortParams(params);

            return {
                ...state,
                requests: {
                    ...state.requests,
                    [serializedParams]: action.payload.response,
                },
            };
        default:
            return state;
    }
}

export const saveRequestResponse = (params, response) => ({
    type: SAVE_REQUEST_RESPONSE,
    payload: {params, response},
});

export const setCustomMarker = (payload) => ({
    type: SET_CUSTOM_MARKER,
    payload,
});

export const setMapBuildings = (payload) => ({
    type: SET_MAP_BUILDINGS,
    payload,
});
