import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';

import Form from '../../../../../../components/Form/Form';
import log from '../../../../../../services/logger/log';
import {setCurrentModel} from '../../../../../../state/ducks/device';
import {dispatch} from '../../../../../../state/store';
import helpers from '../../../../../../utils/helpers';
import deviceModelModalService from './deviceModelModalService';

const DeviceModelModal = (props) => {
    const {onOk, onCancel, modalType, modelId} = props || {};

    const [form] = AntForm.useForm();

    const modalData = deviceModelModalService.getDeviceModelModalContent(modalType);

    const {formContent, title, submitButton, cancelButton, initialValues} = modalData || {};

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            values.deviceModelID = modelId;

            helpers.runFunction(onOk, values);
        } catch (error) {
            log.error(`DeviceModelModal - validate failed: ${error}`);
        }
    };

    const setFormField = (name, value) => form.setFieldsValue({[name]: value});

    const handleSelect = (value, name, option) => {
        const currentValue = form.getFieldValue(name);

        if (currentValue?.value === option.value) return;

        setFormField(name, option.key);
    };

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }

        return () => {
            dispatch(setCurrentModel(null));

            form.resetFields();
        };
    }, []);

    return (
        <Form
            handleSelect={handleSelect}
            closeModal={onCancel}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formContent}
            cancelButton={cancelButton}
            formInstance={form}
            initialValues={initialValues}
        />
    );
};

export default DeviceModelModal;
