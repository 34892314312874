import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import Form from '../../../../components/Form/Form';
import * as formInputNames from '../../../../consts/form/formInputNames';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import {SERVICES_CONFIGURE_KYIVENERGO} from '../../../../consts/modals/modalTypes';
import buildingsService from '../../../../services/buildings/buildingsService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import organizationsService from '../../../../services/organizations/organizationsService';
import servicesService from '../../../../services/services/servicesService';
import settingsService from '../../../../services/settings/settingsService';
import {hideModalAction} from '../../../../state/ducks/modal';
import {makeSelectCurrentUserInfo} from '../../../../state/selectors/currentUser';
import {dispatch} from '../../../../state/store';
import helpers from '../../../../utils/helpers';
import configureServiceKyivEnergoModalService from './configureServiceKyivEnergoModalService';

const ConfigureServiceKyivEnergoModal = (props) => {
    const {onOk} = props || {};

    const localizedStrings = getLocalizedStrings();
    const currentUserInfo = useSelector(makeSelectCurrentUserInfo());
    const {userResponsibilities} = currentUserInfo || {};

    const [responsibility] = userResponsibilities || [];
    const [form] = AntForm.useForm();
    const {initialValues, formFields} = configureServiceKyivEnergoModalService.getFormContent(form, responsibility);

    const handleSelect = async (value, name) => {
        const currentFieldValue = form.getFieldValue(name);

        if (currentFieldValue === value) return;

        form.setFieldsValue({[name]: value});

        switch (name) {
            case formInputNames.COMPANY:
                await buildingsService.getBuildingsListByOrganizationId(value);
                break;
            default:
                break;
        }
    };

    const fetchModalData = () => {
        organizationsService.getOrganizationsNames(responsibility?.cityId);
        servicesService.getKyivEnergoSettings();
        settingsService.getDeviceGroupsList();
    };

    useEffect(() => {
        fetchModalData();

        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, []);
    const handleSubmit = async () => {
        const values = await form.validateFields();

        helpers.runFunction(onOk, values);
    };

    const handleChange = (value, name) => {
        switch (name) {
            case formInputNames.DEVICE_GROUPS_TYPES:
                const isValueArrayEmpty = !value?.length;

                if (isValueArrayEmpty) {
                    form.setFieldsValue({[name]: undefined});
                }
                break;
            default: {
                form.setFieldsValue({[name]: value});
            }
        }
    };

    return (
        <Form
            onFinish={handleSubmit}
            handleSelect={handleSelect}
            cancelButton={localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_CANCEL_BUTTON]}
            submitButton={localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SUBMIT_BUTTON]}
            closeModal={() => dispatch(hideModalAction(SERVICES_CONFIGURE_KYIVENERGO))}
            formInstance={form}
            title={localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_TITLE]}
            formFields={formFields}
            initialValues={initialValues}
            handleChange={handleChange}
        />
    );
};

export default React.memo(ConfigureServiceKyivEnergoModal);
