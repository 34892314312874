import {createSelector} from 'reselect';

const selectParams = (state) => state.requestParams;

const makeSelectRequestParams = (paramsType) =>
    createSelector(selectParams, (paramsState) => {
        return paramsState?.present.requestParams?.find((params) => params.paramsType == paramsType)?.paramsProps;
    });

const makeSelectLastRequestParamsAction = () =>
    createSelector(selectParams, (paramsState) => {
        return paramsState?.present?.lastAction;
    });

const makeSelectPresentRequestParams = (paramsType) =>
    createSelector(selectParams, (paramsState) => {
        return paramsState?.present.requestParams?.find((params) => params.paramsType == paramsType);
    });

export {makeSelectRequestParams, makeSelectLastRequestParamsAction, makeSelectPresentRequestParams};
