import React from 'react';

import {IconChevronLeft} from '../components/Icons';

// todo: Ostrovskyi D. - move to config in future
const pageSizeOptions = ['8', '10', '15', '20', '50'];
const getPaginationOptions = ({current, pageSize, total, onChange, showSizeChanger}) => {
    const paginationOptions = {
        current,
        pageSize,
        total,
        onChange,
        pageSizeOptions,
        position: 'bottomRight',
        hideOnSinglePage: false,
        showSizeChanger,
        showTitle: false,
        prefixCls: 'ep-table-pagination',
        prevIcon: <IconChevronLeft />,
        nextIcon: <IconChevronLeft className='rotate-180' />,
        locale: {
            items_per_page: '',
        },
    };

    return paginationOptions;
};

export default {getPaginationOptions};
