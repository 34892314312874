export const AUTH_SIGN_UP = '/authentication/sign-up';
export const AUTH_SIGN_IN = '/authentication/sign-in';
export const AUTH_PASSWORD_RESTORE = '/authentication/password-restore';
export const AUTH_PASSWORD_RESTORE_VERIFICATION = '/authentication/password-restore-verification';
export const AUTH_CHANGE_PASSWORD = '/authentication/password-change';
export const AUTH_REFRESH_TOKEN = '/authentication/refresh-token';
export const USERS_ADD_NEW_USER = '/users';
export const USERS_EDIT_USER = '/users'; // was  added new request 'user-responsibilities'
export const USERS_GET_USER_ROLES = '/roles';
export const USERS_GET_USER_STATUSES = '/statuses';
export const USERS_GET_USERS_LIST = '/users/list';
export const USERS_GET_USER_INFO_BY_ID = '/users/user-info/{0}';
export const USERS_APPROVE_USER = '/users/approve';
export const USERS_DELETE_USER = '/users';
export const USERS_BY_BUILDING_ID = '/buildings/{0}/users';
export const USER_ADD_ALL_BUILDINGS_RESPONSIBILITIES_BY_COMPANY_ID =
    '/users/responsibilities/grant-buildings-to-manager';
export const USERS_GET_RESPONSIBILITIES_LIST_BY_ID = '/users/responsibilities/{0}/list';
export const USERS_ADD_USER_RESPONSIBILITY = '/users/responsibilities';
export const USERS_ADD_USER_RESPONSIBILITIES_BY_COMPANY_ID = '/users/responsibilities';
export const USERS_EDIT_USER_RESPONSIBILITY = '/users/responsibilities';
export const USERS_DELETE_USER_RESPONSIBILITIES_BY_ID = '/users/responsibilities';
export const USERS_USER_RESPONSIBILITIES = '/users/user-responsibilities';
export const USERS_NOTIFICATIONS_ON = '/users/notifications/on';
export const USERS_NOTIFICATIONS_OFF = '/users/notifications/off';
export const USERS_BLOCK = '/users/block';
export const USERS_UNBLOCK = '/users/unblock';
export const USER_IMAGE = `/users/{0}/image`;
export const USERS_SWITCH_BLOCKING = '/users/switch-blocking';
export const USERS_RESET_USER_PASSWORD = '/users/reset-password';
export const ORGANIZATIONS = '/organizations';
export const ORGANIZATIONS_LIST = '/organizations/list';
export const ORGANIZATIONS_NAMES = '/organizations/names';
export const BUILDING_NAMES_BY_ORGANIZATION_ID = '/organizations/{0}/buildings/names';
export const ORGANIZATION_SWITCH_BLOCK = '/organizations/switch-blocking';
export const ORGANIZATION_BY_ID = '/organizations/{0}';
export const BUILDINGS_LIST_BY_ORGANIZATION_ID = '/organizations/{0}/buildings';
export const BUILDINGS = '/buildings';
export const BUILDING_BY_ID = '/buildings/{0}';
export const BUILDING_DETAILS_BY_ID = '/buildings/{0}/details';
export const INDICATORS_BY_BUILDING_ID = '/buildings/{0}/indicators';
export const ROOMS_BY_BUILDING_ID = '/buildings/{0}/rooms';
export const DATA_COLLECTOR_BY_BUILDING_ID = '/buildings/{0}/uspd';
export const UPLOAD_IMAGE = '/buildings/{0}/image';
export const GROUP_BUILDINGS = '/organizations/{0}/group-buildings';
export const CITIES = '/cities';
export const REPORTS_LIST = '/reports/list';
export const REPORTS_TYPES = '/reports/types';
export const REPORTS = '/reports';
export const REPORTS_DOWNLOAD = '/reports/download';
export const REMOVE_REPORTS_ARCHIVE = '/reports/remove-archive';
export const DEVICE_MODELS = '/devices/models';
//TODO: @VictoriaAnufrieva: change naming due to BE changes
export const DEVICE_TYPES_GROUPS = '/devices/types/groups';
export const DEVICE_TYPES_GROUPS_BY_BUILDING_ID = 'buildings/{0}/groups';
export const DEVICES = '/devices';
export const DEVICE_PROFILE = '/devices/{0}';
export const DEVICE_CHART = '/devices/{0}/chart';
export const DEVICES_MODELS_BY_GROUP_ID = '/devices/models?groupID={0}';
export const DATA_COLLECTORS_LIST = '/uspd/list';
export const DATA_COLLECTORS = '/uspd';
export const DATA_COLLECTORS_UNBLOCK = '/uspd/unblock';
export const DATA_COLLECTORS_BLOCK = '/uspd/block';
export const DATA_COLLECTORS_SWITCH_BLOCK = '/uspd/switch-blocking';
export const DATA_COLLECTOR_BY_ID = `/uspd/{0}`;
export const DATA_COLLECTORS_BY_BUILDING_ID = '/uspd/{0}/list';

//TODO: @VictoriaAnufrieva: change naming due to BE changes
export const SERVICES_KYIVENERGO_BUILDING_LISTS = '/modules/kte'; // before: '/integrations/kyiv-energo'
export const SERVICES_KYIVENERGO_SETTINGS = '/modules/kte/settings'; // before: '/integrations/kyiv-energo/settings'
export const SERVICES_KYIVENERGO_STATISTICS = '/modules/kte/statistics'; // before: '/integrations/kyiv-energo/statistics';
export const SERVICES_KTE_STATE = '/modules/kte/state';
export const SERVICES_KTE_OFF = '/modules/kte/off';
export const SERVICES_KTE_ON = '/modules/kte/on';
export const SERVICES_KTE_DOWNLOAD_STATISTICS = '/modules/kte/statistics/json';
export const SERVICES_LVK_BUILDING_LISTS = '/modules/lvk';
export const SERVICES_LVK_STATE = '/modules/lvk/state';
export const SERVICES_LVK_OFF = '/modules/lvk/off';
export const SERVICES_LVK_ON = '/modules/lvk/on';
export const SERVICES_LVK_SETTINGS = '/modules/lvk/settings';
export const SERVICES_LVK_STATISTICS = '/modules/lvk/statistics';
export const SERVICES_KVK_BUILDING_LISTS = '/modules/kvk';
export const SERVICES_KVK_STATE = '/modules/kvk/state';
export const SERVICES_KVK_OFF = '/modules/kvk/off';
export const SERVICES_KVK_ON = '/modules/kvk/on';
export const SERVICES_KVK_SETTINGS = '/modules/kvk/settings';
export const SERVICES_KVK_STATISTICS = '/modules/kvk/statistics';
export const SERVICES_ARS = 'modules/ars';
export const SERVICES_ARS_LIST = 'modules/ars/list';
export const SERVICES_ARS_STATUS = '/modules/ars';
export const SERVICES_ARS_ON = 'modules/ars/on';
export const SERVICES_ARS_OFF = 'modules/ars/off';
export const SERVICES_ARS_ORGANIZATION_STATISTICS_BY_ID = 'modules/ars/{0}/statistics';
export const SERVICES_ARS_BUILDINGS_ORGANIZATION_ID = 'modules/ars/{0}/buildings';
export const SERVICES_ARS_ORGANIZATION_SETTINGS_BY_ID = 'modules/ars/{0}/settings';
export const SERVICES_ARS_ORGANIZATION_ON = 'modules/ars/{0}/on';
export const SERVICES_ARS_ORGANIZATION_OFF = 'modules/ars/{0}/off';
export const SERVICES_ARS_DELATE_ORGANIZATION = 'modules/ars/{0}';
export const SERVICES_ARS_BUILDINGS_LIST_UNAPPLIED = 'modules/ars/unapplied-organizations';
export const SETTINGS_ROOM_TYPES = '/settings/room-types';
export const SETTINGS_DATA_COLLECTOR_MODELS = '/settings/uspd-models';
export const SETTINGS_ROLES_CLAIMS = '/roles/claims';
export const SETTINGS_DEVICE_GROUPS = '/devices/types/groups';
export const SETTINGS_DEVICE_GROUP_BY_ID = '/devices/types/groups/{0}';
export const SETTINGS_MANUFACTURERS_LIST = '/manufacturers/list';
export const SETTINGS_DEVICES_MODELS_BY_GROUP_ID = '/devices/models?groupID={0}';
export const SETTINGS_DEVICE_TYPES_UNASSIGNED = 'devices/types/unassigned';
export const SETTINGS_CITIES = '/cities';
export const DIAGNOSTIC_LIST = '/diagnostic/{0}';
export const ROOMS = '/rooms';
export const ROOM_DETAILS_BY_ID = '/rooms/{0}/details';
export const MAP = '/map';
export const UPLOAD_FILE = '/organizations/{0}/import/csv';
