import React from 'react';

import Icon from '../Icon/Icon';

const IconChevron = (props) => {
    const {color} = props || {};

    return (
        <Icon viewBox={`0 0 11 11`} fill='none' {...props}>
            <path
                d='M9.16664 4.12499L5.49998 7.79166L1.83331 4.12499'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
            />
        </Icon>
    );
};

export default IconChevron;
