export default {
    app: {
        apiUrl: 'http://preprod-energy01.ua.pnnsoft.com:8001/api',
        dataCollectionApiUrl: 'http://preprod-energy01.ua.pnnsoft.com:8002/api',
        reportsApiUrl: 'http://preprod-energy01.ua.pnnsoft.com:8003/api',
        modulesHostApiUrl: 'http://preprod-energy01.ua.pnnsoft.com:8004/api',
        openStreetApiUrl: 'https://nominatim.openstreetmap.org/',
    },
    dataCollection: {
        maxIntervalMinutes: 60,
        minIntervalMinutes: 5,
        stepMinutes: 5,
    },
    upload: {
        supportedImageTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'], // webp is added
    },
    image: {
        authBackground: 'bg-8',
    },
    durations: {
        notification: 4.5,
        searchDelay: 500,
    },
    dates: {
        minYearDate: 1970,
    },
    logs: {
        isDebug: true,
        logLevel: 'trace',
        antdWarningsEnabled: false,
    },
};
