import {setIsGlobalLoading} from '../../../state/ducks/global';
import {makeIsGlobalLoading} from '../../../state/selectors/global';
import {dispatch, getState} from '../../../state/store';

let activeRequests = 0;
const _setRequestGlobalLoader = (isEnabled = true) => {
    const isLoading = makeIsGlobalLoading()(getState());

    if (isEnabled !== isLoading) {
        dispatch(setIsGlobalLoading(isEnabled));
    }
};

export const handleRequestStart = (config) => {
    if (config?.skipLoader) return;

    if (activeRequests === 0) {
        _setRequestGlobalLoader(true);
    }
    activeRequests++;
};

export const handleRequestEnd = (config) => {
    if (config?.skipLoader) return;

    activeRequests--;
    if (activeRequests === 0) {
        _setRequestGlobalLoader(false);
    }
};
