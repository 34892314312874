import defaultLocalizations from './default';
import LocalizedStrings from './LocalizedStrings';

let localizedStrings;

const initLocalizations = async () => {
    const localizationsData = {
        default: await defaultLocalizations,
    };

    localizedStrings = new LocalizedStrings(localizationsData, {logsEnabled: true});
};

const getLocalizedStrings = () => localizedStrings;

export {initLocalizations, getLocalizedStrings};
