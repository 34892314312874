import React from 'react';

import Icon from '../Icon/Icon';

const IconCopy = (props) => {
    const {color = `#2B3F6C`} = props || {};

    return (
        <Icon width={16} height={16} viewBox={`0 0 16 16`} fill='none' {...props}>
            <rect x='3.33334' y='2' width='6.66667' height='9.33333' rx='2' stroke={color} />
            <path
                d='M11.6667 4.93423C12.2645 5.28004 12.6667 5.92639 12.6667 6.66667V12C12.6667 13.1046 11.7712 14 10.6667 14H8.00001C7.25973 14 6.61339 13.5978 6.26758 13'
                stroke={color}
                strokeLinecap='round'
            />
        </Icon>
    );
};

export default IconCopy;
