import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import helpers from '../../utils/helpers';
import MenuItem from './MenuItem';
import menuService from './menuService';

const menuItemsMap = {
    OBJECTS: ['objects', 'organization', 'building-profile', 'room-profile', 'diagnostic'],
    MAP: ['map'],
    'USER-OBJECTS': ['user-objects', 'room-profile'],
    'DATA-COLLECTORS': ['data-collectors'],
    USERS: ['users', 'users-objects'],
    REPORTS: ['reports'],
    SERVICES: ['services'],
    SETTINGS: ['settings'],
};

const Menu = () => {
    const {pathname} = useLocation();
    const [activeItem, setActiveItem] = useState();

    const menuItems = menuService.getMenuItems();

    const onItemClick = (item) => {
        setActiveItem(item.type);
        helpers.runFunction(item?.onClick);
    };

    useEffect(() => {
        const formattedPathName = pathname.split('/')[1];

        let menuType = null;

        for (const item of menuItems) {
            const type = item.type;

            if (menuItemsMap[type].includes(formattedPathName)) {
                menuType = type;
                break;
            }
        }

        setActiveItem(menuType);
    }, [menuItems, pathname]);

    return (
        <aside className='min-w-[101px] bg-jaguar '>
            <div className='sticky top-[67px] pt-[37px]'>
                {menuItems?.map((item) => (
                    <MenuItem
                        isActive={item.type === activeItem}
                        onClick={() => onItemClick(item)}
                        href={item.href}
                        key={item.type}
                        menuType={item.type}
                        value={item.value}
                    />
                ))}
            </div>
        </aside>
    );
};

export default Menu;
