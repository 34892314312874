import {reportTypes, reportTypesLocalized} from '../../consts/reports/reportTypes.ts';
import {getLocalizedStrings} from '../localization/localizationService.js';

const localizedStrings = getLocalizedStrings();

export const reportsListRequestDataMapping = (data) => {
    const mappedData = {
        list: data?.items,
        totalRecords: data?.totalRecords,
        page: data?.page,
        size: data?.size,
        filters: {
            reportTypeId: data?.filters?.ReportTypeId,
        },
        totalFilteredRecords: data?.totalFilteredRecords,
        hasPreviousPage: data?.hasPreviousPage,
        hasNextPage: data?.hasNextPage,
    };

    return mappedData;
};

export const reportTypesRequestDataMapping = (data) => {
    const localizedStrings = getLocalizedStrings();

    const mappedData = Object.keys(data).map((key) => ({
        key: +key,
        value: localizedStrings[reportTypesLocalized[key]],
        name: localizedStrings[reportTypesLocalized[key]],
    }));

    return mappedData;
};

export const generateReportRequestPostMapping = (data) => {
    const mappedData = {
        groupId: data?.device_type?.id,
        reportType: data?.report_type?.id,
        startDate: data?.start_date,
        endDate: data?.end_date,
        buildingId: data?.building?.id,
        deviceId: data?.device_id?.id,
    };

    if (mappedData?.reportType === reportTypes.Individual || mappedData?.reportType === reportTypes.Building) {
        delete mappedData.groupId;
    }
    if (mappedData?.reportType === reportTypes.Individual || mappedData?.reportType === reportTypes.Building) {
        delete mappedData.buildingId;
    }

    return JSON.stringify(mappedData);
};

export const manipulateReportMapping = (data) => {
    const mappedData = {
        reportIds: data?.length ? [...data] : [data],
    };

    return JSON.stringify(mappedData);
};
