import dayjs from 'dayjs';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import * as formInputNames from '../../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import {reportTypes} from '../../../../../consts/reports/reportTypes.ts';
import deviceService from '../../../../../services/device/deviceService';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import organizationsService from '../../../../../services/organizations/organizationsService.js';
import reportsService from '../../../../../services/reports/reportsService.js';
import settingsService from '../../../../../services/settings/settingsService.js';
import {makeSelectDeviceTypes, makeSelectorDeviceGroups} from '../../../../../state/selectors/device';
import {makeSelectCities, makeSelectOrganizationsNames} from '../../../../../state/selectors/organizations';
import {makeSelectReportTypes} from '../../../../../state/selectors/reports';
import {
    makeSelectArsOrganizationSettings,
    makeSelectArsUnappliedOrganizations,
} from '../../../../../state/selectors/services.js';
import helpers from '../../../../../utils/helpers';
import regexUtils from '../../../../../utils/regexUtils';

const getAddArsOrganizationModalContent = () => {
    const localizedStrings = getLocalizedStrings();

    const initialValues = {};
    const timePickerFormat = 'HH:mm';
    const title = localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_ADD_FORM_TITLE];
    const submitButton = localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_ADD_FORM_SUBMIT_BUTTON];
    const cancelButton = localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_CANCEL_BUTTON];

    const isCurrentReport = (id) => id === reportTypes.Current;
    const isIndividualReport = (id) => id === reportTypes.Individual;
    const isBuildingReport = (id) => id === reportTypes.Building;
    const isGroupReport = (id) => id === reportTypes.Group;

    const formContent = [
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            required: true,
            fieldLabel: 'name',
            name: formInputNames.CITY,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_CITY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_CITY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_CITY_FIELD_ERROR_MESSAGE],
            requestOptions: {
                selectRequestData: makeSelectCities,
                getRequestData: () => organizationsService.getCities(),
            },
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            required: true,
            fieldLabel: 'name',
            name: formInputNames.ORGANIZATION,
            disabled: (_, cityId) => !cityId,
            itemDeps: formInputNames.CITY,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_COMPANY_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FORM_COMPANY_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FORM_COMPANY_FIELD_ERROR_MESSAGE],
            requestOptions: {
                selectRequestData: makeSelectArsUnappliedOrganizations,
            },
        },

        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            required: true,
            value: reportTypes,
            name: formInputNames.REPORT_TYPE,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_REPORT_TYPE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_REPORT_TYPE_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_REPORT_FORM_TYPE_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectReportTypes,
                getRequestData: () => reportsService.getReportTypes(),
            },
        },
        {
            key: helpers.guid(),
            name: formInputNames.DEVICE_GROUP,
            fieldLabel: 'value',
            fieldValue: 'key',
            itemDeps: formInputNames.REPORT_TYPE,
            required: (_, report_type) => isCurrentReport(report_type?.id) || isGroupReport(report_type?.id),
            disabled: (_, report_type) => isIndividualReport(report_type?.id) || isBuildingReport(report_type?.id),
            formItemType: formItemTypes.SELECT,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectDeviceTypes,
                getRequestData: () => deviceService.getDeviceTypesGroups(),
            },
        },
        {
            key: helpers.guid(),
            required: true,
            // row: true,
            maxLength: 5,
            name: formInputNames.START_DATE,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_ERROR],
            pattern: regexUtils.DAY_OF_MONTH_REGEX,
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.LOCAL_TIME,
            formItemType: formItemTypes.TIME_PICKER,
            format: timePickerFormat,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.ALL_BUILDINGS,
            itemDeps: formInputNames.ORGANIZATION,
            disabled: (_, organization) => !organization,
            formItemType: formItemTypes.RADIO,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_ADD_ALL_BUILDINGS_LABEL],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_ADD_ALL_BUILDINGS_ERROR],
            radioButtonOptions: [
                {
                    name: localizedStrings[
                        localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_VALUE_ADD_ALL_BUILDINGS_TRUER
                    ],
                    value: 'true',
                },
                {
                    name: localizedStrings[
                        localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_VALUE_ADD_ALL_BUILDINGS_FALSE
                    ],
                    value: 'false',
                },
            ],
        },
        {
            key: helpers.guid(),
            name: formInputNames.SOURCE,
            formItemType: formItemTypes.RADIO,
            itemDeps: formInputNames.HANDLER,
            required: true,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_LABEL],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_ERROR],
            radioButtonOptions: [
                {
                    name: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_FTP],
                    value: 1,
                },
                {
                    name: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_EMAIL],
                    value: 2,
                },
            ],
        },
        {
            key: helpers.guid(),
            name: formInputNames.SOURCE_ADDRESS,
            required: true,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_ERROR],
        },
        {
            key: helpers.guid(),
            name: formInputNames.FOLDER,
            itemDeps: formInputNames.SOURCE,
            hidden: (_, source) => source !== 1,
            disabled: (_, source) => source !== 1, // "1" it is a value of formInputNames.SOURCE
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_FOLDER_PATH_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_FOLDER_PATH_PLACEHOLDER],
            errorMessage:
                localizedStrings[
                    localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PLACEHOLDER_FOLDER_PATH_ERROR
                ],
        },
        {
            key: helpers.guid(),
            itemDeps: formInputNames.SOURCE,
            required: (_, source) => source !== 2,
            hidden: (_, source) => source !== 1,
            name: formInputNames.LOGIN,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_ERROR],
        },

        {
            key: helpers.guid(),
            itemDeps: formInputNames.SOURCE,
            required: (_, source) => source !== 2,
            hidden: (_, source) => source !== 1,
            formItemType: formItemTypes.PASSWORD,
            name: formInputNames.PASSWORD,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PASSWORD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PASSWORD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.NOTIFICATION_SERVICE_FORM_FIELD_ERROR],
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        formContent,
        initialValues,
    };
};

const getEditArsOrganizationModalContent = () => {
    const localizedStrings = getLocalizedStrings();

    const arsOrganization = useSelector(makeSelectArsOrganizationSettings()) || {};

    const {settings} = arsOrganization || {};

    const {reportProperties, source} = settings || {};

    const isCurrentReport = (id) => id === reportTypes.Current;
    const isGroupReport = (id) => id === reportTypes.Group;
    const isIndividualReport = (id) => id === reportTypes.Individual;
    const isBuildingReport = (id) => id === reportTypes.Building;

    const deviceGroups = useSelector(makeSelectorDeviceGroups()) || {};
    const deviceGroupsList = deviceGroups.items || [];

    const allReportTypes = useSelector(makeSelectReportTypes()) || [];

    useEffect(() => {
        reportsService.getReportTypes();
        settingsService.getDeviceGroupsList();
    }, []);

    const initialValues = {
        [formInputNames.ORGANIZATION_ID]: arsOrganization?.organizationID,
        [formInputNames.ORGANIZATION]: arsOrganization?.organization,
        [formInputNames.REPORT_TYPE]: reportProperties?.type && {
            id: reportProperties?.type,
            value: allReportTypes.find((reportType) => reportType.key === reportProperties?.type)?.value,
        },
        [formInputNames.DEVICE_GROUP]: reportProperties?.groupID && {
            id: reportProperties.groupID,
            value: deviceGroupsList.find((deviceGroup) => deviceGroup.key === reportProperties?.groupID)?.value,
        },
        [formInputNames.START_DATE]: settings?.dayOfMonth ? String(settings?.dayOfMonth) : '',
        [formInputNames.LOCAL_TIME]: settings?.localTime ? dayjs(settings.localTime, 'HH:mm') : undefined,
        [formInputNames.SOURCE]: source?.type,
        [formInputNames.SOURCE_ADDRESS]: source?.address || '',
        [formInputNames.FOLDER]: source?.folder || '',
        [formInputNames.LOGIN]: source?.login || '',
        [formInputNames.PASSWORD]: source?.password || '',
    };

    const timePickerFormat = 'HH:mm';
    const title = localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_EDIT_FORM_TITLE];
    const submitButton = localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_EDIT_FORM_SUBMIT_BUTTON];
    const cancelButton = localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_CANCEL_BUTTON];

    const formContent = [
        {
            key: helpers.guid(),
            name: formInputNames.ORGANIZATION_ID,
            hidden: true,
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            name: formInputNames.ORGANIZATION,
            disabled: true,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_COMPANY_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FORM_COMPANY_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FORM_COMPANY_FIELD_ERROR_MESSAGE],
            requestOptions: {
                selectRequestData: makeSelectOrganizationsNames,
            },
        },

        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            required: true,
            fieldLabel: 'name',
            name: formInputNames.REPORT_TYPE,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_REPORT_TYPE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_REPORT_TYPE_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_REPORT_FORM_TYPE_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectReportTypes,
            },
        },
        {
            key: helpers.guid(),
            name: formInputNames.DEVICE_GROUP,
            fieldLabel: 'value',
            fieldValue: 'key',
            itemDeps: formInputNames.REPORT_TYPE,
            disabled: (_, report_type) => isIndividualReport(report_type?.id) || isBuildingReport(report_type?.id),
            required: (_, report_type) => isCurrentReport(report_type?.id) || isGroupReport(report_type?.id),
            formItemType: formItemTypes.SELECT,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_DEVICE_GROUP_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectorDeviceGroups,
            },
        },
        {
            key: helpers.guid(),
            required: true,
            maxLength: 5,
            name: formInputNames.START_DATE,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_DAY_FIELD_ERROR],
            pattern: regexUtils.DAY_OF_MONTH_REGEX,
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.LOCAL_TIME,
            formItemType: formItemTypes.TIME_PICKER,
            format: timePickerFormat,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SEND_TIME_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            name: formInputNames.SOURCE,
            formItemType: formItemTypes.RADIO,
            itemDeps: formInputNames.HANDLER,
            required: true,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_LABEL],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_ERROR],
            radioButtonOptions: [
                {
                    name: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_FTP],
                    value: 1,
                },
                {
                    name: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SWITCH_PROTOCOL_EMAIL],
                    value: 2,
                },
            ],
        },
        {
            key: helpers.guid(),
            name: formInputNames.SOURCE_ADDRESS,
            required: true,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_SOURCE_ADDRESS_ERROR],
        },
        {
            key: helpers.guid(),
            name: formInputNames.FOLDER,
            itemDeps: formInputNames.SOURCE,
            hidden: (_, source) => source !== 1,
            disabled: (_, source) => source !== 1, // "1" it is a value of formInputNames.SOURCE
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_FOLDER_PATH_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_FOLDER_PATH_PLACEHOLDER],
            errorMessage:
                localizedStrings[
                    localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PLACEHOLDER_FOLDER_PATH_ERROR
                ],
        },
        {
            key: helpers.guid(),
            itemDeps: formInputNames.SOURCE,
            required: (_, source) => source !== 2,
            hidden: (_, source) => source !== 1,
            name: formInputNames.LOGIN,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_LOGIN_ERROR],
        },

        {
            key: helpers.guid(),
            itemDeps: formInputNames.SOURCE,
            required: (_, source) => source !== 2,
            hidden: (_, source) => source !== 1,
            formItemType: formItemTypes.PASSWORD,
            name: formInputNames.PASSWORD,
            label: localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PASSWORD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_ARS_ORGANIZATION_FORM_FIELD_FTP_PASSWORD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.NOTIFICATION_SERVICE_FORM_FIELD_ERROR],
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        formContent,
        initialValues,
    };
};

export default {
    getAddArsOrganizationModalContent,
    getEditArsOrganizationModalContent,
};
