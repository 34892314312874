import storageService from '../../services/storage/storageService';

export const AUTH_SET_AUTH_DATA = 'app/auth/AUTH_SET_AUTH_DATA';
export const AUTH_SET_REGISTRATION_DATA = 'app/auth/AUTH_SET_REGISTRATION_DATA';

export default function reducer(
    state = {
        accessToken: storageService.getAccessTokenFromStorage(),
        refreshToken: storageService.getRefreshTokenFromStorage(),
        name: null,
        surname: null,
        phone: null,
        email: null,
        companyId: null,
        buildingId: null,
        login: storageService.getUserLoginFromStorage(),
        password: storageService.getUserPasswordFromStorage(),
        rememberMe: storageService.getRememberMeFromStorage(),
    },
    action
) {
    switch (action.type) {
        case AUTH_SET_AUTH_DATA: {
            const {accessToken, refreshToken, refreshTokenExpiration, login, expiration, password, rememberMe} =
                action.payload || {};

            return {
                ...state,
                refreshToken,
                refreshTokenExpiration,
                accessToken,
                login,
                password,
                expiration,
                rememberMe,
            };
        }
        case AUTH_SET_REGISTRATION_DATA: {
            const {name, surname, phone, email, login, password, companyId, buildingId} = action.payload || {};

            return {...state, name, surname, phone, email, login, password, companyId, buildingId};
        }
        default:
            return state;
    }
}

export const setAuthData = (payload) => ({
    type: AUTH_SET_AUTH_DATA,
    payload: {...payload},
});
export const setRegistrationData = ({name, surname, phone, email, login, companyId, buildingId}) => ({
    type: AUTH_SET_REGISTRATION_DATA,
    payload: {name, surname, phone, email, login, companyId, buildingId},
});
