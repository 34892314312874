import {Tooltip} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import * as localizationKeys from '../../consts/localization/localizationKeys';
import * as tableSortTypes from '../../consts/table/tableSortTypes';
import useDidUpdate from '../../hooks/useDidUpdate';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {UNDO_REQUEST_PARAMS} from '../../state/ducks/requestParams';
import {makeSelectLastRequestParamsAction} from '../../state/selectors/requestParams';
import helpers from '../../utils/helpers';
import {IconChevronBottomBlack} from '../Icons';

const SORT_CANCEL = 0;
const SORT_ASCEND = 1;
const SORT_DESCEND = 2;

const defaultState = {
    direction: SORT_CANCEL,
    data: null,
};

const SorterTooltip = ({
    sortBy,
    handleSort,
    disabled,
    requestOptions,
    initialSortValue = tableSortTypes.CREATE_TS,
    initialSortDirection = SORT_DESCEND,
}) => {
    const lastRequestParamsAction = useSelector(makeSelectLastRequestParamsAction()) || {};
    const isChangeDirectionFail = lastRequestParamsAction === UNDO_REQUEST_PARAMS;

    const [sortData, setSortData] = useState(defaultState);
    const {direction} = sortData || {};

    const localizedStrings = getLocalizedStrings();

    const isAscendSorting = direction === SORT_ASCEND;
    const isDescendSorting = direction === SORT_DESCEND;

    const isInitialSorting = sortData?.direction == SORT_DESCEND && sortBy == initialSortValue;

    useDidUpdate(() => {
        if (requestOptions?.sortBy !== sortBy) {
            return setSortData(defaultState);
        }

        if (sortData?.data !== null) return;

        setSortData({
            direction: requestOptions?.direction,
            data: [requestOptions?.direction, requestOptions?.sortBy],
        });
    }, [requestOptions]);

    useDidUpdate(() => {
        if (!isChangeDirectionFail || requestOptions?.sortBy !== sortBy) return;

        const newState = {
            direction: requestOptions?.direction,
            data: [requestOptions?.direction, requestOptions?.sortBy],
        };

        setSortData(newState);
    }, [isChangeDirectionFail]);

    const tooltipValue = {
        [SORT_ASCEND]: localizedStrings[localizationKeys.SORT_TOOLTIP_DESCEND],
        [SORT_DESCEND]: isInitialSorting
            ? localizedStrings[localizationKeys.SORT_TOOLTIP_ASCEND]
            : localizedStrings[localizationKeys.SORT_TOOLTIP_CANCEL],
        [SORT_CANCEL]: localizedStrings[localizationKeys.SORT_TOOLTIP_ASCEND],
    };

    const onClick = () => {
        if (disabled) return;

        let sortData = {};

        switch (direction) {
            case SORT_CANCEL: {
                sortData = {direction: SORT_ASCEND, data: [SORT_ASCEND, sortBy]};
                break;
            }
            case SORT_ASCEND: {
                sortData = {direction: SORT_DESCEND, data: [SORT_DESCEND, sortBy]};
                break;
            }
            case SORT_DESCEND: {
                if (isInitialSorting) {
                    sortData = {direction: SORT_ASCEND, data: [SORT_ASCEND, sortBy]};
                    break;
                }
                sortData = {direction: SORT_CANCEL, data: [initialSortDirection, initialSortValue]};
                break;
            }
        }

        setSortData(sortData);

        helpers.runFunction(handleSort, sortData?.data);
    };

    return (
        <Tooltip title={!disabled && tooltipValue[direction]}>
            <div
                onClick={onClick}
                className={cn('flex justify-center items-center flex-col w-6', {
                    'cursor-not-allowed': disabled,
                })}
            >
                <IconChevronBottomBlack
                    height='12'
                    color={isAscendSorting ? 'var(--color-primary)' : '#B3B3B3'}
                    className='rotate-180'
                />
                <IconChevronBottomBlack height='12' color={isDescendSorting ? 'var(--color-primary)' : '#B3B3B3'} />
            </div>
        </Tooltip>
    );
};

export default SorterTooltip;
