import Modal from 'antd/es/modal/Modal';
import React, {createContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import * as modalTypes from '../../consts/modals/modalTypes';
import {selectGlobalModals} from '../../state/selectors/modal';
import OrganizationBuildingModal from './components/BuildingModals/OrganizationBuildingModal/OrganizationBuildingModal';
import BuildingProfileModal from './components/BuildingProfileModals/BuildingProfileModal/BuildingProfileModal';
import UploadFileModal from './components/BuildingProfileModals/BuildingProfileModal/UploadFileModal';
import RoomToBuildingModal from './components/BuildingProfileModals/RoomToBuildingModal/RoomToBuildingModal';
import ConfigureServiceKyivEnergoModal from './components/ConfigureKyivEnergoModal/ConfigureServiceKyivEnergoModal';
import ConfigureServiceKvkModal from './components/ConfigureServiceKvkModal/ConfigureServiceKvkModal';
import ConfigureServiceLvkModal from './components/ConfigureServiceLvkModal/ConfigureServiceLvkModal';
import ConfigureServiceModal from './components/ConfigureServiceModal/ServiceSettingsModal';
import ConfirmModal from './components/ConfirmModal/ConfirmModal';
import DataCollectorModal from './components/DataCollectorModal/DataCollectorModal';
import DeviceModal from './components/DeviceModal/DeviceModal';
import MapModal from './components/MapModal/MapModal';
import OrganizationModal from './components/OrganizationModal/OrganizationModal';
import ReportsModal from './components/ReportsModal/ReportsModal';
import RoomProfileModal from './components/RoomProfileModals/RoomProfileModal/RoomProfileModal';
import ArsBuildingsModal from './components/ServiceArs/ArsBuildingModal/ArsBuildingsModal';
import ArsOrganizationModal from './components/ServiceArs/ArsOrganizationModal/ArsOrganizationModal';
import ArsStatisticsOrganizationModal from './components/ServiceArs/ArsStatisticsOrganizationModal/ArsStatisticsOrganizationModal';
import AddUlcModal from './components/ServiceKyivenergo/AddUlcModal/AddUlcModal';
import ServicesAddBuildingModal from './components/ServicesAddBuildingModal/ServicesAddBuildingModal';
import CityModal from './components/SettingsModals/CityModal/CityModal';
import DCModelModal from './components/SettingsModals/DCModelModal/DCModelModal';
import DeviceModelModal from './components/SettingsModals/DeviceGroupsModal/DeviceGroupModelsModal/DeviceModelModal';
import DeviceGroupsModal from './components/SettingsModals/DeviceGroupsModal/DeviceGroupsModal';
import RoomsModal from './components/SettingsModals/RoomsModal/RoomsModal';
import UserModal from './components/UserModal/UserModal';

const MODAL_COMPONENTS = {
    [modalTypes.EDIT_USERS_MODAL_TYPE]: UserModal,
    [modalTypes.ADD_USERS_MODAL_TYPE]: UserModal,
    [modalTypes.ADD_USERS_TO_BUILDING_MODAL_TYPE]: UserModal,

    [modalTypes.ADD_USER_OBJECTS_MODAL_TYPE]: UserModal,
    [modalTypes.EDIT_USER_OBJECTS_MODAL_TYPE]: UserModal,

    [modalTypes.ORGANIZATION_CREATE_MODAL]: OrganizationModal,
    [modalTypes.ORGANIZATION_COPY_MODAL]: OrganizationModal,
    [modalTypes.ORGANIZATION_EDIT_MODAL]: OrganizationModal,

    [modalTypes.SERVICES_BUILDING_ADD]: ServicesAddBuildingModal,

    [modalTypes.SERVICES_ADD_ULC]: AddUlcModal,
    [modalTypes.SERVICES_EDIT_ULC]: AddUlcModal,

    [modalTypes.ADD_ARS_ORGANIZATION_MODAL_TYPE]: ArsOrganizationModal,
    [modalTypes.EDIT_ARS_ORGANIZATION_MODAL_TYPE]: ArsOrganizationModal,

    [modalTypes.ADD_BUILDINGS_TO_ARS_MODAL_TYPE]: ArsBuildingsModal,

    [modalTypes.ARS_STATISTIC_ORGANIZATION_MODAL_TYPE]: ArsStatisticsOrganizationModal,

    [modalTypes.SERVICES_CONFIGURE_KYIVENERGO]: ConfigureServiceKyivEnergoModal,

    [modalTypes.SERVICES_CONFIGURE_LVK]: ConfigureServiceLvkModal,

    [modalTypes.SERVICES_CONFIGURE_KVK]: ConfigureServiceKvkModal,

    [modalTypes.SERVICES_CONFIGURE_SERVICE]: ConfigureServiceModal,

    [modalTypes.REPORTS_MODAL]: ReportsModal,

    [modalTypes.CREATE_DATA_COLLECTOR_MODAL]: DataCollectorModal,
    [modalTypes.ADD_DATA_COLLECTOR_TO_BUILDING_MODAL]: DataCollectorModal,
    [modalTypes.EDIT_DATA_COLLECTOR_MODAL]: DataCollectorModal,

    [modalTypes.CREATE_BUILDING_MODAL]: OrganizationBuildingModal,
    [modalTypes.EDIT_BUILDING_MODAL]: OrganizationBuildingModal,
    [modalTypes.COPY_BUILDING_MODAL]: OrganizationBuildingModal,

    [modalTypes.COMMON_SETTINGS_TAB_ADD_CITY]: CityModal,

    [modalTypes.SETTING_ADD_ROOM_TYPE]: RoomsModal,
    [modalTypes.SETTING_EDIT_ROOM_TYPE]: RoomsModal,

    [modalTypes.ADD_ROOM_TO_BUILDING]: RoomToBuildingModal,

    [modalTypes.SETTINGS_ADD_DC_MODEL_TYPE]: DCModelModal,
    [modalTypes.SETTINGS_EDIT_DC_MODEL_TYPE]: DCModelModal,

    [modalTypes.ADD_DEVICE_MODAL_TYPE]: DeviceModal,
    [modalTypes.EDIT_DEVICE_MODAL_TYPE]: DeviceModal,

    [modalTypes.BUILDING_PROFILE_MODAL_TYPE]: BuildingProfileModal,

    [modalTypes.UPLOAD_FILE_MODAL_TYPE]: UploadFileModal,

    [modalTypes.ROOM_PROFILE_MODAL_TYPE]: RoomProfileModal,

    [modalTypes.SETTINGS_ADD_DEVICE_MODEL]: DeviceModelModal,
    [modalTypes.SETTINGS_EDIT_DEVICE_MODEL]: DeviceModelModal,

    [modalTypes.SETTINGS_ADD_DEVICE_GROUPS]: DeviceGroupsModal,
    [modalTypes.SETTINGS_EDIT_DEVICE_GROUPS]: DeviceGroupsModal,

    [modalTypes.CONFIRM_MODAL_TYPE]: ConfirmModal,

    [modalTypes.MAP_MODAL_TYPE]: MapModal,
};

const ModalsRoot = () => {
    const modals = useSelector(selectGlobalModals);
    const dispatch = useDispatch();

    if (!modals.length) return null;

    return (
        <>
            {modals.map((modal) => {
                const {modalType, modalProps} = modal;
                const SpecificModal = MODAL_COMPONENTS[modalType];

                return SpecificModal ? (
                    <Modal
                        footer={null}
                        key={modalType}
                        centered
                        closable={false}
                        width='820px'
                        open={!!modalType}
                        wrapClassName='py-20'
                        {...modalProps}
                    >
                        <SpecificModal {...modalProps} modalType={modalType} dispatch={dispatch} />
                    </Modal>
                ) : null;
            })}
        </>
    );
};

export default ModalsRoot;
