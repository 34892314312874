import loadable from '@loadable/component';

const AuthPage = loadable(() => import('../AuthPage/AuthPage'));
const ServicesPage = loadable(() => import('../ServicesPage/ServicesPage'));
const KyivEnergoPage = loadable(() => import('../ServicesPage/KyivEnergoPage/KyivEnergoPage'));
const KvkPage = loadable(() => import('../ServicesPage/KvkPage/KvkPage'));
const LvkPage = loadable(() => import('../ServicesPage/LvkPage/LvkPage'));
const ArsOrganizationsPage = loadable(() => import('../ServicesPage/ArsPage/ArsOrganizationsPage'));
const ArsOrganizationPage = loadable(() => import('../ServicesPage/ArsPage/ArsOrganizationPage/ArsOrganizationPage'));

const MapPage = loadable(() => import('../MapPage/MapPage'));
const RoomerObjectsPage = loadable(() => import('../RoomerObjectsPage/RoomerObjectsPage'));
const UsersObjectsPage = loadable(() => import('../UsersObjectsPage/UsersObjectsPage'));
const OrganizationsPage = loadable(() =>
    import(/* webpackChunkName: "organizations" */ '../OrganizationsPage/OrganizationsPage')
);
const OrganizationPage = loadable(() =>
    import(/* webpackChunkName: "organizations" */ '../OrganizationPage/OrganizationPage')
);
const BuildingProfilePage = loadable(() =>
    import(/* webpackChunkName: "organizations" */ '../BuildingProfilePage/BuildingProfilePage')
);
const RoomProfilePage = loadable(() => import('../RoomProfilePage/RoomProfilePage'));
const SettingsPage = loadable(() => import('../SettingsPage/SettingsPage'));
const UsersPage = loadable(() => import('../UsersPage/UsersPage'));
const MonitoringPage = loadable(() => import('../MonitoringPage/MonitoringPage'));
const ReportsPage = loadable(() => import('../ReportsPage/ReportsPage'));
const DataCollectorsPage = loadable(() => import('../DataCollectorsPage/DataCollectorsPage'));
const ProfilePage = loadable(() => import('../ProfilePage/ProfilePage'));
const DiagnosticPage = loadable(() => import('../DiagnosticPage/DiagnosticPage'));

export default {
    RoomProfilePage,
    BuildingProfilePage,
    RoomerObjectsPage,
    UsersObjectsPage,
    OrganizationPage,
    SettingsPage,
    UsersPage,
    MonitoringPage,
    ServicesPage,
    KyivEnergoPage,
    KvkPage,
    LvkPage,
    ArsOrganizationsPage,
    AuthPage,
    MapPage,
    OrganizationsPage,
    ReportsPage,
    DataCollectorsPage,
    ProfilePage,
    DiagnosticPage,
    ArsOrganizationPage,
};
