import React from 'react';

import * as localizationKeys from '../../../consts/localization/localizationKeys';
import appRouterService from '../../../services/app/appRouterService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import helpers from '../../../utils/helpers';
import {IconCopy, IconDiagnostic, IconEdit, IconTrashCan} from '../../Icons';
import OptionsMenu from '../OptionsMenu';

const BuildingOptionsMenu = ({record, dataSource, handlers, organizationId}) => {
    const localizedStrings = getLocalizedStrings();

    const menuItems = [
        {
            key: helpers.guid(),
            icon: <IconDiagnostic />,
            label: localizedStrings[localizationKeys.COMPANY_DIAGNOSIS_BUTTON],
            onClick: () => appRouterService.forwardToDiagnosticPage(record?.id, organizationId),
        },
        {
            key: helpers.guid(),
            icon: <IconEdit />,
            label: localizedStrings[localizationKeys.BUILDING_MENU_OPTION_EDIT],
            onClick: () => handlers.onEditClick(record),
        },
        {
            key: helpers.guid(),
            icon: <IconCopy />,
            label: localizedStrings[localizationKeys.BUILDING_MENU_OPTION_COPY],
            onClick: () => handlers?.onCopyClick(record),
        },
        {
            key: helpers.guid(),
            icon: <IconTrashCan />,
            label: localizedStrings[localizationKeys.BUILDING_MENU_OPTION_DELETE],
            onClick: () => handlers.handleDelete(record),
            isActionConfirmRequired: true,
        },
    ];

    return <OptionsMenu className={'left'} menuItems={menuItems} record={record} dataSource={dataSource} />;
};

export default BuildingOptionsMenu;
