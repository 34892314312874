import * as tabTypes from '../../consts/tabs/tabTypes';

export const SET_ORGANIZATIONS = 'app/organizations/SET_ORGANIZATIONS';
export const SET_ORGANIZATIONS_NAMES = 'app/organizations/SET_ORGANIZATIONS_NAMES';
export const SET_ACTIVE_TAB = 'app/organizations/SET_ACTIVE_TAB';
export const SET_CURRENT_ORGANIZATION = 'app/organizations/SET_CURRENT_ORGANIZATION';
export const SET_IS_ORGANIZATIONS_LIST_REQUIRES_UPDATE = 'app/organizations/SET_IS_ORGANIZATIONS_LIST_REQUIRES_UPDATE';
export const UPDATE_ORGANIZATION = 'app/organizations/UPDATE_ORGANIZATION';
export const SET_CITIES = 'app/organizations/SET_CITIES';
export const SET_ORGANIZATION_CLIPBOARD = 'app/organizations/SET_ORGANIZATION_CLIPBOARD';
export const REMOVE_ORGANIZATION_BY_ID = 'app/organizations/REMOVE_ORGANIZATION_BY_ID';
export const SET_ORGANIZATION_BUILDING_NAMES = 'app/organizations/SET_ORGANIZATION_BUILDING_NAMES';
export const CLEAN_ORGANIZATIONS_DATA = 'app/organizations/CLEAN_ORGANIZATIONS_DATA';

const initialState = {
    cities: null,
    currentOrganization: null,
    organizationsData: null,
    organizationsNames: null,
    organizationClipboard: null,
    activeTab: tabTypes.TABLE,
    buildingNames: null,
};

export default function reducer(state = {...initialState}, action) {
    switch (action.type) {
        case UPDATE_ORGANIZATION: {
            const organization = action.payload || {};
            const items = state.organizationsData.items;

            return {
                ...state,
                organizationsData: {
                    ...state.organizationsData,
                    items: items.map((item) => {
                        if (item?.id === organization.organizationID) {
                            return {
                                ...item,
                                status: organization?.status,
                            };
                        } else {
                            return item;
                        }
                    }),
                },
            };
        }
        case SET_ORGANIZATIONS: {
            const {organizationsData} = action.payload || {};

            return {...state, organizationsData};
        }
        case SET_ORGANIZATION_CLIPBOARD: {
            const organizationClipboard = action.payload || {};

            return {...state, organizationClipboard};
        }

        case REMOVE_ORGANIZATION_BY_ID: {
            const id = action.payload || {};
            const items = [...state.organizationsData.items].filter((item) => item.id !== id);

            return {
                ...state,
                organizationsData: {
                    ...state.organizationsData,
                    items: [...items],
                },
            };
        }
        case SET_ORGANIZATIONS_NAMES: {
            const {organizationsNames} = action.payload || {};

            return {...state, organizationsNames};
        }
        case SET_IS_ORGANIZATIONS_LIST_REQUIRES_UPDATE: {
            const isOrganizationsListRequiresUpdate = action.payload || {};

            return {...state, isOrganizationsListRequiresUpdate};
        }
        case SET_CURRENT_ORGANIZATION: {
            const currentOrganization = action.payload || {};

            return {...state, currentOrganization};
        }
        case SET_ORGANIZATION_BUILDING_NAMES: {
            const buildingNames = action.payload || {};

            return {...state, buildingNames};
        }
        case SET_ACTIVE_TAB: {
            const activeTab = action.payload || {};

            return {...state, activeTab};
        }
        case SET_CITIES: {
            const cities = action.payload;

            return {...state, cities};
        }
        case CLEAN_ORGANIZATIONS_DATA: {
            return {...state, ...initialState, activeTab: state?.activeTab};
        }
        default:
            return state;
    }
}

export const setOrganizations = (payload) => ({
    type: SET_ORGANIZATIONS,
    payload: {organizationsData: payload},
});

export const setOrganizationBuildingNames = (payload) => ({
    type: SET_ORGANIZATION_BUILDING_NAMES,
    payload,
});

export const setCities = (cities) => ({
    type: SET_CITIES,
    payload: cities,
});

export const setOrganizationsNames = (payload) => ({
    type: SET_ORGANIZATIONS_NAMES,
    payload: {organizationsNames: payload},
});

export const setCurrentOrganization = (payload) => ({
    type: SET_CURRENT_ORGANIZATION,
    payload,
});

export const setActiveTab = (activeTab) => ({
    type: SET_ACTIVE_TAB,
    payload: activeTab,
});

export const updateOrganization = (organization) => ({
    type: UPDATE_ORGANIZATION,
    payload: organization,
});

export const setOrganizationClipboard = (organization) => ({
    type: SET_ORGANIZATION_CLIPBOARD,
    payload: organization,
});

export const removeOrganizationById = (id) => ({
    type: REMOVE_ORGANIZATION_BY_ID,
    payload: id,
});

export const cleanOrganizationData = () => ({
    type: CLEAN_ORGANIZATIONS_DATA,
});
