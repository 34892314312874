import * as formInputNames from '../../../../../consts/form/formInputNames';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import * as modalTypes from '../../../../../consts/modals/modalTypes';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import helpers from '../../../../../utils/helpers';

const getUploadFileModalContent = (modalType, initialData) => {
    const localizedStrings = getLocalizedStrings();

    const title = [localizedStrings[localizationKeys.BUILDING_PROFILE_PAGE_UPLOAD_FILE_TITLE]];

    const submitButton = [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_ADD_FORM_SUBMIT_BUTTON]];

    const cancelButton = [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_ADD_FORM_CANCEL_BUTTON]];

    return {
        title,
        submitButton,
        cancelButton,
    };
};

export default {getUploadFileModalContent};
