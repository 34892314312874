export const addResponsibilityRequestPostMapping = (data) => {
    const mappedData = {
        UserID: data?.userId,
        RoomID: data?.apartment?.id,
        CompanyID: data?.company?.id,
        BuildingID: data?.building?.id,
    };

    return JSON.stringify(mappedData);
};

export const editResponsibilityRequestPostMapping = (data) => {
    const mappedData = {
        ResponsibilityID: data?.responsibilityId,
        UserID: data?.userId,
        RoomID: data?.apartment?.id,
        CompanyID: data?.company?.id,
        BuildingID: data?.building?.id,
    };

    return JSON.stringify(mappedData);
};

export const addResponsibilitiesByIdRequestPostMapping = (data) => {
    const mappedData = {
        UserID: data?.userId,
        CompanyID: data?.company?.id,
    };

    return JSON.stringify(mappedData);
};

export const responsibilitiesListRequestDataMapping = (data) => {
    const mappedData = {
        hasNextPage: data?.hasNextPage,
        hasPreviousPage: data?.hasPreviousPage,
        // filters: filtersMapping(data?.filters),
        items: data?.items,
        itemsInResponse: data?.itemsInResponse,
        page: data?.page,
        pages: data?.pages,
        size: data?.size,
        sortableColumns: data?.sortableColumns,
        totalFilteredRecords: data?.totalFilteredRecords,
        totalRecords: data?.totalRecords,
        query: data?.query,
        sortBy: data?.sortBy,
        direction: data?.direction,
    };

    return mappedData;
};
