import * as formInputNames from '../../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import * as modalTypes from '../../../../../consts/modals/modalTypes';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import {makeSelectDeviceProfileTypes, makeSelectDeviceTypesUnassigned} from '../../../../../state/selectors/device';
import helpers from '../../../../../utils/helpers';

const getDeviceGroupsModalContent = (modalType, initialData) => {
    const localizedStrings = getLocalizedStrings();
    const isAddDeviceGroupsModalType = modalType === modalTypes.SETTINGS_ADD_DEVICE_GROUPS;

    const submitButton = isAddDeviceGroupsModalType
        ? localizedStrings[localizationKeys.SETTING_DEVICE_GROUPS_ADD_FORM_SUBMIT_BUTTON]
        : localizedStrings[localizationKeys.SETTING_DEVICE_GROUPS_EDIT_FORM_SUBMIT_BUTTON];

    const title = isAddDeviceGroupsModalType
        ? localizedStrings[localizationKeys.SETTING_DEVICE_GROUPS_ADD_FORM_TITLE]
        : localizedStrings[localizationKeys.SETTING_DEVICE_GROUPS_EDIT_FORM_TITLE];

    const cancelButton = localizedStrings[localizationKeys.SETTING_DEVICE_GROUPS_FORM_CANCEL_BUTTON];

    const initialValues = isAddDeviceGroupsModalType
        ? {}
        : {
              [formInputNames.DEVICE_GROUPS_NAME]: initialData?.title,
              [formInputNames.DEVICE_GROUPS_DESCRIPTION]: initialData?.description,
              [formInputNames.DEVICE_GROUPS_TYPES]: initialData?.appliedDeviceTypes?.map((item) => item.key),
          };

    const requestOptions = isAddDeviceGroupsModalType
        ? {
              selectRequestData: makeSelectDeviceTypesUnassigned,
          }
        : {
              selectRequestData: makeSelectDeviceProfileTypes,
              options: initialData?.appliedDeviceTypes || [],
          };

    const formContent = [
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.DEVICE_GROUPS_NAME,
            label: localizedStrings[localizationKeys.SETTING_DEVICE_GROUPS_FORM_NAME_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SETTING_DEVICE_GROUPS_FORM_NAME_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SETTING_DEVICE_GROUPS_FORM_NAME_FIELD_ERROR_MESSAGE],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.DEVICE_GROUPS_DESCRIPTION,
            label: localizedStrings[localizationKeys.SETTINGS_DEVICE_GROUPS_FORM_DESCRIPTION_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SETTINGS_DEVICE_GROUPS_FORM_DESCRIPTION_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.SETTINGS_DEVICE_GROUPS_FORM_DESCRIPTION_FIELD_ERROR_MESSAGE],
        },
        {
            key: helpers.guid(),
            name: formInputNames.DEVICE_GROUPS_TYPES,
            fieldLabel: 'value',
            fieldValue: 'key',
            formItemType: formItemTypes.MULTIPLE_SELECT,
            required: true,
            label: localizedStrings[localizationKeys.SETTINGS_DEVICE_GROUPS_FORM_TYPES_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SETTINGS_DEVICE_GROUPS_FORM_TYPES_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SETTINGS_DEVICE_GROUPS_FORM_TYPES_REQUIRED_ERROR],
            withInitialRequest: true,
            requestOptions,
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        initialValues,
        formContent,
    };
};

export default {getDeviceGroupsModalContent};
