import {Tooltip} from 'antd';
import React from 'react';

import * as modalTypes from '../../consts/modals/modalTypes';
import {hideModalAction, showMapModalAction} from '../../state/ducks/modal';
import {dispatch} from '../../state/store';
import IconLocation from '../Icons/IconLocation';

const ChooseOnMapTooltip = ({form}) => {
    const showMapModal = () => {
        dispatch(
            showMapModalAction({
                onCancel: () => dispatch(hideModalAction(modalTypes.MAP_MODAL_TYPE)),
                form,
            })
        );
    };

    const handleTooltipClick = () => {
        showMapModal();
    };

    const tooltipTitle = 'Вибрати на карті';

    return (
        <Tooltip onClick={handleTooltipClick} className='cursor-pointer -mr-2 h-10 w-10 px-1 py-1' title={tooltipTitle}>
            <IconLocation color={'red'} width={15} height={20} />
        </Tooltip>
    );
};

export default ChooseOnMapTooltip;
