import * as formInputNames from '../../../../../consts/form/formInputNames';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import * as modalTypes from '../../../../../consts/modals/modalTypes';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import helpers from '../../../../../utils/helpers';

const getRoomTypeModalContent = (modalType, initialData) => {
    const localizedStrings = getLocalizedStrings();
    const isAddRoomModalType = modalType == modalTypes.SETTING_ADD_ROOM_TYPE;

    const submitButton = isAddRoomModalType
        ? [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_ADD_FORM_SUBMIT_BUTTON]]
        : [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_EDIT_FORM_SUBMIT_BUTTON]];

    const title = isAddRoomModalType
        ? [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_ADD_FORM_TITLE]]
        : [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_EDIT_FORM_TITLE]];

    const cancelButton = [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_ADD_FORM_CANCEL_BUTTON]];

    const initialValues = {
        roomTypeName: initialData?.title,
        roomTypeDescription: initialData?.extraDescription,
    };

    const formContent = [
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.ROOM_TYPE_NAME,
            label: [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_FORM_NAME_FIELD_LABEL]],
            placeholder: [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_FORM_NAME_FIELD_PLACEHOLDER]],
            errorMessage: [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_FORM_NAME_FIELD_ERROR_MESSAGE]],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.ROOM_TYPE_DESCRIPTION,
            label: [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_FORM_DESCRIPTION_FIELD_LABEL]],
            placeholder: [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_FORM_DESCRIPTION_FIELD_PLACEHOLDER]],
            errorMessage: [localizedStrings[localizationKeys.SETTINGS_ROOM_TYPE_FORM_DESCRIPTION_FIELD_ERROR_MESSAGE]],
            maxLength: 7,
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        initialValues,
        formContent,
    };
};

export default {getRoomTypeModalContent};
