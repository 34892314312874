import {UserOutlined} from '@ant-design/icons';
import {Avatar} from 'antd';
import cn from 'classnames';
import React from 'react';

const StatusBadge = ({value, statusColor, image, className, page}) => {
    return (
        <div className={cn('flex items-center', className)}>
            <div className={cn('w-2 h-2 min-w-[8px] rounded-full mr-2', statusColor)} />
            {page === 'users' ? (
                <Avatar
                    className='flex items-center justify-center !mr-[10px]'
                    style={{'min-width': '32px', 'max-width': '32px'}}
                    src={image}
                    icon={<UserOutlined />}
                />
            ) : null}

            {value && <span className='text-elipse'>{value}</span>}
        </div>
    );
};

export default StatusBadge;
