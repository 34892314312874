import {createSelector} from 'reselect';

const selectSettings = (state) => state.settings;

const makeSelectRoomTypes = () => createSelector(selectSettings, (settingsState) => settingsState.roomTypes);

const makeSelectDataCollectorModels = () => createSelector(selectSettings, (settingsState) => settingsState.dcModels);

const makeSelectSettingsRoleClaims = () =>
    createSelector(selectSettings, (settingsState) => settingsState?.users?.roleClaimsBlocks);

const makeSelectDataCollection = () => createSelector(selectSettings, (settingsState) => settingsState.dataCollection);

const makeSelectDataCollectionSettings = () =>
    createSelector(makeSelectDataCollection(), (dataCollectionState) => dataCollectionState.settings);

const makeSelectDataCollectionInProgress = () =>
    createSelector(makeSelectDataCollection(), (dataCollectionState) => dataCollectionState.inProgress);

const makeSelectorManufacturersList = () =>
    createSelector(selectSettings, (settingsState) => settingsState.manufacturers);

const makeSelectorDeviceGroupProfile = () =>
    createSelector(selectSettings, (settingsState) => settingsState.deviceGroupProfile);

export {
    makeSelectRoomTypes,
    makeSelectDataCollectorModels,
    makeSelectSettingsRoleClaims,
    makeSelectDataCollectionSettings,
    makeSelectDataCollectionInProgress,
    makeSelectorManufacturersList,
    makeSelectorDeviceGroupProfile,
};
