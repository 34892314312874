enum DataCollectorTransferStatuses {
    DataTransferDisabled = 1,
    AllDevicesWithoutErrors,
    NoDataRetrieved,
    SomeDevicesWithErrors,
    AllDevicesWithErrors,
    TimepointsDoNotMatchWithCurrentDate,
}

export default DataCollectorTransferStatuses;
