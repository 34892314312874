import {createSelector} from 'reselect';

const selectGlobal = (state) => state.global;

const makeIsAppLoading = () => createSelector(selectGlobal, (globalState) => globalState.isAppLoading);

const makeNotifications = () => createSelector(selectGlobal, (globalState) => globalState.notifications);

const makeIsDemoView = () => createSelector(selectGlobal, (globalState) => globalState.isDemoView);

const makeSelectSearchState = () => createSelector(selectGlobal, (globalState) => globalState.search);

const makeSelectSearchInputValue = () =>
    createSelector(makeSelectSearchState(), (search) => {
        return search.inputValue;
    });

const makeSelectIsRequestInProgress = () =>
    createSelector(selectGlobal, (globalState) => globalState.isRequestInProgress);

const makeSelectOriginImageUrl = () =>
    createSelector(selectGlobal, (globalState) => {
        return globalState.upload.originImageUrl;
    });

const makeSelectShortImageUrl = () =>
    createSelector(selectGlobal, (globalState) => {
        return globalState.upload.shortImageUrl;
    });

const makeSelectImageExtension = () =>
    createSelector(selectGlobal, (globalState) => {
        return globalState.upload.imageExtension;
    });

const makeIsGlobalLoading = () => createSelector(selectGlobal, (globalState) => globalState.isGlobalLoading);

export {
    makeSelectSearchInputValue,
    makeSelectSearchState,
    makeSelectIsRequestInProgress,
    makeIsAppLoading,
    makeNotifications,
    makeIsDemoView,
    makeSelectOriginImageUrl,
    makeSelectShortImageUrl,
    makeIsGlobalLoading,
    makeSelectImageExtension,
};
