import storageService from '../../services/storage/storageService';
import helpers from '../../utils/helpers';

export const LOGOUT_CLEAR_DATA = 'app/global/LOGOUT_CLEAR_DATA';
export const SET_IS_APP_LOADING = 'app/global/SET_IS_APP_LOADING';
export const REMOVE_NOTIFICATION = 'app/global/REMOVE_NOTIFICATION';
export const SET_NOTIFICATION = 'app/global/SET_NOTIFICATION';
export const CLEAR_ALL_NOTIFICATIONS = 'app/global/CLEAR_ALL_NOTIFICATIONS';
export const SET_IS_DEMO_VIEW = 'app/global/SET_IS_DEMO_VIEW';
export const SET_PAGINATION = 'app/global/SET_PAGINATION';
export const SET_FILTERS = 'app/global/SET_FILTERS';
export const SET_IS_REQUEST_IN_PROGRESS = 'app/global/SET_IS_REQUEST_IN_PROGRESS';
export const SET_ORIGIN_IMAGE_URL = 'app/global/SET_ORIGIN_IMAGE_URL';
export const SET_IMAGE_EXTENSIONS = 'app/global/SET_IMAGE_EXTENSIONS';
export const SET_SHORT_IMAGE_URL = 'app/global/SET_SHORT_IMAGE_URL';
export const CLEAR_UPLOAD_STATE = 'app/global/CLEAR_UPLOAD_STATE';
export const SET_IS_GLOBAL_LOADING = 'app/global/SET_IS_GLOBAL_LOADING';

const initialState = {
    isRequestInProgress: false,
    isAppLoading: true,
    notifications: [],
    isDemoView: storageService.getDemoViewFromStorage(),
    pagination: null,
    isGlobalLoading: null,
    upload: {
        originImageUrl: '',
        imageExtension: '',
        shortImageUrl: '',
    },
};

export default function reducer(state = {...initialState}, action) {
    switch (action.type) {
        case SET_ORIGIN_IMAGE_URL: {
            return {
                ...state,
                upload: {
                    ...state.upload,
                    originImageUrl: action.payload,
                },
            };
        }
        case LOGOUT_CLEAR_DATA: {
            return {
                ...state,
                isDemoView: false,
                isAppLoading: false,
            };
        }
        case SET_SHORT_IMAGE_URL: {
            return {
                ...state,
                upload: {
                    ...state.upload,
                    shortImageUrl: action.payload,
                },
            };
        }
        case SET_IMAGE_EXTENSIONS: {
            return {
                ...state,
                upload: {
                    ...state.upload,
                    imageExtension: action.payload,
                },
            };
        }
        case CLEAR_UPLOAD_STATE: {
            return {
                ...state,
                upload: {...initialState.upload},
            };
        }
        case SET_IS_REQUEST_IN_PROGRESS: {
            return {
                ...state,
                isRequestInProgress: action.payload,
            };
        }
        case SET_IS_APP_LOADING: {
            const isAppLoading = action.payload;

            return {...state, isAppLoading};
        }
        case SET_IS_DEMO_VIEW: {
            return {
                ...state,
                isDemoView: action.payload,
            };
        }
        case SET_NOTIFICATION: {
            const {type, notificationId = helpers.guid(), text, ...rest} = action.payload;
            const {notifications} = state;

            const notificationsNew = [...notifications];

            const notification = {
                notificationId,
                type,
                text,
                ...rest,
            };

            notificationsNew.push(notification);

            return {...state, notifications: notificationsNew};
        }
        case REMOVE_NOTIFICATION: {
            const {notificationId} = action.payload;
            const {notifications} = state;
            const notificationsNew = notifications.filter(
                (notification) => notification.notificationId !== notificationId
            );

            return {...state, notifications: notificationsNew};
        }
        case CLEAR_ALL_NOTIFICATIONS: {
            return {
                ...state,
                notifications: action.payload,
            };
        }
        case SET_IS_GLOBAL_LOADING: {
            const isGlobalLoading = action.payload;

            return {...state, isGlobalLoading};
        }
        default:
            return state;
    }
}

export const setIsAppLoaded = () => ({
    type: SET_IS_APP_LOADING,
    payload: false,
});

export const setIsDemoView = (data = true) => ({
    type: SET_IS_DEMO_VIEW,
    payload: data,
});

export const setNotification = ({text, type, ...rest}) => {
    return {
        type: SET_NOTIFICATION,
        payload: {text, type, ...rest},
    };
};

export const removeNotification = (notificationId) => ({
    type: REMOVE_NOTIFICATION,
    payload: {notificationId},
});

export const clearAllNotifications = () => ({
    type: CLEAR_ALL_NOTIFICATIONS,
    payload: [],
});

export const setOriginImageUrl = (payload) => ({
    type: SET_ORIGIN_IMAGE_URL,
    payload,
});

export const setShortImageUrl = (payload) => ({
    type: SET_SHORT_IMAGE_URL,
    payload,
});

export const setImageExtension = (payload) => ({
    type: SET_IMAGE_EXTENSIONS,
    payload,
});

export const clearUploadState = () => ({
    type: CLEAR_UPLOAD_STATE,
});

export const setIsGlobalLoading = (payload) => ({
    type: SET_IS_GLOBAL_LOADING,
    payload,
});

export const logoutClearData = () => ({
    type: LOGOUT_CLEAR_DATA,
});
