import axios from 'axios';

import log from '../logger/log';
import httpStatusTypesService from './httpStatusTypesService';
import {authResponseInterceptorHandler} from './interceptorHandlers/authErrorInterceptorHandler';
import {handleRequestEnd, handleRequestStart} from './interceptorHandlers/loaderRequestHandler';

const axiosInstance = axios.create();

const requestApi = async (method, url, requestConfig) => {
    try {
        const config = {method, url, ...requestConfig};

        return await axiosInstance.request(config);
    } catch (e) {
        logError(e, url, requestConfig, method);
        throw e;
    }
};

const logError = (e, url, requestConfig, method) => {
    const LOG_LENGTH_LIMIT = 10 * 1024;
    const requestConfigCopy = {...requestConfig};

    if (requestConfig?.headers) {
        const trimRequestConfigHeader = (headerName) => {
            const header = requestConfig.headers[headerName];

            if (header) {
                const NUMBER_OF_VISIBLE_CHARS = 10;
                const headerTrimmed = `${header.substring(0, NUMBER_OF_VISIBLE_CHARS)}*****${header.substring(
                    header.length - NUMBER_OF_VISIBLE_CHARS
                )}`;

                requestConfigCopy.headers[headerName] = headerTrimmed;
            }
        };

        trimRequestConfigHeader('Authorization');
        trimRequestConfigHeader('Token');
    }

    const logMessage = `requestApi: url: ${url}; requestConfig: ${JSON.stringify(
        requestConfigCopy
    )}, method: ${method}; error: ${e}; response data: ${JSON.stringify(e.response?.data)?.substring(
        0,
        LOG_LENGTH_LIMIT
    )}`;

    const statusCode = e?.response?.status;
    const isInfoLevel = !statusCode || httpStatusTypesService.isNotAuthorized(statusCode);

    if (isInfoLevel) {
        log.info(logMessage);
    } else {
        log.error(logMessage);
    }
};

axiosInstance.interceptors.request.use(
    async (config) => {
        handleRequestStart(config);

        return config;
    },
    (error) => {
        handleRequestEnd(error?.config || {});

        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        handleRequestEnd(response?.config);

        return response;
    },

    async (error) => {
        handleRequestEnd(error?.config || {});

        return await authResponseInterceptorHandler(error, axiosInstance);
    }
);

export default {
    get: (url, config) => requestApi('get', url, config),
    post: (url, config) => requestApi('post', url, config),
    put: (url, config) => requestApi('put', url, config),
    requestDelete: (url, config) => requestApi('delete', url, config),
};
