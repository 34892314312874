import React, {useMemo} from 'react';

import DataCollectorOptionsMenu from '../../components/TableOptionsMenu/DataCollectorOptionsMenu/DataCollectorOptionsMenu';
import ReportOptionsMenu from '../../components/TableOptionsMenu/ReportOptionsMenu/ReportOptionsMenu';
import UsersOptionsMenu from '../../components/TableOptionsMenu/UsersOptionsMenu/UsersOptionsMenu';
import * as requestParamTypes from '../../consts/app/requestParamTypes';
import SorterTooltip from '../SorterTooltip/SorterTooltip';

const useColumnsModifier = ({
    columns,
    handlers,
    menuComponent: MenuCell,
    dataSource,
    requestOptions,
    initialSortValue,
    initialSortDirection,
    selectedRows,
    paramsType,
}) => {
    const isDisabledKebabMenu = selectedRows.length <= 0;

    const customColumns = columns.map((col) => {
        const isDisabled = !dataSource.length;
        const handleSort = !isDisabled && handlers?.handleSort;

        if (col.sortable) {
            return {
                ...col,
                filters: [],
                filterDropdownOpen: false,
                filterIcon: (
                    <SorterTooltip
                        initialSortDirection={initialSortDirection}
                        initialSortValue={initialSortValue}
                        requestOptions={requestOptions}
                        disabled={isDisabled}
                        handleSort={handleSort}
                        sortBy={col?.key}
                    />
                ),
            };
        }
        return col;
    });

    const getMenuComponent = (paramsType) => {
        switch (paramsType) {
            case requestParamTypes.DATA_COLLECTORS:
                return (
                    <DataCollectorOptionsMenu
                        dataSource={dataSource}
                        handlers={handlers}
                        record={selectedRows}
                        isForHeader
                        isDisabled={isDisabledKebabMenu}
                    />
                );
            case requestParamTypes.USERS:
                return (
                    <UsersOptionsMenu
                        dataSource={dataSource}
                        handlers={handlers}
                        record={selectedRows}
                        isForHeader
                        isDisabled={isDisabledKebabMenu}
                    />
                );
            case requestParamTypes.REPORTS:
                return (
                    <ReportOptionsMenu
                        dataSource={dataSource}
                        handlers={handlers}
                        record={selectedRows}
                        isForHeader
                        isDisabled={isDisabledKebabMenu}
                    />
                );
        }
    };

    const mergedColumns = useMemo(() => {
        const columnsToAdd = [];
        const menuComponent = getMenuComponent(paramsType);

        if (MenuCell) {
            columnsToAdd.push({
                key: 'menu',
                dataIndex: 'menu',
                fixed: 'right',
                render: (_, record) => {
                    return <MenuCell dataSource={dataSource} record={record} handlers={handlers} />;
                },
                title: menuComponent,
            });
        }

        return [...customColumns, ...columnsToAdd];
    }, [customColumns, MenuCell, handlers]);

    return mergedColumns;
};

export default useColumnsModifier;
