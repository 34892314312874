import deepmerge from 'deepmerge';
import {v4} from 'uuid';

import arrayUtils from './arrayUtils';

const guid = () => v4();

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const mergeDeep = deepmerge;

const scrollTop = (yCoord = 0) => window.scrollTo({top: yCoord});

const runFunction = (func, arg) => {
    const args = arrayUtils.toArray(arg);

    if (typeof func === 'function') {
        args ? func(...args) : func();
    }
};

const runFunctionForArray = (func, arg) => {
    const args = arrayUtils.toArray(arg);

    if (typeof func === 'function') {
        args ? func(args) : func();
    }
};

const getEnumValues = (enumObject) =>
    Object.values(enumObject).filter((item) => {
        return isNaN(Number(item));
    });
const getEnumKeys = (enumObject) =>
    Object.keys(enumObject).filter((item) => {
        return isNaN(Number(item));
    });

export default {
    guid,
    mergeDeep,
    scrollTop,
    runFunction,
    runFunctionForArray,
    timeout,
    getEnumValues,
    getEnumKeys,
};
