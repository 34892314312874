import React from 'react';

import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';

const getStatisticsOrganizationColumns = () => {
    const localizedStrings = getLocalizedStrings();

    const columns = [
        {
            key: 'BuildingAddress',
            title: localizedStrings[localizationKeys.SERVICE_ARS_STATISTICS_ORGANIZATION_BUILDING_COLUMN_TITLE],
            dataIndex: 'buildingAddress',
            render: (_, record) => {
                return (
                    <div id={record?.id} className={'text-14'}>
                        {record?.buildingAddress}
                    </div>
                );
            },
        },
        {
            key: 'Status',
            title: localizedStrings[localizationKeys.SERVICE_ARS_STATISTICS_ORGANIZATION_STATUS_COLUMN_TITLE],
            dataIndex: 'status',
            width: '35%',
            render: (_, record) => (
                <div id={record?.id} className={'text-12'}>
                    {record?.status}
                </div>
            ),
        },
        {
            key: 'DateInfo',
            title: localizedStrings[localizationKeys.SERVICE_ARS_STATISTICS_ORGANIZATION_DATE_COLUMN_TITLE],
            dataIndex: 'dateInfo',

            width: '23%',
            render: (_, record) => (
                <div id={record?.id} className={'text-14'}>
                    {record?.dateInfo}
                </div>
            ),
        },
    ];

    return columns;
};

export default {getStatisticsOrganizationColumns};
