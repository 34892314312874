export const NAME = 'name';
export const USER_ID = 'userId';
export const REMEMBER = 'remember';
export const SURNAME = 'surname';
export const PHONE = 'phone';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const OLD_PASSWORD = 'oldPassword';
export const CONFIRM_PASSWORD = 'confirmPassword';
export const APARTMENT = 'apartment';
export const COMPANY = 'company';
export const BUILDING = 'building';
export const ALL_BUILDINGS = 'allBuildings';
export const LOGIN = 'login';
export const ROLE = 'role';
export const STATUS = 'status';
export const ADDRESS = 'address';
export const CITY = 'city';
export const NOTIFICATION = 'notification';
export const START_DATE = 'start_date';
export const END_DATE = 'end_date';
export const LOCAL_TIME = 'local_time';
export const UNIT = 'unit';
export const REPORT_TYPE = 'report_type';
export const SOURCE_ADDRESS = 'source_address';
export const DEVICE_TYPE = 'device_type';
export const DEVICE_ID = 'device_id';
export const DEVICE_GROUP = 'device_group';
export const IMAGE_EXTENSION = 'ImageExtension';
export const IMAGE = 'Image';
export const HANDLER = 'handler';
export const SOURCE = 'source';
export const FORMAT = 'format';
export const ORGANIZATION = 'organization';
export const BUILDING_ID = 'buildingId';
export const DEVICE_NUMBER = 'deviceNumber';
export const DC_TYPE_ID = 'typeId';
export const IP_ADDRESS = 'ipAddress';
export const SIM_NUMBER = 'simNumber';
export const IS_DATA_TRANSFER_ALLOWED = 'isDataTransferAllowed';
export const SERVER = 'server';
export const RESOURCE_ADDRESS = 'resourceAddress';
export const CITY_NAME = 'cityName';
export const FOLDER = 'folder';
export const ROOM_TYPE_NAME = 'roomTypeName';
export const ROOM_TYPE_DESCRIPTION = 'roomTypeDescription';
export const DC_MODEL_NAME = 'dcModelName';
export const DC_MODEL_DESCRIPTION = 'dcModelDescription';
export const CITY_ID = 'cityID';
export const ORGANIZATION_ID = 'organizationID';
export const LATITUDE = 'latitude';
export const LONGITUDE = 'longitude';
export const YEAR = 'year';
export const HEAT_SQUARE = 'heatSquare';
export const SQUARE = 'square';
export const ACCOUNT = 'account';
export const CONSUMER = 'consumer';
export const VERIFICATION_CODE = 'verificationCode';
export const ROOM_NUMBER = 'number';
export const ROOM_TYPE_ID = 'roomTypeId';
export const SECTION = 'section';
export const FLOOR = 'floor';
export const MANUAL_ID = 'manualId';
export const FACTORY_ID = 'factoryId';
export const WATER_DEVICE_ID = 'waterDeviceId';
export const DEVICE_TYPE_ID = 'typeId';
export const GROUPING_TYPE = 'groupingType';
export const PARENT_DEVICE_ID = 'parentId';
export const SERVICE_DATE = 'serviceDate';
export const ROOM_ID = 'roomId';
export const PZPD_ID = 'uspdId';
export const DEVICE_MODEL_ID = 'modelID';
export const DEVICE_MODEL_NAME = 'deviceModelName';
export const DEVICE_MODEL_DESCRIPTION = 'deviceModelDescription';
export const DEVICE_MODEL_MANUFACTURER_ID = 'manufacturerId';
export const DEVICE_GROUPS_DESCRIPTION = 'groupsDescription';
export const DEVICE_GROUPS_NAME = 'groupsName';
export const DEVICE_GROUPS_TYPES = 'groupsTypes';
export const SERVICE_KYIVTEPLOENERGO = 'serviceKyivTeploEnergo';
export const ROOM_ACCOUNT_NUMBER = 'accountNumber';
