import appConfig from '../../../config/appConfig';
import urlUtils from '../../../utils/urlUtils';
import ApiClient from './apiClient';

export default class OpenStreetApiClient extends ApiClient {
    getApiUrl(methodName) {
        const apiUrl = appConfig.getOpenStreetApiUrl();

        return urlUtils.join(apiUrl, methodName);
    }
}
