export const SET_REPORTS_DATA = 'app/reports/SET_REPORTS_DATA';
export const SET_REPORT_TYPES = 'app/reports/SET_REPORT_TYPES';
export const CLEAN_REPORTS_DATA = 'app/reports/CLEAN_REPORTS_DATA';
export const REMOVE_REPORT_BY_ID = 'app/reports/REMOVE_REPORT_BY_ID';

const initialState = {
    reportsList: [],
    filters: null,
    totalRecords: null,
    totalFilteredRecords: null,
    hasNextPage: null,
    hasPreviousPage: null,
    page: 1,
    size: null,
    reportTypes: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_REPORTS_DATA: {
            const {list, totalRecords, hasNextPage, hasPreviousPage, page, size, filters, totalFilteredRecords} =
                action.payload || {};

            return {
                ...state,
                totalRecords,
                totalFilteredRecords,
                hasNextPage,
                hasPreviousPage,
                filters,
                page,
                size,
                reportsList: list,
            };
        }
        case CLEAN_REPORTS_DATA: {
            return {...state, ...initialState};
        }
        case SET_REPORT_TYPES: {
            const {reportTypes} = action.payload || {};

            return {
                ...state,
                reportTypes,
            };
        }
        case REMOVE_REPORT_BY_ID: {
            const {id} = action.payload || {};

            const updatedReports = state?.reportsList.filter((item) => item?.id !== id);

            return {
                ...state,
                reportsList: updatedReports,
            };
        }
        default:
            return state;
    }
}

export const setReportsData = (payload) => ({
    type: SET_REPORTS_DATA,
    payload: {...payload},
});

export const setReportTypes = (payload) => ({
    type: SET_REPORT_TYPES,
    payload: {reportTypes: payload},
});

export const cleanReportsData = () => ({
    type: CLEAN_REPORTS_DATA,
});

export const deleteReportById = (payload) => ({
    type: REMOVE_REPORT_BY_ID,
    payload: {id: payload},
});
