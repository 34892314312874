export const SET_BUILDINGS = 'app/buildings/SET_BUILDINGS';
export const SET_CURRENT_BUILDING = 'app/buildings/SET_CURRENT_BUILDING';
export const SET_ORGANIZATION_ID = 'app/buildings/SET_ORGANIZATION_ID';
export const CLEAN_BUILDINGS_DATA = 'app/buildings/CLEAN_BUILDINGS_DATA';
export const CLEAR_BUILDINGS_LIST = 'app/buildings/CLEAR_BUILDINGS_LIST';
export const SET_ROOM_BUILDING_ID = 'app/buildings/SET_ROOM_BUILDING_ID';
export const SET_BUILDING_PROFILE = 'app/buildings/SET_BUILDING_PROFILE';
export const SET_BUILDING_DATA_COLLECTORS = 'app/buildings/SET_BUILDING_DATA_COLLECTORS';
export const REMOVE_BUILDING_DEVICE_BY_ID = 'app/buildings/REMOVE_BUILDING_DEVICE_BY_ID';
export const SET_ROOMS = 'app/buildings/SET_ROOMS';
export const SET_INDICATORS = 'app/buildings/SET_INDICATORS';
export const SET_SECTIONS = 'app/buildings/SET_SECTIONS';
export const SET_INDICATOR_TYPE = 'app/buildings/SET_INDICATOR_TYPE';
export const UPDATE_BUILDING_PROFILE = 'app/buildings/UPDATE_BUILDING_PROFILE';
export const UPDATE_CURRENT_BUILDING = 'app/buildings/UPDATE_CURRENT_BUILDING';
export const SET_ACTIVE_SECTION = 'app/buildings/SET_ACTIVE_SECTION';
export const SET_DEVICE_TYPE_GROUPS = 'app/buildings/SET_DEVICE_TYPE_GROUPS';
export const SET_USERS_DATA = 'app/buildings/SET_USERS_DATA';

export const initialState = {
    rooms: null,
    buildingsData: null,
    organizationId: null,
    currentBuilding: null,
    buildingProfile: null,
    roomBuildingId: null,
    buildingDataCollectors: null,
    indicators: null,
    sections: null,
    activeSection: null,
    indicatorType: 1,
    deviceTypeGroups: [],
    usersData: null,
};

export default function reducer(state = {...initialState}, action) {
    switch (action.type) {
        case SET_CURRENT_BUILDING: {
            const currentBuilding = action.payload;

            return {...state, currentBuilding};
        }
        case UPDATE_CURRENT_BUILDING: {
            return {
                ...state,
                currentBuilding: {
                    ...state.currentBuilding,
                    ...action.payload,
                },
            };
        }
        case SET_BUILDING_PROFILE: {
            const buildingProfile = action.payload;

            if (!buildingProfile) return {...state, buildingProfile: null};

            const sortedFloors = buildingProfile?.floors?.sort((a, b) => a?.floor - b?.floor);

            const sections = [
                ...new Set(sortedFloors.flatMap((entry) => entry.sections.map((section) => section.section))),
            ].sort((a, b) => +a - +b);

            return {
                ...state,
                buildingProfile: {
                    ...buildingProfile,
                    floors: sortedFloors,
                    sections,
                },
            };
        }
        case UPDATE_BUILDING_PROFILE: {
            return {
                ...state,
                buildingProfile: {
                    ...state.buildingProfile,
                    uspds: action.payload?.items || [],
                    modems: action.payload,
                },
            };
        }
        case SET_BUILDINGS: {
            const {buildingsData} = action.payload || {};

            return {...state, buildingsData};
        }
        case SET_BUILDING_DATA_COLLECTORS: {
            const buildingDataCollectors = action.payload || {};

            return {...state, buildingDataCollectors};
        }
        case SET_ORGANIZATION_ID: {
            const {organizationId} = action.payload || {};

            return {...state, organizationId};
        }
        case SET_INDICATORS: {
            const indicators = action.payload;

            return {
                ...state,
                indicators,
            };
        }
        case SET_SECTIONS: {
            const sections = action.payload;

            return {
                ...state,
                sections,
            };
        }
        case SET_DEVICE_TYPE_GROUPS: {
            const {items} = action.payload;

            return {
                ...state,
                deviceTypeGroups: items,
            };
        }

        case SET_USERS_DATA: {
            return {
                ...state,
                usersData: action.payload,
            };
        }

        case CLEAN_BUILDINGS_DATA: {
            return {...state, ...initialState};
        }
        case CLEAR_BUILDINGS_LIST: {
            return {...state, buildingsData: {...state.buildingsData, items: []}};
        }
        case REMOVE_BUILDING_DEVICE_BY_ID: {
            const deviceId = action.payload || {};
            const commonDevices = [...state.buildingProfile.commonDevices].filter((item) => item.deviceID !== deviceId);
            const newDevices = [...state.buildingProfile.newDevices].filter((item) => item.deviceID !== deviceId);

            return {
                ...state,
                buildingProfile: {
                    ...state.buildingProfile,
                    commonDevices,
                    newDevices,
                },
            };
        }
        case SET_ROOMS: {
            return {
                ...state,
                rooms: {...action.payload},
            };
        }
        case SET_ROOM_BUILDING_ID: {
            const buildingId = action.payload;

            return {
                ...state,
                roomBuildingId: buildingId,
            };
        }
        case SET_INDICATOR_TYPE: {
            const indicatorType = action.payload;

            return {
                ...state,
                indicatorType,
            };
        }
        case SET_ACTIVE_SECTION: {
            const activeSection = action.payload;

            return {
                ...state,
                activeSection,
            };
        }
        default:
            return state;
    }
}

export const setCurrentBuilding = (payload) => ({
    type: SET_CURRENT_BUILDING,
    payload,
});
export const updateCurrentBuilding = (payload) => ({
    type: UPDATE_CURRENT_BUILDING,
    payload,
});

export const setBuildingProfile = (payload) => ({
    type: SET_BUILDING_PROFILE,
    payload,
});
export const updateBuildingProfile = (payload) => {
    return {
        type: UPDATE_BUILDING_PROFILE,
        payload,
    };
};

export const setBuildings = (payload) => ({
    type: SET_BUILDINGS,
    payload: {buildingsData: payload},
});

export const setBuildingsOrganizationId = (organizationId) => ({
    type: SET_ORGANIZATION_ID,
    payload: {organizationId},
});

export const cleanBuildingsData = () => ({
    type: CLEAN_BUILDINGS_DATA,
});

export const clearBuildingsList = () => ({
    type: CLEAR_BUILDINGS_LIST,
});

export const setBuildingDataCollectors = (payload) => ({
    type: SET_BUILDING_DATA_COLLECTORS,
    payload,
});

export const removeBuildingDeviceById = (payload) => ({
    type: REMOVE_BUILDING_DEVICE_BY_ID,
    payload,
});

export const setRooms = (payload) => ({
    type: SET_ROOMS,
    payload: {...payload},
});

export const setRoomBuildingId = (payload) => ({
    type: SET_ROOM_BUILDING_ID,
    payload,
});

export const setIndicators = (payload) => ({
    type: SET_INDICATORS,
    payload,
});

export const setSections = (payload) => ({
    type: SET_SECTIONS,
    payload,
});
export const setDeviceTypeGroups = (payload) => ({
    type: SET_DEVICE_TYPE_GROUPS,
    payload,
});

export const setIndicatorType = (payload) => ({
    type: SET_INDICATOR_TYPE,
    payload,
});

export const setActiveSection = (payload) => ({
    type: SET_ACTIVE_SECTION,
    payload,
});

export const setUsersData = (payload) => ({
    type: SET_USERS_DATA,
    payload,
});
