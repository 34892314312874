import * as apiMethods from '../../consts/api/apiMethods';
import {ORGANIZATION} from '../../consts/app/requestParamTypes';
import {
    removeOrganizationById,
    setCities,
    setCurrentOrganization,
    setOrganizationBuildingNames,
    setOrganizations,
    setOrganizationsNames,
    updateOrganization,
} from '../../state/ducks/organizations';
import {makeSelectRequestParams} from '../../state/selectors/requestParams';
import {dispatch, getState} from '../../state/store';
import ApiClient from '../api/clients/apiClient';
import log from '../logger/log';
import {citiesRequestDataMapping} from '../mappings/cityMappings';
import {
    organizationListRequestDataMapping,
    organizationNamesRequestDataMapping,
} from '../mappings/organizationMappings';

const getOrganizationsList = async (options) => {
    const requestParams = options?.direction ? options : makeSelectRequestParams(ORGANIZATION)(getState());

    await new ApiClient().callPost({
        methodName: apiMethods.ORGANIZATIONS_LIST,
        action: setOrganizations,
        mapper: organizationListRequestDataMapping,
        requestConfig: {
            data: requestParams,
        },
    });
};

const deleteOrganization = async (organizationId) => {
    await new ApiClient().callDelete({
        methodName: apiMethods.ORGANIZATIONS,
        action: () => removeOrganizationById(organizationId),
        requestConfig: {
            data: {
                OrganizationId: organizationId,
            },
        },
    });
};

const switchBlockOrganization = async (organizationId) => {
    await new ApiClient().callPost({
        methodName: apiMethods.ORGANIZATION_SWITCH_BLOCK,
        action: updateOrganization,
        requestConfig: {
            data: {
                OrganizationId: organizationId,
            },
        },
    });
};

const editOrganization = async (formValues) =>
    await new ApiClient().callPut({
        methodName: apiMethods.ORGANIZATIONS,
        requestConfig: {
            data: formValues,
        },
    });

const createOrganization = async (formValues) =>
    await new ApiClient().callPost({
        methodName: apiMethods.ORGANIZATIONS,
        requestConfig: {
            data: formValues,
        },
    });

const getOrganizationsNames = async (cityId) => {
    try {
        const options = {
            methodName: apiMethods.ORGANIZATIONS_NAMES,
            action: setOrganizationsNames,
            mapper: organizationNamesRequestDataMapping,
        };

        if (cityId) {
            options.requestConfig = {
                params: {CityID: cityId},
            };
        }

        return await new ApiClient().callGet(options);
    } catch (e) {
        log.error(`getOrganizationsNames: ${e}; removing organizationsNames from state`);
        dispatch(setOrganizationsNames(null));
    }
};

const getCities = async () =>
    await new ApiClient().callGet({
        methodName: apiMethods.CITIES,
        action: setCities,
        mapper: citiesRequestDataMapping,
    });

const getOrganizationById = async (organizationId, onRequestSuccess) =>
    await new ApiClient().callGet({
        methodName: apiMethods.ORGANIZATION_BY_ID,
        action: setCurrentOrganization,
        requestConfig: {
            args: organizationId,
        },
        onResponse: onRequestSuccess,
    });

const getBuildingsNamesByOrganizationId = async (organizationId) => {
    return await new ApiClient().callGet({
        methodName: apiMethods.BUILDING_NAMES_BY_ORGANIZATION_ID,
        action: setOrganizationBuildingNames,
        requestConfig: {
            args: organizationId,
        },
    });
};

export default {
    getOrganizationById,
    getCities,
    getOrganizationsNames,
    getOrganizationsList,
    deleteOrganization,
    switchBlockOrganization,
    editOrganization,
    createOrganization,
    getBuildingsNamesByOrganizationId,
};
