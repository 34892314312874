import {WILDCARD} from '../consts/route/routePaths';
import history from './history';

const browserHistory = history.getHistory();

const forwardTo = (location) => browserHistory.push(location);

const forwardToNotFound = () => browserHistory.push(WILDCARD);

const goBack = () => browserHistory.goBack();

const reload = () => window.location.reload();

const replace = (location) => browserHistory.replace(location);

const redirect = (location) => {
    window.location.href = location;
};

const blankLinkOpen = (url) => {
    window.open(url, '_blank');
};

export default {
    blankLinkOpen,
    forwardTo,
    forwardToNotFound,
    goBack,
    redirect,
    reload,
    replace,
};
