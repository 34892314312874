import * as apiMethods from '../../consts/api/apiMethods';
import {setReportsData, setReportTypes} from '../../state/ducks/reports';
import ApiClient from '../api/clients/apiClient';
import ReportsApiClient from '../api/clients/reportsApiClient';
import downloadService from '../download/downloadService';
import log from '../logger/log';
import {
    generateReportRequestPostMapping,
    manipulateReportMapping,
    reportsListRequestDataMapping,
    reportTypesRequestDataMapping,
} from '../mappings/reportMappings';

const getReportsList = async ({options}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.REPORTS_LIST,
            requestConfig: {
                data: options,
            },
            mapper: reportsListRequestDataMapping,
            action: setReportsData,
        });
    } catch (e) {
        log.error(`getReportsList: ${e}`);
    }
};

const getReportTypes = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.REPORTS_TYPES,
            mapper: reportTypesRequestDataMapping,
            action: setReportTypes,
        });
    } catch (e) {
        log.error(`getReportTypes: ${e}`);
    }
};

const generateReport = async ({values, onRequestSuccess}) => {
    try {
        await new ReportsApiClient().callPost({
            methodName: apiMethods.REPORTS,
            requestConfig: {
                data: generateReportRequestPostMapping(values),
            },
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`generateReport: ${e}`);
    }
};

const downloadReport = async (obj) => {
    try {
        const response = await new ApiClient().callPost({
            methodName: apiMethods.REPORTS_DOWNLOAD,
            requestConfig: {
                data: manipulateReportMapping(obj.ids),
            },
        });

        const isDownloaded = await downloadService.downloadFileByPath(response.reportDownloadPath);

        await deleteReportArchive(response.reportDownloadPath);

        // if (isDownloaded) {
        //     setTimeout(() => {
        //         helpers.runFunction(obj.onRequestSuccess);
        //     }, 500);
        // } else {
        //     helpers.runFunction(obj.onRequestError);
        // }

        return response;
    } catch (e) {
        log.error(`downloadReport: ${e}`);
    }
};

const deleteReportArchive = async (filePath) => {
    try {
        await new ApiClient().callDelete({
            methodName: apiMethods.REMOVE_REPORTS_ARCHIVE,
            requestConfig: {
                data: {
                    filePath,
                },
            },
        });
    } catch (e) {
        log.error(`deleteReportArchive: ${e}`);
    }
};

const deleteReport = async (obj) => {
    try {
        await new ApiClient().callDelete({
            methodName: apiMethods.REPORTS,
            requestConfig: {
                data: manipulateReportMapping(obj.ids),
            },
            onResponse: obj.onRequestSuccess,
        });
    } catch (e) {
        log.error(`deleteReport: ${e}`);
    }
};

export default {
    getReportsList,
    deleteReport,
    getReportTypes,
    generateReport,
    downloadReport,
};
