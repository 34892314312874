import {Button} from 'antd';
import cn from 'classnames';
import React from 'react';
import {twMerge} from 'tailwind-merge';

const AntButton = (props) => {
    const {className, primary, disabled, bordered, underline, cancelHover, icon, children, ...rest} = props;

    const classes = twMerge(
        cn(
            {underline},
            'flex justify-center items-center w-53 h-[42px] rounded-pageGridSection !shadow-none transition-all text-primary font-semibold leading-[24px]',
            {
                'bg-primary !text-white hover:bg-transparent hover:border hover:border-primary hover:!text-primary':
                    primary && !disabled,
                '!text-gray-disabled border-grey-status bg-cultured': disabled,
                'border py-5 border-faded-sea text-black': bordered,
                'bg-transparent !text-black': bordered && primary,
                'hover:!border-flamingo hover:!text-flamingo': cancelHover && bordered,
            },
            className
        )
    );

    return (
        <Button icon={icon} className={classes} disabled={disabled} {...rest}>
            {children}
        </Button>
    );
};

export default AntButton;
