import {createSelector} from 'reselect';

const selectDevice = (state) => state.device;

const makeSelectDevices = () => createSelector(selectDevice, (deviceState) => deviceState.devices);

const makeSelectDeviceTypes = () => createSelector(selectDevice, (deviceState) => deviceState.deviceTypes);

const makeSelectDeviceTypesUnassigned = () =>
    createSelector(selectDevice, (deviceState) => deviceState.deviceTypesUnassigned);

// const makeSelectDeviceProfileTypes = () => createSelector(selectDevice, (deviceState) => deviceState.deviceInfo?.types);
const makeSelectDeviceProfileTypes = () =>
    createSelector(selectDevice, (deviceState) => deviceState.deviceInfo?.groups);

const makeSelectDeviceModels = () => createSelector(selectDevice, (deviceState) => deviceState.models);

const makeSelectDeviceProfileModels = () =>
    createSelector(selectDevice, (deviceState) => deviceState.deviceInfo?.models);

const makeSelectDeviceInfo = () => createSelector(selectDevice, (deviceState) => deviceState.deviceInfo);

const makeSelectDeviceChart = () => createSelector(selectDevice, (deviceState) => deviceState.deviceChart);

const makeSelectorDeviceGroups = () => createSelector(selectDevice, (deviceState) => deviceState.deviceGroups);

const makeSelectDeviceTypesGroups = () => createSelector(selectDevice, (deviceState) => deviceState.deviceTypesGroups);

const makeSelectorChartActivePeriodKey = () =>
    createSelector(selectDevice, (deviceState) => deviceState.chartActivePeriodKey);

const makeSelectCurrentModel = () => createSelector(selectDevice, (deviceState) => deviceState.currentModel);

export {
    makeSelectDeviceTypes,
    makeSelectDevices,
    makeSelectDeviceModels,
    makeSelectCurrentModel,
    makeSelectDeviceInfo,
    makeSelectDeviceChart,
    makeSelectDeviceProfileTypes,
    makeSelectDeviceProfileModels,
    makeSelectorDeviceGroups,
    makeSelectDeviceTypesUnassigned,
    makeSelectDeviceTypesGroups,
    makeSelectorChartActivePeriodKey,
};
