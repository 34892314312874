import helpers from '../utils/helpers';
import {config} from './config';

const getDefaultConfigData = () => {
    let envConfig;

    switch (process.env.REACT_APP_ENV) {
        case 'local': {
            envConfig = require('./envConfigs/local');
            break;
        }
        case 'dev': {
            envConfig = require('./envConfigs/dev');
            break;
        }
        case 'qa': {
            envConfig = require('./envConfigs/qa');
            break;
        }
        case 'preprod': {
            envConfig = require('./envConfigs/preprod');
            break;
        }
        case 'prod': {
            envConfig = require('./envConfigs/prod');
            break;
        }
        default: {
            envConfig = require('./envConfigs/qa');
            break;
        }
    }

    return envConfig.default;
};

const initExternalConfig = async () => {
    try {
        const externalConfig = await require('./frontend_config.js');

        const overwriteMerge = (_, sourceArray) => sourceArray;

        config.data = helpers.mergeDeep(config.data, externalConfig.default, {arrayMerge: overwriteMerge});
    } catch (e) {
        console.log(`initExternalConfig: error ${e}`);
    }
};

export default {
    getDefaultConfigData,
    initExternalConfig,
};
