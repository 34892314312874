import './components/AntdTableOverride/AntTableOverride.scss';
import './components/AntdTableOverride/AntTablePaginationOverride.scss';

import {Table} from 'antd';
import React, {useEffect, useState} from 'react';

import * as localizationKeys from '../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import tablePaginationService from '../../services/paginationService';
import helpers from '../../utils/helpers';
import {BodyCell, BodyRow, HeaderCell, TableWrapper} from './components/AntdTableOverride/AntdTableOverride';
import useColumnsModifier from './useColumnsModifier';

const AntTable = (props) => {
    const {
        columns = [],
        dataSource = [],
        showHeader = true,
        showSorterTooltip = true,
        rowCount,
        containerClassName,
        wrapperClasses,
        totalRecords,
        onPaginationChange,
        isRowsSelected,
        handlers,
        menuComponent,
        paramsType,
        currentPage,
        requestOptions,
        bodyCellClassName,
        showSizeChanger = true,
        showPagination = true,
        scroll,
        initialSortValue,
        initialSortDirection,
        ...rest
    } = props;

    const [isSelectedAll, setIsSelectedAll] = useState(false);

    useEffect(() => {
        if (isSelectedAll) setSelectedRows(dataSource.map((row) => row.id));
    }, [dataSource, isSelectedAll]);

    useEffect(() => {
        if (!dataSource.length) {
            setSelectedRows([]);
            setIsSelectedAll(false);
        }
    }, [dataSource]);

    // const updateSelectedRows = (newDataSource) => {
    //     // const matchingRows = newDataSource.filter((newRow) =>
    //     //     selectedRows.some((selectedRow) => selectedRow == newRow.id)
    //     // );

    //     setSelectedRows(newDataSource.map((row) => row.id));
    // };

    const [selectedRows, setSelectedRows] = useState([]);

    const mergedColumns = useColumnsModifier({
        columns,
        handlers,
        menuComponent,
        dataSource,
        requestOptions,
        initialSortValue,
        initialSortDirection,
        selectedRows,
        paramsType,
    });

    const localizedStrings = getLocalizedStrings();

    const tableOptions = {
        bordered: true,
        showHeader,
        scroll: scroll || {x: true},
        tableLayout: 'unset',
        showSorterTooltip,
        wrapperClasses,
        ...rest,
    };

    const paginationOptions = tablePaginationService.getPaginationOptions({
        current: currentPage,
        pageSize: rowCount,
        total: totalRecords,
        showSizeChanger,
        onChange: onPaginationChange,
    });

    const componentsOptions = {
        table: TableWrapper,
        header: {
            cell: HeaderCell,
        },
        body: {
            cell: (props) => <BodyCell bodyCellClassName={bodyCellClassName} withMenu={menuComponent} {...props} />,
            row: BodyRow,
        },
    };

    const handleSelectAll = (selected) => {
        setIsSelectedAll(selected);
    };

    return (
        <div className={containerClassName}>
            <Table
                pagination={showPagination && paginationOptions}
                components={componentsOptions}
                columns={mergedColumns}
                rowKey={(record) => record.key || record?.id || helpers.guid()}
                dataSource={dataSource}
                locale={{
                    emptyText: localizedStrings[localizationKeys.TABLE_EMPTY_DATA],
                }}
                {...tableOptions}
                rowSelection={
                    isRowsSelected
                        ? {
                              onChange: (selectedRows) => {
                                  setSelectedRows(selectedRows);
                              },
                              selectedRowKeys: selectedRows,
                              onSelectAll: handleSelectAll,
                          }
                        : false
                }
            />
        </div>
    );
};

export default AntTable;
