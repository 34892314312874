export const SET_DIAGNOSTIC_DATA = 'app/reports/SET_DIAGNOSTIC_DATA';
export const CLEAN_DIAGNOSTIC_DATA = 'app/reports/CLEAN_DIAGNOSTIC_DATA';

const initialState = {
    diagnosticList: [],
    filters: null,
    totalRecords: null,
    totalFilteredRecords: null,
    hasNextPage: null,
    hasPreviousPage: null,
    page: 1,
    size: null,
    reportTypes: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DIAGNOSTIC_DATA: {
            const {list, totalRecords, hasNextPage, hasPreviousPage, page, size, filters, totalFilteredRecords} =
                action.payload || {};

            return {
                ...state,
                totalRecords,
                totalFilteredRecords,
                hasNextPage,
                hasPreviousPage,
                filters,
                page,
                size,
                diagnosticList: list,
            };
        }
        case CLEAN_DIAGNOSTIC_DATA: {
            return {...state, ...initialState};
        }
        default:
            return state;
    }
}

export const setDiagnosticData = (payload) => ({
    type: SET_DIAGNOSTIC_DATA,
    payload,
});

export const cleanDiagnosticData = () => ({
    type: CLEAN_DIAGNOSTIC_DATA,
});
