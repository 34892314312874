import {createSelector} from 'reselect';

const selectReports = (state) => state.reports;

const makeSelectReportsData = () =>
    createSelector(selectReports, (data) => ({
        ...data,
    }));

const makeSelectReportTypes = () => createSelector(selectReports, (reportsState) => reportsState.reportTypes);

export {makeSelectReportsData, makeSelectReportTypes};
