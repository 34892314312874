import React from 'react';

import Icon from '../Icon/Icon';

const IconHouseAddOutline = (props) => {
    return (
        <Icon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
            <path
                d='M17.5 17V14.5H18.5V17V17.5H19H21.5V18.5H19H18.5V19V21.5H17.5V19V18.5H17H14.5V17.5H17H17.5V17ZM12 3.67268L20.697 11.5H17.5V10.19V9.96732L17.3345 9.81835L12.3345 5.31835L12 5.01732L11.6655 5.31835L6.66552 9.81835L6.5 9.96732V10.19V18V18.5H7H11.519C11.545 18.8411 11.5974 19.1748 11.674 19.5H5.5V12V11.5H5H3.30298L12 3.67268Z'
                fill='black'
                stroke='#0187F9'
            />
        </Icon>
    );
};

export default IconHouseAddOutline;
