import {createSelector} from 'reselect';

const selectBuildings = (state) => state.buildings;

const selectUsers = (state) => state.buildings.usersData;

const makeSelectBuildingsData = () => createSelector(selectBuildings, (buildingsState) => buildingsState.buildingsData);

const makeSelectBuildingsList = () =>
    createSelector(makeSelectBuildingsData(), (buildingsState) => {
        const {items} = buildingsState || {};

        return items;
    });

const makeSelectBuildingsOrganizationId = () =>
    createSelector(selectBuildings, (buildingsState) => buildingsState.organizationId);

const makeSelectBuildingsFilters = () =>
    createSelector(makeSelectBuildingsData(), (buildingsData) => buildingsData?.filters);

const makeSelectCurrentBuilding = () =>
    createSelector(selectBuildings, (buildingsState) => buildingsState.currentBuilding);

const makeSelectBuildingProfile = () =>
    createSelector(selectBuildings, (buildingsState) => buildingsState.buildingProfile);

const makeSelectRooms = () => createSelector(selectBuildings, (buildingsState) => buildingsState.rooms);

const makeSelectRoomBuildingId = () =>
    createSelector(selectBuildings, (buildingsState) => buildingsState.roomBuildingId);

const makeSelectBuildingById = (id) =>
    createSelector(makeSelectBuildingsList(), (buildingsList) => {
        return buildingsList?.find((building) => building?.id === id);
    });

const makeSelectBuildingDataCollectors = () =>
    createSelector(selectBuildings, (buildingsState) => buildingsState.buildingDataCollectors);

const makeSelectIndicators = () => createSelector(selectBuildings, (buildingsState) => buildingsState.indicators);

const makeSelectSections = () => createSelector(selectBuildings, (buildingsState) => buildingsState.sections);

const makeSelectDeviceTypeGroups = () =>
    createSelector(selectBuildings, (buildingsState) => buildingsState.deviceTypeGroups);

const makeSelectIndicatorType = () => createSelector(selectBuildings, (buildingsState) => buildingsState.indicatorType);

const makeSelectActiveSection = () => createSelector(selectBuildings, (buildingsState) => buildingsState.activeSection);

const makeSelectUsersDataById = () => createSelector(selectUsers, (usersState) => usersState);

const selectFloors = createSelector([makeSelectBuildingProfile()], (buildingProfile) => buildingProfile.floors || []);

// todo: Ostrovskyi D. - makeSelectBuildingProfileRoomsCount don't work; should be fixed;
const makeSelectBuildingProfileRoomsCount = () =>
    createSelector(selectFloors, (floors) => {
        return floors.reduce((acc, floor) => acc + floor.sections.length, 0);
    });

export {
    makeSelectBuildingProfileRoomsCount,
    makeSelectBuildingProfile,
    makeSelectCurrentBuilding,
    makeSelectBuildingsList,
    makeSelectBuildingsFilters,
    makeSelectBuildingsData,
    makeSelectBuildingsOrganizationId,
    makeSelectRooms,
    makeSelectRoomBuildingId,
    makeSelectBuildingById,
    makeSelectBuildingDataCollectors,
    makeSelectSections,
    makeSelectDeviceTypeGroups,
    makeSelectIndicators,
    makeSelectIndicatorType,
    makeSelectActiveSection,
    makeSelectUsersDataById,
};
