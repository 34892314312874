import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {createDynamicMiddlewares} from 'redux-dynamic-middlewares';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import appConfig from '../config/appConfig';
import * as reducers from './ducks';
import * as global from './ducks/global';

const dynamicMiddlewaresInstance = createDynamicMiddlewares();

let dispatch;
let getState;

const makeConfigureStore = (initialState = {}) => {
    const appReducer = combineReducers(reducers);
    const isDebug = appConfig.getIsDebug();
    const middlewares = [promise, thunk];

    const rootReducer = (state, action) => {
        if (action.type === global.LOGOUT_CLEAR_DATA) {
            return appReducer(undefined, action);
        }

        return appReducer(state, action);
    };

    const store = configureStore({
        preloadedState: initialState,
        reducer: rootReducer,
        devTools: isDebug,
        middleware: [...middlewares, dynamicMiddlewaresInstance.enhancer],
    });

    dispatch = store?.dispatch;
    getState = store?.getState;

    return store;
};

const addReduxLoggerIfEnabled = async () => {
    if (appConfig.getIsDebug()) {
        const {createLogger} = await import('redux-logger');

        const logger = createLogger({});

        dynamicMiddlewaresInstance.addMiddleware(logger);
    }
};

export {addReduxLoggerIfEnabled, makeConfigureStore, dispatch, getState};
