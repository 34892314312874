export const SET_CURRENT_USER_INFO = 'app/users/SET_CURRENT_USER_INFO';
export const SET_CURRENT_USER_ROLES = 'app/users/SET_CURRENT_USER_ROLES';
export const SET_CURRENT_USER_NEW_IMAGE = 'app/users/SET_CURRENT_USER_NEW_IMAGE';
export const SET_CURRENT_USER_STATUSES = 'app/users/SET_CURRENT_USER_STATUSES';
export const CLEAN_CURRENT_USER_DATA = 'app/users/CLEAN_CURRENT_USER_DATA';
export const SET_CURRENT_USER_RESPONSIBILITIES = 'app/users/SET_CURRENT_USER_RESPONSIBILITIES';
export const SET_CURRENT_USER_RESPONSIBILITIES_DATA = 'app/users/SET_CURRENT_USER_RESPONSIBILITIES_DATA';

const initialState = {
    currentUserInfo: null,
    currentUserRoles: null,
    currentUserStatuses: null,
    currentUserResponsibilities: null,
    currentUserResponsibilitiesData: null,
    newImage: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER_INFO: {
            const {currentUserInfo} = action.payload || {};

            return {...state, currentUserInfo: {...state.currentUserInfo, ...currentUserInfo}};
        }
        // TODO: @VictoriaAnufrieva: remove this case if it's not used
        case SET_CURRENT_USER_ROLES: {
            const {currentUserRoles} = action.payload || {};

            return {...state, currentUserRoles};
        }
        // case SET_USER_STATUSES: {
        //     const {userStatuses} = action.payload || {};

        //     const allowedToEditStatuses = userStatuses?.items?.filter(
        //         (status) => status.key !== +userStatusesKeys.WAITING_FOR_APPROVAL
        //     );

        //     return {...state, userStatuses, allowedToEditStatuses: {items: allowedToEditStatuses}};
        // }

        case SET_CURRENT_USER_NEW_IMAGE: {
            const {newImage} = action.payload || {};

            return {...state, newImage};
        }

        case SET_CURRENT_USER_STATUSES: {
            const {currentUserStatuses} = action.payload || {};

            const allowedToEditStatuses = currentUserStatuses?.items?.filter(
                (status) => status.key !== +currentUserStatuses.WAITING_FOR_APPROVAL
            );

            return {...state, currentUserStatuses, allowedToEditStatuses: {items: allowedToEditStatuses}};
        }
        case SET_CURRENT_USER_RESPONSIBILITIES_DATA: {
            const {currentUserResponsibilitiesData} = action.payload || {};

            return {...state, currentUserResponsibilitiesData};
        }
        // TODO: @VictoriaAnufrieva: remove this case if it's not used
        case SET_CURRENT_USER_RESPONSIBILITIES: {
            const {currentUserResponsibilities} = action.payload || {};

            return {...state, currentUserResponsibilities};
        }
        case CLEAN_CURRENT_USER_DATA: {
            return {...state, ...initialState};
        }
        default:
            return state;
    }
}

export const setCurrentUserInfo = (payload) => ({
    type: SET_CURRENT_USER_INFO,
    payload: {currentUserInfo: payload},
});

export const setCurrentUserRole = (payload) => ({
    type: SET_CURRENT_USER_ROLES,
    payload: {currentUserRoles: payload},
});

export const setCurrentUserNewImage = (payload) => ({
    type: SET_CURRENT_USER_NEW_IMAGE,
    payload: {newImage: payload},
});

export const setCurrentUserStatuses = (payload) => ({
    type: SET_CURRENT_USER_STATUSES,
    payload: {currentUserStatuses: payload},
});

export const setCurrentUserResponsibilitiesData = (payload) => ({
    type: SET_CURRENT_USER_RESPONSIBILITIES_DATA,
    payload: {currentUserResponsibilitiesData: payload},
});

export const setCurrentUserResponsibilities = (payload) => ({
    type: SET_CURRENT_USER_RESPONSIBILITIES,
    payload: {currentUserResponsibilities: payload},
});

export const cleanCurrentUserData = () => ({
    type: CLEAN_CURRENT_USER_DATA,
});
