import {createSelector} from 'reselect';

const selectUsers = (state) => state.users;

const makeSelectUsersData = () =>
    createSelector(selectUsers, (data) => ({
        ...data,
    }));

const makeSelectRequestParams = () => createSelector(selectUsers, (usersState) => usersState.requestParams);

const makeSelectUserRoles = () => createSelector(selectUsers, (usersState) => usersState.userRoles);

const makeSelectUserInfo = () => createSelector(selectUsers, (usersState) => usersState.userInfo);

const makeSelectUserStatuses = () => createSelector(selectUsers, (usersState) => usersState.userStatuses);

const makeSelectUserResponsibilities = () =>
    createSelector(selectUsers, (usersState) => usersState.userResponsibilities);

const makeSelectUserResponsibilitiesData = () =>
    createSelector(selectUsers, (usersState) => usersState.userResponsibilitiesData);

const makeSelectEditedUserStatuses = () =>
    createSelector(selectUsers, (usersState) => usersState.allowedToEditStatuses);

export {
    makeSelectUsersData,
    makeSelectUserRoles,
    makeSelectRequestParams,
    makeSelectUserStatuses,
    makeSelectUserInfo,
    makeSelectEditedUserStatuses,
    makeSelectUserResponsibilities,
    makeSelectUserResponsibilitiesData,
};
