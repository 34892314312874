import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import useActionConfirmPopup from '../../hooks/useActionConfirmPopup';
import Dropdown from '../Dropdown/Dropdown';
import {IconThreeDots} from '../Icons';

const OptionsMenu = ({
    menuItems: menuItemsList,
    disabled,
    className,
    record,
    dataSource,
    menuClassName,
    triggerClassName,
    withFirstItemsAligment = false,
    itemId,
    icon = IconThreeDots,
}) => {
    const [isActive, setIsActive] = useState(false);
    const [isMouseEnter, setIsMouseEnter] = useState(false);
    const menuItems = useActionConfirmPopup(menuItemsList);

    const Icon = icon;

    useEffect(() => {
        if (!isActive || disabled) {
            setIsMouseEnter(false);
        }
    }, [isActive]);

    const onMouseLeave = () => {
        if (!isActive) {
            setIsMouseEnter(false);
        }
    };

    const firstThreeElementIds = dataSource?.slice(3).map((item) => item?.id || record?.[itemId]);
    const lastThreeElementIds = dataSource?.slice(-3).map((item) => item?.id || record?.[itemId]);
    const isOneOfLastTableMenus = lastThreeElementIds?.includes(record?.id || record?.[itemId]);
    const isOneOfFirstTableMenus = firstThreeElementIds?.includes(record?.id || record?.[itemId]);

    const menuClasses = cn(
        menuClassName,
        'w-48 rounded-[8px] px-[6px] py-2 right-2 z-50',
        (isOneOfLastTableMenus && !isOneOfLastTableMenus) || !record
            ? menuItems.length < 4
                ? '!-top-[75px]'
                : '!-top-[140px]'
            : '!top-10',
        {
            '!top-[40px]': isOneOfFirstTableMenus && !isOneOfLastTableMenus && withFirstItemsAligment,
        }
    );

    return (
        <div
            className={cn(className, 'w-full h-full hover:cursor-pointer', {['hover:cursor-not-allowed']: disabled})}
            onMouseEnter={() => setIsMouseEnter(true)}
            onMouseLeave={onMouseLeave}
        >
            <Dropdown
                disabled={disabled}
                containerClassName='w-full h-full'
                menuClassName={menuClasses}
                menuItemClassName='h-8 rounded-[6px] hover:bg-cultured hover:text-primary'
                labelClassName='text-12 ml-4 leading-[16px]'
                menu={menuItems}
                afterOpenChange={(isActive) => setIsActive(isActive)}
                trigger={
                    <div className={cn('px-2 w-full h-full flex justify-center items-center', triggerClassName)}>
                        <Icon active={isMouseEnter} disabled={disabled} />
                    </div>
                }
            />
        </div>
    );
};

export default OptionsMenu;
