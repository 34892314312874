import React from 'react';

import Icon from '../Icon/Icon';

const IconSearch = (props) => {
    return (
        <Icon width='22' height='20' viewBox='0 0 22 20' fill='none' {...props}>
            <path
                d='M18.9841 15.9571L15.8674 13.1528C16.873 11.9967 17.4186 10.569 17.4166 9.09933C17.4166 7.79044 16.9865 6.51094 16.1807 5.42263C15.3749 4.33433 14.2296 3.4861 12.8896 2.9852C11.5496 2.48431 10.0751 2.35326 8.65258 2.60861C7.23006 2.86396 5.92338 3.49425 4.8978 4.41978C3.87221 5.34531 3.17378 6.5245 2.89082 7.80825C2.60786 9.09199 2.75309 10.4226 3.30813 11.6319C3.86317 12.8411 4.8031 13.8747 6.00906 14.6019C7.21502 15.3291 8.63285 15.7172 10.0832 15.7172C11.7118 15.719 13.2938 15.2267 14.5749 14.3192L17.6824 17.1318C17.7676 17.2093 17.869 17.2709 17.9807 17.3129C18.0924 17.3549 18.2122 17.3765 18.3332 17.3765C18.4543 17.3765 18.5741 17.3549 18.6858 17.3129C18.7975 17.2709 18.8989 17.2093 18.9841 17.1318C19.07 17.0549 19.1382 16.9634 19.1847 16.8626C19.2313 16.7618 19.2552 16.6537 19.2552 16.5445C19.2552 16.4353 19.2313 16.3271 19.1847 16.2263C19.1382 16.1255 19.07 16.034 18.9841 15.9571ZM4.58324 9.09933C4.58324 8.11766 4.90581 7.15804 5.51016 6.34181C6.11451 5.52558 6.97349 4.88941 7.97849 4.51374C8.98348 4.13807 10.0893 4.03978 11.1562 4.23129C12.2231 4.4228 13.2031 4.89552 13.9723 5.58967C14.7415 6.28382 15.2653 7.16821 15.4776 8.13102C15.6898 9.09383 15.5809 10.0918 15.1646 10.9988C14.7483 11.9057 14.0434 12.6809 13.1389 13.2263C12.2344 13.7716 11.171 14.0627 10.0832 14.0627C8.62455 14.0627 7.22561 13.5398 6.19416 12.609C5.16271 11.6782 4.58324 10.4157 4.58324 9.09933Z'
                fill='#D5D6D7'
            />
        </Icon>
    );
};

export default IconSearch;
