import React from 'react';

import Icon from '../Icon/Icon';

const IconBarChartLine = (props) => {
    return (
        <Icon width='24' height='25' viewBox='0 0 24 25' fill='none' {...props}>
            <path d='M3 12.5H5V21.5H3V12.5ZM19 8.5H21V21.5H19V8.5ZM11 2.5H13V21.5H11V2.5Z' fill='black' />
        </Icon>
    );
};

export default IconBarChartLine;
