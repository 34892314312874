import * as formInputNames from '../../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import settingsService from '../../../../../services/settings/settingsService';
import {makeSelectBuildingProfile} from '../../../../../state/selectors/buildings';
import {makeSelectRoomTypes} from '../../../../../state/selectors/settings';
import {getState} from '../../../../../state/store';
import helpers from '../../../../../utils/helpers';

const getModalContent = () => {
    const {buildingID} = makeSelectBuildingProfile()(getState()) || {};

    const localizedStrings = getLocalizedStrings();

    const title = localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_TITLE];
    const cancelButton = localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_CANCEL];
    const submitButton = localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_SUBMIT];

    const initialValues = {
        buildingId: buildingID,
    };

    const formContent = [
        {
            hidden: true,
            key: helpers.guid(),
            name: formInputNames.BUILDING_ID,
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.ROOM_NUMBER,
            label: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_ROOM_NUMBER_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_ROOM_NUMBER_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.SECTION,
            label: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_SECTION_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_SECTION_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.FLOOR,
            label: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_FLOOR_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_FLOOR_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.ROOM_TYPE_ID,
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'value',
            fieldValue: 'key',
            label: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_ROOM_TYPE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_ROOM_TYPE_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.BUILDING_FORM_REQUIRED_ERROR],
            requestOptions: {
                selectRequestData: makeSelectRoomTypes,
                getRequestData: () => settingsService.getRoomTypes(),
            },
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.SQUARE,
            label: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_SQUARE_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_SQUARE_FIELD_PLACEHOLDER],
        },
        {
            key: helpers.guid(),
            required: false,
            name: formInputNames.ACCOUNT,
            label: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_ACCOUNT_NUMBER_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.ADD_BUILDING_ROOM_MODAL_ACCOUNT_NUMBER_FIELD_PLACEHOLDER],
        },
    ];

    return {
        title,
        submitButton,
        cancelButton,
        formContent,
        initialValues,
    };
};

export default {getModalContent};
