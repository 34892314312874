import React from 'react';

import Icon from '../Icon/Icon';

const IconThreeDots = ({active, disabled, props}) => {
    const fillColor = active && !disabled ? 'var(--color-black)' : '#D9D9D9';

    return (
        <Icon width='4' height='18' viewBox='0 0 4 18' fill='none' {...props}>
            <circle cx='2' cy='2' r='2' fill={fillColor} />
            <circle cx='2' cy='9' r='2' fill={fillColor} />
            <circle cx='2' cy='16' r='2' fill={fillColor} />
        </Icon>
    );
};

export default IconThreeDots;
