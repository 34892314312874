import dayjs from 'dayjs';

export const arsUnappliedOrganizationNamesRequestDataMapping = (data) => {
    const addObjectNameFromValue = (item) => ({
        ...item,
        key: item?.organizationID,
        name: item?.organization,
        label: item?.organization,
    });

    const mappedData = {
        items: [...data.unappliedOrganizations].map((item) => addObjectNameFromValue(item)),
    };

    return mappedData;
};

export const addOrganizationToArsRequestPostMapping = (data) => {
    const isApplyToAllBuildings = JSON.parse(data?.allBuildings);
    const timeOnly = dayjs(data?.local_time).format('HH:mm:ss');

    const mappedData = {
        applyToAllBuildings: isApplyToAllBuildings,
        organizationID: data?.organization,
        settings: {
            dayOfMonth: data?.start_date,
            localTime: timeOnly,
            reportProperties: {
                type: data?.report_type?.id,
                groupID: data?.device_group?.id,
            },

            source: {
                type: data?.source,
                address: data?.source_address,
                folder: data?.folder,
                login: data?.login,
                password: data?.password,
            },
        },
    };

    return JSON.stringify(mappedData);
};

export const editOrganizationToArsRequestPostMapping = (data) => {
    const timeOnly = dayjs(data?.local_time).format('HH:mm:ss');

    const mappedData = {
        organizationID: data?.organizationID,
        settings: {
            dayOfMonth: data?.start_date,
            localTime: timeOnly,
            reportProperties: {
                type: data?.report_type.id,
                groupID: data?.device_group?.id,
            },

            source: {
                type: data?.source,
                address: data?.source_address,
                folder: data?.folder,
                login: data?.login,
                password: data?.password,
            },
        },
    };

    return JSON.stringify(mappedData);
};

export const arsOrganizationsListRequestDataMapping = (data) => {
    const mappedData = {
        hasNextPage: data?.hasNextPage,
        hasPreviousPage: data?.hasPreviousPage,
        // filters: filtersMapping(data?.filters),
        items: data?.items,
        itemsInResponse: data?.itemsInResponse,
        page: data?.page,
        pages: data?.pages,
        size: data?.size,
        sortableColumns: data?.sortableColumns,
        totalFilteredRecords: data?.totalFilteredRecords,
        totalRecords: data?.totalRecords,
        query: data?.query,
        sortBy: data?.sortBy,
        direction: data?.direction,
    };

    return mappedData;
};

export const editArsBuildingsSettingsRequestPostMapping = (values, updatingBuildings) => {
    const timeOnly = dayjs(values?.local_time).format('HH:mm:ss');

    const settings = {
        dayOfMonth: values?.start_date,
        localTime: timeOnly,
        reportProperties: {
            type: values?.report_type.id,
            groupID: values?.device_group?.id,
        },
        source: {
            type: values?.source,
            address: values?.source_address,
            folder: values?.folder,
            login: values?.login,
            password: values?.password,
        },
    };

    const buildings = updatingBuildings.map((updatingBuilding) => {
        return {
            buildingID: updatingBuilding.buildingID,
            settings,
        };
    });

    return buildings;
};
