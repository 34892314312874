import React from 'react';

import Icon from '../Icon/Icon';

const IconLocationYellow = (props) => {
    return (
        <Icon width='8' height='11' viewBox='0 0 8 11' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.99924 -0.000366211C1.79322 -0.000366211 -0.000762939 1.85016 -0.000762939 4.12449C-0.000762939 7.16769 3.68942 10.7849 3.84736 10.9371C3.88975 10.9788 3.94449 10.9995 3.99924 10.9995C4.05398 10.9995 4.10873 10.9788 4.15185 10.9371C4.30832 10.7849 7.99973 7.16769 7.99973 4.12449C7.99973 1.85016 6.20477 -0.000366211 3.99924 -0.000366211ZM3.99924 6.41626C2.77378 6.41626 1.77699 5.38829 1.77699 4.12449C1.77699 2.86102 2.77378 1.83292 3.99924 1.83292C5.22469 1.83292 6.22124 2.86102 6.22124 4.12449C6.22124 5.38797 5.22469 6.41626 3.99924 6.41626Z'
                fill='#FFAE00'
            />
        </Icon>
    );
};

export default IconLocationYellow;
